import { DateFormats } from "../schema";

export const constructDateForUI = (date: Date) => {
  try {
    const splittedDate = getSplittedDate(date);
    return `${getMonthFromNumber(splittedDate[1], true)} ${splittedDate[2]}, ${splittedDate[0]}`;
  } catch (err) {
    return "";
  }
};

export const constructTimeForUi = (time: Date) => {
  try {
    const timeToProcess = new Date(time);
    let hours = timeToProcess.getHours();
    let minutes = timeToProcess.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + ampm;
    return strTime;
  } catch (err) {
    return "";
  }
};

export const getFormattedDate = (date: Date, format: string) => {
  try {
    const dateToProcess = new Date(date);
    if (format === DateFormats.DatePickerDate) {
      return new Date(dateToProcess).toISOString().split("T")[0];
    } else if (format === DateFormats.DefaultDate) {
      return constructDateForUI(dateToProcess);
    } else if (format === DateFormats.FindzFilterDate) {
      const month = getMonthFromISODateString(dateToProcess);
      const parsedDate = getDateFromISODateString(dateToProcess);
      return `${month}/${parsedDate}/${dateToProcess.getFullYear()}`;
    } else if (format === DateFormats.SearchCriteriaFromDate) {
      const splittedDate = getSplittedDate(date);
      return `${getMonthFromNumber(splittedDate[1], true)} ${splittedDate[2]}`;
    } else if (format === DateFormats.DefaultDateTime) {
      return `${constructDateForUI(dateToProcess)} ${constructTimeForUi(dateToProcess)}`;
    } else if (format === DateFormats.DefaultTime) {
      return constructTimeForUi(dateToProcess);
    } else if (format === DateFormats.EventDate) {
      const splittedDate = new Date(dateToProcess).toDateString().split(" ");
      return `${splittedDate[0]} ${splittedDate[1]} ${splittedDate[2]}, ${splittedDate[3]}`;
    }
    return constructDateForUI(dateToProcess);
  } catch (err) {
    console.log("err", err);
    return "";
  }
};

const getMonthFromNumber = (monthNo: string, returnInShortFormat: boolean = false) => {
  switch (monthNo) {
    case "01":
      return returnInShortFormat ? "Jan" : "January";
    case "02":
      return returnInShortFormat ? "Feb" : "February";
    case "03":
      return returnInShortFormat ? "Mar" : "March";
    case "04":
      return returnInShortFormat ? "Apr" : "April";
    case "05":
      return returnInShortFormat ? "May" : "May";
    case "06":
      return returnInShortFormat ? "Jun" : "June";
    case "07":
      return returnInShortFormat ? "Jul" : "July";
    case "08":
      return returnInShortFormat ? "Aug" : "August";
    case "09":
      return returnInShortFormat ? "Sep" : "September";
    case "10":
      return returnInShortFormat ? "Oct" : "October";
    case "11":
      return returnInShortFormat ? "Nov" : "November";
    case "12":
      return returnInShortFormat ? "Dec" : "December";
    default:
      return "";
  }
};

const getMonthFromISODateString = (date: Date) => {
  const splittedDate = getSplittedDate(date);
  return splittedDate[1];
};

const getDateFromISODateString = (date: Date) => {
  const splittedDate = getSplittedDate(date);
  return splittedDate[2];
};

const getSplittedDate = (date: Date) => {
  const dateToProcess = new Date(date);
  return dateToProcess.toISOString().split("T")[0].split("-");
};

export const addInDate = function (date: Date, count: number, propertyToChage: string) {
  const datetoProcess = date ? new Date(date) : new Date();
  if (propertyToChage === "days") {
    datetoProcess.setDate(datetoProcess.getDate() + count);
  } else if (propertyToChage === "hours") {
    datetoProcess.setHours(datetoProcess.getHours() + count);
  } else if (propertyToChage === "minutes") {
    datetoProcess.setMinutes(datetoProcess.getMinutes() + count);
  } else if (propertyToChage === "year") {
    datetoProcess.setFullYear(datetoProcess.getFullYear() + count);
  }
  return datetoProcess;
};

export const subtractInDate = function (date: Date, count: number, propertyToChage: string) {
  const datetoProcess = date ? new Date(date) : new Date();
  if (propertyToChage === "days") {
    datetoProcess.setDate(datetoProcess.getDate() - count);
  } else if (propertyToChage === "year") {
    datetoProcess.setFullYear(datetoProcess.getFullYear() - count);
  }
  return datetoProcess;
};

export const getMomentUnixTime = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const getDateAndTime = (date: Date) => {
  return `${constructDateForUI(date)} ${constructTimeForUi(date)}`;
};

const constructDateForEventReschedule = (date: Date) => {
  const splittedDate = date.toString().split(" ");
  return `${splittedDate[0]}, ${splittedDate[1]} ${splittedDate[2]}, ${splittedDate[3]}`;
};

export const getDateAndTimeForEventReschedule = (date: Date) => {
  return `${constructDateForEventReschedule(date)} ${constructTimeForUi(date)}`;
};
