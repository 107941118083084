import { CommonActions, EventActions, GroupActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { IEvent } from "common-library/lib/schema";

interface IEventState {
  eventsMap: Map<string, IEvent>;
  indexFindToEvents: Map<string, Set<string>>;
}

const defaultEventState: IEventState = {
  eventsMap: new Map(),
  indexFindToEvents: new Map()
};

export const EventLabel = "Event";

export const eventReducer = createReducer(defaultEventState, builder => {
  builder
    .addCase(EventActions.setEventsMap, (state, action) => {
      state.eventsMap = action.payload.eventsMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultEventState;
    })
    .addCase(EventActions.setIndexFindToEvents, (state, action) => {
      state.indexFindToEvents = action.payload.indexFindToEvents;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.eventsMap = new Map();
    });
});
