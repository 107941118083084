import { fork, call, take, takeLatest, select, put, delay, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { MediaActions, CommonActions } from "../actions";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { insertBulkIntoMediaDb, FindzMediaTables, getById } from "../../utils/indexdb";
import { findzServices } from "../../services";
import { getActiveContext, getCurrentUser, getDraftMedia, getFindzMap, getGeoLocationPosition, getMediaIdsUploading } from "../selectors";
import { FindzType, MediaDbType, FindzDbType, GeolocationPositionType, UserType } from "common-library/lib/schema";
import { DraftFindMediaType, IActiveContext, IUploadMediaType } from "../../types/types";
import { appConstants, PublishActions } from "common-library/lib/constants";
import { mediaServices } from "../../services";
import { buffers, eventChannel } from "redux-saga";
import PubSub from "pubsub-js";
import { chunk, groupBy, isEmpty, map } from "lodash";
import { ICacheMedia } from "../../types/cacheMediaTypes";
import { findzUtils } from "../../utils/findzUtils";

export function* cacheFindsMediaSaga(action: ActionType<typeof MediaActions.cacheFindsMedia>) {
  try {
    const { addedList = [], modifiedList = [], removedList = [] } = action.payload;

    const mediaToCache: ICacheMedia[] = [];
    const mediaToRemoveFromCache: ICacheMedia[] = [];

    for (const data of addedList) {
      for (const media of Object.values(data.media?.mediaList || {})) {
        if (media && media.identifier) {
          if (media.preview?.cloudStorageUrl) {
            mediaToCache.push({ url: media.preview?.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier, type: "Preview" });
          } else if (media.cloudStorageUrl) {
            mediaToCache.push({ url: media.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier, type: "Orginal" });
          }
        }
      }
    }

    for (const data of removedList) {
      const existingFind: FindzDbType | undefined = yield call(getById, FirestoreCollectionEnum.Findz, data.identifier);
      for (const media of Object.values(existingFind?.media.mediaList || {})) {
        if (media && media.identifier) {
          if (media.preview?.cloudStorageUrl) {
            mediaToRemoveFromCache.push({ url: media.preview?.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier });
          }
          if (media.cloudStorageUrl) {
            mediaToRemoveFromCache.push({ url: media.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier });
          }
        }
      }
    }

    for (const data of modifiedList) {
      const existingFind: FindzDbType | undefined = yield call(getById, FirestoreCollectionEnum.Findz, data.identifier);
      if (existingFind) {
        const existingMediaList = Object.keys(existingFind?.media?.mediaList || {});
        const newMediaList = Object.keys(data.media?.mediaList || {});

        for (const mediaId of existingMediaList) {
          if (!newMediaList.includes(mediaId)) {
            const mediaList = existingFind?.media?.mediaList || {};
            const media = mediaList[`${mediaId}`];
            if (media && media.identifier) {
              if (media.preview?.cloudStorageUrl) {
                mediaToRemoveFromCache.push({ url: media.preview?.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier });
              }
              if (media.cloudStorageUrl) {
                mediaToRemoveFromCache.push({ url: media.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier });
              }
            }
          }
        }

        for (const mediaId of newMediaList) {
          const media = data.media.mediaList[mediaId];
          const oldMedia = existingFind.media.mediaList[mediaId];
          if (!oldMedia && media && media.identifier) {
            if (media.preview?.cloudStorageUrl) {
              mediaToCache.push({ url: media.preview?.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier, type: "Preview" });
            } else if (media.cloudStorageUrl) {
              mediaToCache.push({ url: media.cloudStorageUrl, findId: data.identifier, mediaId: media.identifier, type: "Orginal" });
            }
          }
        }
      }
    }

    mediaServices.downloadMediaInWorker(mediaToCache, mediaToRemoveFromCache);
  } catch (error) {
    console.error("cacheFindsMediaSaga", error);
  }
}

export function* removeDraftMediaFromIndexedDbSaga(action: ActionType<typeof MediaActions.removeDraftMedia>) {
  try {
    const { mediaId } = action.payload;
    const draftMediaList: string[] = yield select(getDraftMedia);
    const updatedList: typeof draftMediaList = draftMediaList.filter(item => item !== mediaId);
    yield call(mediaServices.removeDraftMedia, [mediaId]);
    yield put(MediaActions.setDraftMedia({ draftMedia: updatedList }));
  } catch (error) {
    console.error("removeDraftMediaFromIndexedDbSaga", error);
  }
}

export function* saveDraftMediaToIndexedDBSaga(action: ActionType<typeof MediaActions.saveDraftMedia.saga>) {
  try {
    yield put(MediaActions.saveDraftMedia.start());
    const { fileList, context } = action.payload;
    const filesArray = Object.values(fileList);
    const activeContext: IActiveContext | undefined = context !== undefined ? context : yield select(getActiveContext);

    if (activeContext) {
      const draftMediaList: string[] = yield select(getDraftMedia);
      const mappedArray: DraftFindMediaType[] = yield call(mediaServices.generateMediaDataForFiles, filesArray, activeContext);

      //contentType, extensionType
      const draftFinds = mappedArray.map(item => item.identifier);

      yield put(MediaActions.setDraftMedia({ draftMedia: draftMediaList.concat(draftFinds) }));
      yield call(insertBulkIntoMediaDb, FindzMediaTables.DraftContent, mappedArray);
    }
    yield put(MediaActions.saveDraftMedia.success());
  } catch (error: any) {
    console.error("saveDraftMediaToIndexedDBSaga", error);
    yield put(MediaActions.saveDraftMedia.error(error));
  }
}

export function* clearDraftMediaFromIndexedDBSaga(action: ActionType<typeof MediaActions.clearDraftMedia>) {
  try {
    yield call(mediaServices.clearDraftMedia);
  } catch (error) {
    console.error("clearDraftMediaFromIndexedDBSaga", error);
  }
}

export function* startMediaUploadSaga(action: ActionType<typeof MediaActions.startMediaUpload>) {
  try {
    const imageList: IUploadMediaType[] = yield call(mediaServices.getMediaToUpload);
    if (imageList.length) {
      const mediaIds = imageList.map(image => image.identifier);
      yield put(MediaActions.updateUploadMediaQueueIds({ mediaIds: new Set(mediaIds) }));
      PubSub.publish(PublishActions.IMAGE_UPLOAD_REQUEST, imageList);
    }
  } catch (error) {
    console.error("startMediaUploadSaga", error);
  }
}

export function* mediaUploadQueueSaga(action: ActionType<typeof MediaActions.watchMediaUploadQueue>) {
  try {
    const channel = eventChannel((emitter: any) => {
      const subscribe = PubSub.subscribe(PublishActions.IMAGE_UPLOAD_REQUEST, (message: any, imageList: IUploadMediaType[]) => {
        const findGroup = Object.values(groupBy(imageList, (singleData: IUploadMediaType) => singleData.findId));
        findGroup.forEach((singleGroup: any) => {
          const chunkedImages = chunk(singleGroup, appConstants.maxImagesPerFind);
          for (let i = 0; i < chunkedImages.length; i++) {
            emitter(chunkedImages[i]);
          }
        });
      });
      return () => {
        PubSub.unsubscribe(subscribe);
      };
    }, buffers.expanding(100) as any);

    while (true) {
      const imageList: any[] = yield take(channel);

      if (navigator.onLine && imageList.length) {
        const currentUser: UserType = yield select(getCurrentUser);

        const {
          response,
          uploadedMedia
        }: {
          response: { completed: boolean; media?: IUploadMediaType; error?: any }[];
          uploadedMedia: Map<string, { uploadedSize: number; noOfFilesUploaded: number }>;
          error: any;
        } = yield call(mediaServices.uploadFindImages, imageList, currentUser);

        if (response) {
          const findsMap: Map<string, FindzType> = yield select(getFindzMap);
          const findzMap: { [key: string]: FindzType } = {};
          const findzMapForOtherGroups: { [key: string]: { [key: string]: IUploadMediaType } } = {};
          const imagesToDelete: IUploadMediaType[] = [];
          const mediaToRemoveFromQueue: string[] = [];
          const mediaIdsUploading: Set<string> = yield select(getMediaIdsUploading);
          const updatedMediaIdsUploading = new Set(mediaIdsUploading);
          response.map(item => {
            if (item.completed) {
              const image = item.media;
              if (image) {
                mediaToRemoveFromQueue.push(image.identifier);
                updatedMediaIdsUploading.delete(image.identifier);
                const currentFind: FindzType | undefined = findsMap.get(image.findId);

                if (currentFind) {
                  const included = Object.values(currentFind.media.mediaList).find(singleMedia => singleMedia.identifier === image.identifier);
                  if (included) {
                    if (image.cloudStorageUrl) {
                      const partialObj: any = findzMap[image.findId] || {};
                      partialObj[`media.mediaList.${image.identifier}.cloudStorageUrl`] = image.cloudStorageUrl;

                      if (image.previewUrl) {
                        partialObj[`media.mediaList.${image.identifier}.preview`] = { cloudStorageUrl: image.previewUrl };
                      }

                      findzMap[image.findId] = partialObj;
                    }
                  } else {
                    imagesToDelete.push(image);
                  }
                } else {
                  findzMapForOtherGroups[image.findId] = {
                    ...findzMapForOtherGroups[image.findId],
                    [image.identifier]: image
                  };
                }
              } else {
                //log the error of that media
              }
            }
          });
          yield delay(2000);
          try {
            const updateFindPromisesForGroups = map(findzMapForOtherGroups, async (findMediaList, findId) => {
              const findData = await findzServices.getFindById(findId);
              const mediaListArray = Object.keys(findData?.media?.mediaList || []);
              const updateFindObject: any = {};
              await Promise.all(
                map(findMediaList, async media => {
                  const mediaAvailability = mediaListArray.find((mediaId: string) => mediaId === media.identifier);
                  if (mediaAvailability) {
                    updateFindObject[`media.mediaList.${media.identifier}.cloudStorageUrl`] = media.cloudStorageUrl;
                    if (media.previewUrl) {
                      updateFindObject[`media.mediaList.${media.identifier}.preview`] = { cloudStorageUrl: media.previewUrl };
                    }
                  } else {
                    imagesToDelete.push(media);
                  }
                })
              );
              const data = await findzServices.updateFindDetails(findId, updateFindObject, [], [], ["_documentUpdatedOn"]);
            });

            const updateFindPromises = map(findzMap, async (find, findId) => {
              // update urls found at and created at address from here as
              // well because in firebase we have a restriction for updating a
              // data only once in 1 second so if simultaneous requests are made t update location
              // address and media url in the document from the two queues then there can be a raise
              // case where one of the request can be dropped if made for the same document
              // const updatedFindObject: any = { identifier: find.identifier, media: { ...find.media }, owner: { ...find.owner } };

              await findzServices.updateFindDetails(findId, findzUtils.convertFindToDbType(find), [], [], ["_documentUpdatedOn"]);
            });
            const imagesToDeletePromises = map(imagesToDelete, async (singleImage: IUploadMediaType) => {
              await mediaServices.deleteFindImage(singleImage.identifier);
            });
            yield Promise.all(updateFindPromisesForGroups);
            yield Promise.all(updateFindPromises);
            yield Promise.all(imagesToDeletePromises);
            yield put(MediaActions.updateUploadMediaQueueIds({ mediaIds: updatedMediaIdsUploading }));
            yield call(mediaServices.removeMediaFromMediaQueue, mediaToRemoveFromQueue);
          } catch (error) {
            console.debug("error", error);
            // const revertedQueue = [...imageQueue, ...imageList];
            // yield put(FindzActions.uploadOfflineImagesSuccess(revertedQueue));
            yield delay(400);
            // yield put(MediaActions.startMediaUpload());
          }
          if (uploadedMedia.size) {
            // for (const [groupId, mediaUploaded] of Array.from(uploadedMedia.entries())) {
            //   if (mediaUploaded.noOfFilesUploaded)
            // }
          }
        } else {
          // yield put(MediaActions.startMediaUpload());
        }
      }
    }
  } catch (error) {
    console.error("mediaUploadQueueSaga", error);
  }
}

export function* addMediaToFindSaga(action: ActionType<typeof MediaActions.addMediaToFind>) {
  const { currentFind, extractExif, selectedLocation, selectedMedia } = action.payload;
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    const draftMediaArr: DraftFindMediaType[] = [];
    if (selectedMedia && selectedMedia.length) {
      const _draftMedia: DraftFindMediaType[] = yield call(mediaServices.getAndClearDraftMedia);
      _draftMedia.forEach(item => {
        if (selectedMedia && selectedMedia.find((id: string) => id === item.identifier)) {
          draftMediaArr.push(item);
        }
      });
    }
    const currentUser: UserType = yield select(getCurrentUser);
    const geoLocationPosition: GeolocationPositionType = yield select(getGeoLocationPosition);
    const dataForUploadQueue: any[] = [];

    const newMediaData: Map<string, MediaDbType> = yield call(
      findzServices.getMediaDetails,
      draftMediaArr,
      currentUser,
      extractExif,
      geoLocationPosition,
      undefined,
      selectedLocation
    );

    yield call(mediaServices.addNewMediaToFind, currentFind as any, newMediaData);

    for (const draftMedia of draftMediaArr) {
      dataForUploadQueue.push({
        ...draftMedia,
        findId: currentFind.identifier,
        findCreatedOn: currentFind.created?.on || new Date(),
        groupId: activeContext.identifier
      });
    }

    yield call(mediaServices.putMediaIntoUploadQueue, dataForUploadQueue);
    yield put(MediaActions.startMediaUpload());
    yield put(CommonActions.toggleShowAddMediaToFindModal());
  } catch (error) {
    console.log("error", error);
    yield put(CommonActions.toggleShowAddMediaToFindModal());
  }
}

export function* deleteFindMediaSaga(action: ActionType<typeof MediaActions.deleteFindMedia.saga>) {
  try {
    const { findId, mediaIds } = action.payload;
    yield put(MediaActions.deleteFindMedia.start());
    const findsMap: Map<string, FindzType> = yield select(getFindzMap);
    const find = findsMap.get(findId);
    const findMedia = find?.media;

    if (find && findMedia) {
      yield call(mediaServices.deleteFindMedia, findId, mediaIds, findsMap);
      yield call(mediaServices.deleteFindMediaAndPreviewStorage, mediaIds, findMedia);
    }
    yield put(MediaActions.deleteFindMedia.success());
  } catch (error) {
    console.log("error", error);
    yield put(MediaActions.deleteFindMedia.error(error as any));
  }
}

function* cacheFindsMediaListener() {
  yield takeEvery(MediaActions.cacheFindsMedia.toString(), cacheFindsMediaSaga);
}

function* removeDraftMediaFromIndexedDbListener() {
  yield takeLatest(MediaActions.removeDraftMedia.toString(), removeDraftMediaFromIndexedDbSaga);
}

function* mediaUploadQueueListener() {
  yield takeLatest(MediaActions.watchMediaUploadQueue.toString(), mediaUploadQueueSaga);
}

function* clearDraftMediaFromIndexedDBListener() {
  yield takeLatest(MediaActions.clearDraftMedia.toString(), clearDraftMediaFromIndexedDBSaga);
}

function* saveDraftMediaToIndexedDBListener() {
  yield takeLatest(MediaActions.saveDraftMedia.saga.toString(), saveDraftMediaToIndexedDBSaga);
}

function* startMediaUploadListener() {
  yield takeLatest(MediaActions.startMediaUpload.toString(), startMediaUploadSaga);
}

function* deleteFindMediaListener() {
  yield takeLatest(MediaActions.deleteFindMedia.saga.toString(), deleteFindMediaSaga);
}

function* addMediaToFindListener() {
  yield takeLatest(MediaActions.addMediaToFind.toString(), addMediaToFindSaga);
}

export default function* mediaSaga() {
  yield fork(cacheFindsMediaListener);
  yield fork(saveDraftMediaToIndexedDBListener);
  yield fork(clearDraftMediaFromIndexedDBListener);
  yield fork(removeDraftMediaFromIndexedDbListener);
  yield fork(mediaUploadQueueListener);
  yield fork(startMediaUploadListener);
  yield fork(deleteFindMediaListener);
  yield fork(addMediaToFindListener);
}
