import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../config/firebaseApp";

export async function getCloudMessagingToken() {
  try {
    const { REACT_APP_VAPID_KEY } = process.env;
    const publicKey = REACT_APP_VAPID_KEY;
    if (Notification.permission === "granted") {
      const token = await getToken(messaging, { vapidKey: publicKey });
      return token;
    }
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, { vapidKey: publicKey });
      return token;
    }
    return "";
  } catch (error) {
    console.error("error", error);
    return "";
  }
}

export function onMessageListener() {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
}
