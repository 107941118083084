import { collectionMasks, IDocumentSecured, maskIDocumentSecured } from "./commonTypes";

export interface VendorType extends VendorFormType, IDocumentSecured {
  created: {
    on: Date;
    by: string;
  };
  groupId?: string;
}

export interface VendorFormType {
  phoneNumber: {
    countryCode: string;
    callingCode: string;
    number: string;
  };
  address: {
    name?: string;
    street: string;
    city: string;
    region: string;
    countryName: string;
    postalCode: string;
    countryCode: string;
  };
  businessName: string;
  firstName: string;
  lastName: string;
  email: string;
  website: string;
  notes: string;
}

export const maskVendorFormType = {
  phoneNumber: {
    countryCode: true,
    callingCode: true,
    number: true,
  },
  address: {
    name: true,
    street: true,
    city: true,
    region: true,
    countryName: true,
    postalCode: true,
    countryCode: true,
  },
  businessName: true,
  firstName: true,
  lastName: true,
  email: true,
  website: true,
  notes: true,
};

Object.freeze(maskVendorFormType);

export const maskVendorType = {
  ...maskIDocumentSecured,
  ...maskVendorFormType,
  created: {
    on: true,
    by: true,
  },
  groupId: true,
};
Object.freeze(maskVendorType);

collectionMasks["vendors"] = maskVendorType;
