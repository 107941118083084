import { LoginMedium, NotificationType, UserType } from "common-library/lib/schema";
import { CreateAccountType } from "../../services/auth.service";
import { asyncActionCreator, actionCreator } from "../helper";
import { PhoneType, VerifyOtpType, SignInMethodsType } from "../../types/types";
import { NotificationSubscriptionAction, NotificationTopicType } from "common-library/lib/constants";

enum AuthAsyncActionTypes {
  VALIDATE_USER = "AUTH/VALIDATE_USER",
  LOGIN_WITH_MEDIUM = "AUTH/LOGIN_WITH_MEDIUM",
  CREATE_ACCOUNT = "AUTH/CREATE_ACCOUNT",
  RESET_PASSWORD = "AUTH/RESET_PASSWORD",
  SEND_CUSTOM_TOKEN = "AUTH/SEND_CUSTOM_TOKEN",
  CLOSE_ACCOUNT = "AUTH/CLOSE_ACCOUNT",
  SEND_OTP = "AUTH/SEND_OTP",
  VERIFY_OTP = "AUTH/VERIFY_OTP",
  RESEND_OTP = "AUTH/RESEND_OTP",
  LISTEN_CURRENT_USER = "AUTH/LISTEN_CURRENT_USER",
  SIGN_OUT = "AUTH/SIGN_OUT",
  SET_SIGN_IN_METHODS_FOR_EMAIL = "AUTH/SET_SIGN_IN_METHODS_FOR_EMAIL",
  SUBSCRIBE_TO_NOTIFICATION = "AUTH/NOTIFICATION/SUBSCRIBE",
  "UNBLOCK_USER" = "UNBLOCK_USER",
  RESEND_VERIFICATION_MAIL = "AUTH/RESEND_VERIFICATION_MAIL"
}

enum AuthSyncActionTypes {
  ACTIVE_CONTEXT = "AUTH/SET_ACTIVE_CONTEXT",
  CURRENT_USER = "AUTH/CURRENT_USER",

  SIGN_IN_METHODS_FOR_EMAIL = "AUTH/SIGN_IN_METHODS_FOR_EMAIL",
  LOGIN_MEDIUM = "AUTH/LOGIN_MEDIUM",
  "SET_NOTIFICATION_SUBSCRIPTIONS" = "AUTH/SET/NOTIFICATION_SUBSCRIPTION"
}

enum AuthListenerActionTypes {
  LISTEN_CURRENT_USER = "AUTH/LISTEN_CURRENT_USER"
}

// Async action

export const validateUser = asyncActionCreator(AuthAsyncActionTypes.VALIDATE_USER);

export const createAccount = asyncActionCreator<undefined, CreateAccountType>(AuthAsyncActionTypes.CREATE_ACCOUNT);

export const sendCustomToken = asyncActionCreator<
  undefined,
  {
    user: UserType;
    publicKey: string;
  }
>(AuthAsyncActionTypes.SEND_CUSTOM_TOKEN);

export const loginWithMedium = asyncActionCreator<
  undefined,
  {
    loginMedium: LoginMedium;
    credentials?: { email: string; password: string };
  }
>(AuthAsyncActionTypes.LOGIN_WITH_MEDIUM);

export const closeAccount = asyncActionCreator<undefined, { userId: string }>(AuthAsyncActionTypes.CLOSE_ACCOUNT);

export const verifyOtp = asyncActionCreator<undefined, VerifyOtpType>(AuthAsyncActionTypes.VERIFY_OTP);

export const sendOtp = asyncActionCreator<undefined, { phone: PhoneType }>(AuthAsyncActionTypes.SEND_OTP);

export const resendOtp = asyncActionCreator<undefined, { phone: PhoneType }>(AuthAsyncActionTypes.RESEND_OTP);

export const resetPassword = asyncActionCreator<undefined, { email: string }>(AuthAsyncActionTypes.RESET_PASSWORD);

export const signOut = asyncActionCreator<undefined, { force: boolean }>(AuthAsyncActionTypes.SIGN_OUT);

export const subscribeToPushNotification = asyncActionCreator<
  undefined,
  { type: NotificationSubscriptionAction.signIn; groupId?: string; userId: string; notificationType?: NotificationTopicType }
>(AuthAsyncActionTypes.SUBSCRIBE_TO_NOTIFICATION);

// Sync action

export const setLoginMedium = actionCreator<LoginMedium>(AuthSyncActionTypes.LOGIN_MEDIUM);

export const setCurrentUser = actionCreator<UserType>(AuthSyncActionTypes.CURRENT_USER);

export const signInMethodsForEmail = actionCreator<SignInMethodsType | undefined>(AuthAsyncActionTypes.SET_SIGN_IN_METHODS_FOR_EMAIL);

// listener action
export const listenToCurrentUser = actionCreator<{ userId: string }>(AuthListenerActionTypes.LISTEN_CURRENT_USER);

export const updateUser = asyncActionCreator<undefined, { user: Partial<UserType> }>(AuthAsyncActionTypes.UNBLOCK_USER);
export const resendVerificationMail = asyncActionCreator<undefined, { user: UserType }>(AuthAsyncActionTypes.RESEND_VERIFICATION_MAIL);

export const setNotificationSubscription = asyncActionCreator<
  undefined,
  {
    type: NotificationSubscriptionAction;
    fcmToken?: string;
    groupId?: string;
    notificationType?: NotificationTopicType;
  }
>(AuthSyncActionTypes.SET_NOTIFICATION_SUBSCRIPTIONS);
