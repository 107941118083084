import { IActiveContext } from "../../types/types";
import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
// import { ActivityVerb, GroupType } from "shared-web/lib/schemas";
import { GroupStatus, InvitationType, GroupType, GroupMemberDbType, ActivityVerb, InvitationActivityType } from "common-library/lib/schema";

import { getMruList } from "./commonSelectors";
import { orderBy, uniqBy } from "lodash";
import { getCurrentUser } from "./authSelectors";
import { getActivitiesMap } from "./activitySelector";

const _getProcessActiveContext = (state: AppState) => state.Group.processActiveContext;

export const getProcessActiveContext = createDraftSafeSelector(_getProcessActiveContext, payload => payload);

//#region From Reducer
const _getActiveContext = (state: AppState): IActiveContext | undefined => state.Group.activeContext;
//#endregion

//#region Memoized Selector
export const getActiveContext = createDraftSafeSelector(_getActiveContext, payload => payload);
//#endregion

//#region from reducer
const _getGroupsMap = (state: AppState): Map<string, GroupType> => state.Group.groupsMap;
//#endregion

//#region Memoized Selector
export const getGroupsMap = createDraftSafeSelector(_getGroupsMap, payload => payload);
//#endregion

const _getPendingInvites = (state: AppState) => state.Group.pendingInvites;

export const getPendingInvites = createDraftSafeSelector(_getPendingInvites, payload => payload);

export const getCurrentPendingInvites = createDraftSafeSelector(
  [_getPendingInvites, getActivitiesMap, getCurrentUser],
  (invitations, activitiesMap, currentUser) => {
    const newMap: Map<string, InvitationType> = new Map();
    const pendinggroups = Object.entries(currentUser?.groups || {})
      .filter(([key, value]) => value.status === GroupStatus.pending)
      .map(([key, value]) => {
        return { identifier: key, ...value };
      });
    const inviteActivities = Array.from(activitiesMap.values())
      .filter(i => i.verb === ActivityVerb.GroupInvite)
      .filter(
        i => i.verb === ActivityVerb.GroupInvite && (i as InvitationActivityType).object.invitation.invitee === currentUser?.identifier
      ) as InvitationActivityType[];
    if (pendinggroups.length) {
      pendinggroups.forEach(group => {
        invitations.forEach(i => {
          if (i.group.identifier === group.identifier && inviteActivities.find(v => v.object.invitation.invitationId === i.identifier)) {
            newMap.set(i.identifier, i);
          }
        });
      });
    }

    return newMap;
  }
);

//#region Memoized Selector
export const getGroupList = createDraftSafeSelector([_getGroupsMap, getMruList], (payload, mruList) => {
  const newGrupList: GroupType[] = [];
  if (mruList?.groupList) {
    for (const groupId of mruList.groupList) {
      const grpData = payload.get(groupId);
      if (grpData) {
        newGrupList.push(grpData);
      }
    }
  }
  const sortedGrupList = orderBy(Array.from(payload.values()), ["name"], "asc");
  return uniqBy(newGrupList.concat(sortedGrupList), item => item.identifier);
});
//#endregion

//#region from reducer
const _getGroupsMembersList = (state: AppState): GroupMemberDbType[] | undefined => state.Group.groupMembersList;
//#endregion

//#region Memoized Selector
export const getGroupsMembersList = createDraftSafeSelector(_getGroupsMembersList, payload => payload);
//#endregion

export const getGroupMembersMap = createDraftSafeSelector(_getGroupsMembersList, payload => {
  const map = new Map<string, GroupMemberDbType>();
  payload?.forEach(member => {
    map.set(member.identifier, member);
  });
  return map;
});

const _currentPublicUserInfo = (state: AppState) => state.Group.currentPublicUserInfo;

export const getCurrentPublicUserInfo = createDraftSafeSelector(_currentPublicUserInfo, payload => payload);

const _fetchUserInfo = (state: AppState) => state.Group.fetchUserInfo;

export const fetchUserInfo = createDraftSafeSelector(_fetchUserInfo, payload => payload);

const _getAddMemberToGroup = (state: AppState) => state.Group.addGroupMember;

export const getAddMemberToGroup = createDraftSafeSelector(_getAddMemberToGroup, payload => payload);

const _getDeleteGroup = (state: AppState) => state.Group.deleteGroup;

export const getDeleteGroup = createDraftSafeSelector(_getDeleteGroup, payload => payload);

const _getRemoveMemberFromGroup = (state: AppState) => state.Group.removeMemberFromGroup;

export const getRemoveMemberFromGroup = createDraftSafeSelector(_getRemoveMemberFromGroup, payload => payload);

const _getExitGroup = (state: AppState) => state.Group.exitGroup;

export const getExitGroup = createDraftSafeSelector(_getExitGroup, payload => payload);

const _createNewGroup = (state: AppState) => state.Group.createNewGroup;

export const getCreateNewGroup = createDraftSafeSelector(_createNewGroup, payload => payload);

const _respondToInvitation = (state: AppState) => state.Group.respondToInvitation;

export const getRespondToInvitation = createDraftSafeSelector(_respondToInvitation, payload => payload);

const _updateGroupInfo = (state: AppState) => state.Group.updateGroupInfo;

export const updateGroupInfo = createDraftSafeSelector(_updateGroupInfo, payload => payload);
