import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ValidateUser from "./app/containers/ValidateUser";
import { AuthGuard, GuardRoute } from "./app/GSRoutes";
import { NavigationPath } from "./app/constants/router.names";
import HeaderAndFooter from "./app/containers/HeaderAndFooterContainer/HeaderAndFooter";

import GSLoader from "./app/components/GSLoader/GSLoader";
import NotFound from "./app/containers/NotFound/NotFound";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./config/theme";
import { useAppDispatch, useAppSelector } from "./app/redux/hooks";
import { getAppTheme, getIsMobile } from "./app/redux/selectors";
import { getCustomizedThemeObject } from "./app/utils/themeUtils";
import { GSMobileRoutes } from "./app/mobile/GSMobileRoutes";
import ContainerWrapper from "./app/mobile/components/ContainerWrapper/ContainerWrapper";
import { CommonActions } from "./app/redux/actions";

//common
const AuthContainer = lazy(() => import("./app/containers/AuthContainer/AuthContainer"));
const EmailLoginContainer = lazy(() => import("./app/containers/EmailLoginContainer/EmailLoginContainer"));
const CreateAccountContainer = lazy(() => import("./app/containers/CreateAccountContainer/CreateAccountContainer"));
const ResetPasswordContainer = lazy(() => import("./app/containers/ResetPasswordContainer/ResetPasswordContainer"));
const PhoneNumberContainer = lazy(() => import("./app/containers/PhoneNumberContainer/PhoneNumberContainer"));
const PhoneVerificationContainer = lazy(() => import("./app/containers/PhoneVerificationContainer/PhoneVerificationContainer"));
const ExtensionLoginSuccessContainer = lazy(() => import("./app/containers/ExtensionLoginSuccessContainer"));
const ProfileCompletionContainer = lazy(() => import("./app/containers/ProfileCompletionContainer"));

//desktop
const HomeContainer = lazy(() => import("./app/containers/HomeContainer/HomeContainer"));

//mobile
const MobileMapContainer = lazy(() => import("./app/mobile/containers/FindzMapContainer/FindzMapContainer"));
const MobileFindDetailScreen = lazy(() => import("./app/mobile/containers/FindzDetailContainer/FindzDetailContainer"));
const MobileHomeContainer = lazy(() => import("./app/mobile/containers/HomeContainer/HomeContainer"));
const MobileFindzContainer = lazy(() => import("./app/mobile/containers/FindzContainer/FindzContainer"));
const MobileMessageContainer = lazy(() => import("./app/mobile/containers/MessageContainer/MessageContainer"));
const MobileTagsContainer = lazy(() => import("./app/mobile/containers/TagsContainer/TagsContainer"));
const MobileTagsDetailContainer = lazy(() => import("./app/mobile/containers/TagDetailContainer/TagDetailContainer"));
const MobileProfileContainer = lazy(() => import("./app/mobile/containers/MobileProfileContainer/MobileProfileContainer"));
const MobileShareContainer = lazy(() => import("./app/mobile/containers/ShareContainer/ShareContainer"));
const MobileMoreContainer = lazy(() => import("./app/mobile/containers/MoreContainer/MoreContainer"));
const MobileMediaDetailsContainer = lazy(() => import("./app/mobile/containers/MediaDetailsContainer/MediaDetailContainer"));
const AddNewGroupContainer = lazy(() => import("./app/mobile/containers/AddNewGroupContainer/AddNewGroupContainer"));
const MobileMultiSelectFindContainer = lazy(() => import("./app/mobile/containers/MultiSelectContainer/FindMultiSelectContainer"));
const AddFindsToCollectionContainer = lazy(() => import("./app/mobile/containers/AddFindsToCollectionContainer/AddFindsToCollectionContainer"));
const UserStatsContainer = lazy(() => import("./app/mobile/containers/UserStatsContainer/UserStatsContainer"));
const MessageDetailScreen = lazy(() => import("./app/mobile/containers/MessageDetailScreen/MessageDetailScreen"));
const BlockedUsersContainer = lazy(() => import("./app/mobile/containers/BlockedUsersContainer/BlockedUsersContainer"));
const ForwardedMessageDetailScreen = lazy(() => import("./app/mobile/containers/ForwardedMessageDetailScreen/ForwardedMessageDetailScreen"));

function App() {
  const appTheme = useAppSelector(getAppTheme);
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(getIsMobile);

  useEffect(() => {
    const hasMobile = navigator.userAgent.indexOf("Mobi"); // -1 if not found
    if (hasMobile >= 0) {
      dispatch(CommonActions.toggleIsMobile({ isMobile: true }));
    }
  }, [dispatch]);

  // if ("virtualKeyboard" in navigator) {
  //   // @ts-ignore
  //   navigator.virtualKeyboard.overlaysContent = true;

  //   // @ts-ignore
  //   navigator.virtualKeyboard.addEventListener("geometrychange", event => {
  //     const { height } = event.target.boundingRect;
  //     const element = document.getElementById("root");
  //     const containerWrapper = document.getElementById("container-wrapper");
  //     const modal = document.getElementById("gs-dialog");
  //     const muiModal = document.querySelectorAll(".MuiModal-root");
  //     const createFindModal = document.getElementById("create-find-modal");
  //     const backDrops = document.querySelectorAll(".MuiBackdrop-root");

  //     if (createFindModal) {
  //       createFindModal.style.height = `calc(100dvh - ${height}px)`;
  //     }
  //     muiModal.forEach(m => {
  //       // @ts-ignore
  //       m.style.height = `calc(100dvh - ${height}px)`;
  //       // @ts-ignore
  //       m.style.margin = 0;
  //     });

  //     backDrops.forEach(m => {
  //       // @ts-ignore
  //       m.style.height = `calc(100dvh - ${height}px)`;
  //       // @ts-ignore
  //       m.style.margin = 0;
  //     });
  //     if (modal) {
  //       modal.style.height = `calc(100dvh - ${height}px)`;
  //     }
  //     if (element) {
  //       element.style.height = `calc(100dvh - ${height}px)`;
  //     }
  //     if (containerWrapper) {
  //       containerWrapper.style.height = `calc(100dvh - ${height}px)`;
  //     }
  //   });
  // }
  const savedPaletteValue = localStorage.getItem("savedThemeValues");

  let parsedValue: any = undefined;
  if (savedPaletteValue) {
    parsedValue = JSON.parse(savedPaletteValue);
  }
  return (
    <ThemeProvider
      theme={getCustomizedThemeObject(
        appTheme === "light" ? theme.light : theme.dark,
        appTheme === parsedValue?.palette?.mode ? parsedValue : undefined
      )}>
      <CssBaseline />
      <Router>
        <ValidateUser>
          <Suspense fallback={<GSLoader getPopupContainer={() => document.body} />}>
            <Routes>
              <Route element={<AuthGuard />}>
                <Route element={<HeaderAndFooter />}>
                  <Route path={NavigationPath.AuthContainer} element={<AuthContainer />} />
                  <Route path={NavigationPath.EmailLoginContainer} element={<EmailLoginContainer />} />
                  <Route path={NavigationPath.CreateAccountContainer} element={<CreateAccountContainer />} />
                  <Route path={NavigationPath.ResetPasswordContainer} element={<ResetPasswordContainer />} />
                </Route>
              </Route>
              <Route element={<GuardRoute />}>
                <Route element={<HeaderAndFooter />}>
                  <Route path={NavigationPath.PhoneNumberContainer} element={<PhoneNumberContainer />} />
                  <Route path={NavigationPath.PhoneVerificationContainer} element={<PhoneVerificationContainer />} />
                  <Route path={NavigationPath.ProfileCompletionContainer} element={<ProfileCompletionContainer />} />
                </Route>
                <Route path={NavigationPath.ExtensionLoginSuccessContainer} element={<ExtensionLoginSuccessContainer />} />
                {isMobile ? (
                  <Route element={<GSMobileRoutes />}>
                    <Route path={NavigationPath.HomeContainer} element={<MobileHomeContainer />} />
                    <Route path={NavigationPath.ShareContainer} element={<MobileShareContainer />} />
                    <Route path={NavigationPath.ProfileContainer} element={<MobileProfileContainer />} />
                    <Route path={NavigationPath.blockedUsers} element={<BlockedUsersContainer />} />
                    <Route path={NavigationPath.MoreContainer} element={<MobileMoreContainer />} />
                    <Route path={NavigationPath.addNewGroup} element={<AddNewGroupContainer />} />
                    <Route path={NavigationPath.UserStatsContainer} element={<UserStatsContainer />} />

                    <Route element={<ContainerWrapper />}>
                      <Route path={NavigationPath.FindzContainer} element={<></>} />
                      <Route path={NavigationPath.TagsContainer} element={<></>} />
                      <Route path={NavigationPath.MessagesContainer} element={<></>} />
                    </Route>
                    <Route>
                      <Route path={NavigationPath.FindzContainer + "/:findId"} element={<MobileFindDetailScreen />} />
                      <Route
                        path={NavigationPath.FindzContainer + "/:findId" + NavigationPath.MediaDetails + "/:mediaId"}
                        element={<MobileMediaDetailsContainer />}
                      />
                    </Route>
                    <Route path={NavigationPath.FindMultiSelectContainer} element={<MobileMultiSelectFindContainer />} />
                    <Route path={NavigationPath.FindzContainer + "/:findId"} element={<MobileFindDetailScreen />} />
                    <Route path={NavigationPath.TagsContainer + "/:tagId"} element={<MobileTagsDetailContainer />} />
                    <Route path={NavigationPath.AddFindsToCollection + "/:tagId"} element={<AddFindsToCollectionContainer />} />
                    <Route path={NavigationPath.MessagesContainer + "/:messageId"} element={<MessageDetailScreen />} />
                    <Route path={NavigationPath.ForwardedMessagesContainer + "/:messageId"} element={<ForwardedMessageDetailScreen />} />
                    <Route path={NavigationPath.FindzMapContainer} element={<MobileMapContainer />} />
                  </Route>
                ) : (
                  <Route path={NavigationPath.HomeContainer + "*"} element={<HomeContainer />} />
                )}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ValidateUser>
      </Router>
    </ThemeProvider>
  );
}

export default App;
