import { collectionMasks, IDocumentSecured, maskIDocumentSecured } from "./commonTypes";

export interface BrandType extends IDocumentSecured {
  created: {
    on: Date;
    by: string;
  };
  name: string;
}
export interface BrandFormType {
  name: string;
}

export const maskBrandType = {
  ...maskIDocumentSecured,
  created: {
    on: true,
    by: true,
  },
  name: true,
};

Object.freeze(maskBrandType);

collectionMasks["brands"] = maskBrandType;
