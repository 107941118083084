const pjson = require("../../../package.json");
export const appConstant = {
  bookmarkChunkLength: 10,
  publicKeyStart: "-----BEGIN PUBLIC KEY-----\n",
  publicKeyEnd: "-----END PUBLIC KEY-----",
  maxFilesAllowed: 10,
  maxFileSize: 52428800,
  minFileSizeToGeneratePreview: 150000,
  displayMRUAllowed: 5,
  defaultSnackbarAutoHideDuration: 5000,
  currentVersion: pjson.version,
  requireLogin: pjson.require_relogin
};

export const maxNoOfDaysBeforeAccountDeletion = 7;

export const allSupportedImageExtensions: string[] = ["jpg", "png", "jpeg", "gif", "bmp", "heic", "webp", "x-icon", "ico", "@screenshot"];

export const videoFormats = ["application/x-mpegURL"];

export const supportedImageContentType = ["image/jpg", "image/gif", "image/png", "image/jpeg", "image/bmp", "image/heic", "image/webp"];
export const docExtensions = ["pdf", "docx", "doc", "xls", "xlsx", "ppt", "pptx", "txt", "kml", "kmz"];
// 52428800 bytes or 50 mb limit for file
export const maxProductFileSize = 52428800;

export const numberOfFilesAllowed = 10;

export const numbersArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const Plan = {
  planType: "Free",
  limits: {
    basicConversation: 10,
    premiumConversation: 10,
    private: {
      finds: 100,
      messages: 250,
      storage: "100 MB"
    }
  }
};

export const BasicConversation = {
  limits: {
    members: 10,
    finds: 50,
    messages: 1000,
    storage: "50 MB"
  }
};

export const PremiumConversation = {
  limits: {
    members: 20,
    finds: 200,
    messages: 2500,
    storage: "200 MB"
  }
};

export const getEndDateToVerifyEmail = (documentCreationDate: Date = new Date()) => {
  let endDateToVerifyEmail: any = new Date(86400000 * maxNoOfDaysBeforeAccountDeletion + new Date(documentCreationDate).getTime());
  const dataToProcess = new Date(endDateToVerifyEmail);
  const splittedDate = dataToProcess.toUTCString().split(" ");
  endDateToVerifyEmail = `${splittedDate[2]} ${splittedDate[1]}, ${splittedDate[3]}`;
  return endDateToVerifyEmail;
};

export const verifyEmailBeforeText = (date: string) => `You must verify your email before ${date} to prevent deletion of your account.`;
