import { takeLatest, fork, put, select, call, take } from "redux-saga/effects";
import {
  BrandActions,
  TagActions,
  CommonActions,
  EventActions,
  FindActions,
  MediaActions,
  VendorActions,
  ActivityActions,
  CommentActions,
  AuthActions,
  GroupActions
} from "../actions";
import { ActionType } from "typesafe-actions";
import { IActiveContext } from "../../types/types";
import { getActiveContext, getCurrentUser } from "../selectors";
import { EOwner, GroupType, UserType, IDocumentSecured } from "common-library/lib/schema";
import { getCommonPref, saveCommonPref, getRouteStatePref } from "../../utils/indexdb";
import { appRegex } from "common-library/lib/constants";
import locationServices from "../../services/location.service";
import { IndexedDbListenerResponse, listenMRUData, urlToFile, putUrlStateToPref, commonServices } from "../../services";
import { isEmpty, uniq } from "lodash";
import { EventChannel } from "redux-saga";
import { groupServices } from "../../services/group.service";
import { getCollectionFromUrl, parseUrlParams } from "../../utils/commonUtils";
import { HomeContainerNavigationPath, NavigationPath } from "../../constants/router.names";
import { Location } from "react-router-dom";

export function* initializeAppDataSaga(action: ActionType<typeof CommonActions.initializationAppData.saga>) {
  const { location, navigate, isMobile } = action.payload;
  try {
    yield put(CommonActions.initializationAppData.start());
    const currentUser: IActiveContext = yield select(getCurrentUser);
    const shouldUsePreviousURL: boolean = true;
    if (currentUser && location) {
      const newActiveContext = {
        name: currentUser.name || "",
        type: EOwner.User,
        identifier: currentUser.identifier
      };

      if (location.pathname == "/" && location.search) {
        const params = parseUrlParams(location);
        if (params.notification) {
          const decodedData = window.atob(params.notification);
          yield put(CommonActions.setBackgrounNotificationData({ data: JSON.parse(decodedData) }));
        }
      }

      if (isMobile) {
        if (location.pathname == "/_share-target") {
          yield put(CommonActions.shareAppOpen({ value: true }));
        } else {
          yield put(CommonActions.shareAppData({ value: undefined }));
        }
        if (location.search) {
          const params = parseUrlParams(location);
          if (params.group) {
            const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
            if (groupData) {
              newActiveContext.identifier = groupData.identifier;
              newActiveContext.name = groupData.name;
              newActiveContext.type = EOwner.Group;
            }
          }
        } else if (shouldUsePreviousURL) {
          const data: Location | undefined = yield call(getRouteStatePref, location.pathname);
          if (data && data.search) {
            const params = parseUrlParams(data);
            if (params.group) {
              const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
              if (groupData) {
                newActiveContext.identifier = groupData.identifier;
                newActiveContext.name = groupData.name;
                newActiveContext.type = EOwner.Group;
              }
            }
            const pathToNaivgate = `${data.pathname}${data.search}`;
            navigate && navigate(pathToNaivgate, { replace: true });
          }
        }
      } else {
        if (location.pathname !== "/") {
          if (location.search) {
            const params = parseUrlParams(location);
            if (params.group) {
              const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
              if (groupData) {
                newActiveContext.identifier = groupData.identifier;
                newActiveContext.name = groupData.name;
                newActiveContext.type = EOwner.Group;
              }
            }
          } else if (shouldUsePreviousURL) {
            const data: Location | undefined = yield call(getRouteStatePref, location.pathname);
            if (data && data.search) {
              const params = parseUrlParams(data);
              if (params.group) {
                const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
                if (groupData) {
                  newActiveContext.identifier = groupData.identifier;
                  newActiveContext.name = groupData.name;
                  newActiveContext.type = EOwner.Group;
                }
              }

              const pathToNaivgate = `${data.pathname}${data.search}`;
              navigate && navigate(pathToNaivgate, { replace: true });
            }
          }
        } else {
          if (shouldUsePreviousURL) {
            if (!location.search) {
              const pathName = `/${HomeContainerNavigationPath.FindzContainer}`;
              const data: Location | undefined = yield call(getRouteStatePref, pathName);

              if (data) {
                if (data.search) {
                  const params = parseUrlParams(data);
                  if (params.group) {
                    const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
                    if (groupData) {
                      newActiveContext.identifier = groupData.identifier;
                      newActiveContext.name = groupData.name;
                      newActiveContext.type = EOwner.Group;
                    }
                  }

                  const pathToNaivgate = `${data.pathname}${data.search}`;
                  navigate && navigate(pathToNaivgate, { replace: true });
                } else {
                  const pathToNaivgate = `${data.pathname}${data.search}`;
                  navigate && navigate(pathToNaivgate, { replace: true });
                }
              } else {
                navigate && navigate(HomeContainerNavigationPath.FindzContainer, { replace: true });
              }
            } else {
              navigate && navigate(HomeContainerNavigationPath.FindzContainer, { replace: true });
            }
          } else {
            navigate && navigate(HomeContainerNavigationPath.FindzContainer, { replace: true });
          }
        }
      }

      yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
      yield put(AuthActions.listenToCurrentUser({ userId: currentUser.identifier }));
      yield put(CommonActions.fetchLatestMruData());
      yield put(GroupActions.fetchLatestGroupInfo());
      yield put(GroupActions.fetchLatestGroupsFromIndexedDb());
      yield put(GroupActions.fetchLatestInvitesFromIndexedDb());
      yield put(GroupActions.fetchLatestInvites());
      yield put(ActivityActions.fetchLatestUserActivities());
      yield put(CommonActions.initializationAppData.success());
    } else {
      yield put(AuthActions.signOut.saga({ force: true }));
    }
  } catch (error) {
    console.error("initializeAppDataSaga", initializeAppDataSaga);
    yield put(CommonActions.initializationAppData.error());
  }
}

export function* fetchLatestDataSaga(action: ActionType<typeof CommonActions.fetchLatestData>) {
  try {
    // lisners from firebase to populate indexed db
    yield put(BrandActions.fetchLatestBrands());
    yield put(VendorActions.fetchLatestVendors());
    yield put(CommentActions.fetchLatestComments());
    yield put(TagActions.fetchLatestTags());
    yield put(FindActions.fetchLatestFinds());
    yield put(EventActions.fetchLatestEvents());

    // watcher sagas to sync media
    yield put(MediaActions.watchMediaUploadQueue());

    // lisners from indexed db to populate redux
    yield put(ActivityActions.fetchLatestActivitiesFromIndexedDb());

    yield put(BrandActions.fetchLatestBrandsFromIndexedDb());
    yield put(VendorActions.fetchLatestVendorsFromIndexedDb());
    yield put(CommentActions.fetchLatestCommentsFromIndexedDb());
    yield put(TagActions.fetchLatestTagsFromIndexedDb());
    yield put(FindActions.fetchLatestFindsFromIndexedDb());
    yield put(EventActions.fetchLatestEventsFromIndexedDb());
    yield put(GroupActions.fetchLatestInvitesFromIndexedDb());
    //triggering the upload
    yield put(MediaActions.startMediaUpload());
  } catch (error) {
    console.error("fetchLatestDataSaga", error);
  }
}

export function* searchLocationSaga(action: ActionType<typeof CommonActions.searchLocation>) {
  try {
    const { calledFrom, searchString: rawString } = action.payload;
    let searchResponse: Record<any, any>;
    const searchString = encodeURIComponent(rawString);

    if (appRegex.coordsRegex.test(rawString)) {
      const [_lat, _lon] = rawString.split(",");
      const lat = Number(_lat.trim());
      const lng = Number(_lon.trim());

      searchResponse = yield call(locationServices.reverseCoords, lat, lng);
      if (!searchResponse.error && !searchResponse.data?.error) {
        if (calledFrom?.current) {
          yield put(CommonActions.setLocationArray({ searchResponse: [searchResponse.data] as any }));
        }
        return;
      }
    }
    searchResponse = yield call(locationServices.searchLocation, searchString);
    if (searchResponse.error || searchResponse.data?.error || searchResponse.data.length <= 0) {
      searchResponse = yield call(locationServices.autoComplete, searchString);
      if (searchResponse.error || searchResponse.data?.error) {
        if (calledFrom?.current) {
          yield put(CommonActions.setLocationArray({ searchResponse: [] }));
        }
        return;
      }
    }
    if (calledFrom?.current) {
      yield put(CommonActions.setLocationArray({ searchResponse: searchResponse.data }));
    }
    return;
  } catch (error) {
    console.error("searchLocationSaga", error);
  }
}

export function* fetchStaticMapImageSaga(action: ActionType<typeof CommonActions.getStaticMapImage>) {
  try {
    const acitveContext: IActiveContext = yield select(getActiveContext);
    const { coords } = action.payload;
    if (acitveContext) {
      const response: { data: { image: string } } = yield call(locationServices.getStaticMap, coords.lat, coords.long);
      if (response.data.image) {
        const imgData = `data:image/jpg;base64,${response.data.image}`;
        const imgFile: File = yield call(urlToFile, imgData);
        if (imgFile) {
          yield put(CommonActions.setStaticMapImageData({ imgData }));
          yield put(
            MediaActions.saveDraftMedia.saga({
              fileList: [imgFile]
            })
          );
        }
      }
    }
  } catch (error) {
    console.error("fetchStaticMapImageSaga", error);
  }
}

export function* listenToMruListIndexedDbSaga(action: ActionType<typeof CommonActions.fetchLatestMruData>) {
  try {
    const listener: EventChannel<any> = yield call(listenMRUData);

    while (true) {
      const { data }: IndexedDbListenerResponse = yield take(listener);
      const activeContext: IActiveContext = yield select(getActiveContext);
      if (isEmpty(activeContext)) {
        return;
      }
      const mruData = (data as any)[0];
      if (mruData) {
        yield put(
          CommonActions.setMRUList({
            mruList: {
              groupList: mruData.value.groupList,
              data: mruData.value[activeContext.identifier]
            }
          })
        );
      }
    }
  } catch (error) {
    console.error("listenToMruListIndexedDbSaga", error);
  }
}

export function* setMruDataSaga(action: ActionType<typeof CommonActions.updateMRUList>) {
  try {
    const { itemIdentifier, key } = action.payload;
    const activeContext: IActiveContext = yield select(getActiveContext);
    const mruData: Record<string, any> = yield call(getCommonPref as any, "mruData");
    if (activeContext) {
      let clonedMruData: Record<string, any> = {
        groupList: []
      };
      if (!isEmpty(mruData)) {
        clonedMruData = mruData;
      }
      let dataToUpdate: string[] = [];
      if (key === "groupList") {
        dataToUpdate = clonedMruData["groupList"];
      } else if (clonedMruData[activeContext.identifier] && clonedMruData[activeContext.identifier][key]) {
        dataToUpdate = clonedMruData[activeContext.identifier][key];
      }
      const filteredDataList = dataToUpdate.filter(id => id !== itemIdentifier);
      filteredDataList.unshift(itemIdentifier);
      if (key === "groupList") {
        clonedMruData = { ...clonedMruData, [key]: uniq(filteredDataList) };
      } else {
        clonedMruData = { ...clonedMruData, [activeContext.identifier]: { [key]: uniq(filteredDataList) } };
      }

      yield call(saveCommonPref as any, "mruData", clonedMruData);
    }
  } catch (error) {
    console.error("setMruDataSaga", error);
  }
}

export function* processUrlWhenNoGroupParam(action: ActionType<typeof CommonActions.processURL.saga>) {
  const { location, navigate } = action.payload;
  yield put(CommonActions.processURL.start());
  const currentUser: UserType = yield select(getCurrentUser);
  const acitveContext: IActiveContext = yield select(getActiveContext);
  const urlPathArray = location.pathname.split("/").filter(item => item && item.split("?")[0]);

  if (urlPathArray.length) {
    if (urlPathArray.length === 2) {
      const collection = getCollectionFromUrl(urlPathArray[0]);
      if (collection) {
        const docId = urlPathArray[1];
        try {
          const documentData: IDocumentSecured | undefined = yield call(commonServices.getDocumentData, collection, docId, "Sever");
          if (documentData && documentData?.owner) {
            if (documentData.owner.identifier !== acitveContext.identifier) {
              const newActiveContext = {
                name: documentData.owner.name || currentUser.name || "",
                type: documentData.owner.type || EOwner.User,
                identifier: documentData.owner.identifier || currentUser.identifier
              };
              yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
            }
          } else {
            navigate && navigate(HomeContainerNavigationPath.UnAuthorizedContainer, { replace: true });
          }
        } catch (error) {
          console.log("error", error);
          navigate && navigate(HomeContainerNavigationPath.UnAuthorizedContainer, { replace: true });
        }
      } else {
        navigate && navigate(HomeContainerNavigationPath.UnAuthorizedContainer, { replace: true });
      }
    } else {
      if (acitveContext && location.pathname !== "/_share-target") {
        // no group search para and active context is group. so change to myfinds
        if (acitveContext.type !== EOwner.User) {
          const newActiveContext = {
            name: currentUser.name || "",
            type: EOwner.User,
            identifier: currentUser.identifier
          };

          yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
        }
      } else if (location.pathname !== "/_share-target") {
        const newActiveContext = {
          name: currentUser.name || "",
          type: EOwner.User,
          identifier: currentUser.identifier
        };

        yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
      }
    }
  } else {
    navigate && navigate(HomeContainerNavigationPath.FindzContainer, { replace: true });
  }
  yield put(CommonActions.processURL.success());
}

export function* processURLSaga(action: ActionType<typeof CommonActions.processURL.saga>) {
  try {
    const { location, navigate } = action.payload;

    const acitveContext: IActiveContext = yield select(getActiveContext);
    const shouldPerisitURLState: boolean = true;
    if (location.search) {
      const params = parseUrlParams(location);
      if (params.group) {
        if (params.group !== acitveContext?.identifier) {
          const groupData: GroupType | undefined = yield call(groupServices.getGroupData as any, params.group, "IndexedDb");
          if (groupData) {
            const newActiveContext = {
              name: groupData.name || "",
              type: EOwner.Group,
              identifier: groupData.identifier
            };
            yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
          } else {
            navigate && navigate(HomeContainerNavigationPath.UnAuthorizedContainer, { replace: true });
          }
        }
      } else {
        yield* processUrlWhenNoGroupParam(action);
      }
    } else {
      yield* processUrlWhenNoGroupParam(action);
    }

    if (shouldPerisitURLState) {
      yield call(putUrlStateToPref, location);
    }
  } catch (error) {
    console.error("processURLSaga", error);
  }
}

function* initializeAppDataListener() {
  yield takeLatest(CommonActions.initializationAppData.saga.toString(), initializeAppDataSaga);
}

function* fetchLatestDataListener() {
  yield takeLatest(CommonActions.fetchLatestData.toString(), fetchLatestDataSaga);
}

function* searchLocationListener() {
  yield takeLatest(CommonActions.searchLocation.toString(), searchLocationSaga);
}

function* getStaticMapImageListener() {
  yield takeLatest(CommonActions.getStaticMapImage.toString(), fetchStaticMapImageSaga);
}

function* listenToMruListIndexedDbListener() {
  yield takeLatest(CommonActions.fetchLatestMruData.toString(), listenToMruListIndexedDbSaga);
}

function* setMruDataListener() {
  yield takeLatest(CommonActions.updateMRUList.toString(), setMruDataSaga);
}

function* processURLListener() {
  yield takeLatest(CommonActions.processURL.saga.toString(), processURLSaga);
}

export default function* commonSaga() {
  yield fork(initializeAppDataListener);
  yield fork(fetchLatestDataListener);
  yield fork(searchLocationListener);
  yield fork(getStaticMapImageListener);
  yield fork(setMruDataListener);
  yield fork(listenToMruListIndexedDbListener);
  yield fork(processURLListener);
}
