import { takeLatest, fork, call, select, put, take } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { GroupActions, CommonActions, ActivityActions } from "../actions";
import {
  listenToFirebase,
  getDataFromChanges,
  syncWithIndexDB,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData,
  subscribeOrUnsubscribeTopics
} from "../../services";
import {
  getActiveContext,
  getCurrentUser,
  getGeoLocationPosition,
  getGroupsMap,
  getIsMobile,
  getIsShareAppOpen,
  getMruList,
  getPendingInvites,
  getTimestamp
} from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import isEmpty from "lodash/isEmpty";
import { ActivityVerb, GroupDbType, GroupType, LocalTimeStampValue, GeolocationPositionType } from "common-library/lib/schema";
import { IActiveContext } from "../../types/types";
import { EventChannel } from "redux-saga";
import { getPathWithQueryString } from "../../utils/commonUtils";
import { IChanges, IQuery } from "gsdb/dist/interfaces";
import {
  EOwner,
  GroupMemberDbType,
  GroupMemberRelatedActivityType,
  GroupRenameActivityType,
  GroupStatus,
  InvitationType,
  UserType
} from "common-library/lib/schema";
import { getGroupMembers, getGroupMembersMap, getGroupMembersNamesFromOfflineData, getMemberNamesOfGroup } from "../../utils/groupUtils";
import { find } from "lodash";
import { groupServices } from "../../services/group.service";
import { firestoreDocRef } from "../../utils/firestoreUtils";
import { createNewActivityService } from "../../services/activity.service";
import { NotificationSubscriptionAction } from "common-library/lib/constants";
import { HomeContainerNavigationPath, NavigationPath } from "../../constants/router.names";

function* processActiveContextSaga(action: ActionType<typeof GroupActions.processActiveContext.saga>) {
  try {
    const { context } = action.payload;
    if (!isEmpty(context)) {
      yield put(GroupActions.processActiveContext.start());
      yield put(GroupActions.setActiveContext({ context }));
      yield put(CommonActions.fetchLatestData());
      yield put(ActivityActions.fetchLatestGroupActivities());
      let groupData = undefined;
      if (context.type === EOwner.Group) {
        const groupsMap: Map<string, GroupType> = yield select(getGroupsMap);
        groupData = groupsMap.get(context.identifier);
      }
      yield put(GroupActions.setGroupMembers({ context: context, groupData }));
      yield put(GroupActions.processActiveContext.success());
    }
  } catch (error) {
    console.error("processActiveContextSaga", error);
    yield put(GroupActions.processActiveContext.error(error as any));
  }
}

export function* fetchLatestGroupInfo(action: ActionType<typeof GroupActions.fetchLatestGroupInfo>) {
  try {
    const currentUser: UserType = yield select(getCurrentUser);
    if (isEmpty(currentUser)) {
      return;
    }
    const timestampMap: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const timeStampForCurrentUser = timestampMap.get(currentUser.identifier);
    const timeStamp = timeStampForCurrentUser ? timeStampForCurrentUser[FirestoreCollectionEnum.Groups]?.timeStampValue : undefined;
    const timeStampValue = timeStamp ? new Date(timeStamp) : undefined;
    const whereClause = [{ key: "memberAccessList", condition: "array-contains", value: currentUser.identifier }];
    const listener: EventChannel<any> = yield call(listenToFirebase, whereClause, FirestoreCollectionEnum.Groups, timeStampValue);

    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);
      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<GroupDbType>(changes, timeStampValue);
      const newData = dataAdded.concat(dataModified);
      yield call(syncWithIndexDB as any, batch, FirestoreCollectionEnum.Groups, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({ collectionType: FirestoreCollectionEnum.Groups, timestamp: latestTimeStamp, groupId: currentUser.identifier })
      );
    }
  } catch (error) {
    console.error("fetchLatestGroupInfo", error);
  }
}

export function* fetchLatestGroupsFromIndexedDbSaga(action: ActionType<typeof GroupActions.fetchLatestGroupsFromIndexedDb>) {
  try {
    const currentUser: UserType = yield select(getCurrentUser);
    if (isEmpty(currentUser)) {
      return;
    }
    const query: IQuery = { query: { memberAccessList: { $eq: currentUser.identifier } } };
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Groups, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      const activeContext: IActiveContext = yield select(getActiveContext);

      let amList: any[] = [];
      let rList: any[] = [];
      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
        if (filteredData.remove.length) {
          rList = filteredData.remove;

          const mruData: Record<string, Array<any>> = yield select(getMruList);
          const _groupRemoved = filteredData.remove.find(item => item.identifier === activeContext.identifier);
          const _currentUserLatest: UserType = yield select(getCurrentUser);
          if (_groupRemoved) {
            const _gData = _currentUserLatest.groups?.[_groupRemoved.identifier];
            if (_gData?.status != GroupStatus.active) {
              const newMruGroupList: string[] = mruData.groupList.filter(
                item => !filteredData.remove.find(removedItem => item === removedItem.identifier)
              );
              yield put(
                CommonActions.setMRUList({
                  mruList: { ...mruData, groupList: newMruGroupList }
                })
              );
              const userGroup = currentUser.groups?.[_groupRemoved.identifier];
              const isActive = userGroup?.status === GroupStatus.active;
              if (!isActive) {
                yield put(
                  GroupActions.processActiveContext.saga({
                    context: { identifier: currentUser.identifier, name: currentUser.name || "", type: EOwner.User }
                  })
                );
              }
            } else {
              rList = rList.filter(item => item.identifier !== _groupRemoved.identifier);
            }
          }
        }
      } else {
        amList = data.add;
      }

      const existingGroupMap: Map<string, GroupType> = yield select(getGroupsMap);
      let newGroupsMap: Map<string, GroupType> = new Map(existingGroupMap);

      amList.forEach(group => {
        const groupMembersNames = getMemberNamesOfGroup(group, currentUser);
        newGroupsMap.set(group.identifier, {
          ...group,
          memberNames: groupMembersNames
        });
      });

      rList.forEach(group => {
        newGroupsMap.delete(group.identifier);
      });

      if (activeContext) {
        const groupData = newGroupsMap.get(activeContext.identifier);
        if (groupData) {
          yield put(GroupActions.setGroupMembers({ context: activeContext, groupData }));
        } else {
          yield put(GroupActions.setGroupMembersList({ groupMembersList: [] }));
        }
      } else {
        yield put(GroupActions.setGroupMembersList({ groupMembersList: [] }));
      }

      yield put(GroupActions.setGroupsMap({ groupsMap: newGroupsMap }));
    }
  } catch (error) {
    console.error("fetchLatestGroupsFromIndexedDbSaga", error);
  }
}

export function* setGroupMembersSaga(action: ActionType<typeof GroupActions.setGroupMembers>) {
  try {
    const { context, groupData } = action.payload;
    const currentUser: UserType | undefined = yield select(getCurrentUser);
    const groupMembersList: GroupMemberDbType[] = [];
    if (currentUser) {
      const groupInfo = context.type === EOwner.Group ? groupData : undefined;
      const groupMembersMap = getGroupMembersMap(context, currentUser, groupInfo as any);

      for (const members of Array.from(groupMembersMap.values())) {
        if (!members.isPastMember) {
          groupMembersList.push(members);
        }
      }
    }
    yield put(GroupActions.setGroupMembersList({ groupMembersList }));
  } catch (error: any) {
    console.error("setGroupMembersSaga", error);
  }
}

export function* fetchUserInfoSaga(action: ActionType<typeof GroupActions.fetchUserInfo.saga>) {
  try {
    const { userDetails } = action.payload;
    if (userDetails) {
      yield put(GroupActions.fetchUserInfo.start());
      const currentPublicUserInfo: any[] = yield call(groupServices.fetchUserInfo, userDetails);
      yield put(GroupActions.setCurrentPublicUserInfo({ currentPublicUserInfo: currentPublicUserInfo }));
      yield put(GroupActions.fetchUserInfo.success());
    }
  } catch (error: any) {
    yield put(GroupActions.fetchUserInfo.error(error));
    console.error("fetchUserInfoSaga", error);
  }
}

export function* addMemberToGroupSaga(action: ActionType<typeof GroupActions.addMemberToGroup.saga>) {
  try {
    const { currentUser, group, user } = action.payload;

    yield put(GroupActions.addMemberToGroup.start());
    yield call(groupServices.addMemberToGroup, group, user, currentUser);
    yield put(GroupActions.setCurrentPublicUserInfo({ currentPublicUserInfo: [] }));
    yield put(GroupActions.addMemberToGroup.success());
  } catch (error: any) {
    console.error("addMemberToGroupSaga", error);
    yield put(GroupActions.addMemberToGroup.error(error));
  }
}

export function* removeMemberFromGroupSaga(action: ActionType<typeof GroupActions.removeMemberFromGroup.saga>) {
  try {
    const { group, role, user, isUnregistered } = action.payload;

    yield put(GroupActions.removeMemberFromGroup.start());
    const currentUser: UserType = yield select(getCurrentUser);
    const activeContext: IActiveContext = yield select(getActiveContext);
    const locationData: GeolocationPositionType = yield select(getGeoLocationPosition);
    yield call(groupServices.removeMemberFromGroup, group, user, role, isUnregistered);
    const groupMembersMap = getGroupMembersMap(activeContext, currentUser, group as any);
    const member = groupMembersMap.get(user.identifier);

    // create activity
    if (user.status === GroupStatus.active) {
      const newActivityId = firestoreDocRef(FirestoreCollectionEnum.Activities).id;
      const activityObj: GroupMemberRelatedActivityType = {
        verb: ActivityVerb.MemberRemoved,
        identifier: newActivityId,
        agent: currentUser.identifier,
        created: {
          by: {
            identifier: currentUser.identifier,
            name: currentUser.name || ""
          },
          on: new Date()
        },
        accessibleTo: { users: [], groups: [activeContext.identifier] },
        object: {
          group: { identifier: activeContext.identifier, name: activeContext.name },
          member: { identifier: user.identifier, name: user.userName || "" }
        },
        readBy: {
          [currentUser.identifier]: new Date()
        },
        _documentUpdatedOn: new Date()
      };

      if (locationData) {
        activityObj.created["at"] = locationData;
      }
      if (member && member.status === "active") {
        activityObj.accessibleTo.users = [user.identifier];
      }
      yield call(createNewActivityService, activityObj);
    }
    yield put(GroupActions.removeMemberFromGroup.success());
  } catch (error) {
    console.error("removeMemberFromGroupSaga", error);
    yield put(GroupActions.removeMemberFromGroup.error(error as any));
  }
}

export function* updateGroupInfoSaga(action: ActionType<typeof GroupActions.updateGroupInfo.saga>) {
  try {
    const { groupId, groupUpdateObj, updateUnregisteredMemberData } = action.payload;

    yield put(GroupActions.updateGroupInfo.start());
    yield call(groupServices.updateGroupInfo, groupId, groupUpdateObj, updateUnregisteredMemberData);
    const givenGroupId = action.payload.groupId;
    const givenGroupName = action.payload.groupUpdateObj.name;

    const oldActiveContext: IActiveContext = yield select(getActiveContext);
    const activeContext: IActiveContext = {
      identifier: action.payload.groupId,
      type: EOwner.Group,
      name: action.payload.groupUpdateObj.name || oldActiveContext.name
    };

    if (oldActiveContext.name !== activeContext.name) {
      const geoCoordinates: GeolocationPositionType = yield select(getGeoLocationPosition);
      const currentUser: UserType = yield select(getCurrentUser);
      yield put(GroupActions.updateActiveContext({ context: activeContext }));
      const userIds: string[] = [];
      const groupListMap: Map<string, GroupDbType> = yield select(getGroupsMap);
      const groupData = groupListMap.get(givenGroupId);
      if (groupData) {
        const groupMemberList = getGroupMembers(groupData as any, currentUser);
        groupMemberList.map((member: GroupMemberDbType) => {
          if (member.identifier !== currentUser.identifier && member.status === GroupStatus.active) {
            userIds.push(member.identifier);
          }
        });

        // create activity
        const newActivityId = firestoreDocRef(FirestoreCollectionEnum.Activities).id;
        const activityObj: GroupRenameActivityType = {
          verb: ActivityVerb.GroupRename,
          identifier: newActivityId,
          agent: currentUser.identifier,
          created: {
            by: {
              identifier: currentUser.identifier,
              name: currentUser.name || ""
            },
            on: new Date()
          },
          accessibleTo: { users: userIds, groups: [givenGroupId] },
          object: {
            group: {
              identifier: givenGroupId,
              oldName: oldActiveContext.name,
              newName: givenGroupName || ""
            }
          },
          readBy: {
            [currentUser.identifier]: new Date()
          },
          _documentUpdatedOn: new Date()
        };

        if (geoCoordinates) {
          activityObj.created["at"] = geoCoordinates;
        }
        yield call(createNewActivityService, activityObj);
      }
    }
    yield put(GroupActions.updateGroupInfo.success());
  } catch (error: any) {
    console.error("updateGroupInfo", error);
    yield put(GroupActions.updateGroupInfo.error(error));
  }
}

export function* exitGroupSaga(action: ActionType<typeof GroupActions.exitGroup.saga>) {
  try {
    const { group, removedUser, navigate } = action.payload;
    yield put(GroupActions.exitGroup.start());
    const isMobile: boolean = yield select(getIsMobile);
    const currentUser: UserType = yield select(getCurrentUser);
    const activeContext: IActiveContext = yield select(getActiveContext);
    const newActiveContext = {
      type: EOwner.User,
      identifier: currentUser.identifier,
      name: currentUser.name || ""
    };
    yield call(groupServices.exitFromGroup, removedUser, group, currentUser, activeContext);
    yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
    yield call(subscribeOrUnsubscribeTopics, {
      userId: currentUser.identifier,
      groupId: group.identifier,
      type: NotificationSubscriptionAction.unsubscribeGroup
    });

    const locationData: GeolocationPositionType = yield select(getGeoLocationPosition);
    const newActivityId = firestoreDocRef(FirestoreCollectionEnum.Activities).id;
    const activityObj: GroupMemberRelatedActivityType = {
      verb: ActivityVerb.MemberLeft,
      identifier: newActivityId,
      agent: currentUser.identifier,
      created: {
        by: {
          identifier: currentUser.identifier,
          name: currentUser.name || ""
        },
        on: new Date()
      },
      accessibleTo: { groups: [activeContext.identifier] },
      object: {
        group: { identifier: activeContext.identifier, name: activeContext.name },
        member: { identifier: currentUser.identifier, name: currentUser.name || "" }
      },
      readBy: {
        [currentUser.identifier]: new Date()
      },
      _documentUpdatedOn: new Date()
    };

    if (locationData) {
      activityObj.created["at"] = locationData;
    }
    yield call(createNewActivityService, activityObj);

    yield put(GroupActions.exitGroup.success());
    let navigationPath = "/";
    if (!isMobile) {
      navigationPath = NavigationPath.FindzContainer;
    }
    navigate && navigate(navigationPath, { replace: true });
  } catch (err: any) {
    yield put(GroupActions.exitGroup.error(err));

    console.error("exitGroupSaga", err);
  }
}

export function* deleteGroupSaga(action: ActionType<typeof GroupActions.deleteGroup.saga>) {
  try {
    const { navigation } = action.payload;
    yield put(GroupActions.deleteGroup.start());
    const isMobile: boolean = yield select(getIsMobile);
    const currentUser: UserType = yield select(getCurrentUser);
    const newActiveContext = {
      type: EOwner.User,
      identifier: currentUser.identifier,
      name: currentUser.name || ""
    };
    yield call(groupServices.deleteGroup, action.payload.group, currentUser);

    yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
    yield put(GroupActions.deleteGroup.success());
    let navigationPath = "/";
    if (!isMobile) {
      navigationPath = NavigationPath.FindzContainer;
    }
    navigation && navigation(navigationPath, { replace: true });
  } catch (error: any) {
    console.error("deleteGroupSaga", error);
    yield put(GroupActions.deleteGroup.error(error));
  }
}

export function* createNewGroupSaga(action: ActionType<typeof GroupActions.createNewGroup.saga>) {
  try {
    const { groupData, navigation } = action.payload;
    yield put(GroupActions.createNewGroup.start());
    const currentUser: UserType = yield select(getCurrentUser);
    const isShareAppOpen: boolean = yield select(getIsShareAppOpen);
    if (currentUser) {
      const newGroup: GroupType = yield call(groupServices.createNewGroupService, groupData, currentUser);
      const activeContext = {
        identifier: newGroup.identifier,
        type: EOwner.Group,
        name: newGroup.name
      };
      newGroup.type = EOwner.Group;
      const groupMemberNames = getGroupMembersNamesFromOfflineData(newGroup as any, currentUser);
      newGroup.memberNames = groupMemberNames;
      const location = window.location;
      let newParams = {} as any;
      let currentPath = location.pathname;
      if (location.pathname == `/${HomeContainerNavigationPath.UnAuthorizedContainer}`) {
        currentPath = HomeContainerNavigationPath.FindzContainer;
      }

      if (activeContext.type === EOwner.Group) {
        newParams.group = activeContext.identifier;
      }
      const newPath = getPathWithQueryString(HomeContainerNavigationPath.FindzContainer, newParams);
      yield put(GroupActions.processActiveContext.saga({ context: activeContext }));
      if (navigation) {
        if (isShareAppOpen) {
          navigation("/_share-target");
        } else {
          navigation(`/${newPath}`, { replace: false });
        }
      }
    }
    yield put(GroupActions.createNewGroup.success());
  } catch (error: any) {
    console.error("createNewGroupSaga", error);
    yield put(GroupActions.createNewGroup.error(error));
  }
}

export function* fetchLatestInvitesSaga(action: ActionType<typeof GroupActions.fetchLatestInvites>) {
  try {
    const currentUser: UserType = yield select(getCurrentUser);
    if (isEmpty(currentUser) || !currentUser.isEmailVerified || !currentUser.phone?.isVerified) {
      return;
    }
    const timestampMap: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const timeStampForCurrentUser = timestampMap.get(currentUser.identifier);
    const timeStamp = timeStampForCurrentUser ? timeStampForCurrentUser[FirestoreCollectionEnum.Invitations]?.timeStampValue : undefined;
    const timeStampValue = timeStamp ? new Date(timeStamp) : undefined;
    const whereClause = [{ key: "invitee", condition: "==", value: currentUser.identifier }];
    const listener: EventChannel<any> = yield call(listenToFirebase, whereClause, FirestoreCollectionEnum.Invitations, timeStampValue, true);

    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);

      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<GroupDbType>(changes, timeStampValue);
      const newData = dataAdded.concat(dataModified);

      yield call(syncWithIndexDB as any, batch, FirestoreCollectionEnum.Invitations, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Invitations,
          timestamp: latestTimeStamp,
          groupId: currentUser.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestInvites", error);
  }
}

export function* fetchLatestInvitesFromIndexDbSaga(action: ActionType<typeof GroupActions.fetchLatestInvitesFromIndexedDb>) {
  const currentUser: UserType = yield select(getCurrentUser);
  if (isEmpty(currentUser) || !currentUser.isEmailVerified || !currentUser.phone?.isVerified) {
    return;
  }
  const query: IQuery = { query: { invitee: { $eq: currentUser.identifier } } };
  const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Invitations, query);
  while (true) {
    const { data, event }: IndexedDbListenerResponse = yield take(listener);
    let amList: any[] = [];
    let rList: any[] = [];
    if (event === "onChange") {
      const filteredData: IChanges = filterChangeData(query, data);
      amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
      if (filteredData.remove.length) {
        rList = filteredData.remove;
      }
    } else {
      amList = data.add;
    }

    const existingInviteMap: Map<string, InvitationType> = yield select(getPendingInvites);
    let newInviteMap: Map<string, InvitationType> = new Map(existingInviteMap);
    amList.forEach(invite => {
      newInviteMap.set(invite.identifier, invite);
    });

    rList.forEach(invite => {
      newInviteMap.delete(invite.identifier);
    });
    yield put(GroupActions.setPendingInvites({ invites: newInviteMap }));
  }
}

export function* respondToInvitationSaga(action: ActionType<typeof GroupActions.respondToInvitation.saga>) {
  try {
    const { activity, isAccepted, shouldBlock, navigation } = action.payload;
    yield put(GroupActions.respondToInvitation.start());
    const currentUser: UserType = yield select(getCurrentUser);
    yield call(groupServices.respondToInvitationService, isAccepted, activity, currentUser, shouldBlock);
    yield put(GroupActions.respondToInvitation.success());

    if (isAccepted) {
      const newActiveContext = {
        name: activity.object.group.name,
        identifier: activity.object.group.identifier,
        type: EOwner.Group
      };
      const location = window.location;
      let newParams = {} as any;
      let currentPath = location.pathname;
      if (location.pathname == `/${HomeContainerNavigationPath.UnAuthorizedContainer}` || location.pathname === "/") {
        currentPath = HomeContainerNavigationPath.FindzContainer;
      }

      if (newActiveContext.type === EOwner.Group) {
        newParams.group = newActiveContext.identifier;
      }
      yield put(
        CommonActions.updateMRUList({ identifier: newActiveContext.identifier, key: "groupList", itemIdentifier: newActiveContext.identifier })
      );
      const newPath = getPathWithQueryString(currentPath, newParams);
      yield put(GroupActions.processActiveContext.saga({ context: newActiveContext }));
      navigation && navigation(newPath);
    }
  } catch (error: any) {
    console.error("respondToInvitationSaga", error);
    yield put(GroupActions.respondToInvitation.error(error));
  }
}

function* processActiveContextListener() {
  yield takeLatest(GroupActions.processActiveContext.saga.toString(), processActiveContextSaga);
}

function* fetchLatestGroupInfoListener() {
  yield takeLatest(GroupActions.fetchLatestGroupInfo.toString(), fetchLatestGroupInfo);
}

function* fetchLatestGroupsFromIndexedDbListener() {
  yield takeLatest(GroupActions.fetchLatestGroupsFromIndexedDb.toString(), fetchLatestGroupsFromIndexedDbSaga);
}

function* setGroupMembersSagaListener() {
  yield takeLatest(GroupActions.setGroupMembers.toString(), setGroupMembersSaga);
}

function* fetchUserInfoListener() {
  yield takeLatest(GroupActions.fetchUserInfo.saga.toString(), fetchUserInfoSaga);
}

function* addMemberToGroupListener() {
  yield takeLatest(GroupActions.addMemberToGroup.saga.toString(), addMemberToGroupSaga);
}

function* removeMemberFromGroupListener() {
  yield takeLatest(GroupActions.removeMemberFromGroup.saga.toString(), removeMemberFromGroupSaga);
}

function* updateGroupInfoListener() {
  yield takeLatest(GroupActions.updateGroupInfo.saga.toString(), updateGroupInfoSaga);
}

function* exitGroupListener() {
  yield takeLatest(GroupActions.exitGroup.saga.toString(), exitGroupSaga);
}

function* deleteGroupListener() {
  yield takeLatest(GroupActions.deleteGroup.saga.toString(), deleteGroupSaga);
}

function* createNewGroupListener() {
  yield takeLatest(GroupActions.createNewGroup.saga.toString(), createNewGroupSaga);
}

function* fetchLatestInvitesListener() {
  yield takeLatest(GroupActions.fetchLatestInvites.toString(), fetchLatestInvitesSaga);
}

function* fetchLatestInvitesFromIndexedDbListener() {
  yield takeLatest(GroupActions.fetchLatestInvitesFromIndexedDb.toString(), fetchLatestInvitesFromIndexDbSaga);
}

function* respondToInvitationListener() {
  yield takeLatest(GroupActions.respondToInvitation.saga.toString(), respondToInvitationSaga);
}

export default function* groupSaga() {
  yield fork(processActiveContextListener);
  yield fork(fetchLatestGroupInfoListener);
  yield fork(fetchLatestGroupsFromIndexedDbListener);
  yield fork(setGroupMembersSagaListener);
  yield fork(fetchUserInfoListener);
  yield fork(addMemberToGroupListener);
  yield fork(removeMemberFromGroupListener);
  yield fork(updateGroupInfoListener);
  yield fork(exitGroupListener);
  yield fork(deleteGroupListener);
  yield fork(createNewGroupListener);
  yield fork(fetchLatestInvitesListener);
  yield fork(fetchLatestInvitesFromIndexedDbListener);
  yield fork(respondToInvitationListener);
}
