import { FuncNotificationSubscriptionParams, GroupStatus, GroupType, LoginMedium } from "common-library/lib/schema";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  signOut,
  signInWithRedirect,
  getRedirectResult,
  User
} from "firebase/auth";
import { EmailTypes, UserAccountStatus, IUserAccountStatus, UserType } from "common-library/lib/schema";
import { appConstants } from "common-library/lib/constants";
import { httpsCallable, getFunctions } from "firebase/functions";
import { FirebaseFunctions, FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getDoc, doc, serverTimestamp, onSnapshot, deleteField } from "firebase/firestore";
import { appConstant } from "../constants/appContants";
import { batchUtil } from "./common/batch";
import db, { firebaseFunctions } from "../../config/firebaseApp";
import { appTexts } from "../constants/appTexts";
import { eventChannel, buffers } from "redux-saga";
import { PublicUserType } from "common-library";
import { dbListenerUnsubscribers, indexdbListenerUnsubscribers } from "./common.service";
import { cloneDeep, isEmpty } from "lodash";
import { getGroupMembers } from "../utils/groupUtils";
import { firestoreDocRef, firestoreGetDoc } from "../utils/firestoreUtils";

const NodeRSA = require("node-rsa");

export interface CreateAccountType {
  email: string;
  name: string;
  password: string;
  phoneNumber: any;
  phoneCountryCode: string;
}
export interface SaveUserType {
  user: User;
  phone?: {
    number: string;
    countryCode: string;
    isVerified: boolean;
  };
  userPhoto: string;
  userName?: string;
  accountStatus: IUserAccountStatus;
}

// export const getUserData = async (uid: string): Promise<UserType> => {
//   const userRef = doc(db, FirestoreCollectionEnum.Users, uid);
//   const userSnapshot = await getDoc(userRef);
//   return userSnapshot.data() as any;
// };

export const getAuthRedirectResult = async () => {
  const auth = getAuth();
  const result = await getRedirectResult(auth);
  return result;
};

export const loginThroughMedium = async (loginMedium: LoginMedium, userCredentials?: { email: string; password: string }) => {
  const auth = getAuth();

  if (loginMedium === LoginMedium.Google) {
    try {
      const provider = new GoogleAuthProvider();
      //Force Account Selection Every time
      provider.setCustomParameters({ prompt: "select_account" });
      signInWithRedirect(auth, provider);
    } catch (error) {
      throw new Error("Google Auth Error");
    }
  } else if (loginMedium === LoginMedium.Email && userCredentials) {
    try {
      const { user } = await signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.password);
      return user;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error("Auth error");
  }
};

export const getSignInMethodsForEmail = async (email: string): Promise<string[]> => {
  const auth = getAuth();
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  return signInMethods;
};

export const saveUserToDatabase = async (user: User, photo: string, loginMedium: LoginMedium, isNewUser: boolean) => {
  try {
    return await saveUserToDb(
      {
        user,
        userPhoto: photo || "",
        accountStatus: {
          status: UserAccountStatus.unverified,
          history: [
            {
              status: UserAccountStatus.unverified,
              timestamp: new Date()
            }
          ]
        },
        phone: {
          countryCode: "",
          isVerified: false,
          number: ""
        }
      },
      loginMedium,
      isNewUser
    );
  } catch (error) {
    throw new Error(appTexts.emailAlreadyUsed);
  }
};

export const generateFirebaseCustomToken = async (uid: string) => {
  const functions = getFunctions();
  const generateToken = httpsCallable(functions, FirebaseFunctions.GenerateFirebaseCustomToken);
  const response = await generateToken({ userIdentifier: uid });
  return response.data;
};

export const encryptCustomToken = (publicKey: string, customToken: string) => {
  const fullPublicKey = appConstant.publicKeyStart + decodeURIComponent(publicKey) + appConstant.publicKeyEnd;
  const key = new NodeRSA();
  key.importKey(fullPublicKey, "public");
  const encryptedCustomToken = key.encrypt(customToken, "base64");
  return encryptedCustomToken;
};

export const resetPassword = async (user: any) => {
  try {
    const auth = getAuth();
    const methods = await fetchSignInMethodsForEmail(auth, user.email);
    if (methods?.length > 0) {
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, FirebaseFunctions.SendEmail);
      const response = await sendEmail({ emailType: EmailTypes.passwordReset, user });
      console.log("Send Reset Email Response", response);
      return response;
    } else {
      throw new Error(appTexts.emailNotFound);
    }
  } catch (error) {
    throw error;
  }
};

const saveUserToDb = async (userData: SaveUserType, loginMedium: LoginMedium, isNewUser: Boolean): Promise<UserType> => {
  const { user, phone, userPhoto, userName, accountStatus } = userData;
  const newDate = new Date();
  const userInfo = user;
  const userRef = doc(db, FirestoreCollectionEnum.Users, userInfo.uid);
  const batch = batchUtil();
  const userSnapshot = await getDoc(userRef);
  if (isNewUser && !userSnapshot.exists()) {
    const userObject: UserType = {
      userPhoto,
      loginMedium,
      name: userName || userInfo.displayName,
      email: userInfo.email as string,
      groups: {},
      identifier: userInfo.uid,
      isEmailVerified: loginMedium === LoginMedium.Email ? userInfo.emailVerified : true,
      _documentCreatedOn: newDate,
      _documentUpdatedOn: newDate,
      notificationPermission: {
        allUserNotifications: true,
        announcements: true,
        muteAll: false,
        groups: {}
      },
      accountStatus
    };
    if (phone) {
      userObject.phone = {
        ...phone,
        isVerified: false,
        fullNumber: `${phone.countryCode}${phone.number}`
      };
      userObject.isEmailVerified = userInfo.emailVerified || false;
    }
    batch.set(userRef, userObject);

    await batch.commit();
    return userObject;
  }
  const userSnapshotData = userSnapshot.data();
  if (userSnapshotData && userSnapshotData.isEmailVerified !== userInfo.emailVerified) {
    let update: any = {};
    update["isEmailVerified"] = true;
    if (userSnapshotData.accountStatus.status === UserAccountStatus.unverified && userSnapshotData.phone?.isVerified) {
      const accountStatusHistory = userSnapshotData.accountStatus.history;
      accountStatusHistory.unshift({
        status: UserAccountStatus.active,
        timestamp: new Date()
      });
      update["accountStatus"] = {
        status: UserAccountStatus.active,
        history: accountStatusHistory
      };
    }
    batch.update(userRef, update);
    await batch.commit();
    const userObject: UserType = {
      ...(userSnapshot.data() as UserType),
      isEmailVerified: true
    };
    return userObject;
  }

  return userSnapshot.data() as UserType;
};

export const createAccount = async ({ accountData }: { accountData: CreateAccountType }) => {
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(auth, accountData.email, accountData.password);
  const user = userCredential?.user;
  let savedUser: UserType;
  savedUser = await saveUserToDb(
    {
      user,
      phone: {
        number: accountData.phoneNumber,
        countryCode: accountData.phoneCountryCode,
        isVerified: false
      },
      userPhoto: "",
      userName: accountData.name,
      accountStatus: {
        status: UserAccountStatus.unverified,
        history: [
          {
            status: UserAccountStatus.unverified,
            timestamp: new Date()
          }
        ]
      }
    },
    LoginMedium.Email,
    true
  );
  try {
    if (user) {
      const userData = {
        name: accountData.name,
        email: accountData.email,
        identifier: user.uid
      };
      await sendVerificationEmail(userData);
    }
  } catch (err) {
    console.error("Email Sent error", err);
  }
  return {
    savedUser
  };
};

export const sendVerificationEmail = async (user: Partial<UserType>) => {
  const userParam = {
    identifier: user.identifier,
    email: user.email,
    name: user.name
  };
  const functions = getFunctions();
  const sendEmail = httpsCallable(functions, FirebaseFunctions.SendEmail);
  return await sendEmail({
    emailType: EmailTypes.emailVerification,
    user: userParam,
    endDateToVerifyEmail: getEndDateToVerifyEmail(user._documentCreatedOn as Date)
  });
};

const getEndDateToVerifyEmail = (documentCreationDate: Date = new Date()) => {
  let endDateToVerifyEmail: any = new Date(86400000 * appConstants.maxNoOfDaysBeforeAccountDeletion + new Date(documentCreationDate).getTime());
  const dataToProcess = new Date(endDateToVerifyEmail);
  const splittedDate = dataToProcess.toUTCString().split(" ");
  endDateToVerifyEmail = `${splittedDate[2]} ${splittedDate[1]}, ${splittedDate[3]}`;
  return endDateToVerifyEmail;
};

export const sendOTP = async (phone: { number: string; countryCode: string; userId: string }) => {
  const functions = getFunctions();
  const sendOtp = httpsCallable(functions, FirebaseFunctions.SendOtp);
  const response: any = await sendOtp({
    phone,
    userId: phone.userId
  });
  return response;
};

export const listenToCurrentUser = (userId: string) => {
  if (dbListenerUnsubscribers.channel_listenToCurrentUser) {
    dbListenerUnsubscribers.channel_listenToCurrentUser.channel && dbListenerUnsubscribers.channel_listenToCurrentUser.channel.close();
    dbListenerUnsubscribers.channel_listenToCurrentUser.snapshotUnsubscribe &&
      dbListenerUnsubscribers.channel_listenToCurrentUser.snapshotUnsubscribe();
  }
  dbListenerUnsubscribers.channel_listenToCurrentUser = {};
  const userRef = doc(db, FirestoreCollectionEnum.Users, userId);
  const listener = eventChannel(emit => {
    let unsubscribed = false;
    let unsubscribeDBSnapshot: () => void;
    let unsubscribe = () => {
      if (unsubscribeDBSnapshot) {
        unsubscribeDBSnapshot();
      }
      unsubscribed = true;
    };
    dbListenerUnsubscribers.channel_listenToCurrentUser.snapshotUnsubscribe = unsubscribe;
    if (!unsubscribed) {
      let returnValue = onSnapshot(
        userRef,
        async snapshot => {
          const user = (await snapshot.data()) as any;
          if (user) {
            emit({
              ...user,
              _documentCreatedOn: user?._documentCreatedOn?.toDate(),
              _documentUpdatedOn: user?._documentUpdatedOn?.toDate(),
              _documentDeletedOn: user?._documentDeletedOn?.toDate()
            } as UserType);
          }
        },
        error => emit(error)
      );

      if (typeof returnValue === "function") {
        unsubscribeDBSnapshot = returnValue;
      }
    }
    return () => unsubscribe;
    //@ts-ignore
  }, buffers.expanding(appConstants.defaultChannelBufferSize));
  dbListenerUnsubscribers.channel_listenToCurrentUser.channel = listener;
  return listener;
};

export const signOutUser = async () => {
  const auth = getAuth();
  const response = await signOut(auth);
  return response;
};

export async function clearListeners() {
  Object.entries(dbListenerUnsubscribers).forEach(([key, listener]) => {
    listener?.channel?.close();
    if (typeof listener?.snapshotUnsubscribe === "function") {
      listener?.snapshotUnsubscribe();
      dbListenerUnsubscribers[key] = undefined as any;
    }
  });
  Object.entries(indexdbListenerUnsubscribers).forEach(([key, listener]) => {
    listener?.channel?.close();
    if (typeof listener?.snapshotUnsubscribe === "function") {
      listener?.snapshotUnsubscribe();
      indexdbListenerUnsubscribers[key] = undefined as any;
    }
  });
}

export const closeAccountServerFunction = async (userId: string) => {
  if (userId) {
    const functions = getFunctions();
    const closeAccount = httpsCallable(functions, FirebaseFunctions.CloseAccount);
    await closeAccount({ userId });
    return true;
  }
  return false;
};

export const verifyPhoneNumber = async (
  phone: {
    number: string;
    otpCode: string;
    countryCode: string;
  },
  currentUser: UserType
) => {
  const functions = getFunctions();
  const verifyOtp = httpsCallable(functions, FirebaseFunctions.VerifyOtp);
  const response: any = await verifyOtp({
    phone,
    userEmail: currentUser.email,
    userId: currentUser.identifier,
    userName: currentUser.name,
    userAvatar: currentUser.userPhoto
  });
  return response;
};

export const updateUserById = async (uid: string, update: Partial<UserType>, currentUser: UserType, groupsList: GroupType[]) => {
  const userRef = firestoreDocRef(FirestoreCollectionEnum.Users, uid);
  const dataToUpdate = cloneDeep(update) as any;
  const batch = batchUtil();
  const hasKey = (key: string) => Object.keys(update).includes(key);
  if (hasKey("email") || hasKey("name") || hasKey("userPhoto") || hasKey("phone")) {
    const publicUserInfoRef = firestoreDocRef(FirestoreCollectionEnum.PublicUserInfo, uid);
    //We don't need to check. Can be removed later
    const publicUserSnapshot = await firestoreGetDoc(FirestoreCollectionEnum.PublicUserInfo, uid);
    const updatedPublicUser: Partial<PublicUserType> = {};

    if (hasKey("email") && update.email) {
      updatedPublicUser.email = update.email;
    }
    if (hasKey("name") && update.name) {
      updatedPublicUser.name = update.name;
    }
    if (hasKey("userPhoto")) {
      updatedPublicUser.userPhoto = update.userPhoto;
    }
    if (update.phone && hasKey("phone")) {
      updatedPublicUser.phone = update.phone.fullNumber;
    }

    if (publicUserSnapshot.exists()) {
      batch.update(publicUserInfoRef, updatedPublicUser);
    }
    const activeGroups = Object.entries(currentUser.groups || {})
      .filter(([key, value], index) => value.status === GroupStatus.active)
      .map(([id]) => id);

    activeGroups.forEach(groupId => {
      const group = groupsList.find(_group => _group.identifier === groupId);
      if (group) {
        const memberList = getGroupMembers(group, currentUser);
        const userData = memberList.find(mem => mem.identifier === currentUser.identifier);
        if (userData) {
          const dataUpdate: any = {};
          const role = userData.role;
          const updatedRole = role !== "owner" ? role + "s" : role;
          if (updatedRole === "owner") {
            if (hasKey("name") && update.name) {
              dataUpdate[`members.${updatedRole}.userName`] = update.name;
            }
            if (hasKey("email") && update.email) {
              dataUpdate[`members.${updatedRole}.email`] = update.email;
            }
            if (hasKey("userPhoto")) {
              dataUpdate[`members.${updatedRole}.userAvatar`] = update.userPhoto;
            }
            if (hasKey("phone") && update.phone) {
              dataUpdate[`members.${updatedRole}.phone`] = update.phone;
            }
          } else {
            if (hasKey("name") && update.name) {
              dataUpdate[`members.${updatedRole}.${currentUser.identifier}.userName`] = update.name;
            }
            if (hasKey("email") && update.email) {
              dataUpdate[`members.${updatedRole}.${currentUser.identifier}.email`] = update.email;
            }
            if (hasKey("userPhoto")) {
              dataUpdate[`members.${updatedRole}.${currentUser.identifier}.userAvatar`] = update.userPhoto;
            }
            if (hasKey("phone") && update.phone) {
              dataUpdate[`members.${updatedRole}.${currentUser.identifier}.phone`] = update.phone;
            }
          }
          batch.update(firestoreDocRef(FirestoreCollectionEnum.Groups, group.identifier), dataUpdate);
        }
      }
    });
  }

  if (update.blockedUsers) {
    if (isEmpty(update.blockedUsers)) {
      dataToUpdate.blockedUsers = deleteField();
    }
  }
  batch.update(userRef, dataToUpdate);
  await batch.commit();
};

export const subscribeOrUnsubscribeTopics = async (params: FuncNotificationSubscriptionParams) => {
  const notificationSubscription = httpsCallable(firebaseFunctions, FirebaseFunctions.NotificationSubscription);
  await notificationSubscription(params);
  return;
};
