import { CommonActions, GroupActions, VendorActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { VendorType } from "common-library/lib/schema";

interface IVendorState {
  vendorsMap: Map<string, VendorType>;
}

const defaultVendorState: IVendorState = {
  vendorsMap: new Map()
};

export const VendorLabel = "Vendor";

export const vendorReducer = createReducer(defaultVendorState, builder => {
  builder
    .addCase(VendorActions.setVendorsMap, (state, action) => {
      state.vendorsMap = action.payload.vendorsMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultVendorState;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.vendorsMap = new Map();
    });
});
