import { CapturedAtType, GeolocationPositionType, LocationDataType } from "common-library";
import { audioExensions, supportedFileContentType, videoExtensions } from "common-library/lib/constants";
import {
  ActivityType,
  ActivityVerb,
  FindzDbType,
  ForwardActivityType,
  MediaType,
  ReactionData,
  TagInfoForFind,
  UserType,
  EOwner,
  MediaDbType,
  FindTypeEnum,
  MediaTypeEnum,
  FindzType,
  CommentType
} from "common-library/lib/schema";
import { cloneDeep, unset, map } from "lodash";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { parse } from "url";
import { allSupportedImageExtensions, docExtensions, supportedImageContentType, videoFormats } from "../constants/appContants";
import { DraftFindMediaType, IActiveContext } from "../types/types";

export const createEmptyFind = (activeContext: IActiveContext, currentUser: UserType, geoLocationPosition: GeolocationPositionType) => {
  return {
    title: "",
    note: "",
    media: {
      sort: [],
      mediaList: {},
      default: ""
    },
    identifier: "",
    owner: {
      ...activeContext
    },
    created: {
      // from: undefined,
      at: {
        // address: undefined,
        geoCoordinates: {
          latitude: geoLocationPosition?.latitude,
          longitude: geoLocationPosition?.longitude,
          altitude: geoLocationPosition?.altitude
        }
      },
      on: new Date(),
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name
      }
    },
    found: {
      // on: undefined,
      at: {
        // address: undefined,
        // url: undefined,
        // geoCoordinates: undefined,
        // event: undefined,
        device: {
          deviceName: "",
          systemName: ""
        }
      },
      by: {
        identifier: "",
        name: ""
      }
    },
    tags: [],
    copies: [],
    priceQuotes: [
      {
        type: "",
        unitPrice: null,
        moq: 0,
        packSize: 1,
        currency: "USD",
        quoteDate: new Date(),
        incoterm: "",
        incotermLocation: {
          locality: "",
          region: "",
          postalCode: "",
          country: ""
        },
        terms: "",
        startDate: new Date(),
        endDate: new Date()
      }
    ],
    vendors: [],
    brands: [],
    type: FindTypeEnum.FILE
  } as Partial<FindzDbType>;
};

export const createDefaultMedia = (currentUser: UserType, locationData: LocationDataType): MediaDbType => {
  return {
    identifier: "emptyMedia",
    created: {
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name ? currentUser.name : ""
      },
      on: new Date(),
      at: {
        geoCoordinates: locationData.geoCoordinates
      }
    },
    found: { at: { device: { deviceName: "", systemName: "" } } },
    size: {
      bytes: 0,
      heightPixels: 0,
      widthPixels: 0
    },
    cloudStorageUrl: "",
    type: MediaTypeEnum.FILE
  };
};

export const getBaseUrl = (url?: string) => {
  return url ? (parse(url).hostname || url).replace(/^.*www\./i, "") : url;
};

export const getCaptureLocation = (capturedAt?: CapturedAtType, from?: string) => {
  return (
    getBaseUrl(from) ||
    (capturedAt?.event ? capturedAt?.event?.name : undefined) ||
    (capturedAt?.address
      ? ["name", "locality", "city", "region", "state", "country"]
          .map(prop => get(capturedAt?.address, prop))
          .filter(val => val)
          .slice(0, 3)
          .join(", ")
      : undefined) ||
    (hasLocation(capturedAt?.geoCoordinates)
      ? `${capturedAt?.geoCoordinates?.latitude.toString().slice(0, 6)}, ${capturedAt?.geoCoordinates?.longitude.toString().slice(0, 6)}` //5 digits including decimal
      : undefined) ||
    ""
  );
};

const hasLocation = (geoCoordinates?: GeolocationPositionType): Boolean => {
  //lat lon 0 is negated
  //In the older records, coords can be string
  return !(
    isEmpty(geoCoordinates) ||
    (Number(geoCoordinates?.latitude) === Number(geoCoordinates?.longitude) && Number(geoCoordinates?.latitude) === 0)
  );
};

const getFileNameWithoutExtensionType = (fileNameWithExtension: string) => {
  if (fileNameWithExtension) {
    const index = fileNameWithExtension.lastIndexOf(".");
    if (index !== -1) {
      const fileName = fileNameWithExtension.slice(0, index);
      if (fileName) {
        return fileName.trim();
      }
    }
  }
  return "";
};

const populateFindMedia = (find: any, findMediaList: any) => {
  const _find = { ...find };
  const sortIds = Object.keys(findMediaList);
  if (_find.media) {
    if (!_find.media.default) {
      _find.media.default = sortIds[0];
    }
    _find.media.mediaList = findMediaList;
    _find.media.sort = sortIds;
  } else {
    _find.media = {
      default: sortIds[0],
      sort: sortIds,
      mediaList: findMediaList
    };
  }
  return _find;
};

const getMediaType = (item: DraftFindMediaType): MediaTypeEnum => {
  const extension = item.extensionType;
  const contentType = item.contentType;
  if (extension) {
    if (allSupportedImageExtensions.includes(extension)) {
      return MediaTypeEnum.PHOTO;
    } else if (extension.toLowerCase() === "pdf" || docExtensions.includes(extension.toLowerCase())) {
      return MediaTypeEnum.DOCUMENTS;
    } else if (videoExtensions.includes(extension)) {
      return MediaTypeEnum.VIDEO;
    }
  } else if (contentType) {
    if (supportedFileContentType.includes(contentType)) {
      return MediaTypeEnum.DOCUMENTS;
    } else if (contentType.startsWith("video/")) {
      return MediaTypeEnum.VIDEO;
    } else if (contentType.startsWith("image/")) {
      return MediaTypeEnum.PHOTO;
    }
  }
  return MediaTypeEnum.FILE;
};

const getForwardActivities = (indexFindToActivities: Set<string>, activities: Map<string, ActivityType>) => {
  const forwards: ForwardActivityType[] = [];
  indexFindToActivities.forEach(activityId => {
    const forwardActivity = activities.get(activityId) as ForwardActivityType | undefined;
    if (forwardActivity && forwardActivity.verb === ActivityVerb.ForwardFind) {
      forwards.push(forwardActivity);
    }
  });
  return forwards;
};

const getFindsDeleteWarningMessage = (
  finds: string[],
  findsMap: Map<string, FindzType>,
  indexFindToComment: Map<string, Set<string>>,
  indexFindToEvents: Map<string, Set<string>>
) => {
  let messageString = "";
  let collectionSet = new Set<string>();
  let messageSet = new Set<string>();
  let eventSet = new Set<string>();
  let countString = "";

  for (const findId of finds) {
    const findData = findsMap.get(findId);
    if (findData) {
      const tagIds = (findData?.tags as TagInfoForFind[])?.map(tag => tag.identifier) || [];
      collectionSet = new Set([...Array.from(collectionSet), ...tagIds]);
    }
    messageSet = new Set([...messageSet, ...(indexFindToComment.get(findId) || new Set())]);
    eventSet = new Set([...eventSet, ...(indexFindToEvents.get(findId) || new Set())]);
  }

  if (collectionSet.size > 0) {
    countString = `${collectionSet.size} ${collectionSet.size === 1 ? "collection" : "collections"}`;
  }

  if (eventSet.size > 0) {
    countString = `${countString}${Boolean(countString) ? (messageSet.size > 0 ? ", " : " and ") : ""}${eventSet.size} ${
      eventSet.size === 1 ? "event" : "events"
    }`;
  }

  if (messageSet.size > 0) {
    countString = `${countString}${countString ? " and " : ""}${messageSet.size} ${messageSet.size === 1 ? "message" : "messages"}`;
  }

  if (finds.length === 1) {
    messageString = `${
      countString ? `This find is used in ${countString}. \nAre you sure you want to delete this Find?` : `Are you sure you want to delete this Find?`
    }`;
  } else if (finds.length > 1) {
    messageString = `${
      countString
        ? `These finds are used across ${countString}. \nAre you sure you want to delete these Finds?`
        : `Are you sure you want to delete these Finds?`
    }`;
  } else {
    messageString = "Are you sure you want to delete this Find?";
  }

  return messageString;
};

const getNewMediaForForwardFind = (originalMedia: MediaDbType, newMediaId: string, currentUser: UserType, location: GeolocationPositionType) => {
  const newMedia = cloneDeep(originalMedia);

  if (!originalMedia.isLinkWithoutMedia) {
    newMedia.cloudStorageUrl = "";
  }
  unset(newMedia, "preview.cloudStorageUrl");
  newMedia.identifier = newMediaId;
  newMedia.created = {
    by: { identifier: currentUser.identifier, name: currentUser.name || "" },
    on: new Date(),
    at: { ...newMedia.created.at, geoCoordinates: location }
  };

  return newMedia;
};

const getUpdatedNewFindObjForForwardFind = (
  originalFindObj: FindzType,
  newFindObj: FindzDbType,
  targetActiveContext: IActiveContext,
  activeContext: IActiveContext,
  currentUser: UserType,
  locationData: GeolocationPositionType,
  newTags: string[]
) => {
  const _newFindObj = {
    ...newFindObj,
    owner: {
      identifier: targetActiveContext.identifier,
      type: targetActiveContext.type,
      name: targetActiveContext.name
    },
    created: {
      at: {
        geoCoordinates: locationData
      },
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name || ""
      },
      on: new Date(),
      from: {
        findId: originalFindObj.identifier,
        owner: {
          ...originalFindObj.owner,
          name: activeContext.type === EOwner.User ? currentUser.name : activeContext.name
        }
      }
    },
    tags: newTags,
    copies: [],
    vendors: newFindObj.vendors,
    brands: newFindObj.brands
  } as FindzDbType;

  unset(_newFindObj, "reactions");
  unset(_newFindObj, "media");

  return _newFindObj;
};

export const getReactionsFromFindz = (comment: CommentType, findzMap: Map<string, FindzType>) => {
  const reactionObjs: {
    emoji: string;
    findReactionObj: {
      findId: string;
      reactionData: ReactionData;
    };
  }[] = [];
  if (comment.reaction && comment.findzId) {
    const findIds = typeof comment.findzId === "string" ? [comment.findzId] : comment.findzId;
    for (const findId of findIds) {
      const findData = findzMap.get(findId);
      if (findData?.reactions) {
        const reactions = Object.entries(findData.reactions);
        reactions.forEach(([reaction, data]) => {
          (data as any[]).forEach(value => {
            if (value.comment === comment.identifier && value.by.identifier === comment.created.by.identifier) {
              const reactionObj = { emoji: reaction, findReactionObj: { findId: comment.findzId as string, reactionData: value } };
              reactionObjs.push(reactionObj);
            }
          });
        });
      }
    }
  }
  return reactionObjs;
};

export const convertFindToDbType = (findData: FindzType) => {
  const newFindData = {
    ...findData
    // tags: findData?.tags?.map(item => item.identifier) || [],
    // brands: findData?.brands?.map(item => item.identifier) || [],
    // vendors: findData?.tags?.map(item => item.identifier) || []
  } as any;
  if (findData.tags?.length) {
    newFindData.tags = findData?.tags?.map(item => item.identifier);
  }
  if (findData.brands?.length) {
    newFindData.brands = findData?.brands?.map(item => item.identifier);
  }
  if (findData.vendors?.length) {
    newFindData.vendors = findData?.vendors?.map(item => item.identifier);
  }
  return newFindData as FindzDbType;
};

const getMediaSizeInMegaByte = (findsList: FindzType[]) => {
  let mediaSize = 0;
  findsList.map(find =>
    map(find.media.mediaList, media => {
      mediaSize += media.size?.bytes ? media.size.bytes : 0;
    })
  );
  return `${(mediaSize / 1048576).toFixed(0)} MB`;
};

const getMediaTypeOfExistingFind = (media: MediaType) => {
  if (media.located) {
    return MediaTypeEnum.LOCATION;
  } else if (media.preview?.icon || media.preview?.iconLabel) {
    return MediaTypeEnum.DOCUMENTS;
  } else if (media.isLinkWithoutMedia) {
    return MediaTypeEnum.LINK;
  } else if (media.size && media.size?.heightPixels && media.size?.widthPixels) {
    return MediaTypeEnum.PHOTO;
  } else if (media.extensionType) {
    const isVideo = videoExtensions.includes(media.extensionType);
    const isDocument = docExtensions.includes(media.extensionType);
    const isImage = allSupportedImageExtensions.includes(media.extensionType);
    const isVcf = media.extensionType.toLowerCase() === "vcf";
    if (isVideo) {
      return MediaTypeEnum.VIDEO;
    } else if (isVcf) {
      return MediaTypeEnum.CONTACT;
    } else if (isDocument) {
      return MediaTypeEnum.DOCUMENTS;
    } else if (isImage) {
      return MediaTypeEnum.PHOTO;
    }
  } else if (media.contentType) {
    const isDocument = supportedFileContentType.includes(media.contentType);
    if (isDocument) {
      return MediaTypeEnum.DOCUMENTS;
    } else if (media.contentType === "text/vcard") {
      return MediaTypeEnum.CONTACT;
    } else if (videoFormats.includes(media.contentType) || media.contentType.startsWith("video/")) {
      return MediaTypeEnum.VIDEO;
    } else if (supportedImageContentType.includes(media.contentType)) {
      return MediaTypeEnum.PHOTO;
    }
  }

  return MediaTypeEnum.FILE;
};

export const findzUtils = {
  hasLocation,
  getReactionsFromFindz,
  getFileNameWithoutExtensionType,
  populateFindMedia,
  getForwardActivities,
  getMediaType,
  getFindsDeleteWarningMessage,
  getNewMediaForForwardFind,
  getUpdatedNewFindObjForForwardFind,
  convertFindToDbType,
  getMediaSizeInMegaByte,
  getMediaTypeOfExistingFind
};
