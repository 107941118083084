import { AsyncState } from "../../types/types";
import { CommonActions, MediaActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { InitialAsyncState, asyncCaseCreator } from "../helper";

interface IMediaState {
  draft: string[];
  deleteFindMedia: InitialAsyncState;
  uploadQueueIdentifiers: Set<string>;
  draftMediaStatus: InitialAsyncState;
}

const defaultMediaState: IMediaState = {
  draft: [],
  deleteFindMedia: { status: AsyncState.idle },
  uploadQueueIdentifiers: new Set(),
  draftMediaStatus: { status: AsyncState.idle }
};

export const MediaLabel = "Media";

export const mediaReducer = createReducer(defaultMediaState, builder => {
  asyncCaseCreator(MediaActions.deleteFindMedia, "deleteFindMedia", builder);
  asyncCaseCreator(MediaActions.saveDraftMedia, "draftMediaStatus", builder);
  builder
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultMediaState;
    })
    .addCase(MediaActions.setDraftMedia, (state, action) => {
      state.draft = action.payload.draftMedia;
    })
    .addCase(MediaActions.clearDraftMedia, (state, action) => {
      state.draft = [];
    })
    .addCase(MediaActions.updateUploadMediaQueueIds, (state, action) => {
      state.uploadQueueIdentifiers = action.payload.mediaIds;
    });
});
