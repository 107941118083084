import { IEvent } from "common-library/lib/schema";
import { actionCreator } from "../helper";

enum EventActionTypes {
  FETCH_LATEST_EVENTS = "EVENTS/FETCH",
  FETCH_LATEST_EVENTS_FROM_INDEXEDDB = "EVENTS/INDEXEDDB/FETCH",
  SET_EVENTS_MAP = "EVENTS/SET_MAP",
  SET_INDEX_FIND_TO_EVENTS = "EVENTS/SET_INDEX_FIND_TO_EVENTS"
}

export const fetchLatestEvents = actionCreator(EventActionTypes.FETCH_LATEST_EVENTS);

export const fetchLatestEventsFromIndexedDb = actionCreator(EventActionTypes.FETCH_LATEST_EVENTS_FROM_INDEXEDDB);

export const setEventsMap = actionCreator<{ eventsMap: Map<string, IEvent> }>(EventActionTypes.SET_EVENTS_MAP);

export const setIndexFindToEvents = actionCreator<{ indexFindToEvents: Map<string, Set<string>> }>(EventActionTypes.SET_INDEX_FIND_TO_EVENTS);
