import React, { FC } from "react";
import Styles from "./SignInFooter.module.scss";
import "../../../styles/styles.scss";
import { appTexts } from "../../constants/appTexts";
import * as appUrls from "../../constants/appUrls";

const SignInFooter: FC<any> = props => {
  return (
    <div className={`${Styles["button-container"]} ${Styles["link-container"]} ${Styles["sub-link"]}`}>
      <span>{appTexts.termsAgreeText}</span>
      <div>
        <span>
          <a href={appUrls.findzTermsAndServices} target={"_blank"} rel="noreferrer">
            {appTexts.termsOfServices}
          </a>
          {" | "}
          <a href={appUrls.findzPrivacyPolicy} target={"_blank"} rel="noreferrer">
            {appTexts.privacyPolicy}
          </a>
        </span>
      </div>
    </div>
  );
};

export default SignInFooter;
