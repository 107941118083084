import { uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { chunk, cloneDeep, compact, isEmpty, isEqual, orderBy } from "lodash";
import { DraftFindMediaType, IActiveContext, IUploadMediaType } from "../types/types";
import { firebaseStorageFindMediaPreviewRef, firebaseStorageFindMediaRef, firebaseStorageUserDpRef } from "../utils/firebaseUtils";
import { FindzMediaTables, getMediaBatch, insertBulkIntoMediaDb, mediaDatabase, getMediaById } from "../utils/indexdb";
import { batchUtil } from "./common/batch";
import { thumbnailUtils } from "@gsynergy/gs-react-components/lib/utils";
import { videoExtensions } from "common-library/lib/constants";
import { allSupportedImageExtensions } from "common-library/lib/utils/commonUtils";
import { nanoid } from "nanoid/non-secure";
import Exif from "exifr";
import { ICacheMedia, SW_MESSAGE_TYPES } from "../types/cacheMediaTypes";
import { appConstant } from "../constants/appContants";
import { EOwner, FindzType, UserType } from "common-library/lib/schema";
import { arrayRemove, deleteField } from "firebase/firestore";
import { firestoreDocRef } from "../utils/firestoreUtils";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { FindzDbType, MediaDbType } from "common-library/lib/schema";
import { allSupportedVideoExtensions } from "common-library/lib/utils/commonUtils";
import { cacheServices } from "./cache.service";
import gisfsicle from "gifsicle-wasm-browser";
import { commonServices } from "./common.service";
const pica = require("pica")();

export async function clearDraftMedia() {
  await mediaDatabase.collection(FindzMediaTables.DraftContent).clear();
  return true;
}

export async function downloadImageFromUrl(imageUrls: string[]) {
  const _arr = await Promise.all(
    imageUrls.map(async url => {
      try {
        const response = await fetch(url);
        if (response) {
          const imgBlob = await response.blob();
          return new File([imgBlob], `image-${imgBlob.size}.png`, {
            type: imgBlob.type
          });
        } else {
          return undefined;
        }
      } catch (error) {
        console.error("downloadImageFromUrl", error);
        return undefined;
      }
    })
  );

  return compact(_arr);
}

export async function getAndClearDraftMedia() {
  try {
    const draft = (await mediaDatabase.collection(FindzMediaTables.DraftContent).query({}).get()) as any[];
    await clearDraftMedia();
    return draft;
  } catch (error) {
    throw error;
  }
}

export async function getMediaToUpload() {
  try {
    const uploadQueue = (await mediaDatabase.collection(FindzMediaTables.UploadQueue).query({}).get()) as any[];
    const orderedList = orderBy(uploadQueue, item => item.findCreatedOn, "asc");
    return orderedList;
  } catch (error) {
    throw error;
  }
}

export async function putMediaIntoUploadQueue(data: any[]) {
  return await insertBulkIntoMediaDb(FindzMediaTables.UploadQueue, data);
}

export async function uploadFindImages(mediaList: IUploadMediaType[], currentUser: UserType) {
  try {
    const uploadedMedia: Map<string, { uploadedSize: number; noOfFilesUploaded: number }> = new Map();
    const fileUploadPromises = mediaList.map(async media => {
      return await putFileToStorage(media, currentUser);
    });

    const uploadImagePromise = await Promise.all(fileUploadPromises);

    const getDownloadUrlsMediaList = await Promise.all(
      uploadImagePromise.map(async data => {
        const { media, result, completed, error, previewResult } = data;

        if (!completed) {
          return { completed: false, media, error };
        }

        if (previewResult) {
          const mediaRef = firebaseStorageFindMediaPreviewRef(media.identifier);
          const url = await getDownloadURL(mediaRef);
          media.previewUrl = url;
        }

        if (result) {
          const mediaRef = firebaseStorageFindMediaRef(media.identifier);
          const url = await getDownloadURL(mediaRef);
          media.cloudStorageUrl = url;

          // for analatics
          const groupUploadedMedia = uploadedMedia.get(media.groupId) || { uploadedSize: 0, noOfFilesUploaded: 0 };
          groupUploadedMedia.uploadedSize += result?.metadata.size || 0;
          groupUploadedMedia.noOfFilesUploaded++;
          uploadedMedia.set(media.groupId, groupUploadedMedia);

          return { completed: true, media };
        }
      })
    );
    const filtertedResult = getDownloadUrlsMediaList.filter(item => item);
    return { response: filtertedResult, uploadedMedia };
  } catch (error) {
    return { error };
  }
}

const getHeight = (currentWidth: any, imageHeight: any, imageWidth: any): number => {
  return (currentWidth * imageHeight) / imageWidth;
};

const getWidth = (currentHeight: any, imageHeight: any, imageWidth: any): number => {
  return (currentHeight * imageWidth) / imageHeight;
};

const resizeTheImage = (previewImage: Blob) => {
  return new Promise((resolve, reject) => {
    const newCanvas = document.createElement("canvas");

    const url = URL.createObjectURL(previewImage);
    const img = new Image();
    img.src = url;
    img.onload = e => {
      if (img.height > img.width) {
        newCanvas.height = getHeight(200, img.height, img.width);
        newCanvas.width = 200;
      } else {
        newCanvas.height = 200;
        newCanvas.width = getWidth(200, img.height, img.width);
      }

      pica
        .resize(img, newCanvas)
        .then((result: any) => {
          const imgUrl = result.toDataURL(`image/webp`, 0.8);
          URL.revokeObjectURL(url);
          result.remove();
          resolve(imgUrl);
        })
        .catch((e: any) => {
          reject(undefined);
        });
    };
  });
};

const getStatusToUploadPreview = (media: IUploadMediaType) => {
  let isImage = false;
  let isVideo = false;
  let isPdf = false;

  if (media.contentType) {
    isImage = media.contentType.startsWith("image/");
    isVideo = media.contentType.startsWith("video/");
    isPdf = media.contentType === "application/pdf";
  }

  if (media.extensionType && !isImage && !isVideo && !isPdf) {
    isImage = allSupportedImageExtensions.includes(media.extensionType);
    isVideo = allSupportedVideoExtensions.includes(media.extensionType);
    isPdf = media.extensionType === "pdf";
  }

  if (isImage && media.data.size > appConstant.minFileSizeToGeneratePreview) {
    return true;
  }
  if (isVideo) {
    return true;
  }
  if (isPdf) {
    return true;
  }

  return false;
};

export async function putFileToStorage(media: IUploadMediaType, currentUser: UserType) {
  try {
    const mediaRef = firebaseStorageFindMediaRef(media.identifier);
    const result = await uploadBytes(mediaRef, media.data, {
      customMetadata: {
        uploadedBy: currentUser.identifier,
        ownerId: media.groupId,
        ownerType: media.groupId === currentUser.identifier ? EOwner.User : EOwner.Group,
        findId: media.findId
      }
    });

    let previewResult: any;
    if (getStatusToUploadPreview(media) && media.thumbnailUrl) {
      try {
        const mediaPreviewRef = firebaseStorageFindMediaPreviewRef(media.identifier);
        const previewBlob = await (await fetch(media.thumbnailUrl)).blob();
        if (previewBlob) {
          const newUrl: any = media.contentType === "image/gif" ? media.thumbnailUrl : await resizeTheImage(previewBlob);
          if (newUrl) {
            const blob = await (await fetch(newUrl)).blob();
            previewResult = await uploadBytes(mediaPreviewRef, blob, {
              customMetadata: {
                uploadedBy: currentUser.identifier,
                ownerId: media.groupId,
                ownerType: media.groupId === currentUser.identifier ? EOwner.User : EOwner.Group,
                findId: media.findId
              }
            });
          }
        }
      } catch (error) {
        console.error("error", error);
      }
    }
    return { completed: true, media, result, previewResult };
  } catch (error: any) {
    return { completed: false, error, media };
  }
}

export async function deleteFindImage(identifier: string) {
  try {
    const mediaRef = firebaseStorageFindMediaRef(identifier);
    const response = await deleteObject(mediaRef);
    return { response };
  } catch (error) {
    console.error("error", error);
    return { error };
  }
}

export async function removeMediaFromMediaQueue(mediaToRemove: string[]) {
  const mediaBatch = getMediaBatch();
  mediaToRemove.map(mediaId => {
    mediaBatch.collection(FindzMediaTables.UploadQueue).doc(mediaId).delete();
  });
  await mediaBatch.commit();
  return true;
}

export async function generateMediaDataForFiles(files: File[], activeContext: IActiveContext) {
  return await Promise.all(
    compact(files).map(async file => {
      const identifier = nanoid();
      const fileName = file.name.split(".");
      let fileExtensions = fileName[fileName.length - 1];
      const obj: DraftFindMediaType = {
        identifier,
        contentType: file.type,
        thumbnailUrl: "",
        extensionType: fileExtensions,
        data: file,
        owner: activeContext
      };
      const isImage = allSupportedImageExtensions.includes(fileExtensions || "");
      const isVideo = videoExtensions.includes(fileExtensions || "");
      const isPdf = file.type === "application/pdf";
      if (isImage) {
        const url = await thumbnailUtils.generateImageThumbnail({ source: file, generatePreview: true, quality: 0.7, canvasId: "previewCanvas" });
        obj.thumbnailUrl = url;
      } else if (isVideo) {
        const isAvi =
          fileExtensions.toLocaleLowerCase() === "avi" || ["video/vnd.avi", "video/avi", "video/msvideo", "video/x-msvideo"].includes(file.type);
        const url = await thumbnailUtils.generateVideoThumbnail({
          source: file,
          canvasId: "previewCanvas",
          generatePreview: !isAvi ? true : false,
          quality: 0.7
        });
        obj.thumbnailUrl = url;
      } else if (isPdf) {
        const { thumbnail } = await thumbnailUtils.generatePDFthumbnail({
          source: file,
          generatePreview: true,
          quality: 0.7,
          canvasId: "previewCanvas"
        });
        if (thumbnail) {
          obj.thumbnailUrl = thumbnail;
        }
      }
      return obj;
    })
  );
}

export async function removeDraftMedia(mediaIds: string[]) {
  const batch = getMediaBatch();
  mediaIds.forEach(async mediaId => {
    const exists = await getMediaById(FindzMediaTables.DraftContent, mediaId);
    if (exists) {
      batch.collection(FindzMediaTables.DraftContent).doc(mediaId).delete();
    }
  });
  await batch.commit();
  return;
}

export async function extractExifData(draftMedias: DraftFindMediaType[], findMediaList: any) {
  const newObj: any = {};
  await Promise.all(
    draftMedias.map(async item => {
      try {
        const foundObj = {
          at: {
            geoCoordinates: {
              latitude: 0,
              longitude: 0,
              altitude: 0
            },
            device: {
              deviceName: "",
              systemName: ""
            }
          }
        } as any;
        const objectToCompare = cloneDeep(foundObj);
        if (findMediaList[item.identifier].created.by) {
          foundObj.by = findMediaList[item.identifier].created.by;
        }
        if (item.data) {
          const gpsData = await Exif.gps(item.data);

          if (gpsData) {
            foundObj.at.geoCoordinates.latitude = gpsData.latitude;
            foundObj.at.geoCoordinates.longitude = gpsData.longitude;
          }

          const otherData = await Exif.parse(item.data, true);

          if (otherData.CreateDate) {
            foundObj.on = otherData.CreateDate;
          }
          if (otherData.CreateDate) {
            foundObj.on = otherData.CreateDate;
          }
          if (otherData.Model) {
            foundObj.at.device.deviceName = otherData.Model;
          }
        }

        const mediaData = {
          ...findMediaList[item.identifier]
        };
        if (!isEqual(objectToCompare, foundObj)) {
          mediaData.found = foundObj;
        }
        newObj[item.identifier] = mediaData;
      } catch (error) {
        newObj[item.identifier] = {
          ...findMediaList[item.identifier]
        };
      }
    })
  );
  return newObj;
}

export function downloadMediaInWorker(mediaToCache: ICacheMedia[], mediaToRemoveFromCache: ICacheMedia[]) {
  navigator.serviceWorker.ready.then(reg => {
    if (reg.active) {
      reg.active.postMessage({
        type: SW_MESSAGE_TYPES.CACHE_MEDIA,
        data: { add: mediaToCache, remove: mediaToRemoveFromCache }
      });
    }
  });
}

async function slowMigrationToGeneratePreview(mediaToGeneratePreview: ICacheMedia[], find: FindzType, currentUser: UserType) {
  const batch = batchUtil();
  if (mediaToGeneratePreview.length) {
    const mediaToGeneratePreviewChunks = chunk(mediaToGeneratePreview, 10);
    for (let index = 0; index < mediaToGeneratePreviewChunks.length; index++) {
      const chunk = mediaToGeneratePreviewChunks[index];
      const findToUpdateMap = new Map();
      await Promise.all(
        chunk.map(async item => {
          const findToUpdate: any = findToUpdateMap.get(item.findId) || {};
          const fetchResp = await fetch(item.url);
          if (fetchResp.status === 200) {
            const dataBlob = await fetchResp.blob();
            if (dataBlob.size > appConstant.minFileSizeToGeneratePreview) {
              const previewBlob = await generateMediaPreview(dataBlob);
              if (previewBlob) {
                const mediaPreviewRef = firebaseStorageFindMediaPreviewRef(item.mediaId);
                try {
                  const previewResult = await uploadBytes(mediaPreviewRef, previewBlob, {
                    customMetadata: {
                      mediaId: item.mediaId,
                      ownerId: find.owner.identifier,
                      ownerType: find.owner.type,
                      uploadedBy: currentUser.identifier,
                      findId: item.findId
                    }
                  });
                  if (previewResult) {
                    try {
                      const url = await getDownloadURL(mediaPreviewRef);
                      findToUpdate[`media.mediaList.${item.mediaId}.preview`] = { cloudStorageUrl: url };
                    } catch (error) {
                      console.error("service uploadFindMediaPreviewAddUpdateUrlInDb", error);
                    }
                  }
                } catch (error) {
                  console.error("service uploadFindMediaPreviewAddUpdateUrlInDb", error);
                }
              }
            }
          }
          if (!isEmpty(findToUpdate)) {
            findToUpdateMap.set(item.findId, findToUpdate);
          }
        })
      );
      if (findToUpdateMap.size) {
        findToUpdateMap.forEach((value, key) => {
          if (!isEmpty(value)) {
            batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, key), value, ["_serverDocumentUpdatedOn"]);
          }
        });
        await batch.commit();
      }
    }
  }
}

export async function generateMediaPreview(mediaData: Blob) {
  if (mediaData) {
    const isPdf = mediaData.type === "application/pdf";
    const isVideo = mediaData.type.startsWith("video/");
    const isImage = mediaData.type.startsWith("image/");

    let generatedString = "";
    if (isImage) {
      generatedString = await thumbnailUtils.generateImageThumbnail({
        source: mediaData,
        generatePreview: true,
        quality: 0.7,
        canvasId: "previewCanvas"
      });
    } else if (isVideo) {
      generatedString = await thumbnailUtils.generateVideoThumbnail({
        source: mediaData,
        generatePreview: true,
        quality: 0.7,
        canvasId: "previewCanvas"
      });
    } else if (isPdf) {
      const { thumbnail } = await thumbnailUtils.generatePDFthumbnail({
        source: mediaData,
        generatePreview: true,
        quality: 0.7,
        canvasId: "previewCanvas"
      });
      if (thumbnail) {
        generatedString = thumbnail;
      }
    }
    if (generatedString) {
      const previewBlob = await (await fetch(generatedString)).blob();
      return previewBlob;
    }
  }
}

export const deleteFindMedia = async (findId: string, mediaIds: string[], findsMap: Map<string, FindzType>) => {
  const batch = batchUtil();
  const updatedFindMediaObj: any = {};
  const find = findsMap.get(findId);

  if (find) {
    mediaIds.forEach(mediaId => {
      const findMedia = find.media;

      const updatedFindMediaSort = findMedia.sort.filter(sortMediaId => sortMediaId !== mediaId);

      updatedFindMediaObj[`media.mediaList.${mediaId}`] = deleteField();
      updatedFindMediaObj["media.sort"] = arrayRemove(mediaId);

      if (mediaId === findMedia.default) {
        updatedFindMediaObj["media.default"] = updatedFindMediaSort[0];
      }
      batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findId), updatedFindMediaObj);
    });
  }
  await batch.commit();
  return Promise.resolve(true);
};

export const deleteFindMediaAndPreviewStorage = async (mediaIds: string[], findMedia: FindzType["media"]) => {
  try {
    const promises = mediaIds.map(async mediaId => {
      const mediaData = findMedia.mediaList[mediaId];
      const findMediaRef = firebaseStorageFindMediaRef(mediaId);
      const deletePreview = Boolean(mediaData.preview?.cloudStorageUrl);

      await deleteObject(findMediaRef);

      if (deletePreview) {
        const findMediaPreviewRef = firebaseStorageFindMediaPreviewRef(mediaId);
        await deleteObject(findMediaPreviewRef);
      }

      return Promise.resolve(true);
    });
    await Promise.all(promises);
    return Promise.resolve(true);
  } catch (error) {
    console.error("media deletion error", error);
  }
};

export const addNewMediaToFind = async (find: FindzType, newMediaData: Map<string, MediaDbType>) => {
  const batch = batchUtil();

  const updatedFindMediaObj: any = {};
  const updatedMediaSort: string[] = [...find.media.sort];

  for (const [mediaId, media] of Object.entries(newMediaData)) {
    updatedMediaSort.push(mediaId);
    updatedFindMediaObj[`media.mediaList.${mediaId}`] = media;
  }

  updatedFindMediaObj["media.sort"] = updatedMediaSort;

  batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, find.identifier), updatedFindMediaObj);

  await batch.commit();

  return Promise.resolve(true);
};

const getDraftMediaForForwardFind = async (media: MediaDbType) => {
  let draftMediaObj: Partial<DraftFindMediaType> = {};
  try {
    const draftMediaObj: Partial<DraftFindMediaType> = {
      identifier: nanoid()
    };
    if (media.contentType) {
      draftMediaObj.contentType = media.contentType;
    }
    if (media.preview?.cloudStorageUrl) {
      draftMediaObj.thumbnailUrl = media.preview?.cloudStorageUrl;
    }
    if (media.extensionType) {
      draftMediaObj.extensionType = media.extensionType;
    }
    if (media.cloudStorageUrl) {
      const blob = await (await fetch(media.cloudStorageUrl)).blob();
      draftMediaObj.data = blob as any;
    }

    return draftMediaObj;
  } catch (error) {
    return draftMediaObj;
  }
};

export const saveProfilePicture = async (userId: string, file: File) => {
  try {
    const fileRef = firebaseStorageUserDpRef(userId);
    const result = await uploadBytes(fileRef, file, {
      customMetadata: {
        userId
      }
    });
    const url = await getDownloadURL(result.ref);
    return url;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProfilePicture = async (userId: string) => {
  try {
    const fileRef = firebaseStorageUserDpRef(userId);
    await deleteObject(fileRef);
    await Promise.resolve(true);
  } catch (err) {
    console.error("deleteProfilePicture", err);
    await Promise.reject(err);
  }
};

export const generateGifPreview = async (file: File): Promise<File> => {
  const fileUrl = URL.createObjectURL(file);
  const fileName = nanoid(12);
  return await gisfsicle
    .run({
      input: [
        {
          file: fileUrl,
          name: fileName
        }
      ],
      command: [`-O3 --colors=64 --lossy=180 --resize=200x_  ${fileName} -o /out/out.gif`]
    })
    .then((d: File[]) => {
      URL.revokeObjectURL(fileUrl);
      return d[0];
    });
};

export const fixBrokenMediaLink = async (data: ICacheMedia[]) => {
  const failedMedias: ICacheMedia[] = [];
  if (data.length) {
    const mediaToDownloadChunks = chunk(data, 10);
    for (let index = 0; index < mediaToDownloadChunks.length; index++) {
      const chunk = mediaToDownloadChunks[index];
      await Promise.all(
        chunk.map(async item => {
          try {
            const respone = await fetch(item.url);
            if (respone.status === 403) {
              failedMedias.push(item);
            }
          } catch (error) {}
        })
      );
    }
  }

  if (failedMedias.length) {
    const findzToUpdate: Map<string, Partial<FindzDbType>> = new Map();
    const failedMediaChumks = chunk(failedMedias, 10);
    for (let index = 0; index < failedMediaChumks.length; index++) {
      const chunk = failedMediaChumks[index];
      await Promise.all(
        chunk.map(async item => {
          try {
            if (item.type) {
              if (item.type === "Orginal") {
                const mediaRef = firebaseStorageFindMediaRef(item.mediaId);
                const url = await getDownloadURL(mediaRef);
                const findToUpdate: any = findzToUpdate.get(item.findId) || {};
                findToUpdate[`media.mediaList.${item.mediaId}.cloudStorageUrl`] = url;
                findzToUpdate.set(item.findId, findToUpdate);
              } else {
                const mediaRef = firebaseStorageFindMediaPreviewRef(item.mediaId);
                const url = await getDownloadURL(mediaRef);
                const findToUpdate: any = findzToUpdate.get(item.findId) || {};
                findToUpdate[`media.mediaList.${item.mediaId}.preview.cloudStorageUrl`] = url;
                findzToUpdate.set(item.findId, findToUpdate);
              }
            }
          } catch (error) {}
        })
      );
    }

    if (findzToUpdate.size) {
      const batch = batchUtil();
      findzToUpdate.forEach((value, key) => {
        batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, key), value);
      });

      await batch.commit();
    }
  }
};

export const mediaServices = {
  deleteFindImage,
  downloadImageFromUrl,
  putFileToStorage,
  uploadFindImages,
  getMediaToUpload,
  clearDraftMedia,
  removeMediaFromMediaQueue,
  removeDraftMedia,
  generateMediaDataForFiles,
  putMediaIntoUploadQueue,
  getAndClearDraftMedia,
  extractExifData,
  downloadMediaInWorker,
  slowMigrationToGeneratePreview,
  generateMediaPreview,
  deleteFindMedia,
  deleteFindMediaAndPreviewStorage,
  addNewMediaToFind,
  getDraftMediaForForwardFind,
  saveProfilePicture,
  deleteProfilePicture,
  fixBrokenMediaLink
};
