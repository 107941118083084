import { AsyncState, GroupMemberRenderType, IActiveContext } from "../../types/types";
import { GroupActions, CommonActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { GroupType } from "common-library/lib/schema";
import { InitialAsyncState, asyncCaseCreator } from "../helper";
import { InvitationType } from "common-library/lib/schema";

interface IGroupState {
  groupsMap: Map<string, GroupType>;
  activeContext?: IActiveContext;
  groupMembersList?: GroupMemberRenderType[];
  currentPublicUserInfo?: any[];
  fetchUserInfo: InitialAsyncState;
  updateGroupInfo: InitialAsyncState;
  addGroupMember: InitialAsyncState;
  deleteGroup: InitialAsyncState;
  exitGroup: InitialAsyncState;
  removeMemberFromGroup: InitialAsyncState;
  processActiveContext: InitialAsyncState;
  createNewGroup: InitialAsyncState;
  respondToInvitation: InitialAsyncState;
  pendingInvites: Map<string, InvitationType>;
}

const defaultGroupState: IGroupState = {
  groupsMap: new Map(),
  pendingInvites: new Map(),
  activeContext: undefined,
  currentPublicUserInfo: [],
  processActiveContext: { status: AsyncState.idle },
  fetchUserInfo: { status: AsyncState.idle },
  updateGroupInfo: { status: AsyncState.idle },
  addGroupMember: { status: AsyncState.idle },
  deleteGroup: { status: AsyncState.idle },
  removeMemberFromGroup: { status: AsyncState.idle },
  exitGroup: { status: AsyncState.idle },
  createNewGroup: { status: AsyncState.idle },
  respondToInvitation: { status: AsyncState.idle }
};

export const GroupLabel = "Group";

export const groupReducer = createReducer(defaultGroupState, builder => {
  asyncCaseCreator(GroupActions.fetchUserInfo, "fetchUserInfo", builder);
  asyncCaseCreator(GroupActions.addMemberToGroup, "addGroupMember", builder);
  asyncCaseCreator(GroupActions.deleteGroup, "deleteGroup", builder);
  asyncCaseCreator(GroupActions.exitGroup, "exitGroup", builder);
  asyncCaseCreator(GroupActions.removeMemberFromGroup, "removeMemberFromGroup", builder);
  asyncCaseCreator(GroupActions.createNewGroup, "createNewGroup", builder);
  asyncCaseCreator(GroupActions.respondToInvitation, "respondToInvitation", builder);
  asyncCaseCreator(GroupActions.updateGroupInfo, "updateGroupInfo", builder);
  asyncCaseCreator(GroupActions.processActiveContext, "processActiveContext", builder);

  builder
    .addCase(GroupActions.setGroupsMap, (state, action) => {
      state.groupsMap = action.payload.groupsMap;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.activeContext = action.payload.context;
    })
    .addCase(GroupActions.updateActiveContext, (state, action) => {
      state.activeContext = action.payload.context;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultGroupState;
    })
    .addCase(GroupActions.setGroupMembersList, (state, action) => {
      state.groupMembersList = action.payload.groupMembersList;
    })
    .addCase(GroupActions.setCurrentPublicUserInfo, (state, action) => {
      state.currentPublicUserInfo = action.payload.currentPublicUserInfo;
    })
    .addCase(GroupActions.setPendingInvites, (state, action) => {
      state.pendingInvites = action.payload.invites;
    });
});
