import React, { CSSProperties, FC } from "react";
import findzLogo from "../../../assets/icons/findzColor.png";
import Styles from "./LogoAndTitle.module.scss";

interface ILogoAndTitle {
  imgStyle?: CSSProperties;
  spanStyle?: CSSProperties;
}

const LogoAndTitle: FC<ILogoAndTitle> = props => {
  const { imgStyle, spanStyle } = props;
  return (
    <div>
      <div className={`${Styles["img-container"]} d-flex al-center`}>
        <img style={{ ...imgStyle }} src={findzLogo} />
        <span style={{ ...spanStyle }}>Findz</span>
      </div>
    </div>
  );
};

export default LogoAndTitle;
