export const phoneNumberSeemsInvalid = "Number appears to be invalid";
export const phoneNumberInvalidCountryCode = "The given number has an invalid country code";
export const phoneNumberIsShort = "The given number is too short";
export const phoneNumberIsLong = "The given number is too long";
export const phoneNumberInvalid = "The given number is not valid";
export const samePhoneNumberError = "You are already using this phone number";
export const cannotStartWithSlash = "Cannot start with a '/'";
export const cannotEndWithSlash = "Cannot end with a '/'";
export const cannotHaveBrackets = "Invalid name. ‘[’, or ‘]’ are not allowed.";
export const invalidCollectionName = "Invalid collection name";
