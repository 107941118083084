/**
 * EditorUtils contains helper
 * functions for our Editor
 */

import { GroupStatus, GroupMemberDbType, UserType, IMention } from "../schema";
export const displayTextWithMentions = (inputText: string, formatMentionNode: any) => {
  /**
   * Use this function to parse mentions markup @[name](id) in the string value.
   */
  if (inputText === "") return null;
  const retLines = inputText.split("\n");
  const formattedText: any[] = [];
  retLines.forEach((retLine, rowIndex) => {
    const mentions = mentionUtils.findMentions(retLine);
    if (mentions.length) {
      let lastIndex = 0;
      mentions.forEach((men, index) => {
        const initialStr = retLine.substring(lastIndex, men.start);
        lastIndex = men.end + 1;
        formattedText.push(initialStr);
        const formattedMention = formatMentionNode(`@${men.name}`, `${index}-${men.identifier}-${rowIndex}`);
        formattedText.push(formattedMention);
        if (mentions.length - 1 === index) {
          const lastStr = retLine.substr(lastIndex); //remaining string
          formattedText.push(lastStr);
        }
      });
    } else {
      formattedText.push(retLine);
    }
    if (rowIndex !== retLines.length - 1) {
      formattedText.push("\n");
    }
  });
  return formattedText;
};

export const mentionUtils = {
  specialTagsEnum: {
    mention: "mention",
    strong: "strong",
    italic: "italic",
    underline: "underline",
  },
  isKeysAreSame: (src: any, dest: any) => src.toString() === dest.toString(),
  getLastItemInMap: (map: any) => Array.from(map)[map.size - 1],
  getLastKeyInMap: (map: any) => Array.from(map.keys())[map.size - 1],
  getLastValueInMap: (map: any) => Array.from(map.values())[map.size - 1],
  updateRemainingMentionsIndexes: (map: any, { start, end }: any, diff: any, shouldAdd: any) => {
    var newMap = new Map(map);
    const keys = mentionUtils.getSelectedMentionKeys(newMap, { start, end });
    keys.forEach((key) => {
      const newKey = shouldAdd ? [key[0] + diff, key[1] + diff] : [key[0] - diff, key[1] - diff];
      const value = newMap.get(key);
      newMap.delete(key);
      //ToDo+ push them in the same order.
      newMap.set(newKey, value);
    });
    return newMap;
  },
  getSelectedMentionKeys: (map: any, { start, end }: any) => {
    // mention [2, 5],
    // selection [3, 6]
    const mantionKeys = [...map.keys()];
    const keys = mantionKeys.filter(([a, b]) => mentionUtils.between(a, start, end) || mentionUtils.between(b, start, end));
    return keys;
  },
  findMentionKeyInMap: (map: any, cursorIndex: any) => {
    // const keys = Array.from(map.keys())
    // OR
    const keys = [...map.keys()];
    const key = keys.filter(([a, b]) => mentionUtils.between(cursorIndex, a, b))[0];
    return key;
  },
  addMenInSelection: (selection: any, prevSelc: any, mentions: any) => {
    /**
     * Both Mentions and Selections are 0-th index based in the strings
     * meaning their indexes in the string start from 0
     * While user made a selection automatically add mention in the selection.
     */
    const sel = { ...selection };
    mentions.forEach(([menStart, menEnd]: any) => {
      if (mentionUtils.diff(prevSelc.start, prevSelc.end) < mentionUtils.diff(sel.start, sel.end)) {
        //user selecting.
        if (mentionUtils.between(sel.start, menStart, menEnd)) {
          //move sel to the start of mention
          sel.start = menStart; //both men and selection is 0th index
        }
        if (mentionUtils.between(sel.end - 1, menStart, menEnd)) {
          //move sel to the end of mention
          sel.end = menEnd + 1;
        }
      } else {
        //previousSelection.diff > currentSelection.diff //user deselecting.
        if (mentionUtils.between(sel.start, menStart, menEnd)) {
          //deselect mention to the end of mention
          sel.start = menEnd + 1;
        }
        if (mentionUtils.between(sel.end, menStart, menEnd)) {
          //deselect mention to the start of mention
          sel.end = menStart;
        }
      }
    });
    return sel;
  },
  moveCursorToMentionBoundry: (selection: any, prevSelc: any, mentions: any, isTrackingStarted: boolean) => {
    /**
     * Both Mentions and Selections are 0-th index based in the strings
     * moveCursorToMentionBoundry will move cursor to the start
     * or to the end of mention based on user traverse direction.
     */

    const sel = { ...selection };
    if (isTrackingStarted) return sel;
    mentions.forEach(([menStart, menEnd]: any) => {
      if (prevSelc.start > sel.start) {
        //traversing Right -to- Left  <=
        if (mentionUtils.between(sel.start, menStart, menEnd)) {
          //move cursor to the start of mention
          sel.start = menStart;
          sel.end = menStart;
        }
      } else {
        //traversing Left -to- Right =>
        if (mentionUtils.between(sel.start - 1, menStart, menEnd)) {
          //move cursor to the end of selection
          sel.start = menEnd + 1;
          sel.end = menEnd + 1;
        }
      }
    });
    return sel;
  },
  between: (x: number, min: number, max: number) => x >= min && x <= max,
  sum: (x: number, y: number) => x + y,
  diff: (x: number, y: number) => Math.abs(x - y),
  isEmpty: (str: string) => str === "",
  getMentionsWithInputText: (inputText: string) => {
    /**
     * translate provided string e.g. `Hey @[mrazadar](id:1) this is good work.`
     * populate mentions map with [start, end] : {...user}
     * translate inputText to desired format; `Hey @mrazadar this is good work.`
     */

    const map = new Map();
    let newValue = "";

    if (inputText === "")
      return {
        map,
        newValue,
      };
    const retLines = inputText.split("\n");
    let indexToAdd = 0;
    retLines.forEach((retLine, rowIndex) => {
      const mentions = mentionUtils.findMentions(retLine);
      if (mentions.length) {
        let lastIndex = 0;
        let endIndexDiff = 0;
        mentions.forEach((men, index) => {
          newValue = newValue.concat(retLine.substring(lastIndex, men.start));
          const name = `@${men.name}`;
          newValue = newValue.concat(name);
          const menEndIndex = men.start + indexToAdd + (name.length - 1);
          map.set([men.start + indexToAdd - endIndexDiff, menEndIndex - endIndexDiff], {
            identifier: men.identifier,
            name: men.name,
          });
          //indexes diff with the new formatted string.
          endIndexDiff = endIndexDiff + Math.abs(men.end + indexToAdd - menEndIndex);
          //update last index
          lastIndex = men.end + indexToAdd + 1;
          if (mentions.length - 1 === index) {
            const lastStr = retLine.substr(lastIndex); //remaining string
            newValue = newValue.concat(lastStr);
          }
        });
      } else {
        newValue = newValue.concat(retLine);
        indexToAdd = retLine.length;
      }
      if (rowIndex !== retLines.length - 1) {
        newValue = newValue.concat("\n");
      }
    });
    return {
      map,
      newValue,
    };
  },
  findMentions: (val: any) => {
    /**
     * Both Mentions and Selections are 0-th index based in the strings
     * meaning their indexes in the string start from 0
     * findMentions finds starting and ending positions of mentions in the given text
     * @param val string to parse to find mentions
     * @returns list of found mentions
     */
    let reg = /@\[([^\]]+?)\]\(identifier:([^\]]+?)\)/gim;
    let indexes = [];
    let match;
    while ((match = reg.exec(val))) {
      indexes.push({
        start: match.index,
        end: reg.lastIndex - 1,
        name: match[1],
        identifier: match[2],
        type: mentionUtils.specialTagsEnum.mention,
      });
    }
    return indexes;
  },
  whenTrue: (next: any, current: any, key: any) => {
    /**
     * whenTrue function will be used to check the
     * boolean props for the component
     * @params {current, next, key}
     * @next: this.props
     * @current: nextProps
     * @key: key to lookup in both objects
     * and will only returns true. if nextProp is true
     * and nextProp is a different version/value from
     * previous prop
     */
    return next[key] && next[key] !== current[key];
  },
  displayTextWithMentions: displayTextWithMentions,
  getDisplayTextWithMention: (message: string) => {
    return mentionUtils.getMentionsWithInputText(message).newValue;
  },
  getMembersForMentions: (groupMembers: GroupMemberDbType[], currentUser: UserType) => {
    return groupMembers
      .filter((member: GroupMemberDbType) => member.identifier != currentUser.identifier && member.status == GroupStatus.active)
      .map(
        (member: GroupMemberDbType) =>
          ({
            identifier: member.identifier,
            name: member.userName || "",
            avatar: member.userAvatar,
          } as IMention)
      );
  },
};

export default mentionUtils;
