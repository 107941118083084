import { ContactType, UserPhoneType, maskContactType } from "./userType";
import { IDocument, EOwner, IMap, maskIDocumentSecured, collectionMasks, ISms } from "./commonTypes";

export enum GroupStatus {
  active = "active",
  pending = "pending",
  removed = "removed",
  declined = "declined",
  exited = "exited",
  groupDeleted = "groupDeleted",
}

export enum GroupMode {
  Chat = "Chat",
  Group = "Group",
  Vendor = "Vendor",
}

export enum GroupRole {
  "owner" = "owner",
  "administrator" = "administrator",
  "collaborator" = "collaborator",
  "viewer" = "viewer",
}

export interface GroupMemberDbType {
  identifier: string;
  status: GroupStatus;
  phone: {
    number: string;
    countryCode: string;
    sms?: ISms;
  };
  role: GroupRole;
  isUnregistered?: boolean;
  memberSince?: Date;
  email?: string | null;
  userName: string | null;
  userAvatar: string | null;
}

export interface GroupMembersDbObjectType {
  pendingOwnerId?: string;
  owner: GroupMemberDbType;
  administrators: {
    [key: string]: GroupMemberDbType;
  };
  collaborators: {
    [key: string]: GroupMemberDbType;
  };
  viewers: { [key: string]: GroupMemberDbType };
  unregistered: { [key: string]: GroupMemberDbType };
}

export interface PastMemberObject {
  identifier: string;
  name: string;
  avatar?: string;
  role: string;
}

export interface IUserInfoType {
  email: string;
  identifier: string;
  phone: string;
  recordID: string;
  name: string;
  doesNotExists?: boolean;
  isAdmin?: boolean;
}

export interface GroupMembersObjectType {
  pendingOwnerId?: string;
  owner: GroupMemberDbType;
  administrators: {
    [key: string]: GroupMemberDbType;
  };
  collaborators: {
    [key: string]: GroupMemberDbType;
  };
  viewers: { [key: string]: GroupMemberDbType };
  unregistered: { [key: string]: GroupMemberDbType };
}

export interface GroupDbType extends IDocument {
  name: string;
  created: {
    on: Date;
    by: {
      identifier: string;
      name: string | null;
      number?: UserPhoneType;
    };
  };
  members: GroupMembersDbObjectType;
  offlineGroupData?: GroupPayloadDataType;
  memberAccessList: string[];
  pastMembers?: IMap<PastMemberObject>; // key is the identifier of the past member
  groupMode?: GroupMode;
  hideContactDetails: boolean;
  plan: {
    groupType: GroupPlanType;
  };
  groupProfilePicture?: string;
}

export enum GroupPlanType {
  "Basic" = "Basic",
  "Premium" = "Premium",
}

export interface GroupType extends GroupDbType {
  type: EOwner;
  memberNames?: string;
}

export interface GroupPayloadDataType {
  title: string;
  administrators: ContactType[];
  collaborators: ContactType[];
  viewers: ContactType[];
  owner: ContactType;
  pendingOwnerId: string;
  moveOwnerTo: string;
  groupMode: GroupMode;
}

export interface InvitationType extends IDocument {
  created: {
    by: {
      identifier: string;
      name: string | null;
    };
    on: Date;
  };
  role: GroupRole;
  moveOwnerTo?: string;
  invitee: string;
  inviteeName: string;
  status: string;
  statusHistory: {
    sent?: Date;
    accepted?: Date;
    declined?: Date;
  };
  group: {
    identifier: string;
    name: string;
  };
}

export const maskGroupMemberDbType = {
  identifier: true,
  status: true,
  phone: {
    number: true,
    countryCode: true,
    sms: { smsStatus: true, lastSmsId: true, history: true },
  },
  role: true,
  isUnregistered: true,
  memberSince: true,
  email: true,
  userName: true,
  userAvatar: true,
};
Object.freeze(maskGroupMemberDbType);

export const maskGroupMembersDbObjectType = {
  pendingOwnerId: true,
  owner: maskGroupMemberDbType,
  administrators: {
    __MAP__: maskGroupMemberDbType,
  },
  collaborators: {
    __MAP__: maskGroupMemberDbType,
  },
  viewers: { __MAP__: maskGroupMemberDbType },
  unregistered: { __MAP__: maskGroupMemberDbType },
};
Object.freeze(maskGroupMembersDbObjectType);

export const maskPastMemberObject = {
  identifier: true,
  name: true,
  avatar: true,
  role: true,
};
Object.freeze(maskPastMemberObject);

export const maskOfflineGroupDataType = {
  title: true,
  administrators: [maskContactType],
  collaborators: [maskContactType],
  viewers: [maskContactType],
  owner: maskContactType,
  pendingOwnerId: true,
  moveOwnerTo: true,
  groupMode: true,
};
Object.freeze(maskOfflineGroupDataType);

export const maskGroupDbType = {
  ...maskIDocumentSecured,
  name: true,
  created: {
    on: true,
    by: {
      identifier: true,
      name: true,
    },
  },
  hideContactDetails: true,
  members: maskGroupMembersDbObjectType,
  offlineGroupData: maskOfflineGroupDataType,
  memberAccessList: [true],
  pastMembers: {
    __MAP__: maskPastMemberObject,
  },
  groupMode: true,
  plan: {
    groupType: true,
  },
  groupProfilePicture: true,
};

Object.freeze(maskGroupDbType);

collectionMasks["groups"] = maskGroupDbType;
