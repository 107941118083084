import { isNumber } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { CommonActions, FindActions } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getActiveContext,
  getActivitiesMap,
  getBackgroundNotificationData,
  getCurrentPendingInvites,
  getCurrentUser,
  getFindzMap,
  getGroupList,
  getInitialUrlProcessingState,
  getIsMobile,
  getShowForwardFindModal
} from "../redux/selectors";
import { getCurrentLocation } from "../services";
import { TFunction } from "../types/localizationTypes";
import { AsyncState } from "../types/types";
import { useTheme } from "@mui/material";
import { applyAplhaToColor } from "../utils/themeUtils";
import { onMessageListener } from "../services/cloudMessaging.services";
import { InvitationActivityType, NotificationType } from "common-library/lib/schema";
import { HomeContainerNavigationPath } from "../constants/router.names";
import { FindzContainerSearchParams } from "../constants/appEnum";
import { getPathWithQueryString } from "../utils/commonUtils";
import { Box, Typography, Alert, Snackbar } from "@mui/material";

export function GSMobileRoutes(props: any) {
  const { children } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation() as { t: TFunction };
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationData, setNotificationData] = useState<undefined | { messageId: string; data: { [key: string]: string } }>(undefined);

  const isMobile = useAppSelector(getIsMobile);
  const currentUser = useAppSelector(getCurrentUser);
  const backgroundNotificationData = useAppSelector(getBackgroundNotificationData);
  const activitiesMap = useAppSelector(getActivitiesMap);
  const userInvites = useAppSelector(getCurrentPendingInvites);
  const initialUrlProcessingState = useAppSelector(getInitialUrlProcessingState);

  useEffect(() => {
    if (backgroundNotificationData) {
      const data = { ...backgroundNotificationData };
      handleNotificationClick(data);
      dispatch(CommonActions.setBackgrounNotificationData({}));
    }
  }, [backgroundNotificationData]);

  onMessageListener()
    .then((payload: any) => {
      if (payload.notification) {
        if (notificationData?.messageId !== payload.messageId) {
          handleIncomingNotification(payload);
        }
      }
    })
    .catch(err => console.error("failed: ", err));

  function handleIncomingNotification({ messageId, data }: any) {
    switch (data.type) {
      case NotificationType.AddComment: {
        if (location.pathname != `/${HomeContainerNavigationPath.MessagesContainer}`) {
          setNotificationData({ messageId, data });
        }
        break;
      }
      default:
        setNotificationData({ messageId, data });
        break;
    }
    return;
  }

  function handleNotificationClick(data: any): void {
    switch (data.type) {
      case NotificationType.Invite:
        handleInviteNotification(data.activityId);
        break;
      case NotificationType.AddFind:
        handleAddFindNotification(data);
        break;
      case NotificationType.GroupModified:
        handleGroupModifiedNotification(data);
        break;
      case NotificationType.AddComment:
        handleMessageNotification(data);
        break;
      case NotificationType.AddCollection:
        handleAddCollectionNotification(data);
        break;
    }
    setNotificationData(undefined);
  }

  function handleAddCollectionNotification(data: any) {
    const params = {} as any;
    params[FindzContainerSearchParams.TAG] = data.tagId;
    params[FindzContainerSearchParams.GROUP] = data.groupId;
    const path = getPathWithQueryString(HomeContainerNavigationPath.FindzContainer, params);
    navigate(path, { replace: true });
  }

  function handleGroupModifiedNotification(data: any) {
    const params = {} as any;
    params[FindzContainerSearchParams.GROUP] = data.groupId;
    const path = getPathWithQueryString(HomeContainerNavigationPath.GroupContainer, params);
    navigate(path, { replace: true });
  }

  function handleAddFindNotification(data: any) {
    const params = {} as any;
    params[FindzContainerSearchParams.GROUP] = data.groupId;
    params[FindzContainerSearchParams.SELECTED] = data.findId;
    const path = getPathWithQueryString(HomeContainerNavigationPath.FindzContainer, params);
    navigate(path, { replace: true });
  }

  function handleInviteNotification(activityId: string) {
    const activity = activitiesMap.get(activityId) as InvitationActivityType;
    if (activity) {
      const _invite = userInvites.get(activity.object.invitation.invitationId);
      if (_invite && _invite.invitee === currentUser?.identifier) {
        // show the invite modal
      }
    }
  }

  function handleMessageNotification(data: any) {
    const params = {} as any;
    params[FindzContainerSearchParams.GROUP] = data.groupId;
    const path = getPathWithQueryString(HomeContainerNavigationPath.MessagesContainer, params);
    navigate(path, { replace: true });
  }

  const handleOfflineEvent = () => {
    dispatch(CommonActions.setUserIsOnline({ value: false }));
  };
  const handleOnlineEvent = () => {
    dispatch(CommonActions.setUserIsOnline({ value: true }));
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--tootip-box", theme.palette.surface?.main as string);
    document.documentElement.style.setProperty("--tooltip-container-bg-color", theme.palette.background.default || theme.palette.background.default);

    // date picker
    document.documentElement.style.setProperty("--date-picker-selected-day-bg-color", theme.palette.primary.main);
    document.documentElement.style.setProperty("--date-picker-selected-day-color", theme.palette.primary.on as string);
    document.documentElement.style.setProperty("--date-picker-current-month-color", theme.palette.surface?.onVariant as string);
    document.documentElement.style.setProperty("--date-picker-disabled-day-color", applyAplhaToColor(theme.palette?.surface?.on, 0.38));
    document.documentElement.style.setProperty("--date-picker-day-color", theme.palette.surface?.on as string);
    document.documentElement.style.setProperty(
      "--date-picker-day-hover-bg-color",
      applyAplhaToColor(theme.palette?.surface?.onVariant, theme.state.hover.stateLayerOpacity)
    );
    document.documentElement.style.setProperty("--date-picker-day-hover-color", theme.palette.surface?.onVariant as string);
    document.documentElement.style.setProperty("--date-picker-range-selector-bg-color", theme.palette.primary.container as string);
    document.documentElement.style.setProperty("--date-picker-weekdays-color", theme.palette.surface?.on as string);
  }, []);

  useEffect(() => {
    dispatch(CommonActions.initializationAppData.saga({ location, navigate, isMobile }));

    window.addEventListener("offline", handleOfflineEvent);

    window.addEventListener("online", handleOnlineEvent);

    navigator.permissions.query({ name: "geolocation" }).then(({ state }) => {
      if (state !== "denied") {
        getCurrentLocation()
          .then((location: any) => {
            const geoCoordinates = location.coords;
            if (geoCoordinates && isNumber(geoCoordinates.latitude) && isNumber(geoCoordinates.longitude)) {
              const geoLocationPosition = {
                latitude: geoCoordinates.latitude,
                longitude: geoCoordinates.longitude,
                altitude: isNumber(geoCoordinates.altitude) ? geoCoordinates.altitude : 0
              };
              dispatch(
                CommonActions.setGeoLocationPosition({
                  geoLocationPosition
                })
              );
            }
          })
          .catch(error => {
            console.error("error", error);
          });
        return;
      }
    });

    return () => {
      window.removeEventListener("offline", handleOfflineEvent);

      window.removeEventListener("online", handleOnlineEvent);
    };
  }, []);

  useEffect(() => {
    if (initialUrlProcessingState.status === AsyncState.success && location.pathname != "/") {
      dispatch(CommonActions.processURL.saga({ location, navigate }));
    }
  }, [location, initialUrlProcessingState]);

  return (
    <>
      {children ? children : null}
      <Outlet />

      <Snackbar
        key={"topRight"}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!notificationData?.data}
        onClose={() => {
          setNotificationData(undefined);
        }}
        autoHideDuration={6000}>
        <Alert
          onClick={() => handleNotificationClick(notificationData?.data)}
          onClose={() => {
            setNotificationData(undefined);
          }}
          severity="info"
          sx={{ width: "100%", cursor: "pointer" }}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", minWidth: 200 }}>
            <Typography variant="titleMedium">{notificationData?.data.title || "Notification"}</Typography>
            <Typography variant="labelMedium">{notificationData?.data.body || ""}</Typography>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
}
