import { takeLatest, fork, call, select, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { CommonActions, VendorActions } from "../actions";
import {
  getListenerToFetchData,
  getDataFromChanges,
  syncWithIndexDB,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData
} from "../../services";
import { getActiveContext, getTimestamp, getVendorsMap } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import { LocalTimeStampValue, VendorType } from "common-library/lib/schema";
import isEmpty from "lodash/isEmpty";
import { IActiveContext } from "../../types/types";
import { getQueryFromActiveContext } from "../../utils/commonUtils";
import { EventChannel } from "redux-saga";
import { IChanges } from "gsdb/dist/interfaces";

export function* fetchLatestVendorsSaga(action: ActionType<typeof VendorActions.fetchLatestVendors>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Vendors, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);
      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<VendorType>(changes, timeStampValue);
      const newData = dataAdded.concat(dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Vendors, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Vendors,
          timestamp: latestTimeStamp,
          groupId: activeContext.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestVendorsSaga", error);
  }
}

export function* fetchLatestVendorsFromIndexedDbSaga(action: ActionType<typeof VendorActions.fetchLatestVendorsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Vendors, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }

      const existingVendorMap: Map<string, VendorType> = yield select(getVendorsMap);
      let newVendorsMap = new Map(existingVendorMap);

      amList.forEach(vendor => {
        newVendorsMap.set(vendor.identifier, vendor);
      });

      rList.forEach(vendor => {
        newVendorsMap.delete(vendor.identifier);
      });

      yield put(VendorActions.setVendorsMap({ vendorsMap: newVendorsMap }));
    }
  } catch (error) {
    console.error("fetchLatestVendorsFromIndexedDbSaga", error);
  }
}

function* fetchLatestVendorsListener() {
  yield takeLatest(VendorActions.fetchLatestVendors.toString(), fetchLatestVendorsSaga);
}

function* fetchLatestVendorsFromIndexedDbListener() {
  yield takeLatest(VendorActions.fetchLatestVendorsFromIndexedDb.toString(), fetchLatestVendorsFromIndexedDbSaga);
}

export default function* vendorSaga() {
  yield fork(fetchLatestVendorsListener);
  yield fork(fetchLatestVendorsFromIndexedDbListener);
}
