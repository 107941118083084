import { Box, SxProps, Theme } from "@mui/material";

export interface GSImageProps {
  width?: string | number;
  height?: string | number;
  src: string;
  alt?: string;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
  onMouseEnter?: (e?: React.MouseEvent) => void;
  onMouseLeave?: (e?: React.MouseEvent) => void;
}

function GSImage(props: GSImageProps) {
  const { width = "100%", style, height = "100%", onClick, onMouseEnter, onMouseLeave, src, alt = "", sx, className } = props;

  return (
    <Box
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      width={width}
      height={height}
      sx={sx}
      component={"img"}
      alt={alt}
      src={src}
    />
  );
}

export default GSImage;
