import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

//#region From Reducer
const _getCurrentUser = (state: AppState) => state.Auth.currentUser;
//#endregion

//#region Memoized Selector
export const getCurrentUser = createDraftSafeSelector(_getCurrentUser, payload => payload);
//#endregion

//#region From Reducer
const _getValidateUser = (state: AppState) => state.Auth.validateUser;
//#endregion

//#region Memoized Selector
export const getValidateUser = createDraftSafeSelector(_getValidateUser, payload => payload);
//#endregion

const _getLoginMedium = (state: AppState) => state.Auth.loginMedium;

export const getLoginMedium = createDraftSafeSelector(_getLoginMedium, payload => payload);

const _getSignOutUser = (state: AppState) => state.Auth.signOut;

export const getSignOutUser = createDraftSafeSelector(_getSignOutUser, payload => payload);

const _getCloseUserAccount = (state: AppState) => state.Auth.closeAccount;

export const getCloseUserAccount = createDraftSafeSelector(_getCloseUserAccount, payload => payload);

export const _getUnblockUser = (state: AppState) => state.Auth.updateUser;

export const getBlockUser = createDraftSafeSelector(_getUnblockUser, payload => payload);

const _getSendOTP = (state: AppState) => state.Auth.sendOtp;

export const getSendOTP = createDraftSafeSelector(_getSendOTP, payload => payload);

const _getVerifyOTP = (state: AppState) => state.Auth.verifyOtp;

export const getVerifyOTP = createDraftSafeSelector(_getVerifyOTP, payload => payload);

const _getResendOTP = (state: AppState) => state.Auth.resendOtp;

export const getResendOTP = createDraftSafeSelector(_getResendOTP, payload => payload);

const _getResendVerificationMail = (state: AppState) => state.Auth.resendVerificationMail;

export const getResendVerificationMail = createDraftSafeSelector(_getResendVerificationMail, payload => payload);

const _getResetPassword = (state: AppState) => state.Auth.resetPassword;

export const getResetPassword = createDraftSafeSelector(_getResetPassword, payload => payload);
