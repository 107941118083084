import { ContentReportCodes, IUserActionEventConfig } from "../schema";

const appVersion = "1.1.0";
export const appConstants = {
  appVersion,
  defaultChannelBufferSize: 10,
  maxMRUAllowed: 20,
  displayMRUAllowed: 3,
  imageQueueChannelBufferSize: 100,
  maxImagesPerFind: 10,
  maxFindzPerChunk: 5,
  FINDS_MULTISELECT_LIMIT: 25,
  findsMediaDownloadQueueBufferSize: 5,
  BACK_PRESS_TIMEOUT_THRESHOLD: 2000,
  TOAST_DURATION: 5000,
  DEBOUNCE_TIMEOUT: 250,
  NOTIFICATION_CHANNEL_ID: "default-channel-id",
  NOTIFICATION_REMINDER_CHANNEL_ID: "reminder-channel-id",
  minimumUserNameLength: 2,
  notificationTimerDuration: 30000,
  companyUrl: "https://findz.app",
  locationTtl: 30, // days
  distanceThresholdInMeters: 100,
  deleteUserGeoLocationsThreshold: 20000,
  maxNoOfDaysBeforeAccountDeletion: 7,
  minHeightAndWidthOfImageToFilter: 64,
};

export const defaultCurrency = "USD";

export enum DataEmitSource {
  cache = "cache",
  pubsub = "pubsub",
  database = "database",
}

export enum PublishActions {
  IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST",
  CACHED_IMAGE_DELETE_REQUEST = "CACHED_IMAGE_DELETE_REQUEST",
  IMAGE_DELETE_REQUEST = "IMAGE_DELETE_REQUEST",
  IMAGE_DOWNLOAD_QUEUE = "IMAGE_DOWNLOAD_QUEUE",
  UPDATE_LOCATION_QUEUE = "LOCATION_UPDATE_QUEUE",
}

export enum DynamicLinkSuffix {
  INVITE = "invite",
  DEFAULT = "get",
}

export enum SubscriptionType {
  subscribe = "subscribeToTopic",
  unsubscribe = "unsubscribeFromTopic",
}

export enum UserNotificationPermissions {
  allUserNotifications = "allUserNotifications",
  announcements = "announcements",
}

export enum NotificationSubscriptionAction {
  signOut = "signOut",
  signIn = "signIn",
  subscribeAll = "subscribeAll",
  unsubscribeAll = "unsubscribeAll",
  subscribeGroup = "subscribeGroup",
  unsubscribeGroup = "unsubscribeGroup",
  subscribeSpecificNotification = "subscribeSpecificNotification",
  unsubscribeSpecificNotification = "unsubscribeSpecificNotification",
}

export enum NotificationTopicType {
  findAdded = "findAdded",
  groupModified = "groupModified",
  events = "events",
  messagedAnyone = "messagedAnyone",
  messagedMe = "messagedMe",
  allUserNotifications = "allUserNotifications",
  announcements = "announcements",
}

export const groupCodes: ContentReportCodes = ["Breaks_Group_Rules", "Irrelevant_Content", "Member_Conflict"];

export const generalCodes: ContentReportCodes = [
  "Bullying_Harrasment",
  "Hate_Speech",
  "Nudity_Sexual_Content",
  "Violence",
  "Intellectual_Property_Violation",
  "Sale_of_Illegal_Goods",
  "Something_else",
];

export const supportedFileContentType = [
  "application/vnd.google-earth.kml+xml",
  "text/vcard",
  "application/vnd.google-earth.kmz",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "text/plain",
];

export const videoExtensions: string[] = [
  "mp4",
  "mov",
  "wmv",
  "avi",
  "avchd",
  "webm",
  "mkv",
  "ogv",
  "qt",
  "rm",
  "amv",
  "m4p",
  "m4v",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "m2v",
  "3gp",
  "3g2",
  "flv",
];
export const audioExensions: string[] = [
  "mp3",
  "aac",
  "aa",
  "aax",
  "dss",
  "dvf",
  "m4a",
  "m4b",
  "m4p",
  "mpc",
  "ogg",
  "oga",
  "mogg",
  "wav",
  "wma",
  "wv",
];
export const audioVideoExtensions: string[] = [...videoExtensions, ...audioExensions];
export type userActionEventType = {
  [userAction: string]: IUserActionEventConfig;
};

export type userActionEventsKey = keyof typeof userActionEvents;

// user action events naming convention
// fz_au_<platform>_<screen>_<actionName>

/* 
Main app screens

// h -> home
// f -> find
// fd -> find detail
// md -> media detail
// af -> add find
// afw -> add find from web
// msf -> multi select find
// t -> tag
// td -> tag details
// e -> event
// ed -> event details
// m -> messages
// cg -> create group
// gi -> group info
// ff -> find forward
// p -> profile
// up -> user profile
// r -> reports
// rl -> reports list
// bu -> blocked users
// v -> vendors
// vd -> vendor details
// afe -> add find to event

Share app screens

sah -> share app home
sati -> share app title and images 
sacf -> share app create find
sacg -> share app create group
*/

export const userActionEvents = {
  // find actions,

  // creates new find from tab bar center icon in findz screen
  createNewFind: {
    mobile: "fz_au_mbl_f_createNewFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by card in find screen
  createNewFindByCard: {
    mobile: "fz_au_mbl_f_createNewFindByCard",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by camera option in adding find screen
  createFindByCamera: {
    mobile: "fz_au_mbl_af_createFindByCamera",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by gallery option
  createFindByGallery: {
    mobile: "fz_au_mbl_af_createFindByGallery",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by internet option
  createFindByInternet: {
    mobile: "fz_au_mbl_af_createFindByInternet",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by files option
  createFindByFiles: {
    mobile: "fz_au_mbl_af_createFindByFiles",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by contact option
  createFindByContact: {
    mobile: "fz_au_mbl_af_createFindByContact",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating find by location option
  createFindByLocation: {
    mobile: "fz_au_mbl_af_createFindByLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding find screen more action buttons at bottom right
  createFindMoreActionButtons: {
    mobile: "fz_au_mbl_af_createFindMoreActionButtons",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // sorting web link photos by size
  sortPhotosBySize: {
    mobile: "fz_au_mbl_afw_sortWebPhotosBySize",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // create single find option while creating find
  createSingleFind: {
    mobile: "fz_au_mbl_af_createSingleFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // create multiple finds option when creating find
  createMultipleFinds: {
    mobile: "fz_au_mbl_af_createMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find media thumbnail
  findMediaThumbnail: {
    mobile: "fz_au_mbl_af_findMediaThumbnail",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // three vertical dots icon in find detail screen for more actions
  findDetailMoreActionButtons: {
    mobile: "fz_au_mbl_fd_findDetailMoreActionButtons",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding photo to find from find detail screen
  addPhotoToFind: {
    mobile: "fz_au_mbl_fd_addPhotoToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding photo to find from carousel
  addPhotoToFindFromCarousel: {
    mobile: "fz_au_mbl_fd_addPhotoToFindFromCarousel",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding collection to find by collection icon in find detail screen
  addCollectionToFindByIcon: {
    mobile: "fz_au_mbl_fd_addCollectionToFindByIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding collection to find from find detail form
  addCollectionToFindByForm: {
    mobile: "fz_au_mbl_fd_addCollectionToFindByForm",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit added find title in find detail form
  editFindTitle: {
    mobile: "fz_au_mbl_fd_editFindTitle",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit added vendor to find
  editFindVendor: {
    mobile: "fz_au_mbl_fd_editFindVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find's vendor detail. action sheet option
  findVendorDetails: {
    mobile: "fz_au_mbl_fd_findVendorDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds from vendor. action sheet option
  showFindsFromVendor: {
    mobile: "fz_au_mbl_fd_showFindsFromVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit price added to find. action sheet option
  editFindPrice: {
    mobile: "fz_au_mbl_fd_editFindPrice",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds with price close to price added to find. action sheet option
  showFindsWithPriceCloseTo: {
    mobile: "fz_au_mbl_fd_showFindsWithPriceCloseTo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit find's brand. action sheet option
  editFindBrand: {
    mobile: "fz_au_mbl_fd_editFindBrand",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds of brand. action sheet option
  showFindsFromBrand: {
    mobile: "fz_au_mbl_fd_showFindsFromBrand",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit note added to find. action sheet option
  editFindNotes: {
    mobile: "fz_au_mbl_fd_editFindNotes",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds with reaction added to find. action sheet option
  showFindsWithReaction: {
    mobile: "fz_au_mbl_fd_showFindsWithReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds with without the reaction added to find. action sheet option
  showFindsWithoutReaction: {
    mobile: "fz_au_mbl_fd_showFindsWithoutReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // delete reaction added to find. action sheet option
  deleteFindReaction: {
    mobile: "fz_au_mbl_fd_deleteFindReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit reaction comment added to find. action sheet option
  editFindReactionMessage: {
    mobile: "fz_au_mbl_fd_editFindReactionMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // copy reaction comment added to find. action sheet option
  copyFindReactionMessage: {
    mobile: "fz_au_mbl_fd_copyFindReactionMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reply to the reaction comment added to find. action sheet option
  replyFindReactionMessage: {
    mobile: "fz_au_mbl_fd_replyFindReactionMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds of the collection added to find. action sheet option
  showFindsWithCollection: {
    mobile: "fz_au_mbl_fd_showFindsWithCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // remove collection added to find. action sheet option
  removeCollectionFromFind: {
    mobile: "fz_au_mbl_fd_removeCollectionFromFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show find's of message added to find. action sheet option
  showFindsOfFindMessage: {
    mobile: "fz_au_mbl_fd_showFindsOfFindMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit message added to find. action sheet option
  editFindMessage: {
    mobile: "fz_au_mbl_fd_editFindMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // copy message added to find. action sheet option
  copyFindMessage: {
    mobile: "fz_au_mbl_fd_copyFindMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reply to message added to find. action sheet option
  replyFindMessage: {
    mobile: "fz_au_mbl_fd_replyFindMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // delete message added to find. action sheet option
  deleteFindMessage: {
    mobile: "fz_au_mbl_fd_deleteFindMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show event added to find. action sheet option
  showFindEventDetails: {
    mobile: "fz_au_mbl_fd_showFindEventDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // web link added to find
  findLink: {
    mobile: "fz_au_mbl_fd_findLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // location added to find
  findLocation: {
    mobile: "fz_au_mbl_fd_findLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding title to find
  addTitleToFind: {
    mobile: "fz_au_mbl_fd_addTitleToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // title added to find
  findTitle: {
    mobile: "fz_au_mbl_fd_findTitle",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding notes to find
  addNotesToFind: {
    mobile: "fz_au_mbl_fd_addNotesToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // notes added to find
  findNotes: {
    mobile: "fz_au_mbl_fd_findNotes",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding price to find
  addPriceToFind: {
    mobile: "fz_au_mbl_fd_addPriceToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // price added to find
  findPrice: {
    mobile: "fz_au_mbl_fd_findPrice",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding vendor to find
  addVendorToFind: {
    mobile: "fz_au_mbl_fd_addVendorToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // vendor added to find
  findVendor: {
    mobile: "fz_au_mbl_fd_findVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding brand to find
  addBrandToFind: {
    mobile: "fz_au_mbl_fd_addBrandToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // brand added to find
  findBrand: {
    mobile: "fz_au_mbl_fd_findBrand",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reaction added to find
  findReaction: {
    mobile: "fz_au_mbl_fd_findReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // comment added to find
  findComment: {
    mobile: "fz_au_mbl_fd_findComment",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // collection added to find
  findCollection: {
    mobile: "fz_au_mbl_fd_findCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // message added to find
  findMessage: {
    mobile: "fz_au_mbl_fd_findMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event added to find
  findEvent: {
    mobile: "fz_au_mbl_fd_findEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding web link to find
  addLinkToFind: {
    mobile: "fz_au_mbl_fd_addLinkToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // view on added web link to find. action sheet option
  viewOnFindLink: {
    mobile: "fz_au_mbl_fd_viewOnFindLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds from web link added to find. action sheet options
  showFindsFromFindLink: {
    mobile: "fz_au_mbl_fd_showFindsFromFindLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing web link added to find. action sheet options
  editFindLink: {
    mobile: "fz_au_mbl_fd_editFindLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // remove web link added to find. action sheet option
  removeFindLink: {
    mobile: "fz_au_mbl_fd_removeFindLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding location to find option in find details form
  addLocationToFind: {
    mobile: "fz_au_mbl_fd_addLocationToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edit location added to find. action sheet option
  editLocationOfFind: {
    mobile: "fz_au_mbl_fd_editLocationOfFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // remove location added to find. action sheet option
  removeLocationFromFind: {
    mobile: "fz_au_mbl_fd_removeLocationFromFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //show location added to find in findz map. action sheet option
  showFindLocationOnFindzMap: {
    mobile: "fz_au_mbl_fd_showFindLocationOnFindzMap",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show location added to find in apple map. action sheet option
  showFindLocationOnAppleMap: {
    mobile: "fz_au_mbl_fd_showFindLocationOnAppleMap",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show location added to find in google map. action sheet option
  showFindLocationOnGoogleMap: {
    mobile: "fz_au_mbl_fd_showOnGoogleMap",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // show finds of location. action sheet option
  showFindsFromLocation: {
    mobile: "fz_au_mbl_fd_showFindsFromLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding reaction to find by collection icon in find details screen
  addReactionToFindByIcon: {
    mobile: "fz_au_mbl_fd_addReactionToFindByIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding reaction to find by option in find details form
  addReactionToFindByForm: {
    mobile: "fz_au_mbl_fd_addReactionToFindByForm",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting added find
  deleteFind: {
    mobile: "fz_au_mbl_fd_deleteFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // sharing find
  shareFind: {
    mobile: "fz_au_mbl_fd_shareFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // forwarding find by icon in find details screen
  forwardFind: {
    mobile: "fz_au_mbl_fd_forwardFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // forwarding find by option in find details form
  forwardFindByForm: {
    mobile: "fz_au_mbl_fd_forwardFindByForm",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding message to find by icon
  addMessageToFind: {
    mobile: "fz_au_mbl_fd_addMessageToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding message to find by option in find details form
  addMessageToFindByForm: {
    mobile: "fz_au_mbl_fd_addMessageToFindByForm",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding find to an event. action sheet option from more actions (three vertical dots icon in details form).
  addFindToAnEvent: {
    mobile: "fz_au_mbl_fd_addFindToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding find to an event by option in find details form
  addFindToAnEventByForm: {
    mobile: "fz_au_mbl_fd_addFindToEventByForm",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding files to find. action sheet option
  addFilesToFind: {
    mobile: "fz_au_mbl_fd_addFilesToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding contact to find. action sheet option
  addContactToFind: {
    mobile: "fz_au_mbl_fd_addContactToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // downloading find media in find details form. action sheet option
  saveToDownloads: {
    mobile: "fz_au_mbl_fd_saveToDownloads",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // showing curreny list, clicking on currency in details form
  showCurrencyList: {
    mobile: "fz_au_mbl_fd_currencyList",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // repoting find to findz. action sheet option
  reportFindToFindz: {
    mobile: "fz_au_mbl_fd_reportFindToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reporting find to admins. action sheet option
  reportFindToAdmins: {
    mobile: "fz_au_mbl_fd_reportFindToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find added on date in findz summary(above details form). shows action sheet options
  findAddedOn: {
    mobile: "fz_au_mbl_fd_findAddedOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find created by in findz summary
  findCreatedBy: {
    mobile: "fz_au_mbl_fd_findCreatedBy",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find found on in findz summary
  findFoundOn: {
    mobile: "fz_au_mbl_fd_findFoundOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find located at in findz summary
  findLocatedAt: {
    mobile: "fz_au_mbl_fd_findLocatedAt",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find found at url in findz summary
  findFoundAtUrl: {
    mobile: "fz_au_mbl_fd_findFoundAtUrl",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // showing finds added on date. action sheet option
  showFindsAddedOn: {
    mobile: "fz_au_mbl_fd_showFindsAddedOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // showing finds found on date. action sheet option
  showFindsFoundOn: {
    mobile: "fz_au_mbl_fd_showFindsFoundOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // showing finds crdeated by. action sheet option
  showFindsCreatedBy: {
    mobile: "fz_au_mbl_fd_showFindsCreatedBy",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // using file name for title
  useFileNameMainApp: {
    mobile: "fz_au_mbl_fd_useFileName",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find media
  findMedia: {
    mobile: "fz_au_mbl_fd_findMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find summary
  findSummary: {
    mobile: "fz_au_mbl_fd_findSummary",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find bottom tab bar icon
  findBottomTabBarIconFindsScreen: {
    mobile: "fz_au_mbl_f_findBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // tag bottom tab bar icon
  tagBottomTabBarIconFindsScreen: {
    mobile: "fz_au_mbl_f_tagBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event bottom tab bar icon
  eventBottomTabBarIconFindsScreen: {
    mobile: "fz_au_mbl_f_eventBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // message bottom tab bar icon
  messageBottomTabBarIconFindsScreen: {
    mobile: "fz_au_mbl_f_messageBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find
  searchFind: {
    mobile: "fz_au_mbl_f_searchFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by added date
  searchFindByAddedDate: {
    mobile: "fz_au_mbl_f_searchFindByAddedDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by added on date
  searchFindAddedOnDate: {
    mobile: "fz_au_mbl_f_searchFindAddedByOnDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by added on before date
  searchFindAddedOnBeforeDate: {
    mobile: "fz_au_mbl_f_searchFindAddedByBeforeDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by added on after date
  searchFindAddedOnAfterDate: {
    mobile: "fz_au_mbl_f_searchFindAddedByAfterDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by added on between date
  searchFindAddedBetweenDate: {
    mobile: "fz_au_mbl_f_searchFindAddedByBetweenDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by found date
  searchFindByFoundDate: {
    mobile: "fz_au_mbl_f_searchFindByFoundDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by found on date
  searchFindFoundOnDate: {
    mobile: "fz_au_mbl_f_searchFindByFoundOnDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by found on before date
  searchFindFoundOnBeforeDate: {
    mobile: "fz_au_mbl_f_searchFindFoundByBeforeDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by found on after date
  searchFindFoundOnAfterDate: {
    mobile: "fz_au_mbl_f_searchFindFoundByAfterDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by found between date
  searchFindFoundBetweenDate: {
    mobile: "fz_au_mbl_f_srchFindFoundByBetweenDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by collection
  searchFindByCollection: {
    mobile: "fz_au_mbl_f_searchFindFoundByCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by reaction
  searchFindByReaction: {
    mobile: "fz_au_mbl_f_searchFindFoundByReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by place
  searchFindByPlace: {
    mobile: "fz_au_mbl_f_searchFindByPlace",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by web
  searchFindByWeb: {
    mobile: "fz_au_mbl_f_searchFindByWeb",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by users
  searchFindByUsers: {
    mobile: "fz_au_mbl_f_searchFindByUsers",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by price
  searchFindByPrice: {
    mobile: "fz_au_mbl_f_searchFindByPrice",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by vendor
  searchFindByVendor: {
    mobile: "fz_au_mbl_f_searchFindByVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching find by brand
  searchFindByBrand: {
    mobile: "fz_au_mbl_f_searchFindByBrand",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in find screen
  myFindsInfoFindScreen: {
    mobile: "fz_au_mbl_f_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in find screen
  groupInfoFindScreen: {
    mobile: "fz_au_mbl_f_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing report to findz. action sheet option
  reportedFindToFindz: {
    mobile: "fz_au_mbl_fd_reportedToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing report to admins. action sheet option
  reportedFindToAdmins: {
    mobile: "fz_au_mbl_fd_reportedToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // hamberger menu icon in find screen
  moreOptionsFindsScreen: {
    mobile: "fz_au_mbl_f_moreOptions",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // multi select find Action

  // deleting multiple finds in findz multi select screen
  deleteMultipleFinds: {
    mobile: "fz_au_mbl_msf_deleteMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding reaction to multiple finds
  addReactionToMultipleFinds: {
    mobile: "fz_au_mbl_msf_addReactionToMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // sharing mulitple finds
  shareMultipleFinds: {
    mobile: "fz_au_mbl_msf_shareMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding message to multiple finds
  addMessageToMultipleFinds: {
    mobile: "fz_au_mbl_msf_addMessageToMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding collection to multiple finds
  addCollectionToMultipleFinds: {
    mobile: "fz_au_mbl_msf_addCollectionMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding event to multiple finds
  addEventToMultipleFinds: {
    mobile: "fz_au_mbl_msf_addEventToMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // forwarding multiple selected finds
  forwardMultipleFinds: {
    mobile: "fz_au_mbl_msf_forwardMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // downloading media of selected muliple finds
  downloadMultipleFinds: {
    mobile: "fz_au_mbl_msf_downloadMultipleFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // three vertical dots icon. shows action buttons
  multiSelectFindsMoreActionButtons: {
    mobile: "fz_au_mbl_msf_moreActionButtons",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // find forward action
  // adding phone number profile from find forward screen
  addPhoneNumberFromFindForward: {
    mobile: "fz_au_mbl_ff_addPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // verifying phone number from find forward screen
  verifyPhoneNumberFromFindForward: {
    mobile: "fz_au_mbl_ff_verifyPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // collection actions

  // adding new collection by tab bar center icon in tag screen
  addNewCollection: {
    mobile: "fz_au_mbl_t_addNewCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding new collection by 'add new collection' card in tag screen
  addNewCollectionByCard: {
    mobile: "fz_au_mbl_t_addNewCollectionByCard",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding new sub collection ton the collelction in tag details
  addNewSubCollection: {
    mobile: "fz_au_mbl_td_addNewSubCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // renaming the collection. action sheet option from tag details
  renameCollection: {
    mobile: "fz_au_mbl_td_renameCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the collection. action sheet option from tag details
  deleteCollection: {
    mobile: "fz_au_mbl_td_deleteCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding finds to collection from finds sub tab in tag details
  addFindsToCollection: {
    mobile: "fz_au_mbl_td_addFindsToCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating new find and adding it to collection from finds sub tab in tag details
  addNewFindToCollection: {
    mobile: "fz_au_mbl_td_addNewFindToCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing the finds added to the collection
  removeSelectedFindsFromCollection: {
    mobile: "fz_au_mbl_td_removeSelectedFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // search collection in tag screen
  searchCollection: {
    mobile: "fz_au_mbl_t_searchCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // navigating to parent collection from sub collection in tag details collection path
  goToParentCollection: {
    mobile: "fz_au_mbl_td_goToParentCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows more action buttons in tag details screen
  tagDetailMoreActionButtons: {
    mobile: "fz_au_mbl_td_tagDetailMoreActionButtons",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in tag screen
  myFindsInfoTagScreen: {
    mobile: "fz_au_mbl_t_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in tag screen
  groupInfoTagScreen: {
    mobile: "fz_au_mbl_t_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in tag detail screen
  myFindsInfoTagDetailScreen: {
    mobile: "fz_au_mbl_td_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in tag detail screen
  groupInfoTagDetailScreen: {
    mobile: "fz_au_mbl_td_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // hamberger menu icon in tag screen
  moreOptionsTagsScreen: {
    mobile: "fz_au_mbl_t_moreOptions",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find bottom tab bar icon
  findBottomTabBarIconTagsScreen: {
    mobile: "fz_au_mbl_t_findBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // tag bottom tab bar icon
  tagBottomTabBarIconTagsScreen: {
    mobile: "fz_au_mbl_t_tagBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event bottom tab bar icon
  eventBottomTabBarIconTagsScreen: {
    mobile: "fz_au_mbl_t_eventBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // message bottom tab bar icon
  messageBottomTabBarIconTagsScreen: {
    mobile: "fz_au_mbl_t_messageBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // add message to tag
  addMessageToTag: {
    mobile: "fz_au_mbl_td_addMessageToTag",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // copying event tag message in comments tab
  copyTagMessage: {
    mobile: "fz_au_mbl_td_copyTagMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing event tag message in comments tab
  editTagMessage: {
    mobile: "fz_au_mbl_td_editTagMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //deleting tag message in comments tab
  deleteTagMessage: {
    mobile: "fz_au_mbl_td_deleteTagMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // replying tag message in comments tab
  replyTagMessage: {
    mobile: "fz_au_mbl_td_replyTagMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // event actions

  // creating new event from tab bar center icon in events screen
  createNewEvent: {
    mobile: "fz_au_mbl_e_createNewEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding finds to the event
  addFindsToEvent: {
    mobile: "fz_au_mbl_ed_addFindsToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating new finds and adding it to the event
  addNewFindToEvent: {
    mobile: "fz_au_mbl_ed_addNewFindToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing the added finds from event
  removeSelectedFindsFromEvent: {
    mobile: "fz_au_mbl_ed_removeSelectedFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // cacelling event. action sheet option
  cancelEvent: {
    mobile: "fz_au_mbl_ed_cancelEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting event. action sheet option
  deleteEvent: {
    mobile: "fz_au_mbl_ed_deleteEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reaction added to event
  eventReaction: {
    mobile: "fz_au_mbl_ed_eventReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reporting event to findz. action sheet option
  reportEventToFindz: {
    mobile: "fz_au_mbl_ed_reportEventToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reporting event to admins. action sheet option
  reportEventToAdmins: {
    mobile: "fz_au_mbl_ed_reportEventToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // addoing meeting link to an event. event details screen
  addMeetingLinkToEvent: {
    mobile: "fz_au_mbl_ed_addMeetingLinkToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // added event meeting link
  eventMeetingLink: {
    mobile: "fz_au_mbl_ed_eventMeetingLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding location to event
  addLocationToEvent: {
    mobile: "fz_au_mbl_ed_addLocationToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // added loction to event
  eventLocation: {
    mobile: "fz_au_mbl_ed_eventLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding remainder to event
  addReminderToEvent: {
    mobile: "fz_au_mbl_ed_addReminderToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding reactionn to event
  addReactionToEvent: {
    mobile: "fz_au_mbl_ed_addReactionToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows more action buttons in event details screen
  eventDetailMoreActionButtons: {
    mobile: "fz_au_mbl_ed_moreActionButtons",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // edititng event location
  editEventLocation: {
    mobile: "fz_au_mbl_ed_editEventLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing the added event location
  removeEventLocation: {
    mobile: "fz_au_mbl_ed_removeEventLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows event at added location. action sheet option
  showEventsAtLocation: {
    mobile: "fz_au_mbl_ed_showEventsAtLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows event's location in apple maps. action sheet option
  showEventLocationInAppleMaps: {
    mobile: "fz_au_mbl_ed_showEventLocationInAppleMap",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows event's location in google maps. action sheet option
  showEventLocationInGoogleMaps: {
    mobile: "fz_au_mbl_ed_showEventLocationGoogleMaps",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows events with added reaction. action sheet option
  showEventsWithReaction: {
    mobile: "fz_au_mbl_ed_showEventsWithReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows events with without the added reaction. action sheet option
  showEventsWithoutReaction: {
    mobile: "fz_au_mbl_ed_showEventsWithoutReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the event reaction
  deleteEventReaction: {
    mobile: "fz_au_mbl_ed_deleteEventReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing the event link
  viewOnEventOnlineLink: {
    mobile: "fz_au_mbl_ed_viewOnEventOnlineLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing the event onine link
  editEventOnlineLink: {
    mobile: "fz_au_mbl_ed_editEventOnlineLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing the event online link
  removeEventOnlineLink: {
    mobile: "fz_au_mbl_ed_removeEventOnlineLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding description to event
  addDescriptionToEvent: {
    mobile: "fz_au_mbl_ed_addDescriptionToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // description of event
  editEventDescription: {
    mobile: "fz_au_mbl_ed_editEventDescription",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //  changing the event's time zone
  changeEventTimeZone: {
    mobile: "fz_au_mbl_ed_changeEventTimeZone",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event title
  eventTitle: {
    mobile: "fz_au_mbl_ed_eventTitle",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //changing the event start date
  changeEventStartDate: {
    mobile: "fz_au_mbl_ed_changeEventStartDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // changing the event end date
  changeEventEndDate: {
    mobile: "fz_au_mbl_ed_changeEventEndDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //  changing the event start time
  changeEventStartTime: {
    mobile: "fz_au_mbl_ed_changeEventStartTime",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // changing the event end time
  changeEventEndTime: {
    mobile: "fz_au_mbl_ed_changeEventEndTime",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding attending status 'yes' to the event
  addAttendingStatusYesForEvent: {
    mobile: "fz_au_mbl_ed_attendingStatusYesEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding attending status 'no' to the event
  addAttendingStatusNoForEvent: {
    mobile: "fz_au_mbl_ed_attendingStatusNoEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event
  searchEvent: {
    mobile: "fz_au_mbl_e_searchEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by location
  searchEventByLocation: {
    mobile: "fz_au_mbl_e_searchEventByLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by date
  searchEventByDate: {
    mobile: "fz_au_mbl_e_searchEventByDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by on date
  searchEventByOnDate: {
    mobile: "fz_au_mbl_e_searchEventByOnDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by before date
  searchEventByBeforeDate: {
    mobile: "fz_au_mbl_e_searchEventByBeforeDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by after date
  searchEventByAfterDate: {
    mobile: "fz_au_mbl_e_searchEventByAfterDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by between date
  searchEventByBetweenDate: {
    mobile: "fz_au_mbl_e_searchEventByBetweenDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by 'created by'
  searchEventByCreated: {
    mobile: "fz_au_mbl_e_searchEventByCreated",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by reaction
  searchEventByReaction: {
    mobile: "fz_au_mbl_e_searchEventByReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by attending status 'yes'
  searchEventByAttending: {
    mobile: "fz_au_mbl_e_searchEventByAttending",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by attending status 'no'
  searchEventByNotAttending: {
    mobile: "fz_au_mbl_e_searchEventByNotAttndng",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching event by not responded to attending
  searchEventByNotResponded: {
    mobile: "fz_au_mbl_e_searchEventByNotRspnded",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing reported event to findz
  reportedEventToFindz: {
    mobile: "fz_au_mbl_ed_reportedToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing reported event to admins
  reportedEventToAdmins: {
    mobile: "fz_au_mbl_ed_reportedToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating new event while adding find to an event
  createNewEventWhileAddingFindToEvent: {
    mobile: "fz_au_mbl_afe_createNewEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in event screen
  myFindsInfoEventScreen: {
    mobile: "fz_au_mbl_e_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in event screen
  groupInfoEventScreen: {
    mobile: "fz_au_mbl_e_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // hamberger menu icon in event screen
  moreOptionsEventsScreen: {
    mobile: "fz_au_mbl_e_moreOptions",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find bottom tab bar icon
  findBottomTabBarIconEventsScreen: {
    mobile: "fz_au_mbl_e_findBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // tag bottom tab bar icon
  tagBottomTabBarIconEventsScreen: {
    mobile: "fz_au_mbl_e_tagBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event bottom tab bar icon
  eventBottomTabBarIconEventsScreen: {
    mobile: "fz_au_mbl_e_eventBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // message bottom tab bar icon
  messageBottomTabBarIconEventsScreen: {
    mobile: "fz_au_mbl_e_messageBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // add message to event
  addMessageToEvent: {
    mobile: "fz_au_mbl_ed_addMessageToEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // copying event tag message in comments tab
  copyEventMessage: {
    mobile: "fz_au_mbl_ed_copyEventMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing event Event message in comments tab
  editEventMessage: {
    mobile: "fz_au_mbl_ed_editEventMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //deleting Event message in comments tab
  deleteEventMessage: {
    mobile: "fz_au_mbl_ed_deleteEventMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // replying Event message in comments tab
  replyEventMessage: {
    mobile: "fz_au_mbl_ed_replyEventMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // message actions

  // create find option in input feild from messages screen
  createFindAndSendMessage: {
    mobile: "fz_au_mbl_m_createFindAndSendMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // sending new message
  sendNewMessage: {
    mobile: "fz_au_mbl_m_sendNewMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reporting message to findz. action sheet option
  reportMessageToFindz: {
    mobile: "fz_au_mbl_m_reportMessageToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reporting messsage to admins. action sheet option
  reportMessageToAdmins: {
    mobile: "fz_au_mbl_m_reportMessageToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // replying to message. action sheet option
  replyToMessage: {
    mobile: "fz_au_mbl_m_replyToMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing the message. action sheet option
  editMessage: {
    mobile: "fz_au_mbl_m_editMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the message. action sheet option
  deleteMessage: {
    mobile: "fz_au_mbl_m_deleteMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // coping the message. action sheet option
  copyMessage: {
    mobile: "fz_au_mbl_m_copyMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows forward activity. action sheet option
  showForwardActivity: {
    mobile: "fz_au_mbl_m_showForwardActivity",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds of message. action sheet option
  showFindOfMessage: {
    mobile: "fz_au_mbl_m_showFindOfMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows collection of message. action sheet option
  showCollectionOfMessage: {
    mobile: "fz_au_mbl_m_showCollectionOfMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows events of message. action sheet option
  showEventOfMessage: {
    mobile: "fz_au_mbl_m_showEventOfMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message
  searchMessage: {
    mobile: "fz_au_mbl_m_searchMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by date
  searchMessageByDate: {
    mobile: "fz_au_mbl_m_searchMessageByDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by on date
  searchMessageByOnDate: {
    mobile: "fz_au_mbl_m_searchMessageByOnDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by before date
  searchMessageByBeforeDate: {
    mobile: "fz_au_mbl_m_searchMessageByBeforeDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by after date
  searchMessageByAfterDate: {
    mobile: "fz_au_mbl_m_searchMessageByAfterDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by between date
  searchMessageByBetweenDate: {
    mobile: "fz_au_mbl_m_searchMessageByBetweenDate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by 'from'
  searchMessageByFrom: {
    mobile: "fz_au_mbl_m_searchMessageByFrom",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by 'to'
  searchMessageByTo: {
    mobile: "fz_au_mbl_m_searchMessageByTo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by reaction
  searchMesageByReaction: {
    mobile: "fz_au_mbl_m_searchMessageByReaction",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by finds
  searchMessageByFind: {
    mobile: "fz_au_mbl_m_searchMessageByFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by collection
  searchMessageByCollection: {
    mobile: "fz_au_mbl_m_searchMessageByCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // searching message by event
  searchMessageByEvent: {
    mobile: "fz_au_mbl_m_searchMessageByEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing reported message to findz. action sheet option
  reportedMessageToFindz: {
    mobile: "fz_au_mbl_m_reportedToFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing reported message to admins. action sheet option
  reportedMessageToAdmins: {
    mobile: "fz_au_mbl_m_reportedToAdmins",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // mentioning user in message
  mentionUserInMessage: {
    mobile: "fz_au_mbl_m_mentionUserInMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in messages screen
  myFindsInfoMessagesScreen: {
    mobile: "fz_au_mbl_m_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in messages screen
  groupInfoMessagesScreen: {
    mobile: "fz_au_mbl_m_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // hamberger menu icon in event screen
  moreOptionsMessagesScreen: {
    mobile: "fz_au_mbl_m_moreOptions",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // find bottom tab bar icon
  findBottomTabBarIconMessagesScreen: {
    mobile: "fz_au_mbl_m_findBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // tag bottom tab bar icon
  tagBottomTabBarIconMessagesScreen: {
    mobile: "fz_au_mbl_m_tagBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // event bottom tab bar icon
  eventBottomTabBarIconMessagesScreen: {
    mobile: "fz_au_mbl_m_eventBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // message bottom tab bar icon
  messageBottomTabBarIconMessagesScreen: {
    mobile: "fz_au_mbl_m_messageBottomTabBarIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding new message
  addMessageMessagesScreen: {
    mobile: "fz_au_mbl_m_addMessage",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // home screen actions

  // navigating to my finds
  myFinds: {
    mobile: "fz_au_mbl_h_myFinds",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // user profile settings
  settings: {
    mobile: "fz_au_mbl_h_settings",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating new by group icon adjacent to group search
  createGroupByIcon: {
    mobile: "fz_au_mbl_h_createGroupByIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creating new group from last option under groups list
  createGroupByLabel: {
    mobile: "fz_au_mbl_h_createGroupByLabel",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // accepting group invitaion
  acceptInvitation: {
    mobile: "fz_au_mbl_h_acceptInvitation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // declining group invitaion
  declineInvitation: {
    mobile: "fz_au_mbl_h_declineInvitation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // decline and block group invitattion
  declineInvitationAndBlockUser: {
    mobile: "fz_au_mbl_h_declineInvitationBlockUser",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // on group invitation received
  invitationReceived: {
    mobile: "fz_au_mbl_h_invitationReceived",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding phone number from home screen
  addPhoneNumberFromHome: {
    mobile: "fz_au_mbl_h_addPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // verifying phone number from home screen
  verifyPhoneNumberFromHome: {
    mobile: "fz_au_mbl_h_verifyPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // resend verification email at top of home screen
  resendVerificationEmailFromHome: {
    mobile: "fz_au_mbl_h_resendVerificationEmail",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // already verified my email at top of home screen
  alreadyVerifiedMyEmailFromHome: {
    mobile: "fz_au_mbl_h_alreadyVerifiedMyEmail",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // group info screen actions

  // adding new member to group
  addNewMember: {
    mobile: "fz_au_mbl_gi_addNewMember",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the group profile picture
  deleteGroupProfilePicture: {
    mobile: "fz_au_mbl_gi_deleteGrpProfilePicture",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing the group name
  editGroupName: {
    mobile: "fz_au_mbl_gi_editGroupName",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding or changing the group profile picture
  addOrChangeGroupProfilePicture: {
    mobile: "fz_au_mbl_gi_addChangeGrpProfilePctr",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding contact details for new memeber
  addContactDetailsAddingMember: {
    mobile: "fz_au_mbl_gi_addContactDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding new memeber to group by selecting contact from phone's contact list
  phoneContactListAddingMember: {
    mobile: "fz_au_mbl_gi_phoneContactList",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // retrying the failed invitation
  retryInvitation: {
    mobile: "fz_au_mbl_gi_retryInvitation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // changing added member's number with new number. when the invitation is failed to send
  tryDifferentNumber: {
    mobile: "fz_au_mbl_gi_tryDifferentNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // making member admin
  makeMemberAdmin: {
    mobile: "fz_au_mbl_gi_makeMemberAdmin",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing member from admin
  removeMemberFromAdmin: {
    mobile: "fz_au_mbl_gi_removeMemberFromAdmin",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // removing memeber from group
  removeMemberFromGroup: {
    mobile: "fz_au_mbl_gi_removeMemberFromGroup",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the group
  deleteGroup: {
    mobile: "fz_au_mbl_gi_deleteGroup",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // exiting from group
  exitFromGroup: {
    mobile: "fz_au_mbl_gi_exitFromGroup",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // enabling notification permissions for group
  enableNotificationsForGroup: {
    mobile: "fz_au_mbl_gi_enableNotifications",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // disabling notifications for group
  disableNotificationsForGroup: {
    mobile: "fz_au_mbl_gi_disableNotifications",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notification for adding find or collection to group
  notifOnAddingFindOrCollection: {
    mobile: "fz_au_mbl_gi_ntfOnAddngFndOrCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notification for adding find or collection to group
  notifOffAddingFindOrCollection: {
    mobile: "fz_au_mbl_gi_ntfOffAddingFndOrCollection",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notification for adding or modifying the group
  notifOnAddOrModifyingEvent: {
    mobile: "fz_au_mbl_gi_ntfOnAddOrModifyngEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notification for adding or modifying the event
  notifOffAddOrModifyingEvent: {
    mobile: "fz_au_mbl_gi_ntfOffAddOrModfyngEvent",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notification for messages from anyone
  notifOnMessagesFromAnyone: {
    mobile: "fz_au_mbl_gi_ntfOnMessagesFromAnyone",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notification for messages from anyone
  notifOffMessagesFromAnyone: {
    mobile: "fz_au_mbl_gi_ntfOffMessagesFromAnyone",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notification for messages to me from group
  notifOnMessagesToMe: {
    mobile: "fz_au_mbl_gi_notifOnMessagesToMe",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notification for messages to me from group
  notifOffMessagesToMe: {
    mobile: "fz_au_mbl_gi_notifOffMessagesToMe",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notifications for group modification
  notifOnGroupModify: {
    mobile: "fz_au_mbl_gi_notifOnGroupModify",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notifications for group modification
  notifOffGroupModify: {
    mobile: "fz_au_mbl_gi_notifOffGroupModify",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // enabling notification permission for findz from goup info screen
  enableNotificationPermissionForFindzFromGroupInfo: {
    mobile: "fz_au_mbl_gi_enableNotificationPermisson",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // hiding contact details
  hideContactDetails: {
    mobile: "fz_au_mbl_gi_hideContactDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // member since details
  memberSinceGroupInfo: {
    mobile: "fz_au_mbl_gi_memberSince",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // member info
  memberInfo: {
    mobile: "fz_au_mbl_gi_memberInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // create group screen actions

  // make member admin while creating group
  makeMemberAdminGroupCreate: {
    mobile: "fz_au_mbl_cg_makeAdminGroupCreate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // remove member from admin while crearting group
  removeMemberAdminGroupCreate: {
    mobile: "fz_au_mbl_cg_removeAdminGroupCreate",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // remove selected member
  removeSelectedMemberGroupCreate: {
    mobile: "fz_au_mbl_cg_removeSelectedMember",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding contact details for adding new member
  addContactDetailsGroupCreate: {
    mobile: "fz_au_mbl_cg_addContactDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // selecting member for new group from phone's contact list
  phoneContactListGroupCreate: {
    mobile: "fz_au_mbl_cg_phoneContactList",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // user profile screen actions

  // adding or changing the user profile picture
  addOrChangeProfilePicture: {
    mobile: "fz_au_mbl_up_addOrChangeProfilePicture",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // deleting the user profile picture
  deleteProfilePicture: {
    mobile: "fz_au_mbl_up_deleteProfilePicture",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing the user profile
  editUserProfile: {
    mobile: "fz_au_mbl_up_editUserProfile",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding phone number to profile
  addPhoneNumberFromUserProfile: {
    mobile: "fz_au_mbl_up_addPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // changing phone number
  changePhoneNumber: {
    mobile: "fz_au_mbl_up_changePhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // verifying the phone number
  verifyPhoneNumberFromUserProfile: {
    mobile: "fz_au_mbl_up_verifyPhoneNumber",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // enabling notification permission for findz from user profile screen
  enableNotificationPermissionForFindzFromUserProfile: {
    mobile: "fz_au_mbl_up_enableNotificationPermisson",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // enabling all notifications for findz
  enableAllNotificationsForFindz: {
    mobile: "fz_au_mbl_up_enableAllNotificationsFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // muting all notifications for findz
  muteAllNotificationsForFindz: {
    mobile: "fz_au_mbl_up_muteAllNotificationForFindz",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notification for all user notifications
  allUserNotificationsOn: {
    mobile: "fz_au_mbl_up_allUserNotificationsOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notification for all user notifications
  allUserNotificationsOff: {
    mobile: "fz_au_mbl_up_allUserNotificationsOff",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning ON notifications for findz announcements
  announcementsNotificationOn: {
    mobile: "fz_au_mbl_up_announcementsNotificationOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // turning OFF notifications for findz announcements
  announcementsNotificationOff: {
    mobile: "fz_au_mbl_up_announcementNotificationOff",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // blocked user list
  blockedUsers: {
    mobile: "fz_au_mbl_up_blockedUsers",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // unblocking the blocked user
  unblockUser: {
    mobile: "fz_au_mbl_bu_unblockUser",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // singing out from app
  signOut: {
    mobile: "fz_au_mbl_up_signOut",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // closing account
  closeAccount: {
    mobile: "fz_au_mbl_up_closeAccount",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // granting camera permission
  grantCameraPermission: {
    mobile: "fz_au_mbl_up_grantCameraPermission",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // granting location permission
  grantLocationPermission: {
    mobile: "fz_au_mbl_up_grantLocationPermission",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // granting storage permission
  grantStoragePermission: {
    mobile: "fz_au_mbl_up_grantStoragePermission",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // granting contacts permission
  grantContactsPermission: {
    mobile: "fz_au_mbl_up_grantContactsPermission",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // resends verification email for account verification
  resendVerificationEmailFromUserProfile: {
    mobile: "fz_au_mbl_up_resendVerificationEmail",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // user selecting on 'i have already verified my email' option from verify email banner
  alreadyVerifiedMyEmailFromUserProfile: {
    mobile: "fz_au_mbl_up_alreadyVerifiedMyEmail",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // findz privacy policy button
  findzPrivacyPolicy: {
    mobile: "fz_au_mbl_up_findzPrivacyPolicy",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // member since details
  memberSinceUserProfile: {
    mobile: "fz_au_mbl_up_memberSince",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // profile screen actions

  // shows user's app usage and stats
  usageAndStats: {
    mobile: "fz_au_mbl_p_usageAndStats",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows vendors list
  vendors: {
    mobile: "fz_au_mbl_p_vendors",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows how to add a find
  howCanIAddFind: {
    mobile: "fz_au_mbl_p_howCanIAddFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // navigates to group info screen
  groupProfileAndSettings: {
    mobile: "fz_au_mbl_p_groupProfileAndSettings",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows all repots list
  reports: {
    mobile: "fz_au_mbl_p_reports",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // crearting new vendor
  createVendor: {
    mobile: "fz_au_mbl_v_createVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // vendor details
  vendorDetails: {
    mobile: "fz_au_mbl_v_vendorDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds of vendor. action sheet option
  showFindsOfVendor: {
    mobile: "fz_au_mbl_vd_showFindsOfVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // create new group from vendor
  createGroupFromVendor: {
    mobile: "fz_au_mbl_vd_createGroupFromVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // editing the vendor
  editVendor: {
    mobile: "fz_au_mbl_vd_editVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // navigates to vendor group
  goToVendorGroup: {
    mobile: "fz_au_mbl_vd_goToVendorGroup",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // craetes new find from vendor detail screen
  createFindFromVendor: {
    mobile: "fz_au_mbl_vd_createFindFromVendor",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows more action buttons for vendor
  vendorDetailMoreActionButtons: {
    mobile: "fz_au_mbl_vd_vendorDetailMoreActnButton",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // my finds info in profile screen
  myFindsInfoProfileScreen: {
    mobile: "fz_au_mbl_p_myFindsInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // group info in profile screen
  groupInfoProfileScreen: {
    mobile: "fz_au_mbl_p_groupInfo",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // reports screen actions

  //withdraw report
  withdrawReportFromReportsList: {
    mobile: "fz_au_mbl_rl_withdrawReport",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // resolve report
  resolveReportFromReportsList: {
    mobile: "fz_au_mbl_rl_resolveReport",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds of the report
  showFindOfReport: {
    mobile: "fz_au_mbl_rl_showFindOfReport",
    pwa: "",
    target: ["fb-rtdb"],
  },
  //shows event of the report
  showEventOfReport: {
    mobile: "fz_au_mbl_rl_showEventOfReport",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows message of the report
  showMessageOfReport: {
    mobile: "fz_au_mbl_rl_showMessageOfReport",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // media details actions

  // deletes media of find
  deleteMedia: {
    mobile: "fz_au_mbl_md_deleteMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shares media of find
  shareMedia: {
    mobile: "fz_au_mbl_md_shareMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows preview of find media clicking on preview button
  previewMediaByIcon: {
    mobile: "fz_au_mbl_md_previewMediaByIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows preview of find media
  previewMedia: {
    mobile: "fz_au_mbl_md_previewMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // downloads find media
  downloadMedia: {
    mobile: "fz_au_mbl_md_downloadMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media added on date media summary in media details screen. clicking on so shows action sheet options
  mediaAddedOn: {
    mobile: "fz_au_mbl_md_mediaAddedOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media created by user
  mediaCreatedBy: {
    mobile: "fz_au_mbl_md_mediaCreatedBy",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media found on date
  mediaFoundOn: {
    mobile: "fz_au_mbl_md_mediaFoundOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media located at
  mediaLocatedAt: {
    mobile: "fz_au_mbl_md_mediaLocatedAt",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media found at url
  mediaFoundAtUrl: {
    mobile: "fz_au_mbl_md_mediaFoundAtUrl",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // sets media as default
  setAsDefaultMedia: {
    mobile: "fz_au_mbl_md_setAsDefaultMedia",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds of added date. action sheet option
  showFindsAddedOnFromMedia: {
    mobile: "fz_au_mbl_md_showFindsAddedOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds found on date. action sheet option
  showFindsFoundOnFromMedia: {
    mobile: "fz_au_mbl_md_showFindsFoundOn",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds created by user. action sheet option
  showFindsCreatedByFromMedia: {
    mobile: "fz_au_mbl_md_showFindsCreatedBy",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds from location. action sheet option
  showFindsFromLocationFromMedia: {
    mobile: "fz_au_mbl_md_showFindsFromLocation",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows location on findz map. action sheet option
  showOnFindzMapFromMedia: {
    mobile: "fz_au_mbl_md_showOnFindzMap",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // viewing link from media. action sheet option
  viewOnLinkFromMedia: {
    mobile: "fz_au_mbl_ms_viewOnLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows finds from link. action sheet option
  showFindsFromLinkFromMedia: {
    mobile: "fz_au_mbl_md_showFindsFromLink",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // media summary
  mediaSummary: {
    mobile: "fz_au_mbl_md_mediaSummary",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // share app actions

  // sorts photos by size
  sortPhotosBySizeFromShareApp: {
    mobile: "fz_au_mbl_sati_sortWebPhotosBySize",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creates group by create new group icon (adjacent to group's srearch)
  createGroupByIconFromShareApp: {
    mobile: "fz_au_mbl_sah_createGroupByIcon",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // creates new group by create new group label under groups list
  createGroupByLabelFromShareApp: {
    mobile: "fz_au_mbl_sah_createGroupByLabel",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding contact details for adding new member to group
  addContactDetailsShareApp: {
    mobile: "fz_au_mbl_sacg_addContactDetails",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding new member to new group by selecting contact from phone's contact list
  phoneContactListShareApp: {
    mobile: "fz_au_mbl_sacg_phoneContactList",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // shows currency list for price
  showCurrencyListFromShareApp: {
    mobile: "fz_au_mbl_sacf_showCurrencyList",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // uses file name for title
  useFileNameShareApp: {
    mobile: "fz_au_mbl_sati_useFileName",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding collection to find
  addCollectionToFindShareApp: {
    mobile: "fz_au_mbl_sacf_addCollectionToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding price to find
  addPriceToFindShareApp: {
    mobile: "fz_au_mbl_sacf_addPriceToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding vendor to find
  addVendorToFindShareApp: {
    mobile: "fz_au_mbl_sacf_addVendorToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding brand to find
  addBrandToFindShareApp: {
    mobile: "fz_au_mbl_sacf_addBrandToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding title to find
  addTitleToFindShareApp: {
    mobile: "fz_au_mbl_sati_addTitleToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // adding notes to find
  addNotesToFindShareApp: {
    mobile: "fz_au_mbl_sati_addNotesToFind",
    pwa: "",
    target: ["fb-rtdb"],
  },

  // report screen actions

  // withdraws report from report screen
  withdrawReportFromReport: {
    mobile: "fz_au_mbl_r_withdrawReport",
    pwa: "",
    target: ["fb-rtdb"],
  },
  // reports content from report screen
  reportContent: {
    mobile: "fz_au_mbl_r_reportContent",
    pwa: "",
    target: ["fb-rtdb"],
  },
};
