import { takeLatest, fork, call, select, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { CommentActions, CommonActions } from "../actions";
import {
  getDataFromChanges,
  syncWithIndexDB,
  getListenerToFetchData,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData
} from "../../services";
import { getActiveContext, getCurrentUser, getFindzMap, getIndexFindToComment, getTimestamp } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import { CommentDbType, CommentType, FindzType, LocalTimeStampValue, UserType } from "common-library/lib/schema";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import { IActiveContext } from "../../types/types";
import { getQueryFromActiveContext } from "../../utils/commonUtils";
import { EventChannel } from "redux-saga";
import { IChanges } from "gsdb/dist/interfaces";
import { getCommentsMap } from "../selectors";
import { updateIndexOfFindToComments } from "../../utils/commentUtils";
import { commentServices } from "../../services/comment.service";

export function* fetchLatestCommentsSaga(action: ActionType<typeof CommentActions.fetchLatestComments>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Comments, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);
      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<CommentDbType>(changes, timeStampValue);
      const newData = concat(dataAdded, dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Comments, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Comments,
          timestamp: latestTimeStamp,
          groupId: activeContext.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestCommentsSaga", error);
  }
}

export function* fetchLatestCommentsFromIndexedDbSaga(action: ActionType<typeof CommentActions.fetchLatestCommentsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Comments, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];

        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }

      const indexFindToComment: Map<string, Set<string>> = yield select(getIndexFindToComment);
      let updatedIndexFindToComment = new Map(indexFindToComment);

      const existingCommentMap: Map<string, CommentType> = yield select(getCommentsMap);
      let newCommentsMap = new Map(existingCommentMap);

      amList.forEach(comment => {
        newCommentsMap.set(comment.identifier, comment);
        if (comment.findzId && !comment.reaction) {
          if (comment.findzId.constructor === Array) {
            for (const findId of comment.findzId) {
              updatedIndexFindToComment = updateIndexOfFindToComments(comment, findId, updatedIndexFindToComment);
            }
          } else {
            updatedIndexFindToComment = updateIndexOfFindToComments(comment, comment.findzId, updatedIndexFindToComment);
          }
        }
      });

      rList.forEach(comment => {
        newCommentsMap.delete(comment.identifier);

        if (comment.findzId && !comment.reaction) {
          if (comment.findzId.constructor === Array) {
            for (const findId of comment.findzId) {
              updatedIndexFindToComment = updateIndexOfFindToComments(comment, findId, updatedIndexFindToComment, true);
            }
          } else {
            updatedIndexFindToComment = updateIndexOfFindToComments(comment, comment.findzId, updatedIndexFindToComment, true);
          }
        }
      });

      yield put(CommentActions.setCommentsMap({ commentsMap: newCommentsMap }));
      yield put(CommentActions.setIndexFindToComment({ indexFindToComment: updatedIndexFindToComment }));
    }
  } catch (error) {
    console.error("fetchLatestCommentsFromIndexedDbSaga", error);
  }
}

export function* createCommentSaga(action: ActionType<typeof CommentActions.createComment.saga>) {
  try {
    const { commentText, findzId, mentions, eventId, reaction, repliedTo, tagId } = action.payload;
    yield put(CommentActions.createComment.start());
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);
    const findsMap: Map<string, FindzType> = yield select(getFindzMap);
    if (currentUser && activeContext) {
      yield call(
        commentServices.createComment,
        activeContext,
        currentUser,
        {
          text: commentText,
          findzId,
          mentions,
          eventId,
          reaction,
          repliedTo,
          tagId
        },
        findsMap
      );
    }
    yield put(CommentActions.createComment.success(null));
  } catch (error: any) {
    console.error("createCommentSaga", error);
    yield put(CommentActions.createComment.error(error));
  }
}

export function* updateCommentSaga(action: ActionType<typeof CommentActions.updateComment>) {
  try {
    const { commentDataToUpdate, commentDataToDelete, commentId } = action.payload;

    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);
    if (currentUser && activeContext) {
      yield call(commentServices.updateComment, commentId, commentDataToUpdate, commentDataToDelete);
    }
  } catch (error) {
    console.error("updateCommentSaga", error);
  }
}

function* fetchLatestCommentsListener() {
  yield takeLatest(CommentActions.fetchLatestComments.toString(), fetchLatestCommentsSaga);
}

function* fetchLatestCommentsFromIndexedDbListener() {
  yield takeLatest(CommentActions.fetchLatestCommentsFromIndexedDb.toString(), fetchLatestCommentsFromIndexedDbSaga);
}

function* createCommentListener() {
  yield takeLatest(CommentActions.createComment.saga.toString(), createCommentSaga);
}

function* updateCommentListener() {
  yield takeLatest(CommentActions.updateComment.toString(), updateCommentSaga);
}

export default function* commentSaga() {
  yield fork(fetchLatestCommentsListener);
  yield fork(fetchLatestCommentsFromIndexedDbListener);
  yield fork(createCommentListener);
  yield fork(updateCommentListener);
}
