import { useEffect, useState } from "react";
import { CommonActions, AuthActions } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUrlQuery } from "../hooks";
import GSLoader from "../components/GSLoader/GSLoader";
import { NavigationPath } from "../constants/router.names";
import { useNavigate } from "react-router-dom";
import { AsyncState } from "../types/types";
import { getSignOutUser, getValidateUser, showFullScreenLoader } from "../redux/selectors";

function ValidateUser(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useUrlQuery();
  const [loading, setLoading] = useState(true);
  const validateUser = useAppSelector(getValidateUser);
  const currentUser = useAppSelector(state => state.Auth.currentUser);
  const isAuthorized = useAppSelector(state => state.Common.isAuthorized);
  const publicKey = useAppSelector(state => state.Common.publicKey);
  const signOutUser = useAppSelector(getSignOutUser);

  const showFullscreenLoader = useAppSelector(showFullScreenLoader);

  // Start User Validation
  useEffect(() => {
    const pubKey = query.get("pubKey");
    if (pubKey) {
      dispatch(CommonActions.setPublicKey({ publicKey: pubKey }));
    }
    if (!isAuthorized && validateUser.status === AsyncState.inProgress) {
      setLoading(true);
      dispatch(AuthActions.validateUser.saga());
    }

    if (validateUser.status === AsyncState.success) {
      if (isAuthorized && currentUser) {
        if (!currentUser.name) {
          navigate(NavigationPath.ProfileCompletionContainer);
        } else if (publicKey) {
          navigate(NavigationPath.ExtensionLoginSuccessContainer);
        }
      }
      if (signOutUser.status === AsyncState.idle || signOutUser.status === AsyncState.success) {
        setLoading(false);
      }
    }
  }, [validateUser.status, signOutUser.status]);

  // Handle Auth state
  useEffect(() => {
    if (isAuthorized) {
      const auth = getAuth();
      const unSub = onAuthStateChanged(auth, user => {
        if (!user) {
          dispatch(AuthActions.signOut.saga({ force: false }));
        }
      });
      return () => {
        unSub();
      };
    }
  }, [isAuthorized]);

  return showFullscreenLoader || loading ? <GSLoader getPopupContainer={() => document.body} /> : props.children;
}

export default ValidateUser;
