import { AddressType, ILocation } from "../schema/findzType";
import { appConstants } from "../constants";

export const getUrlQueryParams = (url: string) => {
  const regex = /([^&=]+)=?([^&]*)/g;
  const params: any = {};
  let match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
};

export const filterUndefinedValuesFromAddress = (address: AddressType | {}): any => {
  return Object.entries(address)
    .filter(([_, value]) => Boolean(value))
    .reduce((acc, [key, value]) => (acc = { ...acc, [key]: value }), {});
};

export const getAddressFromLocationResponse = (locationData: Partial<ILocation>): AddressType => {
  return {
    name: locationData?.address?.name,
    country: locationData?.address?.country || "",
    city: locationData?.address?.city,
    postalCode: locationData?.address?.postcode,
    state: locationData?.address?.state,
    region: locationData?.address?.region,
    street: locationData?.address?.road,
    locality: locationData?.address?.neighbourhood,
  };
};

export const getEndDateToVerifyEmail = (documentCreationDate: Date = new Date()) => {
  let endDateToVerifyEmail: any = new Date(86400000 * appConstants.maxNoOfDaysBeforeAccountDeletion + new Date(documentCreationDate).getTime());
  const dataToProcess = new Date(endDateToVerifyEmail);
  const splittedDate = dataToProcess.toUTCString().split(" ");
  endDateToVerifyEmail = `${splittedDate[2]} ${splittedDate[1]}, ${splittedDate[3]}`;
  return endDateToVerifyEmail;
};

export const allSupportedImageExtensions: string[] = ["jpg", "png", "jpeg", "gif", "bmp", "heic", "webp", "x-icon", "ico", "@screenshot"];

export const allSupportedVideoExtensions: string[] = ["mp4", "webm", "avi", "flv", "mov", "3gp"];

export const replaceChar = (originalString: string, replaceChar: string, index: number) => {
  let newStringArray = originalString.split("");
  newStringArray[index] = replaceChar;
  let newString = newStringArray.join("");
  return newString;
};
