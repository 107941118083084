import { isUndefined, isObject, isDate, isPlainObject, isArray, cloneDeep } from "lodash";
import { FieldValue, GeoPoint, Bytes } from "firebase/firestore";

const DB_GUARD_MAP_FIELD = "__MAP__";

const getMask = (mask: any, path: any) => {
  const keys = path.split(".");
  let maskAtKey = mask;

  while (maskAtKey && keys.length) {
    if (maskAtKey === true) {
      // for update keys which are dynamic such as user.notificationPermissions.groups.asdfghgasdfg123.findzAdded
      maskAtKey = true;
      keys.shift();
    } else {
      maskAtKey = maskAtKey[keys.shift()] || maskAtKey[DB_GUARD_MAP_FIELD];
    }
  }

  return keys.length == 0 ? maskAtKey : undefined;
};

const isFirestoreFieldValue = (value: any) => {
  // return false;
  return isObject(value) && value instanceof FieldValue;
};

const isValidFirestoreValue = (value: any) => {
  // return false;
  return (
    // is a firestore type (Fieldvalue, Geopoint, Blob)
    isFirestoreFieldValue(value) || value instanceof Bytes || value instanceof GeoPoint
  );
};

const isValidLeafValue = (value: any) => {
  return (
    (!isUndefined(value) && !isObject(value)) || // is a defined primitive (is defined and is not a Javascript object)
    isDate(value) || // is a Javascript Date
    isValidFirestoreValue(value)
  );
};

const isValidValue = (value: any) => {
  return (
    isPlainObject(value) || // is a POJO
    isArray(value) || // is an Array
    isValidLeafValue(value) // is a valid leaf value
  );
};

export const guardObjectUsingGivenMask = (object: any, mask: any) => {
  let rValue: any;
  // Check if any of the given keys is a simple field path.  Simple paths do not support complex field names or arrays
  if (
    isPlainObject(object) &&
    Object.keys(object).some(
      oKey => oKey.match(/^[a-zA-Z0-9_]+[.a-zA-Z0-9_]+/) // to take as many nested obejcts
    )
  ) {
    // process as flat update object.  Iteration governed by object
    rValue = {};
    for (const oKey in object) {
      let vMask = getMask(mask, oKey);
      vMask && (rValue[oKey] = guardObjectUsingGivenMask(object[oKey], vMask));
    }
  } else {
    // Process as POJO.  Iteration governed by mask and object in tandem
    if (isFirestoreFieldValue(object)) {
      // Does not protect against use of non-compatible field values
      rValue = object;
    } else {
      if (isPlainObject(mask) && isPlainObject(object)) {
        rValue = {};

        for (const [key, mValue] of Object.entries(mask)) {
          if (key === DB_GUARD_MAP_FIELD) {
            for (const oKey in object) {
              let oValue;
              mValue && (oValue = guardObjectUsingGivenMask(object[oKey], mValue));
              !isUndefined(oValue) && (rValue[oKey] = oValue);
            }
          } else {
            const oValue = object[key];
            !isUndefined(oValue) && mValue && (rValue[key] = guardObjectUsingGivenMask(oValue, mValue));
          }
        }
      } else if (isArray(mask) && isArray(object)) {
        rValue = [];

        const mValue = mask.length ? mask[0] : true; // Empty array means that it accepts any primitive value
        object.forEach((aValue, aKey) => {
          const aRValue = guardObjectUsingGivenMask(aValue, mValue);
          aRValue && (rValue[aKey] = aRValue); // Maintain key in the array.
        });
      } else if (mask && !isObject(mask)) {
        // Does not protect against use of non compatible values
        if (isValidLeafValue(object)) {
          rValue = object;
        } else {
          rValue = cloneDeep(object);
        }
      }
    }
  }

  return rValue;
};
