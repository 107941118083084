import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { FindzType } from "common-library/lib/schema";
import { getBaseUrl, getCaptureLocation } from "../../utils/findzUtils";
import uniq from "lodash/uniq";
import get from "lodash/get";

//#region from reducer
const _getFindzMap = (state: AppState): Map<string, FindzType> => state.Find.findzMap;
//#endregion

//#region Memoized Selector
export const getFindzMap = createDraftSafeSelector(_getFindzMap, payload => payload);
//#endregion

export const getLocationList = createDraftSafeSelector(_getFindzMap, payload => {
  let locationList = [];
  let finds: FindzType[] = Array.from(payload.values());
  for (let i = 0; i < finds.length; i++) {
    const currentFind = finds[i];
    if (currentFind.located && currentFind.located.at) {
      const locatedAt = getCaptureLocation(currentFind.located.at);
      if (locatedAt && locatedAt !== "unknown") {
        locationList.push(locatedAt);
      }
    }
    const foundAt = getCaptureLocation(currentFind.found.at);
    if (foundAt && foundAt !== "unknown") {
      locationList.push(foundAt);
    }
  }
  return uniq(locationList);
});

export const getUrlList = createDraftSafeSelector(_getFindzMap, payload => {
  let urlList: string[] = [];
  let finds: FindzType[] = Array.from(payload.values());
  for (let i = 0; i < finds.length; i++) {
    if (finds[i]?.found?.at?.url) {
      urlList.push(getBaseUrl(finds[i].found.at.url) as string);
    }
    if (finds[i].located && finds[i].located?.at && finds[i].located?.at.url) {
      urlList.push(getBaseUrl(finds[i].located?.at.url) as string);
    }
    finds[i]?.media?.sort?.forEach(mediaId => {
      if (
        finds[i].media.mediaList[mediaId]?.found &&
        finds[i].media.mediaList[mediaId]?.found?.at &&
        finds[i].media.mediaList[mediaId]?.found?.at?.url
      ) {
        urlList.push(getBaseUrl(finds[i].media.mediaList[mediaId].found?.at.url) as string);
      }
    });
  }
  return uniq(urlList);
});

export const getMaxPrice = createDraftSafeSelector(_getFindzMap, payload => {
  let maxPrice = Number.MIN_SAFE_INTEGER;
  let finds = Array.from(payload.values());
  for (let i = 0; i < finds.length; i++) {
    let price = get(finds[i], "priceQuotes[0].unitPrice", 1) || 1;
    if (price > maxPrice) {
      maxPrice = price;
    }
  }
  return maxPrice === Number.MIN_SAFE_INTEGER ? 1 : maxPrice;
});

export const getMinPrice = createDraftSafeSelector(_getFindzMap, payload => {
  let minPrice = Number.MAX_SAFE_INTEGER;
  let finds = Array.from(payload.values());
  for (let i = 0; i < finds.length; i++) {
    let price = get(finds[i], "priceQuotes[0].unitPrice", 0) || 0;
    if (price < minPrice) {
      minPrice = price;
    }
  }
  return minPrice === Number.MAX_SAFE_INTEGER ? 0 : minPrice;
});

const _getIsLoading = (state: AppState) => state.Find.isLoading;

export const getIsLoading = createDraftSafeSelector(_getIsLoading, payload => payload);

const _getFindDelete = (state: AppState) => state.Find.findDelete;

export const getFindDelete = createDraftSafeSelector(_getFindDelete, payload => payload);

const _getForwardFind = (state: AppState) => state.Find.forwardFind;

export const getForwardFind = createDraftSafeSelector(_getForwardFind, payload => payload);

const _getCreatedFind = (state: AppState) => state.Find.createFind;

export const getCreatedFind = createDraftSafeSelector(_getCreatedFind, payload => payload);
