import { takeLatest, fork, call, select, takeEvery, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { CommonActions, FindActions, MediaActions } from "../actions";
import {
  getListenerToFetchData,
  getDataFromChanges,
  syncWithIndexDB,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData,
  updateFind,
  findzServices,
  mediaServices,
  downloadImageFromUrl,
  getDataForContext
} from "../../services";
import {
  getActiveContext,
  getBrandsList,
  getBrandsMap,
  getCurrentUser,
  getDraftMedia,
  getFindzMap,
  getGeoLocationPosition,
  getIndexFindToEvents,
  getTagsMap,
  getTimestamp,
  getVendorList,
  getVendorsMap
} from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import {
  BrandType,
  FindTypeEnum,
  FindzDbType,
  FindzType,
  ForwardActivityType,
  LocalTimeStampValue,
  MediaTypeEnum,
  TagType,
  VendorType,
  GeolocationPositionType,
  IMap,
  MediaDbType
} from "common-library/lib/schema";
import isEmpty from "lodash/isEmpty";
import { DraftFindMediaType, IActiveContext, IUploadMediaType } from "../../types/types";
import { EventChannel } from "redux-saga";
import { getQueryFromActiveContext } from "../../utils/commonUtils";
import { IChanges } from "gsdb/dist/interfaces";
import { UserType } from "common-library";
import { createBrand } from "../../services/brand.service";
import { createVendor } from "../../services/vendor.service";

import { findzUtils, getReactionsFromFindz } from "../../utils/findzUtils";
import { nanoid } from "nanoid/non-secure";
import { getLocalIconForUrl } from "common-library/lib/utils";
import { cloneDeep, findIndex, set, values } from "lodash";
import { firestoreDocRef } from "../../utils/firestoreUtils";
import { commentServices } from "../../services/comment.service";
import { getActivityObjForForwardFind } from "../../utils/activityUtils";
import { createNewForwardActivity } from "../../services/activity.service";
import { getExistingVendor } from "../../utils/vendorUtils";
import { getExistingBrand } from "../../utils/brandUtils";

export function* fetchLatestFindsSaga(action: ActionType<typeof FindActions.fetchLatestFinds>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Findz, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);
      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<FindzDbType>(changes, timeStampValue);

      const newData = dataAdded.concat(dataModified);

      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Findz, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Findz,
          timestamp: latestTimeStamp,
          groupId: activeContext.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestFindsSaga", error);
  }
}

export function* fetchLatestFindsFromIndexedDbSaga(action: ActionType<typeof FindActions.fetchLatestFindsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Findz, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);
      let amList: any[] = [];
      let rList: any[] = [];
      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
        yield put(MediaActions.cacheFindsMedia({ addedList: filteredData.add, modifiedList: filteredData.update, removedList: filteredData.remove }));
      } else {
        if (data.add.length) {
          amList = data.add;
          yield put(MediaActions.cacheFindsMedia({ addedList: data.add }));
        }
      }

      //post processing and building/updating Map
      const currentUser: UserType = yield select(getCurrentUser);
      const tagsMap: Map<string, TagType> = yield select(getTagsMap);
      const brandsMap: Map<string, BrandType> = yield select(getBrandsMap);
      const vendorsMap: Map<string, VendorType> = yield select(getVendorsMap);
      const existingFindzMap: Map<string, FindzType> = yield select(getFindzMap);
      const newFindzMap: Map<string, FindzType> = new Map(existingFindzMap);

      amList.forEach(find => {
        const _find = { ...find };
        if (find.tags && find.tags.length) {
          const _tagData: { identifier: string; color: string; path: string }[] = [];
          find.tags.forEach((tagId: string) => {
            const tData = tagsMap.get(tagId);
            if (tData) {
              _tagData.push({
                identifier: tagId,
                color: tData.color,
                path: tData.path
              });
            }
          });
          _find.tags = _tagData;
        }
        _find.brands = [];
        if (find.brands && find.brands.length) {
          find.brands.forEach((brandId: string) => {
            const _bData = brandsMap.get(brandId);
            _find.brands.push({ identifier: brandId, name: _bData?.name || "" });
          });
        }
        _find.vendors = [];
        if (find.vendors && find.vendors.length) {
          find.vendors.forEach((vendorId: string) => {
            const _vData = vendorsMap.get(vendorId);
            _find.vendors.push({
              identifier: vendorId,
              businessName: _vData?.businessName || ""
            });
          });
        }

        _find.reactionsCount = [];

        for (const reaction in find.reactions) {
          if (find.reactions && find.reactions[reaction].length) {
            _find.reactionsCount.push({
              reaction,
              count: find.reactions[reaction].length,
              isEmojiUsedByUser: find.reactions[reaction].some((r: any) => r.by.identifier === currentUser.identifier)
            });
          }
        }
        newFindzMap.set(find.identifier, _find);
      });

      rList.forEach(find => {
        newFindzMap.delete(find.identifier);
      });
      yield put(FindActions.setFinds({ findzMap: newFindzMap }));
    }
  } catch (error) {
    console.error("fetchLatestFindsFromIndexedDbSaga", error);
  }
}

export function* createFindSaga(action: ActionType<typeof FindActions.createFind.saga>) {
  try {
    const { find, selectedMedia, draftMedia, useFileNameAsTitle, isLinkWithoutMedia, defaultMediaId, isMultipleFind, extractExif, isMessageScreen } =
      action.payload;
    yield put(FindActions.createFind.start());
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType | undefined = yield select(getCurrentUser);
    const brands: BrandType[] = yield select(getBrandsList);
    const vendors: VendorType[] = yield select(getVendorList);
    const geoLocationPosition: GeolocationPositionType = yield select(getGeoLocationPosition);

    if (currentUser && activeContext && !isEmpty(find)) {
      find.title = find.title?.trim() || "";
      find.note = find.note?.trim() || "";

      /**
       * populate brands
       */
      if (find?.brands?.length) {
        const brandName = find.brands[0].trim();
        let _brand = brands.find(b => b.name.trim().toLowerCase() === brandName.toLowerCase());
        if (!Boolean(_brand)) {
          _brand = yield call(createBrand, currentUser, activeContext, brandName);
        }
        if (_brand?.identifier) {
          find.brands = [_brand?.identifier];
        }
      }

      /**
       * populate vendors
       */
      if (find?.vendors?.length) {
        const vendorName = find.vendors[0].trim();
        let _vendor = vendors.find(b => b.businessName.trim().toLowerCase() === vendorName.toLowerCase());
        if (!Boolean(_vendor)) {
          _vendor = yield call(createVendor, currentUser, activeContext, vendorName);
        }
        if (_vendor?.identifier) {
          find.vendors = [_vendor?.identifier];
        }
      }
      /**
       * media to add in the find
       */
      let draftMediaArr: DraftFindMediaType[] = [];
      if (draftMedia && draftMedia.length) {
        draftMediaArr = [...draftMedia];
      } else if (selectedMedia && selectedMedia.length) {
        const _draftMedia: DraftFindMediaType[] = yield call(mediaServices.getAndClearDraftMedia);
        _draftMedia.forEach(item => {
          if (selectedMedia && selectedMedia.find((id: string) => id === item.identifier)) {
            draftMediaArr.push(item);
          }
        });
      }

      const finds = [];

      if (!isLinkWithoutMedia) {
        if (isMultipleFind) {
          delete find.media?.default;
          for (let index = 0; index < draftMediaArr.length; index++) {
            const draftMedia = draftMediaArr[index];

            // @ts-ignore
            const findMediaList: any = yield call(findzServices.getMediaDetails, [draftMedia], currentUser, extractExif, geoLocationPosition, find);
            const _find = findzUtils.populateFindMedia(cloneDeep(find), findMediaList);

            if (find.title) {
              _find.title = `${find.title} - ${index + 1}`;
            } else if (useFileNameAsTitle) {
              const fileNameWithExtension = draftMedia.data.name;
              if (fileNameWithExtension) {
                const fileName = findzUtils.getFileNameWithoutExtensionType(fileNameWithExtension);
                _find.title = fileName;
              }
            }

            const defaultMediaData = findMediaList[draftMedia.identifier];
            if (defaultMediaData.found?.on) {
              set(_find, "found.on", defaultMediaData.found?.on);
            }
            if (defaultMediaData?.located?.at) {
              set(_find, "located.at", defaultMediaData?.located?.at);
            }
            set(_find, "found.at", defaultMediaData.found?.at);

            finds.push(_find);
          }
        } else {
          // @ts-ignore

          const findMediaList: any = yield call(findzServices.getMediaDetails, draftMediaArr, currentUser, extractExif, geoLocationPosition, find);
          const _find = findzUtils.populateFindMedia(cloneDeep(find), findMediaList);

          if (defaultMediaId) {
            const _defaultMedia = draftMediaArr.find(draftMedia => draftMedia.identifier === defaultMediaId);
            if (_defaultMedia) {
              if (useFileNameAsTitle) {
                const fileNameWithExtension = _defaultMedia.data.name;
                if (fileNameWithExtension) {
                  const fileName = findzUtils.getFileNameWithoutExtensionType(fileNameWithExtension);
                  _find.title = fileName;
                }
              }
              const defaultMediaData = findMediaList[_defaultMedia.identifier];
              if (defaultMediaData.found?.on) {
                set(_find, "found.on", defaultMediaData.found?.on);
              }
              if (defaultMediaData?.located?.at) {
                set(_find, "located.at", defaultMediaData?.located?.at);
              }
              set(_find, "found.at", defaultMediaData.found?.at);
            }
          }
          finds.push(_find);
        }
      } else {
        const mediaObj = {
          identifier: nanoid(),
          owner: activeContext
        } as DraftFindMediaType;

        // @ts-ignore
        const findMediaList: any = yield call(findzServices.getMediaDetails, [mediaObj], currentUser, false, geoLocationPosition);
        if (find.media) {
          const mediaIds = Object.keys(findMediaList);
          find.media.mediaList = findMediaList;
          if (!find.media.default) {
            if (defaultMediaId) {
              find.media.default = defaultMediaId;
            } else {
              find.media.default = mediaIds[0];
            }
          }
          find.media.sort = mediaIds;
          find.media.mediaList[mediaIds[0]].isLinkWithoutMedia = true;
          find.media.mediaList[mediaIds[0]].type = MediaTypeEnum.LINK;
          if (find.located && find.located.at.url) {
            const localIcon = getLocalIconForUrl(find.located?.at.url || "");
            if (localIcon) {
              find.media.mediaList[mediaIds[0]].type = MediaTypeEnum.DOCUMENTS;
              find.media.mediaList[mediaIds[0]].preview = {
                icon: localIcon.imageName,
                iconLabel: localIcon.previewLabel
              };
            }
          }
          finds.push(find);
        }
      }
      const findDataList: FindzDbType[] = yield call(findzServices.createFinds, finds);
      const dataForUploadQueue: any[] = [];

      for (const findData of findDataList) {
        draftMediaArr.forEach(item => {
          if (findData.media.mediaList.hasOwnProperty(item.identifier)) {
            dataForUploadQueue.push({
              ...item,
              findId: findData.identifier,
              findCreatedOn: findData.created?.on || new Date(),
              groupId: activeContext.identifier
            });
          }
        });
      }
      yield call(mediaServices.putMediaIntoUploadQueue, dataForUploadQueue);
      yield put(MediaActions.startMediaUpload());
      yield put(CommonActions.setCreateFindzModalIsOpen({ isOpen: false }));
      if (isMessageScreen) {
        yield put(FindActions.createFind.success({ findsId: findDataList.map(item => item.identifier) }));
      } else {
        yield put(FindActions.createFind.success({}));
      }
    }
  } catch (error) {
    console.error("createFindSaga", error);
  }
}

export function* updateFindSaga(action: ActionType<typeof FindActions.updateFinds>) {
  try {
    const { findId, find, findDataToRemove, mediaToDelete, isPathUpdate } = action.payload;
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType | undefined = yield select(getCurrentUser);

    if (activeContext && currentUser) {
      if (find.brands?.length) {
        const brands: BrandType[] = yield select(getBrandsList);
        const brandName = find.brands[0].trim();
        let _brand = brands.find(b => b.name.trim().toLowerCase() === brandName.toLowerCase());
        if (!Boolean(_brand)) {
          _brand = yield call(createBrand, currentUser, activeContext, brandName);
        }
        if (_brand?.identifier) {
          find.brands = [_brand?.identifier];
        }
      }
      /**
       * populate vendors
       */
      if (find?.vendors?.length) {
        const vendors: VendorType[] = yield select(getVendorList);
        const vendorName = find.vendors[0].trim();
        let _vendor = vendors.find(b => b.businessName.trim().toLowerCase() === vendorName.toLowerCase());
        if (!Boolean(_vendor)) {
          _vendor = yield call(createVendor, currentUser, activeContext, vendorName);
        }
        if (_vendor?.identifier) {
          find.vendors = [_vendor?.identifier];
        }
      }
      if (mediaToDelete?.length) {
        yield put(MediaActions.deleteFindMedia.saga({ findId: findId, mediaIds: mediaToDelete }));
      }

      yield call(updateFind, [findId], find, findDataToRemove, undefined, isPathUpdate);
    }
  } catch (error) {
    console.error("updateFindSaga", error);
  }
}

export function* deleteFindsSaga(action: ActionType<typeof FindActions.deleteFinds.saga>) {
  try {
    yield put(FindActions.deleteFinds.start());

    const { finds } = action.payload;

    let updatedDataForUploadQueue: IUploadMediaType[] = yield call(mediaServices.getMediaToUpload);
    const indexFindToEvents: Map<string, Set<string>> = yield select(getIndexFindToEvents);

    for (const find of finds) {
      const mediaList = find.media.mediaList;
      for (const media of values(mediaList)) {
        if (updatedDataForUploadQueue.length) {
          updatedDataForUploadQueue = updatedDataForUploadQueue.filter(uploadQueueMedia => uploadQueueMedia.identifier !== media.identifier);
        }
        yield call(mediaServices.deleteFindMediaAndPreviewStorage, [media.identifier], find.media);
      }
    }

    yield call(mediaServices.putMediaIntoUploadQueue, updatedDataForUploadQueue);
    yield call(findzServices.deleteFinds, finds, indexFindToEvents);
    yield put(FindActions.deleteFinds.success());
  } catch (error: any) {
    console.error("deleteFindsSaga", error);
    yield put(FindActions.deleteFinds.error(error));
  }
}

export function* processWebFindBeforeSubmitSaga(action: ActionType<typeof CommonActions.processWebFindBeforeSubmit.saga>) {
  try {
    const { findDetails, imagesToDownload, defaultImage } = action.payload;
    yield put(CommonActions.processWebFindBeforeSubmit.start());
    const imgUrlsArray = Array.from(imagesToDownload);
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (activeContext) {
      if (!imgUrlsArray.length) {
        yield put(FindActions.createFind.saga({ find: findDetails, isLinkWithoutMedia: true }));
        yield put(CommonActions.processWebFindBeforeSubmit.success());
      } else {
        const imgFileArray: File[] = yield call(downloadImageFromUrl, imgUrlsArray);
        if (imgFileArray.length) {
          let defaultMediaIndex = 0;
          const mappedArray: DraftFindMediaType[] = yield call(mediaServices.generateMediaDataForFiles, imgFileArray, activeContext);
          if (defaultImage) {
            defaultMediaIndex = imgUrlsArray.indexOf(defaultImage);
          }
          if (defaultMediaIndex >= mappedArray.length) {
            defaultMediaIndex = mappedArray.length - 1;
          }
          if (findDetails.media) {
            findDetails.media.default = mappedArray[defaultMediaIndex].identifier;
          }
          yield put(FindActions.createFind.saga({ find: findDetails, draftMedia: mappedArray }));
          yield put(CommonActions.processWebFindBeforeSubmit.success());
        } else {
          yield put(FindActions.createFind.saga({ find: findDetails, isLinkWithoutMedia: true }));
          yield put(
            CommonActions.processWebFindBeforeSubmit.error(new Error("Failed to download images, Find will be created using link only") as any)
          );
        }
      }
    }
  } catch (error) {
    console.error("processWebFindBeforeSubmitSaga", error);
  }
}

export function* dragFindsAndTagsSaga(action: ActionType<typeof FindActions.dragFindsAndTags>) {
  try {
    const { findIds, tagIds } = action.payload;
    const activeContext: IActiveContext = yield select(getActiveContext);
    const tagsMap: Map<string, TagType> = yield select(getTagsMap);
    const findsMap: Map<string, FindzType> = yield select(getFindzMap);
    if (activeContext) {
      yield call(findzServices.processDraggedFindsAndTags, tagIds, findIds, tagsMap, findsMap);
    }
  } catch (error) {
    console.error("dragFindsAndTagsSaga", error);
  }
}

export function* forwardFindsSaga(action: ActionType<typeof FindActions.forwardFinds.saga>) {
  try {
    yield put(FindActions.forwardFinds.start());

    const { findzList, targetActiveContext, messageText = "", newTagsToAdd = [], copyVendor = false, messageMentions = [] } = action.payload;

    const vendorsMap: Map<string, VendorType> = yield select(getVendorsMap);
    const brandsMap: Map<string, BrandType> = yield select(getBrandsMap);
    const currentUser: UserType = yield select(getCurrentUser);
    const geoLocationPosition: GeolocationPositionType = yield select(getGeoLocationPosition);
    const activeContext: IActiveContext = yield select(getActiveContext);

    const newFindIds: string[] = [];
    const dataForUploadQueue: IUploadMediaType[] = [];
    const forwardedFindzData: { from: string; to: string }[] = [];

    const newFindObjs: FindzDbType[] = [];
    const updateOriginalFinds: { originalFindId: string; newFindId: string }[] = [];

    for (const find of findzList) {
      const newFindId = firestoreDocRef(FirestoreCollectionEnum.Findz).id;
      if (!newFindId) {
        throw new Error("couldn't generate find id");
      }

      newFindIds.push(newFindId);
      const type = find.type ? find.type : FindTypeEnum.FILE;
      let newFindObj: FindzDbType = { ...(find as any), identifier: newFindId, type };

      if (find.vendors.length) {
        const vendorsOfTargetGroup: VendorType[] = yield call(getDataForContext, FirestoreCollectionEnum.Vendors, targetActiveContext);

        for (const vendor of find.vendors) {
          const vendorData = vendorsMap.get((vendor as any).identifier);
          let existingVendor = undefined;

          if (vendorData) {
            existingVendor = getExistingVendor(vendorData, vendorsOfTargetGroup);
          }

          if ((copyVendor || isEmpty(existingVendor)) && vendorData) {
            const newVendor: VendorType = yield call(createVendor, currentUser, targetActiveContext, vendorData.businessName);
            newFindObj.vendors = [newVendor.identifier];
          } else if (existingVendor) {
            newFindObj.vendors = [existingVendor.identifier];
          }
        }
      }

      if (find.brands.length) {
        const brandsOfTargetGroup: BrandType[] = yield call(getDataForContext, FirestoreCollectionEnum.Brands, targetActiveContext);

        for (const brand of find.brands) {
          const brandData = brandsMap.get((brand as any).identifier);
          let existingBrand = undefined;

          if (brandData) {
            existingBrand = getExistingBrand(brandData, brandsOfTargetGroup);
          }

          if (isEmpty(existingBrand) && brandData) {
            const newBrand: BrandType = yield call(createBrand, currentUser, targetActiveContext, brandData.name);
            newFindObj.brands = [newBrand.identifier];
          } else if (existingBrand) {
            newFindObj.brands = [existingBrand.identifier];
          }
        }
      }

      newFindObj = findzUtils.getUpdatedNewFindObjForForwardFind(
        find,
        newFindObj,
        targetActiveContext,
        activeContext,
        currentUser,
        geoLocationPosition,
        newTagsToAdd
      ) as FindzDbType;

      const mediaList: IMap<MediaDbType> = {};
      let mediaSort = cloneDeep(find.media.sort);

      for (const media of values(find.media.mediaList)) {
        const draftMediaObj: DraftFindMediaType = yield call(mediaServices.getDraftMediaForForwardFind, media);
        dataForUploadQueue.push({
          ...draftMediaObj,
          findId: newFindId,
          groupId: targetActiveContext.identifier
        });

        if (media.identifier === find.media.default) {
          set(newFindObj, "media.default", draftMediaObj.identifier);
        }

        const mediaSortedIndex = findIndex(mediaSort, (id: string) => id === media.identifier);
        if (mediaSortedIndex > -1) {
          mediaSort[mediaSortedIndex] = draftMediaObj.identifier;
        }
        const newMedia: any = findzUtils.getNewMediaForForwardFind(media, draftMediaObj.identifier, currentUser, geoLocationPosition);
        if (!media.cloudStorageUrl || media.isLinkWithoutMedia) {
          newMedia.type = FindTypeEnum.LINK;
        } else {
          newMedia.type = findzUtils.getMediaType(draftMediaObj);
        }

        if (media.located && media.located?.at.address) {
          let locAddress = media.located.at.address;
          let _newAddress: any = {};
          Object.entries(locAddress).forEach(([key, value]) => {
            if (value) {
              _newAddress[key] = value;
            }
          });
          newMedia.located.at.address = _newAddress;
        }
        if (media.created && media.created?.at.address) {
          let locAddress = media.created.at.address;
          let _newAddress: any = {};
          Object.entries(locAddress).forEach(([key, value]) => {
            if (value) {
              _newAddress[key] = value;
            }
          });
          newMedia.created.at.address = _newAddress;
        }
        if (media.found && media.found?.at.address) {
          let locAddress = media.found.at.address;
          let _newAddress: any = {};
          Object.entries(locAddress).forEach(([key, value]) => {
            if (value) {
              _newAddress[key] = value;
            }
          });
          newMedia.found.at.address = _newAddress;
        }

        mediaList[draftMediaObj.identifier] = newMedia;
      }

      set(newFindObj, "media.sort", mediaSort);
      set(newFindObj, "media.mediaList", mediaList);

      forwardedFindzData.push({
        from: find.identifier,
        to: newFindId
      });
      newFindObjs.push(newFindObj);
      updateOriginalFinds.push({
        originalFindId: find.identifier,
        newFindId
      });
    }

    yield call(findzServices.createFinds, newFindObjs);
    yield call(findzServices.updateOriginalFindForForwardFind, updateOriginalFinds);

    const newActivityId = firestoreDocRef(FirestoreCollectionEnum.Activities).id;
    if (newActivityId) {
      const newActivityObj: ForwardActivityType = getActivityObjForForwardFind(
        newActivityId,
        currentUser,
        activeContext,
        targetActiveContext,
        forwardedFindzData,
        messageText,
        geoLocationPosition
      );

      yield call(createNewForwardActivity, newActivityId, newActivityObj);

      if (messageText) {
        yield call(commentServices.createComment, targetActiveContext, currentUser, {
          text: messageText,
          findzId: newFindIds,
          mentions: messageMentions,
          activityId: newActivityId
        });
      }
    }
    yield call(mediaServices.putMediaIntoUploadQueue, dataForUploadQueue);
    yield put(MediaActions.startMediaUpload());
    yield put(CommonActions.toggleForwardFindModal({ data: undefined }));
    yield put(FindActions.forwardFinds.success());
  } catch (error: any) {
    yield put(FindActions.forwardFinds.error(error));
    console.log("forwardFindsSaga error", error);
  }
}

export function* removeReactionFromFindsSaga(action: ActionType<typeof FindActions.deleteReaction>) {
  try {
    const { commentData, commentId } = action.payload;
    const findsMap: Map<string, FindzType> = yield select(getFindzMap);
    const reactionObjs = getReactionsFromFindz(commentData, findsMap);
    if (reactionObjs.length) {
      yield call(findzServices.removeReactionFromFinds, commentId, reactionObjs);
    }
  } catch (error) {
    console.error(error);
  }
}

function* dragFindsAndTagsListener() {
  yield takeLatest(FindActions.dragFindsAndTags.toString(), dragFindsAndTagsSaga);
}

function* processWebFindBeforeSubmitListener() {
  yield takeLatest(CommonActions.processWebFindBeforeSubmit.saga.toString(), processWebFindBeforeSubmitSaga);
}

function* fetchLatestFindsListener() {
  yield takeLatest(FindActions.fetchLatestFinds.toString(), fetchLatestFindsSaga);
}

function* deleteFindsListener() {
  yield takeEvery(FindActions.deleteFinds.saga.toString(), deleteFindsSaga);
}

function* fetchLatestFindsFromIndexedDbListener() {
  yield takeEvery(FindActions.fetchLatestFindsFromIndexedDb.toString(), fetchLatestFindsFromIndexedDbSaga);
}

function* updateFindListener() {
  yield takeLatest(FindActions.updateFinds.toString(), updateFindSaga);
}

function* createFindListener() {
  yield takeLatest(FindActions.createFind.saga.toString(), createFindSaga);
}

function* forwardFindsListener() {
  yield takeLatest(FindActions.forwardFinds.saga.toString(), forwardFindsSaga);
}

function* removeReactionFromFindsListener() {
  yield takeLatest(FindActions.deleteReaction.toString(), removeReactionFromFindsSaga);
}

export default function* findsSaga() {
  yield fork(fetchLatestFindsListener);
  yield fork(deleteFindsListener);
  yield fork(fetchLatestFindsFromIndexedDbListener);
  yield fork(updateFindListener);
  yield fork(createFindListener);
  yield fork(processWebFindBeforeSubmitListener);
  yield fork(dragFindsAndTagsListener);
  yield fork(forwardFindsListener);
  yield fork(removeReactionFromFindsListener);
}

//#endregion
