import { CommentType, FindzType, TagType, UserType } from "common-library/lib/schema";
import { filter, orderBy } from "lodash";
import { CommentDbType } from "common-library/lib/schema";
import { IActiveContext } from "../types/types";

export const updateIndexOfFindToComments = (
  comment: CommentType,
  findId: string,
  indexFindToComment: Map<string, Set<string>>,
  removed: boolean = false
) => {
  const updatedIndexFindToComment = new Map(indexFindToComment);
  const commentIds = updatedIndexFindToComment.get(findId);
  if (!removed) {
    if (commentIds) {
      const updatedCommentIds = new Set(commentIds);
      updatedCommentIds.add(comment.identifier);
      updatedIndexFindToComment.set(findId, updatedCommentIds);
    } else {
      updatedIndexFindToComment.set(findId, new Set([comment.identifier]));
    }
  } else if (commentIds) {
    commentIds.delete(comment.identifier);
    if (commentIds.size === 0) {
      updatedIndexFindToComment.delete(findId);
    } else {
      updatedIndexFindToComment.set(findId, new Set(commentIds));
    }
  }
  return updatedIndexFindToComment;
};

export const getCommentDataFromCommentId = (
  commentId: string,
  commentsMap: Map<string, CommentType> = new Map(),
  findsMap: Map<string, FindzType> = new Map(),
  tagsMap: Map<string, TagType> = new Map()
) => {
  let tagData: TagType | undefined = undefined;
  let findData: FindzType | undefined = undefined;
  const commentData = commentsMap.get(commentId);
  if (commentData) {
    if (commentData.tagId) {
      tagData = tagsMap.get(commentData.tagId);
      if (tagData) {
        const findsListOfTag = orderBy(
          filter(Array.from(findsMap.values()), (find: FindzType) => find.tags.some(tag => tag.identifier === tagData?.identifier)),
          "_documentUpdatedOn",
          "desc"
        );
        findData = findsListOfTag.length > 0 ? findsListOfTag[0] : undefined;
      }
    } else if (commentData.findzId) {
      if (typeof commentData.findzId === "string") {
        findData = findsMap.get(commentData.findzId);
      } else {
        findData = findsMap.get(commentData.findzId[0]);
      }
    }
  }
  return { commentData, tagData, findData };
};

export const createEmptyComment = (activeContext: IActiveContext, currentUser: UserType): CommentDbType => {
  return {
    created: {
      by: { identifier: currentUser.identifier, name: currentUser.name },
      on: new Date()
    },
    owner: activeContext,
    identifier: "",
    text: "",
    isDeleted: false
  } as CommentDbType;
};
