import { collectionMasks, IDocumentSecured, maskIDocumentSecured } from "./commonTypes";

export interface TagDefaultType extends IDocumentSecured {
  name: string;
  color: string;
  finds: string[];
  ancestors: string[];
  isRootTag: boolean;
  created: {
    on: Date;
    by: { identifier: string; name: string };
  };
  children: string[];
}

export interface TagType extends TagDefaultType {
  path: string;
}

export interface TagDbType extends TagDefaultType {}

export interface TagInterface {
  tagsList: TagType[];
  loadingTags: boolean;
  fetchingTagsError: string;
  activeTag: TagType;
}

export const maskTagDefaultType = {
  ...maskIDocumentSecured,
  name: true,
  color: true,
  finds: [true],
  ancestors: [true],
  isRootTag: true,
  created: {
    on: true,
    by: true,
  },
  children: [true],
};

Object.freeze(maskTagDefaultType);

export const maskTagType = {
  ...maskTagDefaultType,
  path: true,
  allFindsCount: true,
};

Object.freeze(maskTagType);

export const maskTagDbType = {
  ...maskTagType,
};

Object.freeze(maskTagDbType);

collectionMasks["tags"] = maskTagDbType;
