export interface ICacheMedia {
  url: string;
  findId: string;
  mediaId: string;
  type?: "Preview" | "Orginal";
}

export enum SW_MESSAGE_TYPES {
  SKIP_WAITING = "SKIP_WAITING",
  CACHE_MEDIA = "CACHE_MEDIA"
}
