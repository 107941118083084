import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import * as appTexts from "../constants/appTexts";

export const testPhoneNumber = (phoneNumberText: string, countryCode: string) => {
  const phoneNumber = phoneNumberText.trim();
  try {
    const inputValidator = PhoneNumberUtil.getInstance();
    const phoneNumberParsed = parsePhoneNumber(phoneNumber, countryCode);
    let isValid = false;
    if (phoneNumber.startsWith("+")) {
      isValid = inputValidator.isValidNumber(phoneNumberParsed);
    } else isValid = inputValidator.isValidNumberForRegion(phoneNumberParsed, countryCode);
    if (!isValid) {
      const isNumberPossible = inputValidator.isPossibleNumberWithReason(phoneNumberParsed);
      if (isNumberPossible === 1) return { error: appTexts.phoneNumberInvalidCountryCode };
      else if (isNumberPossible === 2) return { error: appTexts.phoneNumberIsShort };
      else if (isNumberPossible === 3) return { error: appTexts.phoneNumberIsLong };
      else return { error: appTexts.phoneNumberSeemsInvalid };
    } else return { number: phoneNumber };
  } catch (error) {
    return { error: appTexts.phoneNumberInvalid };
  }
};

const parsePhoneNumber = (phoneNumberText: string, countryCode: string) => {
  const phoneNumber = phoneNumberText.trim();
  const inputValidator = PhoneNumberUtil.getInstance();
  if (phoneNumber.startsWith("+")) return inputValidator.parse(phoneNumber);
  return inputValidator.parse(phoneNumber, countryCode);
};

export const getParsedPhoneNumber = (phoneNumber: string, countryCode: string = "US") => {
  const inputValidator = PhoneNumberUtil.getInstance();
  const phoneNumberParsed = parsePhoneNumber(phoneNumber, countryCode);
  const formattedNumber = inputValidator.format(phoneNumberParsed, PhoneNumberFormat.E164);
  return formattedNumber;
};

export const getNumber = (phoneNumberText: string, countryCode: string) => {
  const number = phoneNumberText.trim();
  const inputValidator = PhoneNumberUtil.getInstance();
  let parsedNumber;
  if (number.startsWith("+")) parsedNumber = inputValidator.parseAndKeepRawInput(number);
  else parsedNumber = inputValidator.parseAndKeepRawInput(number, countryCode);
  const nationalNumber = parsedNumber.getNationalNumber()?.toString() || phoneNumberText;
  const callingCode = parsedNumber.getCountryCode()?.toString() || "+1";
  return { number: nationalNumber, callingCode };
};

export const getFormattedNumber = (phoneNumberText: string, countryCode: string) => {
  const number = phoneNumberText.trim();
  const inputValidator = PhoneNumberUtil.getInstance();
  if (number.startsWith("+")) {
    const parsedNumber = inputValidator.parseAndKeepRawInput(number);
    return inputValidator.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  }
  const parsedNumber = inputValidator.parseAndKeepRawInput(number, countryCode);
  // return parsedNumber.getNationalNumber()?.toString() || phoneNumberText;
  return inputValidator.format(parsedNumber, PhoneNumberFormat.NATIONAL);
};

const isInvalidCollectionName = (value: string): Boolean => {
  const splittedTags = value.split("/");
  const invalidTagIndex = splittedTags.findIndex((tag) => tag.trim() === "");
  return invalidTagIndex >= 0;
};

export const validateCollectionInput = (text: string): string => {
  if (text?.startsWith("/")) return appTexts.cannotStartWithSlash;
  else if (text?.endsWith("/")) return appTexts.cannotEndWithSlash;
  else if (text.indexOf("[") >= 0 || text.indexOf("]") >= 0) return appTexts.cannotHaveBrackets;
  //check for empty text
  else if (isInvalidCollectionName(text)) return appTexts.invalidCollectionName;
  else return "";
};
