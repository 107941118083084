import { ContactType, GroupType, GroupMemberDbType, InvitationActivityType, InvitationType, UserType } from "common-library/lib/schema";

import { IActiveContext, CreateGroupPayloadType } from "../../types/types";
import { actionCreator, asyncActionCreator } from "../helper";
import { NavigateFunction } from "react-router-dom";

enum GroupActionTypes {
  SET_GROUPS = "GROUPS/SET",
  FETCH_LATEST_GROUPS = "GROUPS/FETCH",
  FETCH_LATEST_INVITES = "GROUPS/FETCH_LATEST_INVITES",
  FETCH_LATEST_INVITES_FROM_INDEXEDDB = "GROUPS/INDEXEDDB/FETCH_LATEST_INVITES",
  SET_PENDING_INVITES = "SET_PENDING_INVITES",
  SET_ACTIVE_CONTEXT = "GROUPS/ACTIVE_CONTEXT/SET",
  UPDATE_ACTIVE_CONTEXT = "GROUPS/ACTIVE_CONTEXT/UPDATE",
  FETCH_LATEST_GROUPS_FROM_INDEXEDDB = "GROUP/INDEXEDDB/FETCH",
  PROCESS_ACTIVE_CONTEXT = "GROUPS/ACTIVE_CONTEXT/PROCESS_SAGA",
  SET_GROUP_MEMBERS = "GROUPS/SET_GROUP_MEMBERS",
  SET_GROUP_MEMBERS_LIST = "GROUPS/SET_GROUP_MEMBERS_LIST",
  "GET_USER_PUBLIC_INFO" = "GROUP/GET_PUBLIC_USER_INFO",
  "SET_CURRENT_PUBLIC_USER_INFO" = "GROUP/SET_CURRENT_PUBLIC_USER_INFO",
  "ADD_MEMBER_TO_GROUP" = "GROUP/ADD_MEMBER_TO_GROUP",
  "REMOVE_MEMBER_FROM_GROUP" = "GROUP/REMOVE_MEMBER_FROM_GROUP",
  "UPDATE_GROUP_INFO" = "GROUP/UPDATE_GROUP_INFO",
  "EXIT_GROUP" = "GROUP/EXIT_GROUP",
  "DELETE_GROUP" = "GROUP/DELETE_GROUP",
  "UNBLOCK_USER" = "GROUP/UNBLOCK_USER",
  "CREATE_NEW_GROUP" = "GROUP/CREATE_NEW_GROUP",
  "RESPOND_TO_INVITATION" = "RESPOND_TO_INVITATION"
}

export const setGroupsMap = actionCreator<{ groupsMap: Map<string, GroupType> }>(GroupActionTypes.SET_GROUPS);

export const fetchLatestGroupInfo = actionCreator(GroupActionTypes.FETCH_LATEST_GROUPS);

export const processActiveContext = asyncActionCreator<undefined, { context: IActiveContext }>(GroupActionTypes.PROCESS_ACTIVE_CONTEXT);

export const setActiveContext = actionCreator<{ context: IActiveContext }>(GroupActionTypes.SET_ACTIVE_CONTEXT);

export const updateActiveContext = actionCreator<{ context: IActiveContext }>(GroupActionTypes.UPDATE_ACTIVE_CONTEXT);

export const fetchLatestGroupsFromIndexedDb = actionCreator(GroupActionTypes.FETCH_LATEST_GROUPS_FROM_INDEXEDDB);

export const setGroupMembers = actionCreator<{ context: IActiveContext; groupData?: GroupType }>(GroupActionTypes.SET_GROUP_MEMBERS);

export const setGroupMembersList = actionCreator<{ groupMembersList: GroupMemberDbType[] }>(GroupActionTypes.SET_GROUP_MEMBERS_LIST);

export const fetchUserInfo = asyncActionCreator<
  undefined,
  { userDetails: { phoneNumbers: any[]; emailAddresses: any[]; name: string; recordID: string } }
>(GroupActionTypes.GET_USER_PUBLIC_INFO);

export const setCurrentPublicUserInfo = actionCreator<{ currentPublicUserInfo: any[] }>(GroupActionTypes.GET_USER_PUBLIC_INFO);

export const addMemberToGroup = asyncActionCreator<undefined, { group: GroupType; user: ContactType; currentUser: UserType }>(
  GroupActionTypes.ADD_MEMBER_TO_GROUP
);

export const removeMemberFromGroup = asyncActionCreator<
  undefined,
  { group: GroupType; user: GroupMemberDbType; role: string; isUnregistered?: boolean }
>(GroupActionTypes.REMOVE_MEMBER_FROM_GROUP);

export const updateGroupInfo = asyncActionCreator<
  undefined,
  { groupId: string; groupUpdateObj: Partial<GroupType>; updateUnregisteredMemberData?: boolean }
>(GroupActionTypes.UPDATE_GROUP_INFO);

export const exitGroup = asyncActionCreator<undefined, { removedUser: GroupMemberDbType; group: GroupType; navigate: NavigateFunction }>(
  GroupActionTypes.EXIT_GROUP
);

export const deleteGroup = asyncActionCreator<undefined, { group: GroupType; navigation: NavigateFunction }>(GroupActionTypes.DELETE_GROUP);

export const createNewGroup = asyncActionCreator<undefined, { groupData: CreateGroupPayloadType; navigation: NavigateFunction }>(
  GroupActionTypes.CREATE_NEW_GROUP
);

export const fetchLatestInvites = actionCreator(GroupActionTypes.FETCH_LATEST_INVITES);

export const fetchLatestInvitesFromIndexedDb = actionCreator(GroupActionTypes.FETCH_LATEST_INVITES_FROM_INDEXEDDB);

export const setPendingInvites = actionCreator<{ invites: Map<string, InvitationType> }>(GroupActionTypes.SET_PENDING_INVITES);

export const respondToInvitation = asyncActionCreator<
  undefined,
  { isAccepted: boolean; activity: InvitationActivityType; shouldBlock: boolean; navigation: NavigateFunction }
>(GroupActionTypes.RESPOND_TO_INVITATION);
