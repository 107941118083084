export enum EOwner {
  User = "User",
  Group = "Group"
}

export enum ESavedPreferanceKeys {
  ActiveContext = "activeContext",
  MediaUploadQueue = "mediaUploadQueue",
  SharedData = "sharedData"
}
