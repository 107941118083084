import React, { useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Paper, SxProps, Theme } from "@mui/material";
import GSIcon from "../../../components/GSIcon/GSIcon";
import GSImage from "../../../components/GSImage/GSImage";
import { Icons } from "../../../../assets/icons";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { NavigationPath } from "../../../constants/router.names";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { getPathWithQueryString, parseUrlParams } from "../../../utils/commonUtils";
import { Link } from "react-router-dom";
function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function BottomNavigationBar() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(["/finds", "/tags", "/messages"]);
  const currentTab = routeMatch?.pattern?.path;
  const navigateTo = (path: NavigationPath) => {
    const params = parseUrlParams(location);
    const _path = getPathWithQueryString(path, params);
    navigate(_path, { replace: true });
  };

  useEffect(() => {
    if (location.pathname.startsWith(NavigationPath.FindzContainer)) {
      setValue(0);
    } else if (location.pathname.startsWith(NavigationPath.TagsContainer)) {
      setValue(1);
    } else if (location.pathname.startsWith(NavigationPath.MessagesContainer)) {
      setValue(2);
    }
  }, [location.pathname]);

  return (
    <Paper sx={{ zIndex: 99, height: "48px" }} elevation={2}>
      <BottomNavigation
        sx={styles.bottomNavigation}
        value={value}
        showLabels
        data-testid="bottom-navigator"
        onChange={(event, newValue) => {
          setValue(newValue);
        }}>
        <BottomNavigationAction
          onClick={() => navigateTo(NavigationPath.FindzContainer)}
          showLabel
          label="Finds"
          data-testid="bottom-navigator-finds"
          icon={<GSImage src={Icons.findzIcon} width="24px" height="24px" />}
        />

        <BottomNavigationAction
          onClick={() => navigateTo(NavigationPath.TagsContainer)}
          showLabel
          label="Tags"
          data-testid="bottom-navigator-tags"
          icon={<GSIcon name="label" baseClassName="material-icons-outlined" fontSize="22px" />}
        />

        {/* <BottomNavigationAction label="Events" icon={<GSIcon name="event" />} /> */}

        <BottomNavigationAction
          data-testid="bottom-navigator-chat"
          onClick={() => navigateTo(NavigationPath.MessagesContainer)}
          showLabel
          label="Chat"
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          icon={<GSIcon name="chat" fontSize="22px" />}
        />
      </BottomNavigation>
    </Paper>
  );
}

const styles: Record<string, SxProps<Theme>> = {
  bottomNavigation: {
    display: "flex",
    height: "48px",
    "& .MuiBottomNavigationAction-root": {
      height: "100%",
      borderTop: "3px solid transparent",
      transition: "border-top 0.3s ease-in-out",
      "&.Mui-selected": { borderColor: "red" }
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: "12px"
    }
  }
};

export default BottomNavigationBar;
