export enum FirebaseFunctions {
  SendOtp = "sendOtp",
  VerifyOtp = "verifyOtp",
  RespondToInvitation = "respondToInvitation",
  AddUserToGroup = "addUserToGroup",
  DeleteGroup = "deleteGroup",
  RemoveUserFromGroup = "removeUserFromGroup",
  NotificationSubscription = "notificationSubscription",
  CloseAccount = "closeAccount",
  SendEmail = "sendEmail",
  ReverseCoords = "reverseCoords",
  SearchLocation = "searchLocation",
  AutoCompleteLocation = "autoCompleteLocation",
  GetStaticMap = "getStaticMap",
  VerifyEmail = "verifyEmail",
  GetHtmlPage = "getHtmlPage",
}
