import { CommonActions, ActivityActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { ActivityType } from "common-library/lib/schema";

interface IActivityState {
  activitiesMap: Map<string, ActivityType>;
  indexFindToActivities: Map<string, Set<string>>;
}

const defaultActivityState: IActivityState = {
  activitiesMap: new Map(),
  indexFindToActivities: new Map()
};

export const ActivityLabel = "Activity";

export const activityReducer = createReducer(defaultActivityState, builder => {
  builder
    .addCase(ActivityActions.setActivitiesMap, (state, action) => {
      state.activitiesMap = action.payload.activitiesMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultActivityState;
    })
    .addCase(ActivityActions.setIndexFindToActivities, (state, action) => {
      state.indexFindToActivities = action.payload.indexFindToActivities;
    });
});
