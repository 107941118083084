import React from "react";
import Style from "./NotFound.module.scss";
import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";
import "../../../styles/styles.scss";

function NotFound() {
  return (
    <div className={`${Style["container"]} d-flex jc-center al-center fd-column`}>
      <div className={`${Style["header"]} d-flex jc-center al-center fd-column`}>
        <LogoAndTitle />
      </div>
      <div className={`${Style["info"]}`}>Page Not Found</div>
    </div>
  );
}

export default NotFound;
