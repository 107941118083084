import { firebaseStorage } from "../../config/firebaseApp";
import { ref } from "firebase/storage";

export const firebaseStorageRef = (path: string) => {
  return ref(firebaseStorage, path);
};

export const firebaseStorageFindMediaRef = (fileName: string) => {
  return firebaseStorageRef(`findz-media/${fileName}`);
};

export const firebaseStorageFindMediaPreviewRef = (fileName: string) => {
  return firebaseStorageRef(`findz-media/preview/200px/${fileName}`);
};

export const firebaseStorageUserDpRef = (userId: string) => {
  return firebaseStorageRef(`user-dp/${userId}`);
};
