import { Icon, SxProps, Theme } from "@mui/material";
import * as React from "react";
import { ICONSIZE } from "../../../styles/size";
interface GSIconPropsTypes {
  name: string;
  sx?: SxProps<Theme>;
  onClick?: (e?: React.MouseEvent) => void;
  onMouseEnter?: (e?: React.MouseEvent) => void;
  onMouseLeave?: (e?: React.MouseEvent) => void;
  baseClassName?: string;
  fontSize?: string | number;
  color?: "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  className?: string;
}

const GSIcon = (props: GSIconPropsTypes) => {
  const { fontSize = ICONSIZE.NORMAL, name, sx, onClick, onMouseEnter, onMouseLeave, baseClassName, color, className } = props;

  return (
    <Icon
      sx={sx}
      style={{ fontSize }}
      className={className}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      baseClassName={baseClassName}
      color={color}>
      {name}
    </Icon>
  );
};
export default GSIcon;
