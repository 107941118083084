import { LocationDataType, FindzType, FindzDbType } from "common-library/lib/schema";
import { actionCreator, asyncActionCreator } from "../helper";
import { IActiveContext } from "../../types/types";

enum MediaActionTypes {
  DOWNLOAD_FINDS_MEDIA = "MEDIA/FINDS_DOWNLOAD",
  SAVE_DRAFT_MEDIA = "SAVE/DRAFT_MEDIA",
  "SET_DRAFT_MEDIA" = "SET/DRAFT_MEDIA",
  "CLEAR_DRAFT_MEDIA" = "CLEAR/DRAFT_MEDIA",
  "REMOVE_DRAFT_MEDIA" = "REMOVE_DRAFT_MEDIA",
  WATCH_UPLOAD_MEDIA_QUEUE = "MEDIA/QUEUE/WATCH",
  START_UPLOAD_MEDIA = "MEDIA/QUEUE/START",
  ADD_MEDIA_TO_FIND = "MEDIA/ADD_MEDIA_TO_FIND",
  DELETE_FIND_MEDIA = "MEDIA/DELETE_FIND_MEDIA",
  UPDATE_UPLOAD_MEDIA_QUEUE_IDENTIFIERS = "MEDIA/UPDATE_UPLOAD_MEDIA_QUEUE_IDENTIFIERS"
}

export const cacheFindsMedia = actionCreator<{ addedList?: FindzDbType[]; modifiedList?: FindzDbType[]; removedList?: FindzDbType[] }>(
  MediaActionTypes.DOWNLOAD_FINDS_MEDIA
);

export const saveDraftMedia = asyncActionCreator<undefined, { fileList: File[] | FileList; context?: IActiveContext }>(
  MediaActionTypes.SAVE_DRAFT_MEDIA
);

export const setDraftMedia = actionCreator<{
  draftMedia: string[];
}>(MediaActionTypes.SET_DRAFT_MEDIA);

export const clearDraftMedia = actionCreator(MediaActionTypes.CLEAR_DRAFT_MEDIA);

export const removeDraftMedia = actionCreator<{ mediaId: string }>(MediaActionTypes.REMOVE_DRAFT_MEDIA);

export const watchMediaUploadQueue = actionCreator(MediaActionTypes.WATCH_UPLOAD_MEDIA_QUEUE);

export const startMediaUpload = actionCreator(MediaActionTypes.START_UPLOAD_MEDIA);

export const addMediaToFind = actionCreator<{
  currentFind: FindzType;
  extractExif?: boolean;
  selectedLocation?: LocationDataType;
  selectedMedia?: string[];
}>(MediaActionTypes.ADD_MEDIA_TO_FIND);

export const deleteFindMedia = asyncActionCreator<undefined, { findId: string; mediaIds: string[] }>(MediaActionTypes.DELETE_FIND_MEDIA);

export const updateUploadMediaQueueIds = actionCreator<{ mediaIds: Set<string> }>(MediaActionTypes.UPDATE_UPLOAD_MEDIA_QUEUE_IDENTIFIERS);

export const MediaActions = {
  saveDraftMedia,
  setDraftMedia,
  clearDraftMedia,
  removeDraftMedia,
  watchMediaUploadQueue,
  startMediaUpload,
  cacheFindsMedia,
  addMediaToFind,
  deleteFindMedia,
  updateUploadMediaQueueIds
};
