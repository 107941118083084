import { CommonActions, FindActions, GroupActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { FindzType } from "common-library/lib/schema";
import { asyncCaseCreator, InitialAsyncState } from "../helper";
import { AsyncState } from "../../types/types";

interface IFindState {
  findzMap: Map<string, FindzType>;
  isLoading: boolean;
  findDelete: InitialAsyncState;
  forwardFind: InitialAsyncState;
  createFind: InitialAsyncState;
}

const defaultFindState: IFindState = {
  findzMap: new Map(),
  isLoading: false,
  findDelete: { status: AsyncState.idle },
  forwardFind: { status: AsyncState.idle },
  createFind: { status: AsyncState.idle }
};

export const FindLabel = "Find";

export const findReducer = createReducer(defaultFindState, builder => {
  // async
  asyncCaseCreator(FindActions.deleteFinds, "findDelete", builder);
  asyncCaseCreator(FindActions.forwardFinds, "forwardFind", builder);
  asyncCaseCreator(FindActions.createFind, "createFind", builder);

  //sync
  builder
    .addCase(FindActions.setFinds, (state, action) => {
      state.findzMap = action.payload.findzMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultFindState;
    })
    .addCase(FindActions.setIsLoading, (state, action) => {
      state.isLoading = action.payload.isLoading;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.findzMap = new Map();
    });
});
