import { UserType, CommentType, FindzType } from "common-library/lib/schema";
import { arrayRemove, arrayUnion, deleteField } from "firebase/firestore";
import { isEmpty } from "lodash";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { IActiveContext } from "../types/types";
import { createEmptyComment } from "../utils/commentUtils";
import { firestoreDocRef } from "../utils/firestoreUtils";
import { batchUtil, IBatchUtil } from "./common/batch";
import { findzServices } from "./find.service";

const createComment = async (activeContext: IActiveContext, currentUser: UserType, commentData: any, findsMap?: Map<string, FindzType>) => {
  const newComment = createEmptyComment(activeContext, currentUser);
  const newCommentRef = firestoreDocRef(FirestoreCollectionEnum.Comments);

  const dataToCreate = { ...newComment, identifier: newCommentRef.id } as any;
  let batch = batchUtil();
  if (commentData.text) {
    dataToCreate.text = commentData.text;
  }
  if (commentData.findzId) {
    dataToCreate.findzId = commentData.findzId;
  }
  if (commentData.mentions) {
    dataToCreate.mentions = commentData.mentions;
  }
  if (commentData.tagId) {
    dataToCreate.tagId = commentData.tagId;
  }
  if (commentData.eventId) {
    dataToCreate.eventId = commentData.eventId;
  }
  if (commentData.activityId) {
    dataToCreate.activityId = commentData.activityId;
  }
  if (commentData.repliedTo) {
    dataToCreate.repliedTo = commentData.repliedTo;
  }

  if (commentData.reaction && findsMap && findsMap.size) {
    dataToCreate.reaction = commentData.reaction;
    if (commentData.findzId) {
      const dataToUpdate = {} as any;
      dataToUpdate[`reactions`] = {
        [`${commentData.reaction}`]: {
          by: { identifier: currentUser.identifier, name: currentUser.name },
          comment: dataToCreate.identifier,
          on: new Date()
        }
      };
      const findIds = typeof commentData.findzId === "string" ? [commentData.findzId] : commentData.findzId;
      const findIdsToUpdate: string[] = [];
      for (const findId of findIds) {
        const findData = findsMap.get(findId);
        // if reaction is not there, add it
        if (findData) {
          let reactionAlreadyExists = false;
          if (findData.reactions) {
            const reactionsList = findData.reactions;
            if (reactionsList) {
              for (const [reaction, reactionData] of Object.entries(reactionsList)) {
                if (reaction === commentData.reaction) {
                  reactionData.forEach(item => {
                    if (item.by.identifier === currentUser?.identifier) {
                      reactionAlreadyExists = true;
                    }
                  });
                }
              }
              if (!reactionAlreadyExists) {
                findIdsToUpdate.push(findId);
              }
            }
          } else {
            findIdsToUpdate.push(findId);
          }
        }
      }
      if (findIdsToUpdate.length) {
        const newbatch = await findzServices.updateFind(findIdsToUpdate, dataToUpdate, undefined, batch);
        if (newbatch) {
          batch = newbatch;
        }
      }
    }
  }
  batch.set(newCommentRef, dataToCreate);
  await batch.commit();
  return Promise.resolve(true);
};

const updateComment = async (
  commentId: string,
  commentDataToInsert: Partial<CommentType>,
  commentDataToRemove?: Partial<CommentType>,
  writeBatch?: IBatchUtil
) => {
  try {
    let batch = writeBatch ? writeBatch : batchUtil();
    const commentRef = firestoreDocRef(FirestoreCollectionEnum.Comments, commentId);
    if (commentDataToRemove) {
      const dataToRemoveKeys = Object.keys(commentDataToRemove);
      const hasKey = (key: string) => dataToRemoveKeys.includes(key);
      const partialDataToRemove = {} as any;
      if (hasKey("mentions")) {
        if (commentDataToRemove.mentions?.length) {
          commentDataToRemove.mentions?.forEach(mention => {
            const dataToUpdate = {} as any;
            dataToUpdate["mentions"] = arrayRemove(mention);
            batch.update(commentRef, dataToUpdate);
          });
        } else {
          partialDataToRemove["mentions"] = deleteField();
        }
      }
      if (!isEmpty(partialDataToRemove)) {
        batch.update(commentRef, partialDataToRemove);
      }
    }

    if (commentDataToInsert) {
      const insertKeys = Object.keys(commentDataToInsert);
      const hasKey = (key: string) => insertKeys.includes(key);
      const partialDataToUpdate = {} as any;
      if (hasKey("text")) {
        partialDataToUpdate["text"] = commentDataToInsert.text;
      }
      if (hasKey("mentions")) {
        if (commentDataToInsert.mentions?.length) {
          commentDataToInsert.mentions.forEach(mention => {
            const dataToUpdate = {} as any;
            dataToUpdate["mentions"] = arrayUnion(mention);
            batch.update(commentRef, dataToUpdate);
          });
        }
      }
      if (hasKey("isDeleted")) {
        partialDataToUpdate["isDeleted"] = commentDataToInsert.isDeleted;
      }
      if (hasKey("history")) {
        partialDataToUpdate.history = commentDataToInsert.history;
      }
      if (!isEmpty(partialDataToUpdate)) {
        batch.update(commentRef, partialDataToUpdate);
      }
    }
    if (writeBatch) {
      return batch;
    }
    await batch.commit();
    return;
  } catch (error) {
    console.error("updateCommentSevice", error);
  }
};

export const commentServices = {
  createComment,
  updateComment
};
