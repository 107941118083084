const CryptoJS = require("crypto-js");

export const encrypt = (stringToEncrypt: string, secretKey: string) => {
  try {
    const encryptedString = CryptoJS.AES.encrypt(stringToEncrypt, secretKey).toString();
    return encryptedString;
  } catch (error) {
    console.log("encryption failed", error);
    return "";
  }
};

export const decrypt = (encryptedString: string, secretKey: string) => {
  try {
    const decryptedString = CryptoJS.AES.decrypt(encryptedString, secretKey).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedString;
  } catch (error) {
    console.log("decryption failed", error);
    return "";
  }
};
