export const countries = [
  {
    name: "Argentina",
    flag: "🇦🇷",
    code: "AR",
    dial_code: "+54",
    currencyCode: "ARS",
    symbol: "$",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dial_code: "+61",
    currencyCode: "AUD",
    symbol: "$",
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    code: "BS",
    dial_code: "+1242",
    currencyCode: "BSD",
    symbol: "$",
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    code: "BE",
    dial_code: "+32",
    currencyCode: "EUR",
    symbol: "€",
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    code: "BR",
    dial_code: "+55",
    currencyCode: "BRL",
    symbol: "R$",
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    code: "BG",
    dial_code: "+359",
    currencyCode: "BGN",
    symbol: "лв",
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    code: "CA",
    dial_code: "+1",
    currencyCode: "CAD",
    symbol: "$",
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    code: "CL",
    dial_code: "+56",
    currencyCode: "CLP",
    symbol: "$",
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    code: "HR",
    dial_code: "+385",
    currencyCode: "HRK",
    symbol: "kn",
  },
  {
    name: "Czech Republic",
    flag: "🇨🇿",
    code: "CZ",
    dial_code: "+420",
    currencyCode: "CZK",
    symbol: "Kč",
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    code: "DK",
    dial_code: "+45",
    currencyCode: "DKK",
    symbol: "kr",
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    code: "EG",
    dial_code: "+20",
    currencyCode: "EGP",
    symbol: "E£",
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    code: "HU",
    dial_code: "+36",
    currencyCode: "HUF",
    symbol: "ft",
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    code: "IS",
    dial_code: "+354",
    currencyCode: "ISK",
    symbol: "kr",
  },
  {
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
    currencyCode: "INR",
    symbol: "₹",
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    code: "IL",
    dial_code: "+972",
    currencyCode: "ILS",
    symbol: "₪",
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    code: "JP",
    dial_code: "+81",
    currencyCode: "JPY",
    symbol: "¥",
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    code: "MX",
    dial_code: "+52",
    currencyCode: "MXN",
    symbol: "$",
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    code: "MA",
    dial_code: "+212",
    currencyCode: "MAD",
    symbol: ".د.م",
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    code: "NZ",
    dial_code: "+64",
    currencyCode: "NZD",
    symbol: "$",
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    code: "NO",
    dial_code: "+47",
    currencyCode: "NOK",
    symbol: "kr",
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    code: "PK",
    dial_code: "+92",
    currencyCode: "PKR",
    symbol: "₨",
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    code: "PA",
    dial_code: "+507",
    currencyCode: "PAB",
    symbol: "B/",
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    code: "PY",
    dial_code: "+595",
    currencyCode: "PYG",
    symbol: "₲",
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    code: "PE",
    dial_code: "+51",
    currencyCode: "PEN",
    symbol: "S/",
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    code: "PL",
    dial_code: "+48",
    currencyCode: "PLN",
    symbol: "zł",
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    code: "RO",
    dial_code: "+40",
    currencyCode: "RON",
    symbol: "lei",
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    code: "SG",
    dial_code: "+65",
    currencyCode: "SGD",
    symbol: "$",
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    code: "ZA",
    dial_code: "+27",
    currencyCode: "ZAR",
    symbol: "R",
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    code: "LK",
    dial_code: "+94",
    currencyCode: "LKR",
    symbol: "₨",
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    code: "SE",
    dial_code: "+46",
    currencyCode: "SEK",
    symbol: "KR",
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    code: "CH",
    dial_code: "+41",
    currencyCode: "CHF",
    symbol: "CHF",
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    code: "TN",
    dial_code: "+216",
    currencyCode: "TND",
    symbol: "DT",
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    code: "TR",
    dial_code: "+90",
    currencyCode: "TRY",
    symbol: "₺",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
    currencyCode: "GBP",
    symbol: "£",
  },
  {
    name: "United States of America",
    flag: "🇺🇸",
    code: "US",
    dial_code: "+1",
    currencyCode: "USD",
    symbol: "$",
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    code: "UY",
    dial_code: "+598",
    currencyCode: "UYU",
    symbol: "$",
  },
];
