import { BrandType } from "common-library/lib/schema";
import { actionCreator } from "../helper";

enum BrandActionTypes {
  FETCH_LATEST_BRANDS = "BRANDS/FETCH",
  FETCH_LATEST_BRANDS_FROM_INDEXEDDB = "BRANDS/INDEXEDDB/FETCH",
  SET_BRANDS_MAP = "BRANDS/SET_MAP"
}

export const fetchLatestBrands = actionCreator(BrandActionTypes.FETCH_LATEST_BRANDS);

export const fetchLatestBrandsFromIndexedDb = actionCreator(BrandActionTypes.FETCH_LATEST_BRANDS_FROM_INDEXEDDB);

export const setBrandsMap = actionCreator<{ brandsMap: Map<string, BrandType> }>(BrandActionTypes.SET_BRANDS_MAP);
