import { VendorFormType } from "../schema";

export const createDefaultVendor = (businessName?: string): VendorFormType => {
  return {
    phoneNumber: {
      countryCode: "",
      callingCode: "",
      number: "",
    },
    address: {
      street: "",
      city: "",
      region: "",
      countryName: "",
      countryCode: "",
      postalCode: "",
    },
    businessName: businessName ? businessName : "",
    firstName: "",
    lastName: "",
    email: "",
    website: "",
    notes: "",
  };
};
