import { GroupStatus, IDocument } from "./index";
import { AddressType } from "./findzType";
import { ActivityInviterType } from "./activityType";
import { GroupRole } from "./groupType";
import { collectionMasks, IMap, ISms, maskIDocumentSecured } from "./commonTypes";

const maskAddressType = {
  name: true,
  street: true,
  locality: true,
  state: true,
  postalCode: true,
  country: true,
  city: true,
  region: true,
};
Object.freeze(maskAddressType);

export enum UserAccountStatus {
  active = "active",
  unverified = "unverified",
  disabled = "disabled",
  deleted = "deleted",
}

export interface IUserAccountStatus {
  status: UserAccountStatus;
  history: [
    {
      status: UserAccountStatus;
      timestamp: Date;
      reason?: string;
    }
  ];
}

export interface UserType extends IDocument {
  groups?: {
    [key: string]: {
      status: GroupStatus;
      activityStatus?: {
        [key: string]: "read" | "unread" | "archived" | "accepted" | "declined";
      };
    };
  };
  fcmTokens?: string[];
  notificationPermission: IUserNotificationPermission;
  name: string | null;
  identifier: string;
  loginMedium: LoginMedium;
  email?: string;
  userPhoto: string;
  phone?: UserPhoneType;
  location?: {
    cca2: string;
    street: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  geoLocations?: LocationDataType[];
  isEmailVerified: boolean;
  _documentCreatedOn?: Date;
  blockedUsers?: IMap<BlockedUserInfoType>;
  accountStatus: IUserAccountStatus;
  favorites?: {
    //user specific favourite
    [key: string]: {
      // groupId
      tags: string[];
    };
  };
}

export interface BlockedUserInfoType {
  name: string;
  phone: string;
  photoUrl: string;
  email: string;
  identifier: string;
  createdOn: Date;
}

export interface PublicUserType extends IDocument {
  email: string;
  name: string;
  userPhoto: string;
  userGroups?: string[];
  phone?: string;
}

export interface IUserNotificationPermission {
  muteAll: boolean;
  allUserNotifications: boolean;
  announcements: boolean;
  groups: {
    [group_id: string]: IGroupNotificationPermission;
  };
}

export interface IGroupNotificationPermission {
  muteAll: boolean;
  findAdded: boolean;
  messagedAnyone: boolean;
  messagedMe: boolean;
  groupModified: boolean;
  events: boolean;
}

export const userNotificationPermission = {
  allUserNotifications: false,
  announcements: false,
};

export const groupNotificationPermission = {
  muteAll: false,
  findAdded: false,
  messagedAnyone: false,
  messagedMe: false,
  groupModified: false,
  events: true,
};

export interface UserPhoneType {
  countryCode: string;
  number: string;
  isVerified: boolean;
  fullNumber?: string;
  sms?: ISms;
}

export interface GeolocationPositionType {
  latitude: number;
  longitude: number;
  altitude: number;
}

export interface LocationDataType {
  geoCoordinates: GeolocationPositionType;
  address?: AddressType;
  ttl?: Date;
  error?: string;
}

export interface ContactType {
  name: string;
  recordID: string;
  phoneNumbers?: { label?: string; number: string }[];
  emailAddresses?: { label?: string; email: string }[];
}

export interface PendingUserType {
  activities: {
    [key: string]: PendingUserActivityType;
  };
}

export type AuthUserType = {
  isEmailVerified: boolean;
  identifier: string;
  email: string;
};

export interface PendingUserActivityType {
  createdBy: ActivityInviterType;
  verb: string;
  role: GroupRole;
  group: {
    identifier: string;
    name: string;
  };
}

export enum LoginMedium {
  Google = "Google",
  Apple = "Apple",
  Email = "Email",
  CustomToken = "CustomToken",
}

export interface LocalTimeStampValue {
  [collectiontype: string]: {
    timeStampValue: any;
  };
}

export interface UserFilterType {
  name: string;
  identifier: string;
}

export const maskGeolocationPositionType = {
  latitude: true,
  longitude: true,
  altitude: true,
};
Object.freeze(maskGeolocationPositionType);

export const maskLocationDataType = {
  geoCoordinates: maskGeolocationPositionType,
  address: maskAddressType,
};
Object.freeze(maskLocationDataType);

export const maskUserNotificationPermissionType = {
  muteAll: true,
  allUserNotifications: true,
  announcements: true,
  groups: {
    __MAP__: true,
  },
};
Object.freeze(maskUserNotificationPermissionType);

export const maskUserPhoneType = {
  countryCode: true,
  number: true,
  isVerified: true,
  fullNumber: true,
  sms: { history: true },
};
Object.freeze(maskUserPhoneType);

export const maskBlockedUserInfoType = {
  name: true,
  phone: true,
  photoUrl: true,
  email: true,
  identifier: true,
  createdOn: true,
};
Object.freeze(maskBlockedUserInfoType);

export const maskUserType = {
  ...maskIDocumentSecured,
  groups: {
    __MAP__: {
      status: true,
      activityStatus: {
        __MAP__: true,
      },
    },
  },
  fcmTokens: [true],
  loginMedium: true,
  notificationPermission: maskUserNotificationPermissionType,
  name: true,
  phone: maskUserPhoneType,
  location: {
    cca2: true,
    street: true,
    city: true,
    state: true,
    country: true,
    postalCode: true,
  },
  geoLocations: [maskLocationDataType],
  isEmailVerified: true,
  _documentCreatedOn: true,
  email: true,
  userPhoto: true,
  accountStatus: true,
  blockedUsers: { __MAP__: maskBlockedUserInfoType },
  favorites: {
    __MAP__: {
      tags: true,
    },
  },
};
Object.freeze(maskUserType);

export const maskPublicUserType = {
  ...maskIDocumentSecured,
  email: true,
  name: true,
  userPhoto: true,
  userGroups: [true],
  phone: true,
};
Object.freeze(maskPublicUserType);

export const maskContactType = {
  name: true,
  recordID: true,
  phoneNumbers: [{ label: true, number: true }],
  emailAddresses: [{ label: true, email: true }],
};

Object.freeze(maskContactType);

collectionMasks["users"] = maskUserType;
collectionMasks["publicUserInfo"] = maskPublicUserType;

export interface localHistoryForPermissions {
  hasAlreadyAsked: boolean;
  date: Date;
}
