import { NotificationSubscriptionAction, NotificationTopicType, DataEmitSource } from "../constants";

export interface IMap<T> {
  [index: string]: T;
  [index: number]: T;
}

export interface IChanges<T> {
  added: Map<string, T>;
  modified: Map<string, T>;
  removed: Map<string, T>;
}

export interface IUpdatedData<T> {
  data: IChanges<T>;
  latestTimeStamp: any;
  source: DataEmitSource;
  showShimmer?: boolean;
}

export enum EOwner {
  User = "User",
  Group = "Group",
}

export interface IDocument {
  identifier: string;
  _documentUpdatedOn: Date;
  _serverDocumentUpdatedOn?: Date;
  _documentDeletedOn?: Date;
  _appVersion?: string;
  _serverDocumentCreatedOn?: Date;
}

export const maskIDocument = {
  identifier: true,
  _documentUpdatedOn: true,
  _documentDeletedOn: true,
  _serverDocumentUpdatedOn: true,
  _appVersion: true,
  _serverDocumentCreatedOn: true,
};
Object.freeze(maskIDocument);

export interface IDocumentSecured extends IDocument {
  owner: {
    identifier: string;
    type: EOwner;
    name?: string;
  };
}

export const maskIDocumentSecured = {
  ...maskIDocument,
  owner: {
    identifier: true,
    type: true,
    name: true,
  },
};
Object.freeze(maskIDocumentSecured);

export interface CreatedByType {
  by: string;
  on: Date;
  at?: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
}

export enum NotificationType {
  Invite = "invite",
  Forward = "forward",
  AddFind = "addFind",
  AddComment = "addComment",
  AddCollection = "addCollection",
  AccessRemoved = "accessRemoved",
  GroupModified = "groupModified",
  AddEvent = "addEvent",
  EventDeleted = "eventDeleted",
  EventCancelled = "eventCancelled",
  EventRescheduled = "eventRescheduled",
  EventReminder = "eventReminder",
  VerifyEmail = "verifyEmail",
  ReportToAdmin = "reportToAdmin",
  UserEngagement = "userEngagement",
}

export enum CudActions {
  added = "added",
  modified = "modified",
  removed = "removed",
}

export interface IMention {
  name: string;
  identifier: string;
  avatar: string;
}

export const maskIMention = {
  name: true,
  identifier: true,
  avatar: true,
};

Object.freeze(maskIMention);

export interface IMessage {
  userId?: string;
  condition: string;
  message: {
    body: string;
    title: string;
    type: string;
    data: IMap<any>;
  };
}

export interface ITopicMessage {
  userId?: string;
  topic: string;
  message: {
    body: string;
    title: string;
    type: string;
    data: IMap<any>;
  };
}

export const maskIMessage = {
  userId: true,
  condition: true,
  message: {
    body: true,
    title: true,
    type: true,
    data: {
      __MAP__: true,
    },
  },
};

Object.freeze(maskIMessage);

export const maskCreatedByType = {
  by: true,
  on: true,
  at: {
    latitude: true,
    longitude: true,
    altitude: true,
  },
};
Object.freeze(maskCreatedByType);

export interface IPushNotificationRequest extends IDocument {
  messages: Array<IMessage>;
}

export const maskIPushNotificationRequest = {
  ...maskIDocument,
  messages: [maskIMessage],
};

export const collectionMasks: any = {};

collectionMasks["pushNotificationRequests"] = maskIPushNotificationRequest;

export enum CommentEntityType {
  Findz = "Findz",
  Tags = "Tags",
  Events = "Events",
  Message = "Message",
  Activity = "Activity",
}

export interface FuncNotificationSubscriptionParams {
  userId: string;
  type: NotificationSubscriptionAction;
  fcmToken?: string;
  groupId?: string;
  notificationType?: NotificationTopicType;
}

export enum EmailTypes {
  emailVerification = "emailVerification",
  passwordReset = "passwordReset",
  welcomeUser = "welcomeUser",
  userDisabled = "userDisabled",
  userEnabled = "userEnabled",
  userClosedAccount = "userClosedAccount",
  invitation = "invitation",
  findzReport = "findzReport",
  userEngagement = "userEngagement",
}

export interface SendGridData {
  user?: { name: string; email: string; identifier: string };
  reasonForDisabling?: string;
  invitationDynamicData?: string;
  endDateToVerifyEmail?: string;
  setting?: any;
  reportInfo?: {
    env: string;
    userId: string;
    entityType: "Find" | "Message" | "Event";
    entityId: string;
    reportId: string;
    reasons: string;
  };
  engagementEmailInfo?: {
    subject: string;
    body: string;
  };
}

export enum SmsStatus {
  delivered = "delivered",
  undelivered = "undelivered",
  failed = "failed",
}

export interface SmsStatusHisory {
  status: SmsStatus;
  fullNumber?: string;
  timestamp: Date;
}

export interface ISms {
  smsStatus?: SmsStatus;
  lastSmsId?: string;
  history: {
    [smsId: string]: Array<SmsStatusHisory>;
  };
}

export interface IUserAction {
  lastActionTakenOn?: number;
  lastNotificationShownOn?: number;
  doNotShowAgain?: boolean;
}

export interface IUserActionEventConfig {
  mobile: string;
  pwa: string;
  target: string[];
}

export interface IUserActionRemoteConfig {
  [userAction: string]: IUserActionRemoteConfigData;
}

export interface IUserActionRemoteConfigData {
  type: string;
  title?: string;
  description: string;
  image?: string;
  priority: number;
  conditions?: { [key: string]: boolean };
  spanDays?: number;
  actions?: IUserActionPopupActionButtons[];
}

export interface IScreenRules {
  [rule: string]: {
    priority: number;
    spanDays?: number; // Don't fire until so many days have passed since the last fire
    maxFires?: number; // The max number of times this rule will be fired for a user
    if: {
      [event: string]: boolean;
    };
    then: {
      walkthrough?: string; // ID of the walkthrough
      event?: string;
    };
  };
}

export interface IUserActionPopupActionButtons {
  text: string; // Could include "Learn More"
  link: string;
}

export interface IWalkthroughData {
  action?: string; // ID of the action where this will show as a tooltip.  No action leads to an In-App Card.
  image?: string; // name of the image in storage
  title?: string;
  description: string;
  dismissText?: string; // Defaults to "OK".  Other examples: "Got It!"
  showMe?: string; // ID of another walkthrough
  actions?: IUserActionPopupActionButtons[];
}

export interface IWalkthroughRemoteConfig {
  [platform: string]: IScreenWalkthroughRemoteConfig;
}

export interface IScreenWalkthroughRemoteConfig {
  [screen: string]: {
    walkthroughs: {
      [walkthrough: string]: IWalkthroughData[];
    };
    onScreenHelp?: {
      [action: string]: string; // ID of the walkthrough
    };
    rules: IScreenRules;
    screenHelp?: IScreenHelp;
  };
}

export interface IScreenHelp {
  title?: string;
  description: string;
  image?: string; // name of the image in storage
  actions?: IUserActionPopupActionButtons[];
}

export interface IUserActionAnalytics {
  analytics: {
    by_user: {
      [userId: string]: IUserAnalytics;
    };
    images: IMap<string>; // key is name of image and value is the image url
  };
}

export interface ISubscriptionTopicData {
  subscribed: boolean;
  shownConfetti: boolean;
}

export interface IUserAnalyticsLocationData {
  currentLocation: string;
  locations: {
    [countryCode: string]: string; // country Name
  };
}

export enum UserAccountStatusRTDB {
  acitve = "active",
  adminDeleted = "admin-deleted",
  closed = "closed",
}

export interface IUserAnalytics {
  userData: {
    name: string;
    email: string;
    status: UserAccountStatusRTDB;
  };
  location: IUserAnalyticsLocationData;
  platform: {
    [platformName: string]: {
      actions: {
        [actionId: string]: IUserAction;
      };
    };
  };
  screens?: {
    [screenId: string]: {
      [ruleId: string]: {
        lastFiredTimeStamp: number;
        count: number;
      };
    };
  };
  subscriptionTopics: {
    collectionNotCreated: ISubscriptionTopicData;
    eventNotCreated: ISubscriptionTopicData;
    findNotCreated: ISubscriptionTopicData;
    groupNotCreated: ISubscriptionTopicData;
    messageNotCreated: ISubscriptionTopicData;
  };
  appAnalytics: {
    fz_analytics_mblAndroid: platformAnalyticsData;
    fz_analytics_mblIos: platformAnalyticsData;
    fz_analytics_pwaIos: platformAnalyticsData;
    fz_analytics_pwaAndroid: platformAnalyticsData;
    fz_analytics_pwaTablet: platformAnalyticsData;
    fz_analytics_pwaDesktop: platformAnalyticsData;
    fz_analytics_extChrome: platformAnalyticsData;
    fz_analytics_extSafari: platformAnalyticsData;
    fz_analytics_extFirefox: platformAnalyticsData;
  };
  notificationConfigs?: {
    [configId: string]: IUserNotificationAndEmailConfigData;
  };
  emailConfigs?: {
    [configId: string]: IUserNotificationAndEmailConfigData;
  };
}
export interface platformAnalyticsData {
  appVersion: string; // current user's app version
  lastActive: number; // timestamp
}

export interface IUserNotificationAndEmailConfigData {
  lastSentTimeStamp: number;
  count: number;
}

export enum UserAnalyticsSubscriptionTopics {
  collectionNotCreated = "collectionNotCreated",
  eventNotCreated = "eventNotCreated",
  findNotCreated = "findNotCreated",
  groupNotCreated = "groupNotCreated",
  messageNotCreated = "messageNotCreated",
}

export interface IConfettiRemoteConfigData {
  title: string;
  image: string;
  description: string;
}

export interface IConfettiRemoteConfig {
  [subscriptionTopic: string]: IConfettiRemoteConfigData;
}

export interface IUserNotificationAndEmailRemoteConfig {
  [configId: string]: {
    title: string;
    description: string;
    link?: string; // can be deeplink or url
    spanDays?: number;
    maxLimit?: number;
    sendAt?: string; // date string to send at
    rules: {
      [ruleId: string]: {
        [rule: string]: any;
      };
    };
  };
}

export interface IWalkthroughLogData {
  screen: string;
  ruleId: string;
}

export enum DateFormats {
  DatePickerDate = "YYYY-MM-DD",
  DefaultDate = "MMM DD, YYYY",
  FindzFilterDate = "MM/DD/YYYY",
  SearchCriteriaFromDate = "MMM DD",
  DefaultDateTime = "MMM DD, YYYY hh:mm A",
  DefaultTime = "hh:mm A",
  EventDate = "ddd MMM Do, YYYY",
}
