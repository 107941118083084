import { IActiveContext } from "../types/types";
import { UserType } from "common-library/lib/schema";
import { batchUtil } from "./common/batch";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { VendorType } from "common-library/lib/schema";
import { firestoreDocRef } from "../utils/firestoreUtils";
import { intersection } from "lodash";
import { commonServices } from "./common.service";

export async function createVendor(currentUser: UserType, activeContext: IActiveContext, vendorName: string) {
  const batch = batchUtil();

  if (!vendorName) {
    throw new Error(`Vendor name not available for ${activeContext?.type}`);
  }
  const vendorId = firestoreDocRef(FirestoreCollectionEnum.Vendors).id;
  if (!vendorId) {
    throw new Error("[VENDOR SERVICE]: Error generating vendor ID");
  }
  const date = new Date();
  const vendor: VendorType = {
    businessName: vendorName,
    identifier: vendorId,
    address: {
      city: "",
      countryCode: "",
      countryName: "",
      postalCode: "",
      region: "",
      street: "",
      name: ""
    },
    email: "",
    firstName: "",
    lastName: "",
    notes: "",
    phoneNumber: {
      callingCode: "",
      countryCode: "",
      number: ""
    },
    website: "",
    created: {
      by: currentUser.identifier,
      on: date
    },
    owner: {
      identifier: activeContext.identifier,
      type: activeContext.type
    },
    _documentUpdatedOn: date
  };

  batch.set(firestoreDocRef(FirestoreCollectionEnum.Vendors, vendor.identifier), vendor);
  await batch.commit();
  return Promise.resolve(vendor);
}

export const hasSameVendorForContext = async (vendorsList: VendorType[], context: IActiveContext) => {
  const vendorsListForContext: VendorType[] = await commonServices.getDataForContext(FirestoreCollectionEnum.Vendors, context);
  let existingVendor: string[] = [];

  if (vendorsListForContext.length) {
    const allVendorNames = vendorsList.map(vendor => vendor.businessName);
    const allVendorWebsites = vendorsList.map(vendor => vendor.website);
    const allTargetVendorNames = vendorsListForContext.map(vendor => vendor.businessName);
    const allTargetVendorWebsites = vendorsListForContext.map(vendor => vendor.website);

    existingVendor = intersection(allTargetVendorNames, allVendorNames) || intersection(allTargetVendorWebsites, allVendorWebsites);
  }
  return Boolean(existingVendor.length);
};
