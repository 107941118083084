import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { VendorType } from "common-library/lib/schema";
import { getActiveContext } from "./groupSelectors";

//#region from reducer
const _getVendorsMap = (state: AppState): Map<string, VendorType> => state.Vendor.vendorsMap;
//#endregion

//#region Memoized Selector
export const getVendorsMap = createDraftSafeSelector(_getVendorsMap, payload => payload);
//#endregion

export const getVendorList = createDraftSafeSelector([_getVendorsMap, getActiveContext], (payload, activeContext) => {
  return Array.from(payload.values());
});
