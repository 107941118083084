import { ContactType, EOwner, IUserInfoType, UserType } from "common-library/lib/schema";
import { TagDbType, TagType } from "common-library/lib/schema";
import { IQuery, ISubscription } from "gsdb/dist/interfaces";
import concat from "lodash/concat";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { useEffect, useState } from "react";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { IActiveContext } from "../types/types";
import { PhoneNumberUtil } from "google-libphonenumber";
import { database } from "./indexdb";
import { firestoreDocRef } from "./firestoreUtils";
import { Location } from "react-router-dom";
import { isEmpty, orderBy } from "lodash";
import { FindzContainerSearchParams } from "../constants/appEnum";
import { appConstant, maxNoOfDaysBeforeAccountDeletion } from "../constants/appContants";
import { fuzzy } from "fast-fuzzy";

import queryString from "./urlUtils";
import { getParsedPhoneNumber, testPhoneNumber } from "common-library/lib/utils/validator";

export const isValidURL = (str: string) => {
  const pattern = /^((http|https|ftp):\/\/)?[^\/\s]+\.[^\/\s]+.*$/;
  return pattern.test(str);
};

export const encodeString = (str: string) => window.btoa(str);
export const decodeString = (str: string) => window.atob(str);

export const getQueryFromActiveContext = (activeContext: IActiveContext): IQuery => {
  const _query: IQuery = { query: { "owner.identifier": { $eq: activeContext?.identifier } } };
  return _query;
};

export const getQueryFromMruList = () => {
  const _query: IQuery = { query: { key: { $eq: "mruData" } } };
  return _query;
};

export function useQuery<T>(collection: FirestoreCollectionEnum, query: IQuery, initialValue?: any): Array<T> {
  const [state, setState] = useState<Array<T>>([]);

  useEffect(() => {
    let subscription: ISubscription;
    let isMounted = true;

    if (initialValue && isMounted) {
      setState(initialValue);
    }

    if (query !== undefined && query !== null) {
      database
        .collection(collection)
        .query(query)
        .subscribe((data: T[] | undefined) => {
          if (isMounted && data) {
            setState(data);
          }
        })
        .then(_subscription => (subscription = _subscription))
        .catch(err => {
          console.error("Error in getting data", err);
        });
    }
    return () => {
      subscription?.unsubscribe();
      isMounted = false;
    };
  }, [query]);

  return state;
}

export const getChildrenFromEntity = (entities: any[], property: string, isMap?: boolean): string[] => {
  let responseArray: any[] = [];

  entities.forEach(element => {
    responseArray = concat(responseArray, isMap ? Object.values(get(element, property) || {}) : get(element, property));
  });

  return uniq(responseArray);
};

export const toTitleCase = (value: string | undefined | null) => {
  if (value) {
    return value.slice(0, 1).toUpperCase() + value.slice(1, value.length);
  }

  return value;
};

export const getFlattenTagName = (tag: TagType, tags: TagType[]) => {
  if (tag.ancestors.length) {
    const ancestors = tag.ancestors.map(id => tags.find(tag => tag.identifier === id));
    return ancestors.map(tag => tag?.name).join("/") + `/${tag.name}`;
  }
  return tag.name;
};

export const getTagsToCreateAndUpdate = (tags: TagDbType[], collectionName: string, currentUser: UserType, activeContext: IActiveContext) => {
  const collections = collectionName
    .trim()
    .split("/")
    .filter(collectionName => Boolean(collectionName));
  let ancestors: string[] = [];
  const date = new Date();
  let tagsToCreate: TagDbType[] = [];
  const tagsToUpdate: Partial<any>[] = [];
  let tagsToLookIn = [...tags];
  if (currentUser && activeContext) {
    for (let i = 0; i < collections.length; i++) {
      const collectionName = collections[i].trim();
      /**
       * first get the parent of the collection
       */
      const parent = tagsToLookIn.find(tag => {
        if (i === 0) {
          return tag.isRootTag && tag.name.trim().toLowerCase() === collectionName.toLowerCase();
        }
        return tag.name.trim().toLowerCase() === collectionName.toLowerCase();
      });
      if (parent) {
        ancestors.push(parent.identifier);
        //if parent is there look into there childern for rest of tags.
        tagsToLookIn = parent.children.map((id: string) => tags.find(tag => tag.identifier === id)).filter((tag: any) => tag !== undefined) as any[];
      } else {
        tagsToLookIn = [];
        const tag: TagDbType = {
          ancestors: ancestors,
          children: [],
          color: "#CC0000",
          created: {
            by: {
              identifier: currentUser.identifier,
              name: currentUser.name || ""
            },
            on: date
          },
          finds: [],
          identifier: firestoreDocRef(FirestoreCollectionEnum.Tags)?.id,
          isRootTag: ancestors.length === 0, // root tag
          name: toTitleCase(collectionName) || collectionName,
          owner: {
            identifier: activeContext.identifier,
            type: activeContext.type,
            name: (activeContext?.type === EOwner.Group ? activeContext.name : currentUser.name) || ""
          },
          _documentUpdatedOn: date
        };
        const ancestorId = ancestors[ancestors.length - 1];
        if (ancestorId) {
          let ancestor = tagsToCreate.find(t => t.identifier === ancestorId);
          if (ancestor) {
            ancestor.children.push(tag.identifier);
          } else {
            ancestor = tags.find(t => t.identifier === ancestorId);
            if (ancestor) {
              tagsToUpdate.push({ identifier: ancestor.identifier, child: tag.identifier });
            }
          }
        }
        tagsToCreate = [...tagsToCreate, tag];
        ancestors = [...ancestors, tag.identifier];
      }
    }
  }
  return { tagsToCreate, tagsToUpdate };
};

export const parseUrlParams = (data: Location) => {
  let params: any = {};
  if (data.search) {
    params = queryString.parseUrl(data.search);
  }
  return params;
};

export const getPathWithQueryString = (path: string, params: any) => {
  if (isEmpty(params)) {
    return path;
  }
  return `${path}?${queryString.stringify(params)}`;
};

export const isNameEmailOrPhoneNumber = (str: string): { isName: boolean; isEmail: boolean; isPhoneNumber: boolean } => {
  let isName = false;
  let isEmail = false;
  let isPhoneNumber = false;
  const namePattern = /^[A-Za-z\s]{2,}$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^[\d\s\-()]+$/;
  isName = namePattern.test(str);
  isEmail = emailPattern.test(str);
  isPhoneNumber = phonePattern.test(str);
  return { isName, isEmail, isPhoneNumber };
};

export function getCollectionFromUrl(key: string) {
  if (key == "add-finds-to-collection") {
    key = "tags";
  }
  const collectionRouteMap: any = {
    finds: FirestoreCollectionEnum.Findz,
    messages: FirestoreCollectionEnum.Comments,
    tags: FirestoreCollectionEnum.Tags,
    events: FirestoreCollectionEnum.Events,
    groups: FirestoreCollectionEnum.Groups
  };
  return collectionRouteMap[key] as FirestoreCollectionEnum;
}

export const getNewSearchParamsWithGroup = (currentSearchParams: URLSearchParams) => {
  const newSearchParams = new URLSearchParams();
  const groupId = currentSearchParams.get(FindzContainerSearchParams.GROUP);
  if (groupId) {
    newSearchParams.set(FindzContainerSearchParams.GROUP, groupId);
  }
  return newSearchParams;
};

export const getMRUMergedList = (mruList: string[], objList: any[], orderByFn: string, orderByDirection: any = "desc") => {
  try {
    const displayMRUList = mruList.slice(0, appConstant.displayMRUAllowed);
    const mruListObjs: any[] = [];
    const nonMruObjs: any[] = [];

    for (const obj of objList) {
      const mruIndex = displayMRUList.findIndex((mruItem: any) => mruItem === obj.identifier);
      if (mruIndex !== -1) {
        mruListObjs[mruIndex] = obj;
      } else {
        nonMruObjs.push(obj);
      }
    }

    const filteredMruList = mruListObjs.filter(mruItem => !!mruItem);
    return [...filteredMruList, ...orderBy(nonMruObjs, orderByFn, orderByDirection)];
  } catch (error) {
    console.error("error", error);
    return objList;
  }
};

export const generateFileNameForMediaDownload = (title: string, extensionType?: string) => {
  const date = new Date();
  const timestamp = Math.floor(date.getTime() + date.getSeconds());
  const formattedTitle = title ? title.replaceAll(" ", "_") : "";
  let fileName = "download";
  if (extensionType) {
    fileName = `${formattedTitle ? formattedTitle : "findz"}_${timestamp}.${extensionType}`;
  } else {
    fileName = `${formattedTitle ? formattedTitle : "findz"}_${timestamp}`;
  }
  return fileName;
};

export const downloadFile = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const blobURL = URL.createObjectURL(blob);

  const aTag = document.createElement("a");
  aTag.style.display = "none";
  aTag.href = blobURL;
  aTag.setAttribute("download", fileName);
  document.body.appendChild(aTag);

  aTag.click();
  URL.revokeObjectURL(blobURL);
  aTag.remove();
};

export const fuzzySearch = (field: string, text: string, options?: any) => {
  options = options && typeof options == "object" ? options : {};
  options.threshold =
    options.threshold && typeof options.threshold == "number" && options.threshold <= 1 && options.threshold > 0
      ? options.threshold
      : Math.min(0.9, 1 - Math.min(text.length, 10) * 0.04);
  if (field && fuzzy(text, field) >= options.threshold) {
    return true;
  }
  return false;
};

export const convertToContactType = (contact: IUserInfoType, countryCode: string): ContactType => {
  return {
    name: contact.name,
    recordID: contact.recordID,
    phoneNumbers: contact.phone ? [{ number: getParsedPhoneNumber(contact.phone, countryCode) }] : [],
    emailAddresses: contact.email ? [{ email: contact.email }] : []
  };
};

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export class Validator {
  invalid: boolean;
  reason: string;
  constructor() {
    this.invalid = false;
    this.reason = "";
  }
  updateResult(isValid: boolean, reason: string) {
    if (!this.invalid && !isValid) {
      this.invalid = true;
      this.reason = reason;
    }
  }

  emptryString(input: string) {
    this.updateResult(!!input, "Phone number required");
    return this;
  }

  phoneNumberValidator(input: string) {
    const validatePhone = input.match(/^\d+$/);
    if (!validatePhone) {
      this.updateResult(false, "Invalid Phone number");
    } else {
      this.updateResult(true, "");
    }
    return this;
  }

  onChangePhoneValidator(input: string) {
    const validatePhone = input.match(/^[0-9]{0,12}$/);
    this.updateResult(!!validatePhone, "Invalid Phone Number");
    return this;
  }

  validate() {
    return { invalid: this.invalid, reason: this.reason };
  }
}

export const getNumber = (phoneNumberText: string, countryCode: string) => {
  const number = phoneNumberText.trim();
  const inputValidator = PhoneNumberUtil.getInstance();
  let parsedNumber;
  if (number.startsWith("+")) {
    parsedNumber = inputValidator.parseAndKeepRawInput(number);
  } else {
    parsedNumber = inputValidator.parseAndKeepRawInput(number, countryCode);
  }
  const nationalNumber = parsedNumber.getNationalNumber()?.toString() || phoneNumberText;
  const callingCode = parsedNumber.getCountryCode()?.toString();
  // || getCallingCode(countryCode);
  return { number: nationalNumber, callingCode };
};

export const createDefaultContact = () => {
  return {
    hasThumbnail: false,
    thumbnailPath: "",
    postalAddresses: [
      {
        label: "",
        formattedAddress: "",
        street: "",
        pobox: "",
        neighborhood: "",
        city: "",
        region: "",
        state: "",
        postCode: "",
        country: ""
      }
    ],
    prefix: "",
    suffix: "",
    department: "",
    birthday: {
      day: 10,
      month: 2,
      year: 1995
    },
    color: "",
    givenName: "nameInput",
    middleName: "",
    company: "",
    familyName: "",
    jobTitle: "",
    recordID: "",
    phoneNumbers: [],
    emailAddresses: []
  };
};

export const removeInvalidEmailAndNumberFromContact = (contact: any, countryCode: string) => {
  const validEmails: any[] = [];
  if (contact.emailAddresses?.length) {
    contact.emailAddresses.forEach((emailAddress: any) => {
      if (isValidEmail(emailAddress.email)) {
        validEmails.push(emailAddress);
      }
    });
  }
  const validPhoneNumbers: any[] = [];
  if (contact.phoneNumbers?.length) {
    contact.phoneNumbers.forEach((phoneNumber: any) => {
      let testResult = testPhoneNumber(phoneNumber.number, countryCode);
      if (!testResult.error) {
        validPhoneNumbers.push(phoneNumber);
      }
    });
  }

  if (!validEmails.length && !validPhoneNumbers.length) {
    throw new Error("Invalid Contact");
  }
  contact.emailAddresses = validEmails;
  contact.phoneNumbers = validPhoneNumbers;
  return contact;
};

/**
 *  1 if first version is greater,
 *  0 if versions are equal,
 * -1 if the second version is greater
 */
export function compareSemVer(v1: string, v2: string) {
  const v1arr = v1.split(".") as any[];
  const v2arr = v2.split(".") as any[];
  const k = Math.min(v1arr.length, v2arr.length);
  for (let i = 0; i < k; ++i) {
    v1arr[i] = parseInt(v1arr[i], 10);
    v2arr[i] = parseInt(v2arr[i], 10);
    if (v1arr[i] > v2arr[i]) {
      return 1;
    }
    if (v1arr[i] < v2arr[i]) {
      return -1;
    }
  }
  return v1.length === v2.length ? 0 : v1.length < v2.length ? -1 : 1;
}

function shouldForceUserToSignout(appVersion: string, versionsRequireLogin: Array<string>) {
  const sortVersionsRequireLogin = versionsRequireLogin.sort((a, b) => compareSemVer(a, b));

  const first = compareSemVer(appVersion, sortVersionsRequireLogin[0]);
  const second = compareSemVer(appVersion, sortVersionsRequireLogin[1]);

  if (first >= 0 && second <= 0) {
    return true;
  }

  return false;
}

export const shouldSignoutWhenVersionChange = () => {
  const currentVersion = appConstant.currentVersion;
  const installedVersion = localStorage.getItem("app_version");
  const requireLogin: Array<string> = appConstant.requireLogin;

  if (currentVersion !== installedVersion) {
    localStorage.setItem("app_version", currentVersion);
    if (requireLogin.length > 0) {
      if (installedVersion === undefined || installedVersion === null) {
        return true;
      }

      const forceUserToLogout = shouldForceUserToSignout(currentVersion, requireLogin);
      if (forceUserToLogout) {
        return true;
      }
    }
  }

  return false;
};
