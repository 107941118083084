import { combineReducers } from "redux";
import { commonReducer, CommonLabel } from "./common.reducer";
import { AuthLabel, authReducer } from "./auth.reducer";
import { groupReducer, GroupLabel } from "./group.reducer";
import { FindLabel, findReducer } from "./find.reducer";
import { MediaLabel, mediaReducer } from "./media.reducer";
import { VendorLabel, vendorReducer } from "./vendor.reducer";
import { BrandLabel, brandReducer } from "./brand.reducer";
import { TagLabel, tagReducer } from "./tag.reducer";
import { EventLabel, eventReducer } from "./event.reducer";
import { CommentLabel, commentReducer } from "./comment.reducer";
import { ActivityLabel, activityReducer } from "./activity.reducer";

export const rootReducer = combineReducers({
  [CommonLabel]: commonReducer,
  [AuthLabel]: authReducer,
  [GroupLabel]: groupReducer,
  [FindLabel]: findReducer,
  [VendorLabel]: vendorReducer,
  [MediaLabel]: mediaReducer,
  [BrandLabel]: brandReducer,
  [TagLabel]: tagReducer,
  [EventLabel]: eventReducer,
  [CommentLabel]: commentReducer,
  [ActivityLabel]: activityReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
