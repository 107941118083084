import { GeolocationPositionType, maskGeolocationPositionType } from "./userType";
import { collectionMasks, EOwner, IDocumentSecured, maskIDocumentSecured } from "./commonTypes";
import { AddressType, Reaction, maskAddressType, maskReaction } from "./findzType";

export interface IEvent extends IDocumentSecured {
  name: string;
  description?: string;
  color?: string;
  finds?: string[];
  collections?: string[];
  created: {
    on: Date;
    by: {
      identifier: string;
      name: string;
    };
  };
  owner: {
    identifier: string;
    type: EOwner;
    name: string;
  };
  invitees?: string[];
  rsvps?: {
    accepted?: string[];
    declined?: string[];
  };
  schedule: {
    startDateTime: Date;
    endDateTime: Date;
    timeZone: string;
  };
  cancelled?: {
    on: Date;
    by: {
      identifier: string;
      name: string;
    };
  };
  location?: {
    geoCoordinates?: GeolocationPositionType;
    address?: AddressType; // Should be populated if the app is able to obtain address using location services,
  };
  reactions?: Reaction;
  webMeetingLink?: string;
}

export const maskEventType = {
  ...maskIDocumentSecured,
  name: true,
  description: true,
  color: true,
  finds: true,
  created: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
  },
  owner: {
    identifier: true,
    type: true,
    name: true,
  },
  invitees: true,
  rsvps: {
    accepted: true,
    declined: true,
  },
  schedule: {
    startDateTime: true,
    endDateTime: true,
    timeZone: true,
  },
  cancelled: {
    on: true,
    by: {
      identifier: true,
      name: true,
    },
  },
  location: {
    geoCoordinates: maskGeolocationPositionType,
    address: maskAddressType, // Should be populated if the app is able to obtain address using location services
  },
  webMeetingLink: true,
  reactions: { __MAP__: [maskReaction] },
};

Object.freeze(maskEventType);

collectionMasks["events"] = maskEventType;

export interface IEventReminder {
  triggerMilliSeconds: number; // Subtract this from the start datetime of the event to schedule an on-device reminder.
  scheduledPushNotification: number; // ID of the scheduled push notification
  userSelection: {
    value: number; // The value of the reminder, i.e. 10, 20, 30
    denomination: string; // The denomination of the reminder, i.e. "minutes", "hours" or "days"
  };
}
