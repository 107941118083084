import { createStore, applyMiddleware, compose } from "redux";
import { enableMapSet } from "immer";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./reducers/root.reducer";
import rootSaga from "./sagas";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { PreloadedState } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  process.env.REACT_APP_REDUX_DEV_TOOLS === "TRUE"
    ? composeWithDevToolsDevelopmentOnly(applyMiddleware(sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));

export const setupStore = (preloadedState?: PreloadedState<typeof rootReducer>) => {
  return createStore(rootReducer, preloadedState, composeEnhancers);
};

const store = setupStore();

enableMapSet();
sagaMiddleware.run(rootSaga);

export default store;

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
