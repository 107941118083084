import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ActivityType } from "common-library/lib/schema";

//#region from reducer
const _getActivitiesMap = (state: AppState): Map<string, ActivityType> => state.Activity.activitiesMap;
//#endregion

//#region Memoized Selector
export const getActivitiesMap = createDraftSafeSelector(_getActivitiesMap, payload => payload);
//#endregion

//#region from reducer
const _getIndexFindToActivities = (state: AppState): Map<string, Set<string>> => state.Activity.indexFindToActivities;
//#endregion

//#region Memoized Selector
export const getIndexFindToActivities = createDraftSafeSelector(_getIndexFindToActivities, payload => payload);
//#endregion
