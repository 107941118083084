import { takeLatest, fork, call, select, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { BrandActions, CommonActions } from "../actions";
import {
  getDataFromChanges,
  syncWithIndexDB,
  getListenerToFetchData,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData
} from "../../services";
import { getActiveContext, getTimestamp } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import { BrandType, LocalTimeStampValue } from "common-library/lib/schema";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import { IActiveContext } from "../../types/types";
import { getQueryFromActiveContext } from "../../utils/commonUtils";
import { EventChannel } from "redux-saga";
import { IChanges } from "gsdb/dist/interfaces";
import { getBrandsMap } from "../selectors";

export function* fetchLatestBrandsSaga(action: ActionType<typeof BrandActions.fetchLatestBrands>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Brands, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);

      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<BrandType>(changes, timeStampValue);
      const newData = concat(dataAdded, dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Brands, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Brands,
          timestamp: latestTimeStamp,
          groupId: activeContext.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestBrandsSaga", error);
  }
}

export function* fetchLatestBrandsFromIndexedDbSaga(action: ActionType<typeof BrandActions.fetchLatestBrandsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Brands, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];

        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }

      const existingBrandMap: Map<string, BrandType> = yield select(getBrandsMap);
      const newBrandsMap = new Map(existingBrandMap);

      amList.forEach((brand: BrandType) => {
        newBrandsMap.set(brand.identifier, brand);
      });

      rList.forEach((brand: BrandType) => {
        newBrandsMap.delete(brand.identifier);
      });

      yield put(BrandActions.setBrandsMap({ brandsMap: newBrandsMap }));
    }
  } catch (error) {
    console.error("fetchLatestBrandsFromIndexedDbSaga", error);
  }
}

function* fetchLatestBrandsListener() {
  yield takeLatest(BrandActions.fetchLatestBrands.toString(), fetchLatestBrandsSaga);
}

function* fetchLatestBrandsFromIndexedDbListener() {
  yield takeLatest(BrandActions.fetchLatestBrandsFromIndexedDb.toString(), fetchLatestBrandsFromIndexedDbSaga);
}

export default function* brandSaga() {
  yield fork(fetchLatestBrandsListener);
  yield fork(fetchLatestBrandsFromIndexedDbListener);
}
