import { takeLatest, fork, call, select, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { ActivityActions, CommonActions } from "../actions";
import { getDataFromChanges, syncWithIndexDB, listenToIndexedDb, IndexedDbListenerResponse, filterChangeData } from "../../services";
import { getActiveContext, getCurrentUser, getIndexFindToActivities, getTimestamp } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import { ActivityType, LocalTimeStampValue, EOwner, ForwardActivityType, UserType } from "common-library/lib/schema";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import { IActiveContext } from "../../types/types";
import { EventChannel } from "redux-saga";
import { IChanges, IQuery } from "gsdb/dist/interfaces";
import { getActivitiesMap } from "../selectors";
import {
  getListenerToFetchUserActivityData,
  getListenerToFetchGroupActivityData,
  unsubscribeActivitiesDBListener
} from "../../services/activity.service";
import { updateIndexOfFindToActivities } from "../../utils/activityUtils";

export function* fetchLatestUserActivitiesSaga(action: ActionType<typeof ActivityActions.fetchLatestUserActivities>) {
  try {
    const currentUser: UserType = yield select(getCurrentUser);
    if (isEmpty(currentUser)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchUserActivityData, currentUser, timestamp);
    const batch = getBatch();
    while (true) {
      const { changes } = yield take(listener);
      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<ActivityType>(changes, timeStampValue);
      const newData = concat(dataAdded, dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Activities, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Activities,
          timestamp: latestTimeStamp,
          groupId: currentUser.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestUserActivitiesSaga", error);
  }
}

export function* fetchLatestGroupActivitiesSaga(action: ActionType<typeof ActivityActions.fetchLatestUserActivities>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    if (activeContext.type === EOwner.User) {
      unsubscribeActivitiesDBListener("group");
    } else {
      const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);

      const { listener, timeStampValue } = yield call(getListenerToFetchGroupActivityData, activeContext, timestamp);
      const batch = getBatch();
      while (true) {
        const { changes } = yield take(listener);
        const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<ActivityType>(changes, timeStampValue);
        const newData = concat(dataAdded, dataModified);
        yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Activities, newData, dataDeleted);
        yield put(
          CommonActions.updateTimestamp({
            collectionType: FirestoreCollectionEnum.Activities,
            timestamp: latestTimeStamp,
            groupId: activeContext.identifier
          })
        );
      }
    }
  } catch (error) {
    console.error("fetchLatestGroupActivitiesSaga", error);
  }
}

export function* fetchLatestActivitiesFromIndexedDbSaga(action: ActionType<typeof ActivityActions.fetchLatestActivitiesFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);
    if (isEmpty(activeContext)) {
      return;
    }
    const _query: IQuery = {};
    if (activeContext.type === EOwner.User) {
      _query.query = { "accessibleTo.users": { $eq: activeContext.identifier } };
    } else {
      _query.$or = { "accessibleTo.groups": { $eq: activeContext.identifier }, "accessibleTo.users": { $eq: currentUser.identifier } };
    }

    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Activities, _query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);
      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(_query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }
      const existingActivityMap: Map<string, ActivityType> = yield select(getActivitiesMap);
      const newActivitiesMap = new Map(existingActivityMap);

      const indexFindToActivities: Map<string, Set<string>> = yield select(getIndexFindToActivities);
      let updatedIndexFindToActivities = new Map(indexFindToActivities);

      amList.forEach(activity => {
        newActivitiesMap.set(activity.identifier, activity);
        if (activity.object?.finds?.length) {
          for (const findId of activity.object?.finds) {
            updatedIndexFindToActivities = updateIndexOfFindToActivities(activity, findId, updatedIndexFindToActivities);
          }
        }
      });

      rList.forEach(activity => {
        newActivitiesMap.delete(activity.identifier);
        if (activity.object?.finds?.length) {
          for (const findId of activity.object?.finds) {
            updatedIndexFindToActivities = updateIndexOfFindToActivities(activity, findId, updatedIndexFindToActivities, true);
          }
        }
      });
      yield put(ActivityActions.setActivitiesMap({ activitiesMap: newActivitiesMap }));
      yield put(ActivityActions.setIndexFindToActivities({ indexFindToActivities: updatedIndexFindToActivities }));
    }
  } catch (error) {
    console.error("fetchLatestActivitiesFromIndexedDbSaga", error);
  }
}

function* fetchLatestUserActivitiesListener() {
  yield takeLatest(ActivityActions.fetchLatestUserActivities.toString(), fetchLatestUserActivitiesSaga);
}

function* fetchLatestGroupActivitiesListener() {
  yield takeLatest(ActivityActions.fetchLatestGroupActivities.toString(), fetchLatestGroupActivitiesSaga);
}

function* fetchLatestActivitiesFromIndexedDbListener() {
  yield takeLatest(ActivityActions.fetchLatestActivitiesFromIndexedDb.toString(), fetchLatestActivitiesFromIndexedDbSaga);
}

export default function* activitySaga() {
  yield fork(fetchLatestUserActivitiesListener);
  yield fork(fetchLatestGroupActivitiesListener);
  yield fork(fetchLatestActivitiesFromIndexedDbListener);
}
