import { ContactType, EOwner, GroupDbType, GroupMemberDbType, GroupRole, GroupStatus, GroupType, UserType } from "common-library/lib/schema";
import { cloneDeep, concat, findIndex, get, map, values } from "lodash";
import { appTexts } from "../constants/appTexts";
import { GroupMemberRenderType, IActiveContext } from "../types/types";

export const getMemberNamesOfGroup = (group: GroupType, currentUser: UserType): string => {
  const { memberAccessList, members } = group;
  let administrators: Record<string, any> = {};
  let collaborators: Record<string, any> = {};
  let owner: any;
  if (members) {
    administrators = members.administrators;
    collaborators = members.collaborators;
    owner = members.owner;
  }

  const limitTo = 2;
  const memberNames = memberAccessList
    .map(id => (administrators && administrators[id]) || (collaborators && collaborators[id]) || owner)
    .filter(member => Boolean(member?.userName) && member?.identifier !== currentUser?.identifier)
    .map(member => member?.userName || "")
    .map(name => name?.split(" ")[0])
    .slice(0, limitTo)
    .join(", ");

  if (memberNames.length === 0) {
    return appTexts.onlyMe;
  }

  // limitTo + 1 -> Added one to not count the current user.
  if (memberAccessList.length > limitTo + 1) {
    return `${memberNames}, +${memberAccessList.length - (limitTo + 1)} more`;
  }

  return memberNames;
};

export const prioritizeCurrentUserMap = (membersMap: Map<string, GroupMemberRenderType>, currentUser: UserType) => {
  const groupMembersMap = new Map(membersMap);
  const currentUserMap = new Map();
  const member = groupMembersMap.get(currentUser.identifier);
  if (member) {
    currentUserMap.set(currentUser.identifier, { ...member, userName: "Me" });
    groupMembersMap.delete(currentUser.identifier);
  }
  const updatedMembersMap: Map<string, GroupMemberRenderType> = new Map([...Array.from(currentUserMap), ...Array.from(groupMembersMap)]);
  return updatedMembersMap;
};

export const getGroupMembersMapFromOfflineData = (groupData: GroupType, currentUser: UserType) => {
  const groupMemberList: Map<string, GroupMemberRenderType> = new Map();
  if (groupData.offlineGroupData) {
    const { owner, administrators, collaborators, viewers } = groupData.offlineGroupData;
    const addMember = (groupMembers: ContactType[], role: GroupMemberDbType["role"]) =>
      map(groupMembers, (groupMember: ContactType) =>
        groupMemberList.set(groupMember.recordID, {
          role,
          userName: groupMember.name ? groupMember.name.trim().replace(/\s.*/g, "") : "",
          identifier: groupMember.recordID,
          phone: {
            number: groupMember.phoneNumbers && groupMember.phoneNumbers.length > 0 ? groupMember.phoneNumbers[0].number : "",
            countryCode: ""
          },
          status: role === GroupRole.owner ? GroupStatus.active : GroupStatus.pending,
          userAvatar: "",
          email: groupMember.emailAddresses?.length ? groupMember.emailAddresses[0].email : ""
        })
      );
    addMember([owner], GroupRole.owner);
    addMember(administrators, GroupRole.administrator);
    addMember(collaborators, GroupRole.collaborator);
    addMember(viewers, GroupRole.viewer);
  }
  return prioritizeCurrentUserMap(groupMemberList, currentUser);
};

export const prioritizeCurrentUser = (memberList: GroupMemberDbType[], currentUser: UserType) => {
  const updatedList = cloneDeep(memberList);
  const currentUserIndex = findIndex(updatedList, { identifier: currentUser.identifier });
  if (currentUserIndex >= 0) {
    const currentUserData = updatedList[currentUserIndex];
    updatedList.splice(currentUserIndex, 1);
    updatedList.unshift(currentUserData);
  }
  return updatedList;
};

export const getGroupMembers = (groupData: GroupType, currentUser: UserType) => {
  if (groupData.members) {
    const { owner, administrators, collaborators, unregistered } = groupData.members;
    const groupMemberList = concat([owner], values(administrators), values(collaborators), values(unregistered));
    return prioritizeCurrentUser(groupMemberList, currentUser);
  }
  const membersMap = getGroupMembersMapFromOfflineData(groupData, currentUser);
  return Array.from(membersMap.values());
};

export const getGroupMembersMap = (
  selectedContext: IActiveContext,
  currentUser: UserType,
  groupData?: GroupType
  // contactList?: RenderContact[]
): Map<string, GroupMemberRenderType> => {
  const currentUserMap: Map<string, GroupMemberRenderType> = new Map();
  currentUserMap.set(currentUser.identifier, {
    userName: "Me",
    userAvatar: currentUser.userPhoto,
    identifier: currentUser.identifier,
    phone: currentUser.phone as any,
    status: GroupStatus.active,
    email: currentUser.email,
    role: GroupRole.owner
  });
  if (groupData && selectedContext.type === EOwner.Group) {
    let allGroupMembers: Map<string, GroupMemberRenderType> = new Map();

    if (groupData.members) {
      const constructMembers = (membersList: { [key: string]: GroupMemberRenderType }, role?: GroupRole, pastMembersList?: boolean) => {
        const membersMap: Map<string, GroupMemberRenderType> = new Map();
        map(membersList, (member: GroupMemberRenderType, memberId: string) => {
          try {
            const phoneNumber =
              member.phone && member.phone.countryCode && member.phone.number ? `${member.phone.countryCode}${member.phone.number}` : "";
            const name = member.userName || member.name || phoneNumber;
            membersMap.set(memberId, {
              identifier: memberId,
              phone: member.phone,
              role: role || member.role,
              status: role === GroupRole.owner ? GroupStatus.active : member.status,
              userName: currentUser.identifier === memberId ? "Me" : name,
              isUnregistered: role ? member.isUnregistered : true,
              email: get(member, "email", ""),
              memberSince: member.memberSince || undefined,
              userAvatar: member.userAvatar, // memberId === currentUser.identifier ? currentUser.userPhoto: ""
              isPastMember: pastMembersList || false
            });
          } catch (error) {
            console.debug("error", error);
          }
        });
        return membersMap;
      };
      const { members, pastMembers } = groupData;
      const admins = constructMembers(members.administrators, GroupRole.administrator);
      const collaborators = constructMembers(members.collaborators, GroupRole.collaborator);
      const viewers = constructMembers(members.viewers, GroupRole.collaborator);
      if (members.owner) {
        const owner = constructMembers({ [members.owner.identifier]: members.owner }, GroupRole.owner);
        const unregistered = constructMembers(members.unregistered);
        const pastmembersList = constructMembers(pastMembers as any, undefined, true);
        allGroupMembers = new Map([
          ...Array.from(pastmembersList),
          ...Array.from(owner),
          ...Array.from(admins),
          ...Array.from(viewers),
          ...Array.from(unregistered),
          ...Array.from(collaborators)
        ]);
      }
    } else {
      allGroupMembers = getGroupMembersMapFromOfflineData(groupData, currentUser);
    }
    return prioritizeCurrentUserMap(allGroupMembers, currentUser);
  }
  return currentUserMap;
};

export const getMembersOfGroup = (groupData: GroupDbType) => {
  const groupMemberList: GroupMemberDbType[] = [];
  if (groupData.members) {
    const { owner, administrators, collaborators, unregistered } = groupData.members;
    const addMember = (groupMembers: any, role: string) => {
      Object.keys(groupMembers).forEach(key => {
        groupMemberList.push({ ...groupMembers[key], role });
      });
    };
    addMember({ [owner.identifier]: owner }, GroupRole.owner);
    addMember(administrators, GroupRole.administrator);
    addMember(collaborators, GroupRole.collaborator);
    addMember(unregistered, "unregistered");
  }
  return groupMemberList;
};

export const canDeleteGroupAsset = (item: any, currentUser: UserType, groupMembersList: GroupMemberDbType[]) => {
  if (item.owner.identifier === currentUser?.identifier) {
    return true;
  }
  if (item.owner.type === EOwner.Group) {
    const member = groupMembersList?.find(member => member.identifier === currentUser.identifier);
    if (member) {
      const isAdmin = member.role === GroupRole.administrator || member.role === GroupRole.owner;
      if (isAdmin) {
        return true;
      }

      if (member.identifier === item.created.by.identifier) {
        return true;
      }
    }
  }
  return false;
};

export const getGroupMembersNamesFromOfflineData = (groupData: GroupType, currentUser: UserType) => {
  let groupMemberNames = "";
  if (groupData.offlineGroupData) {
    const { owner, administrators, collaborators, viewers } = groupData.offlineGroupData;
    const allMembers = concat([owner], administrators, collaborators, viewers);
    const currentUserIndex = findIndex(allMembers, { recordID: currentUser.identifier });
    if (currentUserIndex !== -1) {
      allMembers.splice(currentUserIndex, 1);
    }
    map(
      allMembers,
      (groupMember: ContactType, index: number) =>
        (groupMemberNames += `${groupMember.name ? groupMember.name.trim().replace(/\s.*/g, "") : ""}${
          allMembers.length === 1 || index === allMembers.length - 1 ? "" : ","
        } `)
    );
  }
  return groupMemberNames.replace(/\,*\s*\,\s*$/g, "");
};
