import { BrandType, UserType, EOwner } from "../schema";

export const createDefaultBrand = (
  name: string,
  currentUser: UserType,
  type: EOwner
): BrandType => {
  return {
    name,
    identifier: "",
    created: {
      on: new Date(),
      by: currentUser.identifier,
    },
    owner: {
      identifier: currentUser.identifier,
      type,
    },
    _documentUpdatedOn: new Date(),
  };
};
