import { LoginMedium, UserType } from "common-library/lib/schema";
import { AuthActions, CommonActions } from "../actions";
import { AsyncState, SignInMethodsType } from "../../types/types";
import { asyncCaseCreator, InitialAsyncState } from "../helper";
import { createReducer } from "@reduxjs/toolkit";

interface IAuthState {
  validateUser: InitialAsyncState;
  createAccount: InitialAsyncState;
  sendCustomToken: InitialAsyncState;
  loginWithMedium: InitialAsyncState;
  closeAccount: InitialAsyncState;
  verifyOtp: InitialAsyncState;
  sendOtp: InitialAsyncState;
  resendOtp: InitialAsyncState;
  resetPassword: InitialAsyncState;
  signOut: InitialAsyncState;
  loginMedium?: LoginMedium;
  currentUser?: UserType;
  signInMethodsForEmail?: SignInMethodsType;
  updateUser: InitialAsyncState;
  subscribeToNotification: InitialAsyncState;
  resendVerificationMail: InitialAsyncState;
}

export const AuthLabel = "Auth";

const defaultAuthState: IAuthState = {
  validateUser: { status: AsyncState.inProgress },
  createAccount: { status: AsyncState.idle },
  sendCustomToken: { status: AsyncState.inProgress },
  loginWithMedium: { status: AsyncState.idle },
  closeAccount: { status: AsyncState.idle },
  verifyOtp: { status: AsyncState.idle },
  sendOtp: { status: AsyncState.idle },
  resendOtp: { status: AsyncState.idle },
  resetPassword: { status: AsyncState.idle },
  signOut: { status: AsyncState.idle },
  updateUser: { status: AsyncState.idle },
  subscribeToNotification: { status: AsyncState.idle },
  resendVerificationMail: { status: AsyncState.idle }
};

export const authReducer = createReducer(defaultAuthState, builder => {
  // async
  asyncCaseCreator(AuthActions.validateUser, "validateUser", builder);
  asyncCaseCreator(AuthActions.createAccount, "createAccount", builder);
  asyncCaseCreator(AuthActions.sendCustomToken, "sendCustomToken", builder);
  asyncCaseCreator(AuthActions.loginWithMedium, "loginWithMedium", builder);
  asyncCaseCreator(AuthActions.closeAccount, "closeAccount", builder);
  asyncCaseCreator(AuthActions.verifyOtp, "verifyOtp", builder);
  asyncCaseCreator(AuthActions.sendOtp, "sendOtp", builder);
  asyncCaseCreator(AuthActions.resendOtp, "resendOtp", builder);
  asyncCaseCreator(AuthActions.resetPassword, "resetPassword", builder);
  asyncCaseCreator(AuthActions.signOut, "signOut", builder);
  asyncCaseCreator(AuthActions.updateUser, "updateUser", builder);
  asyncCaseCreator(AuthActions.setNotificationSubscription, "subscribeToNotification", builder);
  asyncCaseCreator(AuthActions.resendVerificationMail, "resendVerificationMail", builder);

  // sync

  builder
    .addCase(AuthActions.setLoginMedium, (state, action) => {
      state.loginMedium = action.payload;
    })
    .addCase(AuthActions.setCurrentUser, (state, action) => {
      state.currentUser = action.payload;
    })
    .addCase(AuthActions.signInMethodsForEmail, (state, action) => {
      state.signInMethodsForEmail = action.payload;
    })
    // clear auth store
    .addCase(CommonActions.clearReduxStore, state => {
      return defaultAuthState;
    });
});
