const signIn = "Sign In";
const signInWithGoogle = "Sign In With Google";
const signInWithApple = "Sign In With Apple";
const signInWithEmail = "Sign In With Email";
const tryAgain = "Try Again";
const invalidEmail = "Invalid Email";
const passwordError = "Password should be 6 characters or more";
const findzWCID = "What can I do with Findz?";
const termsAgreeText = "By continuing you agree to our";
const termsOfServices = "Terms of Service";
const privacyPolicy = "Privacy Policy";
const forgotPassword = "I forgot my password";
const loginError = "The user name and password does not match our records. Please try again";
const userNotExists = "User does not exist";
const userCancelled = "User Cancelled";
const somethingWentWrong = "Something went wrong!";
const internalError = "Internal error";
const emailAlreadyUsed = "This email is already being used";
const resetPasswordSuccess = "A link to reset your password has been sent to your email.";
const resetPasswordFor = "Reset password for";
const reset = "Reset";
const signInUsingDifferentMethod = "Sign in using a different method";
const confirmSignInUsingDifferentMethod = "Are you sure you want to sign in using a different method?";
const resetPasswordEmailPlaceHolder = "Email - use your account email";
const emailNotFound = "An Account with this email not found";
const nameInputValidation = "Name should be 3 characters or more";
const passwordMatchError = "Retyped Password does not match";
const createAccount = "Create Account";
const newToFindz = "New to Findz?";
const createAccountNamePlaceHolder = "Name";
const createAccountEmailPlaceHolder = "Email";
const createAccountPasswordPlaceHolder = "Password";
const createAccountRePasswordPlaceHolder = "Retype Password";
const createAccountPhonePlaceHolder = "Phone - you will verify this later";
const signedInUsingGoogle = "You are signed in using Google";
const signedInUsingApple = "You are signed in using Apple";
const phoneNumberInfoText = "A verifiable phone number is required to complete creating your account";
const sendVerificationCode = "Send Verification Code";
const phoneNumberPlaceHolder = "Phone - use your account phone";
const changeNumber = "Use a different phone number";
const otpHint = "Enter the six digit code sent to your phone";
const otpPlaceHolder = "Code sent to your phone";
const verify = "Verify";
const loginSuccessMessage = "You have successfully signed in.  You can close this window and start using the Findz extension in your browser.";
const groupsSubtitle = "Collaborate with friends or colleagues on projects, events, or group shopping";
const myFindsSubtitle = "Your private curation of links, products, and notes";
const myfindz = "My Finds";
const onlyMe = "Only Me";
const signOut = "Sign Out";
const importBookmarks = "Import Bookmarks";
const tryAgainError = "Something went wrong. Please try again later.";
const whatShouldWeCallYou = "What should we call you?";
const startUsingFindz = "Start Using Findz";
const loginTooManyRequest =
  "Access to this account has been temporarily disabled due to many failed sign in attempts. You can immediately restore it by resetting your password or you can try again later.";
const signinTooManyRequest = "Too many attempts have been made.  Please try after some time.";
const wrongPassword = "Wrong password.  Please try again or reset your password.";
const resetPassword = "Reset Password";
const retry = "Retry";
const cancel = "Cancel";
const userNotFound = "Findz does not have an account with the given email";
const authInternalError = "Something went wrong. Please try again later.";
const previousSignInMehtods = (previousSignInMehtods: string) => `You previously signed in using ${previousSignInMehtods}`;
const emailAlreadyExists = "An account with this email already exists.";
const google = "Google";
const apple = "Apple";
const email = "Email";
const permissionDenied = "Cannot access location. Permission Denied";
const locationNotAvailable = "Location information is not available";
const timedOut = "This is taking longer than expected. Please try again later";
const cannotStartWithSlash = "Cannot start with a '/'";
const cannotEndWithSlash = "Cannot end with a '/'";
const cannotHaveBrackets = "Cannot have [ or ] symbols";
const collectionNameInvalid = "Invalid Collection Name";
const tagHint = "Use '/' to create sub-collection (e.g. Women/Tops)";
const genericError = "Something went wrong";
const searchLocation = "Search for a location...";
const addLocation = "Add this location";
const locationInputPlaceholder = "Add location...";
const useCurrentLocation = "Use Current Location";
const searchByText = "Search by ";
const remove = "Remove";
const discardChanges = "Are you sure want to discard changes?";
const confirmDiscardChanges = "Yes, Discard";
const cancelDiscardChanges = "Cancel";

export const appTexts = {
  discardChanges,
  confirmDiscardChanges,
  cancelDiscardChanges,
  remove,
  searchLocation,
  searchByText,
  addLocation,
  locationInputPlaceholder,
  useCurrentLocation,
  genericError,
  tagHint,
  permissionDenied,
  locationNotAvailable,
  timedOut,
  cannotStartWithSlash,
  cannotEndWithSlash,
  cannotHaveBrackets,
  collectionNameInvalid,
  signIn,
  signInWithGoogle,
  signInWithApple,
  signInWithEmail,
  invalidEmail,
  passwordError,
  findzWCID,
  termsAgreeText,
  termsOfServices,
  privacyPolicy,
  forgotPassword,
  loginError,
  userNotExists,
  userCancelled,
  somethingWentWrong,
  internalError,
  resetPasswordSuccess,
  resetPasswordFor,
  reset,
  signInUsingDifferentMethod,
  resetPasswordEmailPlaceHolder,
  emailNotFound,
  nameInputValidation,
  passwordMatchError,
  createAccountNamePlaceHolder,
  createAccountEmailPlaceHolder,
  createAccountPhonePlaceHolder,
  createAccountPasswordPlaceHolder,
  createAccountRePasswordPlaceHolder,
  createAccount,
  newToFindz,
  signedInUsingGoogle,
  signedInUsingApple,
  phoneNumberInfoText,
  sendVerificationCode,
  phoneNumberPlaceHolder,
  changeNumber,
  otpHint,
  otpPlaceHolder,
  verify,
  loginSuccessMessage,
  confirmSignInUsingDifferentMethod,
  emailAlreadyUsed,
  groupsSubtitle,
  myFindsSubtitle,
  myfindz,
  onlyMe,
  signOut,
  importBookmarks,
  tryAgainError,
  whatShouldWeCallYou,
  startUsingFindz,
  tryAgain,
  loginTooManyRequest,
  signinTooManyRequest,
  wrongPassword,
  resetPassword,
  retry,
  cancel,
  userNotFound,
  authInternalError,
  previousSignInMehtods,
  emailAlreadyExists,
  google,
  apple,
  email
};
