export enum queryPartColors {
  "context" = "#1951A8",
  "attribute" = "#1951A8",
  "context-operator" = "#1951A8",
  "numeric-operator" = "#1951A8",
  "numeric-comparator" = "#1951A8",
  "omni-operator" = "#1951A8",
  "omni-comparator" = "#1951A8",
  "set-comparator" = "#1951A8",
  "subset-operator" = "#1951A8",
  "context-location" = "#4A4A4A",
  "context-date" = "#4A4A4A",
  "context-user" = "#1951A8",
  "context-user-name" = "#4A4A4A",
  "tag" = "#1951A8",
  "tag-path" = "#4A4A4A",
  "text" = "#4A4A4A",
  "number" = "#4A4A4A",
  "incomplete" = "#EA4335",
  "incorrect" = "#EA4335",
  "and-or" = "#097604",
}
