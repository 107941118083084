import React, { FC } from "react";
import ReactDOM from "react-dom";
import Styles from "./GSLoader.module.scss";
import "../../../styles/styles.scss";

import findzOuterLogo from "../../../assets/icons/outer.png";
import innerLogo from "../../../assets/icons/inner.png";

interface ILoader {
  loaderMessage?: string;
  styleProps?: React.CSSProperties;
  loaderTextStyle?: React.CSSProperties;
  getPopupContainer?: () => HTMLElement;
}

const GSLoader: FC<ILoader> = props => {
  const { loaderMessage, styleProps, loaderTextStyle, getPopupContainer } = props;
  const renderLoader = () => {
    return (
      <div className={`${Styles["img-spinner"]} d-flex al-center jc-center fd-column`} style={styleProps}>
        <div className={Styles["inner-container"]}>
          <img className={Styles["image"]} src={findzOuterLogo} alt="" width="80" height="80" />
          <img src={innerLogo} alt="" width="80" height="80" />
        </div>
        <div className={Styles["loader-container"]}>
          {loaderMessage && (
            <div className={Styles["loader-text"]} style={loaderTextStyle}>
              {loaderMessage}
            </div>
          )}
        </div>
      </div>
    );
  };

  return getPopupContainer ? ReactDOM.createPortal(renderLoader(), getPopupContainer()) : renderLoader();
};

export default GSLoader;
