export const SIZE = {
  NONE: 0,
  XSMALL: 1,
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
  XLARGE: 5,
  XXLARGE: 6
};

export const RADIUS = {
  NONE: 0,
  XSMALL: 1,
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
  XLARGE: 5,
  XXLARGE: 6
};

export const ICONSIZE = {
  XSMALL: "12px",
  SMALL: "16px",
  MEDIUM: "20px",
  NORMAL: "24px",
  LARGE: "44px",
  XLARGE: "48px",
  XXLARGE: "72px"
};
