import { CommonActions, CommentActions, GroupActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { CommentType } from "common-library/lib/schema";
import { AsyncState } from "../../types/types";
import { InitialAsyncState, asyncCaseCreator } from "../helper";

interface ICommentState {
  createComment: InitialAsyncState;
  commentsMap: Map<string, CommentType>;
  indexFindToComment: Map<string, Set<string>>;
}

const defaultCommentState: ICommentState = {
  commentsMap: new Map(),
  indexFindToComment: new Map(),
  createComment: { status: AsyncState.idle }
};

export const CommentLabel = "Comment";

export const commentReducer = createReducer(defaultCommentState, builder => {
  // async
  asyncCaseCreator(CommentActions.createComment, "createComment", builder);

  builder
    .addCase(CommentActions.setCommentsMap, (state, action) => {
      state.commentsMap = action.payload.commentsMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultCommentState;
    })
    .addCase(CommentActions.setIndexFindToComment, (state, action) => {
      state.indexFindToComment = action.payload.indexFindToComment;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.commentsMap = new Map();
    });
});
