import { ActivityType, UserType, ForwardActivityType, LocalTimeStampValue } from "common-library/lib/schema";
import { limit, onSnapshot, query, where } from "firebase/firestore";
import { buffers, eventChannel, EventChannel } from "redux-saga";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { dbListenerUnsubscribers, IDirection, IQueryBuilderType, queryBuilder } from ".";
import { IActiveContext } from "../types/types";
import { firestoreDocRef } from "../utils/firestoreUtils";
import { batchUtil } from "./common/batch";

export const unsubscribeActivitiesDBListener = (context: "group" | "user") => {
  const unsubscribeDBListener = dbListenerUnsubscribers[`${context}_activities`]?.snapshotUnsubscribe;
  if (unsubscribeDBListener) {
    unsubscribeDBListener();
  }
  dbListenerUnsubscribers[`${context}_activities`] = {};
};

export const getListenerToFetchUserActivityData = (currentUser: UserType, timestampMap: Map<string, LocalTimeStampValue>) => {
  const timeStampForCurrentGroup = timestampMap.get(currentUser.identifier);
  const timeStamp = timeStampForCurrentGroup ? timeStampForCurrentGroup[FirestoreCollectionEnum.Activities]?.timeStampValue : undefined;
  const timeStampValue = timeStamp ? new Date(timeStamp) : undefined;
  const whereClause = [{ key: "accessibleTo.users", condition: "array-contains", value: currentUser.identifier }];
  const listener = listenToFirebaseForActivities(whereClause, "user", timeStampValue);
  return { listener, timeStampValue };
};

export const getListenerToFetchGroupActivityData = (activeContext: IActiveContext, timestampMap: Map<string, LocalTimeStampValue>) => {
  const timeStampForCurrentGroup = timestampMap.get(activeContext.identifier);
  const timeStamp = timeStampForCurrentGroup ? timeStampForCurrentGroup[FirestoreCollectionEnum.Activities]?.timeStampValue : undefined;
  const timeStampValue = timeStamp ? new Date(timeStamp) : undefined;
  const whereClause = [{ key: "accessibleTo.groups", condition: "array-contains", value: activeContext.identifier }];
  const listener = listenToFirebaseForActivities(whereClause, "group", timeStampValue);
  return { listener, timeStampValue };
};

export const listenToFirebaseForActivities = (
  whereClause: IQueryBuilderType[],
  context: "group" | "user",
  timeStampValue: Date | undefined,
  isCollectionGroup?: boolean,
  orderBy?: IDirection[]
): EventChannel<any> | unknown => {
  const unsubscribeDBListener = dbListenerUnsubscribers[`${context}_activities`]?.snapshotUnsubscribe;
  if (unsubscribeDBListener) {
    unsubscribeDBListener();
  }
  dbListenerUnsubscribers[`${context}_activities`] = {};

  const lastSyncedServerTimestamp = timeStampValue ? timeStampValue : new Date(1);
  const _orderBy: IDirection[] = orderBy ? orderBy : [{ key: "_serverDocumentUpdatedOn", direction: "desc" }];

  const listener = eventChannel<any>(emit => {
    const thresholdTimestamp = new Date(lastSyncedServerTimestamp.valueOf() - 5 * 60000);
    const builtQuery = queryBuilder(whereClause, FirestoreCollectionEnum.Activities, isCollectionGroup, _orderBy);
    const _query = query(builtQuery, where("_serverDocumentUpdatedOn", ">", thresholdTimestamp), limit(50));
    const unsubscribeDBSnapshot = onSnapshot(_query, { includeMetadataChanges: false }, querySnapshot => {
      emit({ changes: querySnapshot.docChanges() });
    });
    dbListenerUnsubscribers[`${context}_activities`].snapshotUnsubscribe = unsubscribeDBSnapshot;
    return () => unsubscribeDBSnapshot();
  }, buffers.expanding(10));
  return listener;
};

export const createNewForwardActivity = async (newActivityId: string, newForwardActivityObj: ForwardActivityType) => {
  const batch = batchUtil();
  const newActivityRef = firestoreDocRef(FirestoreCollectionEnum.Activities, newActivityId);
  batch.set(newActivityRef, newForwardActivityObj);
  await batch.commit();
  return Promise.resolve(true);
};

export const createNewActivityService = async (activity: ActivityType) => {
  const writeBatch = batchUtil();
  let newActivityId = "";
  if (!activity.identifier) {
    const newActivityDoc = await firestoreDocRef(FirestoreCollectionEnum.Activities);
    newActivityId = newActivityDoc.id;
  } else {
    newActivityId = activity.identifier;
  }
  if (!newActivityId) {
    throw new Error("Could not generate new activity id");
  }
  writeBatch.set(firestoreDocRef(FirestoreCollectionEnum.Activities, newActivityId), {
    ...activity,
    identifier: newActivityId
  });
  await writeBatch.commit();

  return Promise.resolve();
};
