import { Box, SxProps, Theme } from "@mui/material";
import { useState, useEffect, useRef, lazy } from "react";
import Header from "../Header/Header";
import BottomNavigationBar from "../BottomNavigationBar/BottomNavigationBar";
import { useAppSelector } from "../../../redux/hooks";
import { getActiveContext, getCurrentUser, getGroupList, getProcessActiveContext, getProcessUrl } from "../../../redux/selectors";
import { EOwner } from "common-library/lib/schema";
import { getMemberNamesOfGroup } from "../../../utils/groupUtils";
import { Outlet, Link, matchPath, useLocation, useNavigate } from "react-router-dom";

import { getPathWithQueryString, parseUrlParams } from "../../../utils/commonUtils";
import { NavigationPath } from "../../../constants/router.names";
import GSLoader from "../../../components/GSLoader/GSLoader";
import { AsyncState } from "../../../types/types";

const TagsContainer = lazy(() => import("../../containers/TagsContainer/TagsContainer"));
const MessageContainer = lazy(() => import("../../containers/MessageContainer/MessageContainer"));
const FindzContainer = lazy(() => import("../../containers/FindzContainer/FindzContainer"));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  keepMounted: boolean;
  style?: React.CSSProperties;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, keepMounted, style, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} style={style} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {!!keepMounted && children}
    </div>
  );
}

function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const initialHeaderState = { avatar: "", subTitle: "", title: "" };

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function ContainerWrapper({ children, isDetailScreen }: any) {
  const currentUser = useAppSelector(getCurrentUser);
  const mountedRoutes = useRef<string[]>([]);
  const getProcessActiveCtx = useAppSelector(getProcessActiveContext);

  const _processUrl = useAppSelector(getProcessUrl);
  const groups = useAppSelector(getGroupList);
  const navigate = useNavigate();
  const location = useLocation();
  const routeMatch = useRouteMatch([NavigationPath.FindzContainer, NavigationPath.TagsContainer, NavigationPath.MessagesContainer]);

  const currentTab = routeMatch?.pattern?.path;
  if (currentTab && !mountedRoutes.current.includes(currentTab)) {
    mountedRoutes.current.push(currentTab);
  }
  const activeContext = useAppSelector(getActiveContext);
  const [headerInfo, setHeaderInfo] = useState(initialHeaderState);

  const handleBackPress = () => {
    navigate("/", { replace: true });
  };

  const handleHeaderClick = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("group")) {
      navigate(`${NavigationPath.ProfileContainer}?group=${queryParams.get("group")}`, { replace: false });
    } else {
      navigate(NavigationPath.UserStatsContainer, { replace: false });
    }
  };

  const handleMenuClick = () => {
    const params = parseUrlParams(location);
    const path = getPathWithQueryString(NavigationPath.MoreContainer, params);
    navigate(path);
  };

  useEffect(() => {
    if (activeContext && currentUser && !isDetailScreen) {
      if (activeContext.type === EOwner.Group) {
        const group = groups.find(grup => grup.identifier === activeContext.identifier);
        if (group) {
          setHeaderInfo({ title: activeContext.name, avatar: group.groupProfilePicture || "", subTitle: getMemberNamesOfGroup(group, currentUser) });
        }
      } else if (activeContext.type === EOwner.User) {
        setHeaderInfo({ title: "My Finds", subTitle: "Only Me", avatar: currentUser.userPhoto || "" });
      }
    } else {
      setHeaderInfo(initialHeaderState);
    }
    return () => {
      setHeaderInfo(initialHeaderState);
    };
  }, [activeContext, currentUser, groups, isDetailScreen]);

  return (
    <Box sx={containerWrapperSx.container} id="container-wrapper">
      {(_processUrl.status === AsyncState.inProgress || getProcessActiveCtx.status === AsyncState.inProgress) && (
        <GSLoader getPopupContainer={() => document.body} />
      )}
      <Header
        showAvatar={true}
        hideMenuButton={false}
        photoUrl={headerInfo.avatar}
        title={headerInfo.title}
        subTitle={headerInfo.subTitle}
        onBackPress={handleBackPress}
        onClickHeader={handleHeaderClick}
        onClickMenu={handleMenuClick}
      />

      <Box sx={containerWrapperSx.childContainer} data-testid="container-wrapper-body">
        <CustomTabPanel
          style={{ height: "calc(100svh - 48px)", overflowX: "hidden" }}
          keepMounted={mountedRoutes.current.includes(NavigationPath.FindzContainer || "")}
          value={`${currentTab}`}
          index={NavigationPath.FindzContainer}>
          <FindzContainer />
        </CustomTabPanel>
        <CustomTabPanel
          style={{ height: "calc(100svh - 48px)" }}
          keepMounted={mountedRoutes.current.includes(NavigationPath.TagsContainer || "")}
          value={`${currentTab}`}
          index={NavigationPath.TagsContainer}>
          <TagsContainer />
        </CustomTabPanel>
        <CustomTabPanel
          style={{ height: "calc(100svh - 48px)", overflowX: "hidden" }}
          keepMounted={mountedRoutes.current.includes(NavigationPath.MessagesContainer || "")}
          value={`${currentTab}`}
          index={NavigationPath.MessagesContainer}>
          <MessageContainer />
        </CustomTabPanel>
        <Outlet />
      </Box>

      {!isDetailScreen && <BottomNavigationBar />}
    </Box>
  );
}

export default ContainerWrapper;

const containerWrapperSx: Record<string, SxProps<Theme>> = {
  container: { height: "100dvh", width: "100dvw" },
  childContainer: theme => ({ overflow: "unset", bgcolor: theme.palette.surface?.level1 })
};
