import { VendorType } from "common-library/lib/schema";
import { actionCreator } from "../helper";

enum VendorActionTypes {
  FETCH_LATEST_VENDORS = "VENDORS/FETCH",
  FETCH_LATEST_VENDORS_FROM_INDEXEDDB = "VENDORS/INDEXEDDB/FETCH",
  SET_VENDORS_MAP = "VENDORS/SET_MAP"
}

export const fetchLatestVendors = actionCreator(VendorActionTypes.FETCH_LATEST_VENDORS);

export const fetchLatestVendorsFromIndexedDb = actionCreator(VendorActionTypes.FETCH_LATEST_VENDORS_FROM_INDEXEDDB);

export const setVendorsMap = actionCreator<{ vendorsMap: Map<string, VendorType> }>(VendorActionTypes.SET_VENDORS_MAP);
