import { IDocumentSecured, collectionMasks, maskIDocumentSecured } from "./commonTypes";

export enum ReportStatus {
  Reported = "Reported",
  Resolved = "Resolved",
  Withdrawn = "Withdrawn",
}

export enum ReportMode {
  Report = "Report",
  Withdraw = "Withdraw",
}

export type ReportIndexType = {
  findzReport?: string;
  adminReport?: string;
};

export const ContentReports = Object.freeze({
  Breaks_Group_Rules: "Breaks Group Rules",
  Irrelevant_Content: "Irrelevant Content",
  Member_Conflict: "Member Conflict",
  Bullying_Harrasment: "Bullying / Harrasment",
  Hate_Speech: "Hate Speech",
  Nudity_Sexual_Content: "Nudity / Sexual Content",
  Violence: "Violence",
  Intellectual_Property_Violation: "Intellectual Property Violation",
  Sale_of_Illegal_Goods: "Sale of Illegal Goods",
  Something_else: "Something else",
});

const ContentReportKeys = Object.keys(ContentReports);
export type ContentReportCodes = Partial<typeof ContentReportKeys>;

export interface ContentReportDbType extends IDocumentSecured {
  created: {
    by: {
      identifier: string;
      name: string;
    };
    on: Date;
  };
  reportedContent: {
    findId?: string;
    commentId?: string;
    eventId?: string;
  };
  reportToAdmins: boolean;
  reportToFindz: boolean;
  reportedUser: string;
  reportReasons: Partial<typeof ContentReports>[];
  reportExplanation?: string;
  reportStatus: ReportStatus;
}

export const maskContentReportDbType = {
  ...maskIDocumentSecured,
  created: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
  },
  reportedContent: {
    findId: true,
    commentId: true,
    eventId: true,
  },
  reportToAdmins: true,
  reportToFindz: true,
  reportedUser: true,
  reportReasons: true,
  reportExplanation: true,
  reportStatus: true,
};
Object.freeze(maskContentReportDbType);

collectionMasks["contentReports"] = maskContentReportDbType;

export interface ContentReportType extends ContentReportDbType {}
