import { collectionMasks, IDocument, IMention, maskIDocumentSecured, maskIMention, maskCreatedByType } from "./commonTypes";
import { GroupRole } from "./groupType";

export enum ActivityVerb {
  GroupInviteDeclined = "group.invite.declined",
  GroupInviteAccepted = "group.invite.accepted",
  GroupInvite = "group.invite",
  GroupDelete = "group.delete",
  GroupRename = "group.rename",
  ForwardFind = "find.forward",
  MemberRemoved = "group.member.removed",
  MemberLeft = "group.member.left",
  EventDeleted = "event.deleted",
  EventCancelled = "event.cancelled",
  EventRescheduled = "event.rescheduled",
}

export interface ActivityInviterType {
  name: string | null;
  phone: string;
  photoUrl: string;
  email: string;
  identifier: string;
}

export interface ActivityCreatedByType {
  by: {
    identifier: string;
    name: string;
  };
  on: Date;
  at?: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
}

export interface BaseActivityType extends IDocument {
  created: ActivityCreatedByType;
  agent: string;
  verb: ActivityVerb;
  accessibleTo: {
    groups?: Array<string>;
    users?: Array<string>;
  };
  readBy: {
    [key: string]: Date;
  };
}

export interface ForwardActivityType extends BaseActivityType {
  object: {
    invitation: {
      role: GroupRole;
      invitationId: string;
      inviter: ActivityInviterType;
      invitee: string;
      inviteeName: string;
    };
    fromGroup: {
      identifier: string;
      name: string;
      type: string;
    };
    toGroup: {
      identifier: string;
      name: string;
      type: string;
    };
    finds: { from: string; to: string }[];
    message: string;
    mentions?: IMention[];
  };
}

export interface GroupMemberRelatedActivityType extends BaseActivityType {
  object: {
    group: { identifier: string; name: string };
    member: { identifier: string; name: string };
  };
}

export interface GroupRenameActivityType extends BaseActivityType {
  object: {
    group: { identifier: string; oldName: string; newName: string };
  };
}

export interface InvitationActivityType extends BaseActivityType {
  object: {
    group: { name: string; identifier: string };
    invitation: {
      role: GroupRole;
      invitationId: string;
      inviter: ActivityInviterType;
      invitee: string;
      inviteeName: string;
    };
    members?: {
      collaborators?: {
        [key: string]: boolean;
      };
      administrators?: {
        [key: string]: boolean;
      };
      viewers?: {
        [key: string]: boolean;
      };
    };
  };
}

export interface GroupDeleteActivityType extends BaseActivityType {
  object: {
    group: { name: string; identifier: string; memberAccessList: string[] };
  };
}

export interface EventActivityType extends BaseActivityType {
  object: {
    event: {
      name: string;
      identifier: string;
      scheduled?: { startDateTime: string; endDateTime: string; timeZone: string };
    };
    group: { name: string; identifier: string };
  };
}

export type ActivityType =
  | InvitationActivityType
  | ForwardActivityType
  | GroupDeleteActivityType
  | GroupRenameActivityType
  | GroupMemberRelatedActivityType
  | EventActivityType;

export const maskActivityInviterType = {
  name: true,
  phone: true,
  photoUrl: true,
  email: true,
  identifier: true,
};

Object.freeze(maskActivityInviterType);

const maskActivityCreatedByType = {
  ...maskCreatedByType,
  by: {
    identifier: true,
    name: true,
  },
};

export const maskBaseActivityType = {
  ...maskIDocumentSecured,
  agent: true,
  verb: true,
  accessibleTo: {
    groups: [true],
    users: [true],
  },
  created: maskActivityCreatedByType,
  readBy: { __MAP__: true },
};
Object.freeze(maskBaseActivityType);

export const maskActivityDBType = {
  ...maskBaseActivityType,
  object: {
    group: { name: true, identifier: true, oldName: true, newName: true, memberAccessList: true },
    event: { name: true, identifier: true, scheduled: true },
    invitation: {
      role: true,
      invitationId: true,
      inviter: maskActivityInviterType,
      invitee: true,
      inviteeName: true,
    },
    members: {
      collaborators: {
        __MAP__: true,
      },
      administrators: {
        __MAP__: true,
      },
      viewers: {
        __MAP__: true,
      },
    },
    fromGroup: {
      identifier: true,
      name: true,
      type: true,
    },
    toGroup: {
      identifier: true,
      name: true,
      type: true,
    },
    member: { identifier: true, name: true },
    finds: [{ from: true, to: true }],
    message: true,
    mentions: [maskIMention],
  },
};

collectionMasks["activities"] = maskActivityDBType;
