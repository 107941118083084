import { useMemo, useReducer, Dispatch } from "react";

function generateInitialState<T extends string = string>(keys: T[]) {
  const l: Record<string, boolean> = {};
  keys.forEach(key => {
    l[key] = false;
  });

  return l as Record<T, boolean>;
}

function generateDispatch<T extends string = string>(
  keys: T[],
  dispatch: Dispatch<{
    type: T;
    payload: boolean;
  }>
) {
  const k: Record<string, (payload: boolean) => void> = {};
  keys.forEach(key => {
    k[key] = (payload: boolean) => {
      dispatch({ type: key, payload });
    };
  });
  return k as Record<T, (payload: boolean) => void>;
}

const useBooleanReducer = <T extends string = string>(keys: T[], initialState?: Partial<Record<T, boolean>>) => {
  const _initialState = { ...generateInitialState(keys), ...(initialState ? initialState : {}) };

  const _reducer = (state: typeof _initialState, action: { type: typeof keys[number]; payload: boolean }) => {
    state[action.type] = action.payload;
    return state;
  };
  const [_state, _dispatch] = useReducer(_reducer, _initialState);

  const dispatch = useMemo(() => generateDispatch(keys, _dispatch), []);

  return [_state, dispatch] as [typeof _state, typeof dispatch];
};

export default useBooleanReducer;
