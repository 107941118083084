import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { firestoreDocRef, firestoreGetDoc, queryFirebaseDocs } from "../utils/firestoreUtils";
import { getById } from "../utils/indexdb";
import {
  ContactType,
  GroupDbType,
  GroupMemberDbType,
  GroupMembersDbObjectType,
  GroupMembersObjectType,
  GroupPayloadDataType,
  GroupPlanType,
  GroupRole,
  IUserInfoType,
  PastMemberObject,
  PendingUserActivityType,
  SmsStatus,
  UserType
} from "common-library/lib/schema";
import { deleteField, arrayRemove, where } from "firebase/firestore";
import { cloneDeep, map, omit, uniqBy } from "lodash";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../config/firebaseApp";
import { FirebaseFunctions } from "common-library/lib/constants";
import { GroupStatus, GroupType, InvitationActivityType } from "common-library/lib/schema";
import { batchUtil } from "./common/batch";
import { IActiveContext } from "../types/types";

async function getGroupData(groupId: string, from: "IndexedDb" | "Sever") {
  if (from === "Sever") {
    const docs = await firestoreGetDoc(FirestoreCollectionEnum.Groups, groupId);
    const docData: any = docs.data();
    return docData;
  } else {
    const data = await getById(FirestoreCollectionEnum.Groups, groupId);
    return data;
  }
}

async function fetchUserInfo(userDetails: { name: string; recordID: string; phoneNumbers: any[]; emailAddresses: any[] }) {
  try {
    const { phoneNumbers = [], emailAddresses = [], name, recordID } = userDetails;

    const docs: IUserInfoType[] = [];

    const phoneNumbersList = phoneNumbers?.map(phoneNumberObj => phoneNumberObj.number) || [];

    const emailAddressList = emailAddresses.map(emailAddressObj => emailAddressObj.email) || [];

    if (phoneNumbersList.length) {
      const publicUserInfoFromPhone = await queryFirebaseDocs(FirestoreCollectionEnum.PublicUserInfo, [where("phone", "in", phoneNumbersList)]);

      if (publicUserInfoFromPhone.size > 0) {
        publicUserInfoFromPhone.docs.map((userInfo: any) => {
          if (!userInfo.data()._documentDeletedOn) {
            docs.push({
              email: userInfo.data().email,
              identifier: userInfo.data().identifier,
              phone: userInfo.data().phone,
              recordID: userDetails.recordID,
              name: userInfo.data().name
            });
          }
        });
      }
    }

    if (emailAddressList.length) {
      const publicUserInfoFromEmail = await queryFirebaseDocs(FirestoreCollectionEnum.PublicUserInfo, [where("email", "in", emailAddressList)]);

      if (publicUserInfoFromEmail.size > 0) {
        publicUserInfoFromEmail.docs.map((userInfo: any) => {
          if (!userInfo.data()._documentDeletedOn) {
            docs.push({
              email: userInfo.data().email,
              identifier: userInfo.data().identifier,
              phone: userInfo.data().phone,
              recordID: userDetails.recordID,
              name: userInfo.data().name
            });
          }
        });
      }
    }

    const uniquePublicUserInfoDocs = uniqBy(docs, (doc: IUserInfoType) => doc.identifier);

    const phoneNumbersNotFound = phoneNumbers.filter((phoneObj: { number: string; label: string }) =>
      docs.every(doc => doc.phone !== phoneObj.number)
    );
    for (const phoneNumberObj of phoneNumbersNotFound) {
      uniquePublicUserInfoDocs.push(createDummyPublicUserDoc("", phoneNumberObj.number, name, recordID, phoneNumberObj.label));
    }

    const emailsNotFound = emailAddresses.filter((emailObj: { email: string; label: string }) => docs.every(doc => doc.email !== emailObj.email));
    for (const emailObj of emailsNotFound) {
      uniquePublicUserInfoDocs.push(createDummyPublicUserDoc(emailObj.email, "", name, recordID, emailObj.label));
    }

    return uniquePublicUserInfoDocs;
  } catch (error) {
    console.error("error", error);
    return Promise.reject(error);
  }
}

const hasKey = (object: Record<string, any>, key: string) => {
  return Object.keys(object).includes(key);
};
async function updateGroupInfo(groupId: string, group: Partial<GroupType>, updateUnregisteredMemberData?: boolean) {
  const batch = batchUtil();

  // Change role in activities for unregistered members
  if (updateUnregisteredMemberData) {
    await Promise.all(
      map(group.members?.unregistered, async (pendingUser: GroupMemberDbType, id: string) => {
        const userData = (await firestoreGetDoc(FirestoreCollectionEnum.PendingUser, id)).data();

        userData &&
          map(userData.activities, (activityData: PendingUserActivityType, activityId: string) => {
            if (activityData.group.identifier === groupId) {
              const updatedData = cloneDeep(userData);
              updatedData.activities[activityId].role = pendingUser.role;
              const ref = firestoreDocRef(FirestoreCollectionEnum.PendingUser, id);

              batch.update(ref, {
                activities: updatedData.activities
              });
            }
          });
      })
    );
  }

  const updatedGroup: Partial<GroupType> = {};
  if (group.name) {
    updatedGroup.name = group.name;
  }
  if (hasKey(group, "hideContactDetails")) {
    updatedGroup.hideContactDetails = group.hideContactDetails;
  }

  if (group.groupProfilePicture || group.groupProfilePicture === "") {
    updatedGroup.groupProfilePicture = group.groupProfilePicture;
  }
  if (group.members) {
    updatedGroup.members = getGroupMembersDbList(group.members) as any;
  }

  if (group.plan) {
    updatedGroup.plan = { ...group.plan };
  }
  batch.update(firestoreDocRef(FirestoreCollectionEnum.Groups, groupId), updatedGroup);
  await batch.commit();
  return Promise.resolve(true);
}

async function addMemberToGroup(group: GroupType, user: ContactType, currentUser: UserType) {
  const operation = httpsCallable(firebaseFunctions, FirebaseFunctions.AddUserToGroup);

  const response: any = await operation({ group, user, inviter: currentUser });

  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return Promise.resolve(true);
}

async function removeMemberFromGroup(group: GroupType, user: GroupMemberDbType, role: string, isUnregistered?: boolean) {
  const pastMemberObject: PastMemberObject = {
    name: user.userName || "",
    role: role,
    identifier: user.identifier
  };
  if (user.userAvatar) {
    pastMemberObject.avatar = user.userAvatar;
  }

  const removeMember = httpsCallable(firebaseFunctions, FirebaseFunctions.RemoveUserFromGroup);
  // pendingUserId (For unregistered users if the email is present then set email as id)
  const response: any = await removeMember({
    role,
    isUnregistered,
    userId: user.identifier,
    groupId: group.identifier,
    status: user.status,
    pastMemberObject,
    pendingUserId: user.email || user.identifier
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return Promise.resolve(true);
}

const exitFromGroup = async (removedUser: GroupMemberDbType, group: GroupType, currentUser: UserType, activeContext: IActiveContext) => {
  const batch = batchUtil();
  const pastMemberObject: PastMemberObject = {
    name: removedUser.userName || "",
    role: removedUser.role,
    identifier: removedUser.identifier
  };
  if (removedUser.userAvatar) {
    pastMemberObject.avatar = removedUser.userAvatar;
  }

  batch.update(firestoreDocRef(FirestoreCollectionEnum.Groups, group.identifier), {
    [`members.${removedUser.role}s.${currentUser.identifier}`]: deleteField(),
    [`pastMembers.${removedUser.identifier}`]: pastMemberObject,
    [`memberAccessList`]: arrayRemove(currentUser.identifier)
  });

  batch.update(firestoreDocRef(FirestoreCollectionEnum.Users, currentUser.identifier), {
    [`groups.${group.identifier}.status`]: GroupStatus.exited,
    [`notificationPermission.groups.${activeContext.identifier}`]: deleteField()
  });

  batch.update(firestoreDocRef(FirestoreCollectionEnum.PublicUserInfo, currentUser.identifier), {
    userGroups: arrayRemove(group.identifier)
  });
  await batch.commit();
  return Promise.resolve(true);
};

const deleteGroup = async (group: GroupType, currentUser: UserType) => {
  const deleteGroup = httpsCallable(firebaseFunctions, FirebaseFunctions.DeleteGroup);
  const response: any = await deleteGroup({ group, currentUser });
  if (!response.data.error) {
    return Promise.resolve(true);
  }
  return response.data.error;
};

const createDummyPublicUserDoc = (email: string, phone: string, name: string, recordID: string, label: string) => {
  return {
    email,
    phone,
    name,
    recordID,
    identifier: email || phone || "",
    label,
    doesNotExists: true
  };
};

const getGroupMembersDbList = (members: GroupMembersObjectType) => {
  const { administrators, collaborators, viewers, owner, unregistered } = members;
  const admins = constructDbMembers(administrators, GroupRole.administrator);
  const collabs = constructDbMembers(collaborators, GroupRole.collaborator);
  const allViewers = constructDbMembers(viewers, GroupRole.viewer);
  const pendingMembers = constructDbMembers(unregistered);
  const ownerData = constructDbMembers({ [owner.identifier]: owner }, GroupRole.owner);
  const allGroupMembers = {
    owner: Object.values(ownerData)[0] as GroupMemberDbType,
    administrators: admins,
    collaborators: collabs,
    viewers: allViewers,
    unregistered: pendingMembers
  } as GroupMembersDbObjectType;
  return allGroupMembers;
};

const constructDbMembers = (membersList: { [key: string]: GroupMemberDbType }, role?: GroupRole) => {
  const memberObject = {} as any;
  Object.entries(membersList).forEach(([identifier, member]) => {
    const memberData = {
      identifier,
      userName: member.userName,
      userAvatar: member.userAvatar,
      phone: member.phone,
      role: role || member.role,
      email: member.email || "",
      status: member.status || GroupStatus.pending
    } as GroupMemberDbType;

    if (member.memberSince) {
      memberData[`memberSince`] = member.memberSince;
    }

    if (member.isUnregistered) {
      memberData[`isUnregistered`] = member.isUnregistered;
    }
    memberObject[identifier] = memberData;
  });
  return memberObject;
};

const createNewGroupService = async (groupDataPayload: any, currentUser: UserType): Promise<Partial<GroupDbType>> => {
  const groupData = omit(groupDataPayload, ["selectedFindzList", "flowId"]) as GroupPayloadDataType;

  let newGroupId = groupDataPayload.identifier;
  if (!newGroupId) {
    const newGroupDoc = firestoreDocRef(FirestoreCollectionEnum.Groups);
    newGroupId = newGroupDoc.id;
    if (!newGroupId) {
      throw new Error("Could not generate group id");
    }
  }

  const newGroup: Partial<GroupDbType> = {
    name: groupData.title,
    identifier: newGroupId,
    created: {
      on: new Date(),
      by: { identifier: currentUser.identifier, name: currentUser.name }
    },
    members: {
      viewers: {},
      administrators: {},
      collaborators: {},
      owner: {
        phone: {
          countryCode: currentUser.phone?.countryCode || "",
          number: currentUser.phone?.number || "",
          sms: { history: {}, lastSmsId: "", smsStatus: SmsStatus.delivered }
        },
        memberSince: new Date(),
        role: GroupRole.owner,
        status: GroupStatus.active,
        userAvatar: currentUser.userPhoto,
        userName: currentUser.name,
        email: currentUser.email,
        isUnregistered: currentUser.isEmailVerified,
        identifier: groupData.owner.recordID
      },
      unregistered: {}
    },
    memberAccessList: [currentUser.identifier],
    _documentUpdatedOn: new Date(),
    offlineGroupData: { ...groupData, pendingOwnerId: "", moveOwnerTo: "" },
    groupMode: groupData.groupMode,
    hideContactDetails: false,
    plan: {
      groupType: GroupPlanType.Basic
    }
  };

  const batch = batchUtil();
  const ownerUpdate: any = {};
  batch.set(firestoreDocRef(FirestoreCollectionEnum.Groups, newGroupId), newGroup);

  ownerUpdate[`groups.${newGroupId}.status`] = GroupStatus.active;
  ownerUpdate[`notificationPermission.groups.${newGroupId}`] = {
    muteAll: false,
    findAdded: true,
    messagedAnyone: true,
    messagedMe: false,
    groupModified: true,
    events: true
  };

  batch.update(firestoreDocRef(FirestoreCollectionEnum.Users, groupData.owner.recordID), ownerUpdate);
  await batch.commit();
  return Promise.resolve(newGroup);
};

const respondToInvitationService = async (isAccepted: boolean, activity: InvitationActivityType, currentUser: UserType, shouldBlock?: boolean) => {
  const respondToInvitation = httpsCallable(firebaseFunctions, FirebaseFunctions.RespondToInvitation);
  const response: any = await respondToInvitation({ isAccepted, activity, currentUser, shouldBlock });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response;
};

export const groupServices = {
  getGroupData,
  fetchUserInfo,
  addMemberToGroup,
  updateGroupInfo,
  removeMemberFromGroup,
  exitFromGroup,
  deleteGroup,
  createNewGroupService,
  respondToInvitationService
};
