const nanoid = require("nanoid/non-secure");
import { MediaType, UserType, LocationDataType, MediaTypeEnum } from "../schema";

export const createDefaultMedia = (currentUser: UserType, locationData?: LocationDataType): MediaType => {
  return {
    identifier: nanoid(40),
    created: {
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name ? currentUser.name : "",
      },
      on: new Date(),
      at: {
        /**
         * need to furthure refactor, need to remove default 0,0,0.
         */
        geoCoordinates: locationData
          ? locationData.geoCoordinates
          : {
              latitude: 0,
              longitude: 0,
              altitude: 0,
            },
      },
    },
    found: { at: {} },
    size: {
      bytes: 0,
      heightPixels: 0,
      widthPixels: 0,
    },
    cloudStorageUrl: "",
    type: MediaTypeEnum.FILE,
  };
};
