import {
  collectionMasks,
  IDocumentSecured,
  IMention,
  maskIDocumentSecured,
  maskIMention,
  EOwner,
} from "./commonTypes";
export interface CommentDbType extends IDocumentSecured {
  text: string;
  tagId?: string;
  findzId?: string | Array<string>;
  activityId?: string;
  eventId?: string;
  created: {
    by: {
      identifier: string;
      name: string;
    };
    on: Date;
  };
  mentions?: IMention[];
  history?: Map<string, any>;
  repliedTo?: string;
  owner: {
    identifier: string;
    type: EOwner;
    name: string;
  };
  reaction?: string;
  isDeleted: boolean;
}

export interface CommentType extends CommentDbType {}

export const maskCommentDbType = {
  ...maskIDocumentSecured,
  text: true,
  tagId: true,
  findzId: true,
  activityId: true,
  eventId: true,
  created: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
  },
  mentions: [maskIMention],
  history: {
    __MAP__: true,
  },
  repliedTo: true,
  reaction: true,
  owner: {
    identifier: true,
    type: true,
    name: true,
  },
  isDeleted: true,
};
Object.freeze(maskCommentDbType);

collectionMasks["comments"] = maskCommentDbType;
