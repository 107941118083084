import { FC } from "react";
import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";
import SignInFooter from "../../components/Footer/SignInFooter";
import Styles from "./HeaderAndFooter.module.scss";
import "../../../styles/styles.scss";
import { Outlet } from "react-router-dom";

const HeaderAndFooter: FC<any> = props => {
  return (
    <div className={`${Styles.container} d-flex fd-column`}>
      <div className={`${Styles.header} d-flex jc-center al-center`}>
        <LogoAndTitle />
      </div>
      {props.children}
      <Outlet />
      <div className={`${Styles.footer} d-flex jc-center al-center`}>
        <SignInFooter />
      </div>
    </div>
  );
};

export default HeaderAndFooter;
