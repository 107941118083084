import {
  FindzDbType,
  GeolocationPositionType,
  LocationDataType,
  ReactionData,
  UserType,
  FindzType,
  FindTypeEnum,
  MediaTypeEnum,
  TagType
} from "common-library/lib/schema";
import { arrayRemove, arrayUnion, deleteField } from "firebase/firestore";
import { cloneDeep, isEmpty } from "lodash";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { DraftFindMediaType } from "../types/types";
import { createDefaultMedia, findzUtils } from "../utils/findzUtils";

import { firestoreDocRef, firestoreGetDoc } from "../utils/firestoreUtils";
import { getAllDescendantsForTag } from "../utils/tagsUtils";
import { batchUtil } from "./common/batch";
import { mediaServices } from "./media.service";
import { updateTag } from "./tag.service";

export async function createFinds(finds: Array<Partial<FindzDbType>>) {
  try {
    const batch = batchUtil();
    const updatedFindDataList = [];

    for (const find of finds) {
      const newFindRef = find.identifier
        ? firestoreDocRef(FirestoreCollectionEnum.Findz, find.identifier)
        : firestoreDocRef(FirestoreCollectionEnum.Findz);
      const newFindId = find.identifier || newFindRef.id;
      const updatedFindData = { ...find, identifier: newFindId };
      batch.set(newFindRef, updatedFindData);
      updatedFindDataList.push(updatedFindData);

      if (updatedFindData.tags && Array.isArray(updatedFindData.tags)) {
        for (const tag of updatedFindData.tags) {
          batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, tag), {
            finds: arrayUnion(updatedFindData.identifier)
          });
        }
      }
    }

    await batch.commit();
    return updatedFindDataList;
  } catch (error) {
    throw error;
  }
}

export async function updateFind(
  findIds: string[],
  findDataToUpdate: Partial<FindzDbType>,
  findDataToRemove?: Partial<FindzDbType>,
  writeBatch?: ReturnType<typeof batchUtil>,
  isPathUpdate?: boolean
) {
  try {
    const findDataToUpdateKeys = Object.keys(findDataToUpdate);
    let batch = writeBatch ? writeBatch : batchUtil();
    for (const findId of findIds) {
      const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, findId);
      if (findDataToRemove) {
        const findDataToRemoveKeys = Object.keys(findDataToRemove);
        const hasKey = (key: string) => findDataToRemoveKeys.includes(key);
        const partialDataToUpdate: any = {};
        if (hasKey("tags")) {
          if (findDataToRemove?.tags?.length) {
            partialDataToUpdate["tags"] = arrayRemove(...findDataToRemove.tags);
            findDataToRemove.tags.forEach(async (tagId: string) => {
              const newBatch = await updateTag(tagId, {}, { finds: [findId] }, batch);
              if (newBatch) {
                batch = newBatch;
              }
            });
          } else {
            partialDataToUpdate["tags"] = deleteField();
          }
        }
        if (hasKey("brands")) {
          const brands = findDataToRemove.brands;
          if (brands?.length) {
            partialDataToUpdate["brands"] = arrayRemove(...brands);
          } else {
            partialDataToUpdate["brands"] = deleteField();
          }
        }
        if (hasKey("located")) {
          if (isEmpty(findDataToRemove.located)) {
            partialDataToUpdate["located"] = deleteField();
          }
        }
        if (hasKey("priceQuotes")) {
          if (findDataToRemove.priceQuotes?.length) {
            partialDataToUpdate["priceQuotes"] = arrayRemove(...findDataToRemove.priceQuotes);
          } else {
            partialDataToUpdate["priceQuotes"] = deleteField();
          }
        }
        if (hasKey("reactions")) {
          if (findDataToRemove.reactions) {
            for (const [key, value] of Object.entries(findDataToRemove?.reactions)) {
              const dataToUpdate = {} as any;
              dataToUpdate[`reactions.${key}`] = arrayRemove(value);
              batch.update(findRef, dataToUpdate);
            }
          } else {
            partialDataToUpdate["reactions"] = deleteField();
          }
        }

        if (hasKey("vendors")) {
          const vendors = findDataToRemove.vendors;
          if (vendors?.length) {
            partialDataToUpdate["vendors"] = arrayRemove(...vendors);
          } else {
            partialDataToUpdate["vendors"] = deleteField();
          }
        }

        if (!isEmpty(partialDataToUpdate)) {
          batch.update(findRef, partialDataToUpdate);
        }
      }
      if (findDataToUpdateKeys.length) {
        const hasKey = (key: string) => findDataToUpdateKeys.includes(key);
        let partialDataToUpdate: any = {};
        if (isPathUpdate) {
          partialDataToUpdate = { ...findDataToUpdate };
        }
        if (hasKey("tags")) {
          if (findDataToUpdate?.tags?.length) {
            partialDataToUpdate["tags"] = arrayUnion(...findDataToUpdate.tags);
            findDataToUpdate.tags.forEach(async (tagId: string) => {
              const newBatch = await updateTag(tagId, { finds: [findId] }, undefined, batch);
              if (newBatch) {
                batch = newBatch;
              }
            });
          }
        }
        if (hasKey("priceQuotes")) {
          partialDataToUpdate["priceQuotes"] = findDataToUpdate.priceQuotes;
        }
        if (hasKey("located")) {
          partialDataToUpdate["located"] = findDataToUpdate.located;
        }
        if (hasKey("note")) {
          if (findDataToUpdate.note?.length) {
            partialDataToUpdate["note"] = findDataToUpdate.note;
          } else {
            partialDataToUpdate["note"] = deleteField();
          }
        }
        if (hasKey("title")) {
          if (findDataToUpdate.title?.length) {
            partialDataToUpdate["title"] = findDataToUpdate.title;
          } else {
            partialDataToUpdate["title"] = deleteField();
          }
        }

        if (hasKey("media")) {
          if (findDataToUpdate.media?.default) {
            partialDataToUpdate["media.default"] = findDataToUpdate.media.default;
          }
          if (findDataToUpdate.media?.mediaList) {
            partialDataToUpdate["media.mediaList"] = findDataToUpdate.media.mediaList;
          }
          if (findDataToUpdate.media?.sort) {
            partialDataToUpdate["media.sort"] = arrayUnion(...findDataToUpdate.media.sort);
          }
        }

        if (hasKey("reactions")) {
          if (findDataToUpdate.reactions) {
            for (const [key, value] of Object.entries(findDataToUpdate?.reactions)) {
              const dataToUpdate = {} as any;
              dataToUpdate[`reactions.${key}`] = arrayUnion(value);
              batch.update(findRef, dataToUpdate);
            }
          }
        }

        if (hasKey("brands")) {
          const brands = findDataToUpdate.brands;
          partialDataToUpdate["brands"] = brands;
        }

        if (hasKey("vendors")) {
          const vendors = findDataToUpdate.vendors;
          partialDataToUpdate["vendors"] = vendors;
        }

        if (!isEmpty(partialDataToUpdate)) {
          batch.update(findRef, partialDataToUpdate);
        }
      }
    }
    if (writeBatch) {
      return batch;
    }
    await batch.commit();
    return;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateCloudStorageInMedia(findId: string, mediaId: string, cloudStorageurl: string, bytes: number = 0) {
  const batch = batchUtil();
  batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findId), {
    [`media.mediaList.${mediaId}.cloudStorageUrl`]: cloudStorageurl,
    [`media.mediaList.${mediaId}.size.bytes`]: bytes
  });
  await batch.commit();
  return Promise.resolve();
}

export async function getFindById(findId: string) {
  try {
    const docs = await firestoreGetDoc(FirestoreCollectionEnum.Findz, findId);
    const docData: any = docs.data();
    return docData;
  } catch (error) {
    console.error("error", error);
    return {};
  }
}

export async function updateFindData(findId: string, data: any, overrideDefaults: string[] = []) {
  const batch = batchUtil();
  const findzRef = firestoreDocRef(FirestoreCollectionEnum.Findz, findId);
  batch.update(findzRef, data, overrideDefaults);
  batch.commit();
  return Promise.resolve(true);
}

export async function updateFindDetails(
  findzUid: string,
  findzData: Partial<FindzDbType>,
  removedTags: string[] = [],
  addedTags: string[] = [],
  overrideDefaults: string[] = []
) {
  try {
    let batch = batchUtil();

    const findToUpdate = cloneDeep(findzData);
    delete findToUpdate.owner;

    if (addedTags) {
      // batch = findzService.updateFindsListForTags(batch, addedTags, findzUid, UpdateTagFindsListOperation.Add);
    }

    if (removedTags.length) {
      // batch = updateFindsListForTags(batch, removedTags, findzUid, UpdateTagFindsListOperation.Remove);
    }

    if (addedTags.length) {
      batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findzUid), {
        tags: arrayUnion(...addedTags)
      });
    }

    if (removedTags && removedTags.length) {
      batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findzUid), {
        tags: arrayRemove(...removedTags)
      });
    }

    batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findzUid), findToUpdate, overrideDefaults);
    await batch.commit();
    return Promise.resolve(true);
  } catch (error) {
    console.error("error", error);
    return Promise.resolve(false);
  }
}

export async function getMediaDetails(
  draftMedia: DraftFindMediaType[],
  currentUser: UserType,
  extractExifData: boolean = false,
  geoLocationPosition: GeolocationPositionType,
  find?: FindzType,
  mediaLocationData?: LocationDataType
) {
  const medias = [] as any;
  draftMedia.forEach(item => {
    const emptyMedia = createDefaultMedia(currentUser, { geoCoordinates: geoLocationPosition });
    emptyMedia.identifier = item.identifier;
    if (item.contentType) {
      emptyMedia.contentType = item.contentType;
    }
    if (item.extensionType) {
      emptyMedia.extensionType = item?.extensionType;
    }
    if (item.data) {
      emptyMedia.size = { bytes: item?.data.size };
    }
    emptyMedia.type = findzUtils.getMediaType(item);

    if ((find && find?.type === "Location" && find.located) || mediaLocationData) {
      const located: any = mediaLocationData ? { at: mediaLocationData } : find?.located;
      emptyMedia.located = {
        at: {
          address: located.at.address,
          geoCoordinates: located.at.geoCoordinates
            ? {
                altitude: located.at.geoCoordinates.altitude,
                latitude: parseFloat(located.at.geoCoordinates.latitude.toString()),
                longitude: parseFloat(located.at.geoCoordinates.longitude.toString())
              }
            : {
                altitude: 0,
                latitude: 0,
                longitude: 0
              }
        },
        on: new Date()
      };
      emptyMedia.type = MediaTypeEnum.LOCATION;
    }
    if (find && find?.type === FindTypeEnum.LINK && find.found.at) {
      emptyMedia.found = { ...emptyMedia.found, at: find.found.at };
    }

    medias.push(emptyMedia);
  });
  let mediaListObj = {} as any;
  medias.forEach((item: any) => {
    mediaListObj[item.identifier] = item;
  });
  if (extractExifData) {
    mediaListObj = await mediaServices.extractExifData(draftMedia, mediaListObj);
  }
  return mediaListObj;
}

export const processDraggedFindsAndTags = async (
  tagIds: string[],
  findIds: string[],
  tagsMap: Map<string, TagType>,
  findsMap: Map<string, FindzType>
) => {
  try {
    const batch = batchUtil();

    for (const tagId of tagIds) {
      const tagData = tagsMap.get(tagId);
      if (!tagData) {
        continue;
      }

      const allDescendants = getAllDescendantsForTag(tagData, tagsMap, []);
      const allDescendantTags = allDescendants.map(tag => tag.identifier);

      for (const findId of findIds) {
        const findData = findsMap.get(findId);
        if (!findData) {
          continue;
        }

        const tagsToRemoveFromFind: string[] = findData.tags
          .filter(attachedTag => {
            return allDescendantTags.includes(attachedTag.identifier) || tagData.ancestors.includes(attachedTag.identifier);
          })
          .map(tagToRemove => tagToRemove.identifier);

        for (const tagToRemove of tagsToRemoveFromFind) {
          const tagRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tagToRemove);
          const partialDataToUpdate = {} as any;
          partialDataToUpdate["finds"] = arrayRemove(findData.identifier);
          batch.update(tagRef, partialDataToUpdate);
        }

        const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, findId);
        const partialDataToUpdate: any = {};
        if (tagsToRemoveFromFind.length) {
          partialDataToUpdate["tags"] = arrayRemove(...tagsToRemoveFromFind);
          batch.update(findRef, partialDataToUpdate);
        }
        partialDataToUpdate["tags"] = arrayUnion(tagId);
        batch.update(findRef, partialDataToUpdate);
      }

      const tagRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tagId);
      const partialDataToUpdate = {} as any;
      partialDataToUpdate["finds"] = arrayUnion(...findIds);
      batch.update(tagRef, partialDataToUpdate);
    }

    await batch.commit();
    return Promise.resolve(true);
  } catch (error) {
    console.error("processDraggedFindsAndTags", error);
    return Promise.resolve(false);
  }
};

const deleteFinds = async (finds: FindzType[], indexFindToEvents: Map<string, Set<string>>) => {
  const batch = batchUtil();

  for (const find of finds) {
    for (const tag of find.tags) {
      const tagRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tag.identifier);
      batch.update(tagRef, {
        finds: arrayRemove(find.identifier)
      });
    }

    const eventIds = indexFindToEvents.get(find.identifier);
    if (eventIds?.size) {
      for (const eventId of eventIds) {
        const eventRef = firestoreDocRef(FirestoreCollectionEnum.Events, eventId);
        batch.update(eventRef, {
          finds: arrayRemove(find.identifier)
        });
      }
    }

    const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, find.identifier);
    batch.delete(findRef);
  }

  await batch.commit();
  return Promise.resolve(true);
};

const updateOriginalFindForForwardFind = async (objs: { originalFindId: string; newFindId: string }[]) => {
  const batch = batchUtil();
  for (const obj of objs) {
    const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, obj.originalFindId);
    batch.update(findRef, {
      copies: arrayUnion(obj.newFindId)
    });
  }
  await batch.commit();
  return Promise.resolve(true);
};

const removeReactionFromFinds = async (
  commentId: string,
  reactionObjs: { emoji: string; findReactionObj: { findId: string; reactionData: ReactionData } }[],
  writeBatch?: ReturnType<typeof batchUtil>
) => {
  try {
    if (reactionObjs.length > 0) {
      let batch = writeBatch ? writeBatch : batchUtil();
      reactionObjs.forEach(reactionObj => {
        const { emoji, findReactionObj } = reactionObj;
        const { findId, reactionData } = findReactionObj;
        if (reactionData) {
          if (Array.isArray(findId)) {
            findId.forEach(id => {
              const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, id);
              let updatedFindzDoc = {} as any;
              updatedFindzDoc[`reactions.${emoji}`] = arrayRemove(...[reactionData]);
              batch.update(findRef, updatedFindzDoc);
            });
          } else {
            const findRef = firestoreDocRef(FirestoreCollectionEnum.Findz, findId);
            let updatedFindzDoc = {} as any;
            updatedFindzDoc[`reactions.${emoji}`] = arrayRemove(...[reactionData]);
            batch.update(findRef, updatedFindzDoc);
          }
        }
      });
      const commmentRef = firestoreDocRef(FirestoreCollectionEnum.Comments, commentId);
      batch.update(commmentRef, { isDeleted: true });
      if (writeBatch) {
        return batch;
      }
      await batch.commit();
    }
    return Promise.resolve(true);
  } catch (error) {
    console.error("removeReactionsFromFinds", error);
    return Promise.resolve(false);
  }
};

export const findzServices = {
  createFinds,
  getFindById,
  removeReactionFromFinds,
  processDraggedFindsAndTags,
  updateFindData,
  updateFind,
  updateFindDetails,
  updateCloudStorageInMedia,
  getMediaDetails,
  deleteFinds,
  updateOriginalFindForForwardFind
};
