import { takeLatest, fork, call, select, takeEvery, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { TagActions, CommonActions } from "../actions";
import {
  addTag,
  getListenerToFetchData,
  getDataFromChanges,
  syncWithIndexDB,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData,
  updateTag,
  moveTagToAnotherTag,
  addToFavorite,
  removeFromFavorite,
  updateTagsForFind,
  createCollectionsFromPath,
  updateIdsForTagsAndFinds,
  createTags,
  updateTags,
  editTag,
  deleteTag
} from "../../services";
import { getActiveContext, getCurrentUser, getFindzMap, getTimestamp } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import isEmpty from "lodash/isEmpty";
import { getBatch } from "../../utils/indexdb";
import { FindzType, LocalTimeStampValue, TagDbType, TagType } from "common-library/lib/schema";
import { IActiveContext } from "../../types/types";
import { UserType } from "common-library";
import { getQueryFromActiveContext, getTagsToCreateAndUpdate } from "../../utils/commonUtils";
import { EventChannel } from "redux-saga";
import { IChanges } from "gsdb/dist/interfaces";
import { getTagsMap } from "../selectors";
import { createTagPath, removeRedundantParentOrChild } from "../../utils/tagsUtils";

export function* fetchLatestTagsSaga(action: ActionType<typeof TagActions.fetchLatestTags>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Tags, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);

      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<TagDbType>(changes, timeStampValue);
      const newData = dataAdded.concat(dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Tags, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({ collectionType: FirestoreCollectionEnum.Tags, timestamp: latestTimeStamp, groupId: activeContext.identifier })
      );
    }
  } catch (error) {
    console.error("fetchLatestTagsSaga", error);
  }
}

export function* fetchLatestTagsFromIndexedDbSaga(action: ActionType<typeof TagActions.fetchLatestTagsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Tags, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];

        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }

      const existingTagsMap: Map<string, TagType> = yield select(getTagsMap);
      const newTagMapToUpdate = new Map(existingTagsMap);

      amList.forEach(tag => {
        newTagMapToUpdate.set(tag.identifier, tag);
      });

      amList.forEach(tag => {
        const path = createTagPath(tag.identifier, newTagMapToUpdate as any);
        newTagMapToUpdate.set(tag.identifier, { ...tag, path });
      });

      rList.forEach(tag => {
        newTagMapToUpdate.delete(tag.identifier);
      });

      yield put(TagActions.setTagsMap({ tagsMap: newTagMapToUpdate }));
    }
  } catch (error) {
    console.error("fetchLatestTagsFromIndexedDbSaga", error);
  }
}

export function* addTagForFindSaga(action: ActionType<typeof TagActions.addTagToFind>) {
  try {
    const { newTagPath, tagsMap, currentFind } = action.payload;
    const tagsList = Array.from(tagsMap.values());

    const context: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);

    const tags = [{ path: newTagPath, color: "#4A4A4A", parentTag: undefined }];
    const newTagsList: TagType[] = yield call(createCollectionsFromPath, tags, tagsList, currentUser, context);

    yield call(updateTagsForFind, newTagsList, newTagPath, currentFind, tagsMap);

    if (newTagsList.length) {
      yield put(CommonActions.updateMRUList({ identifier: context.identifier, key: "tagList", itemIdentifier: newTagsList[0].identifier }));
    }
  } catch (error) {
    console.error("addTagForFindSaga error", error);
  }
}

export function* updateTagForFindSaga(action: ActionType<typeof TagActions.updateTagToFind>) {
  try {
    const { currentFind, tagsMap, tagToAdd } = action.payload;
    const context: IActiveContext = yield select(getActiveContext);
    const removedTags = removeRedundantParentOrChild(currentFind, tagsMap, tagToAdd);

    yield call(updateIdsForTagsAndFinds, [currentFind.identifier], removedTags, [tagToAdd.identifier]);
    yield put(CommonActions.updateMRUList({ identifier: context.identifier, key: "tagList", itemIdentifier: tagToAdd.identifier }));
  } catch (error) {
    console.error("updateTagForFindSaga error", error);
  }
}

export function* addTagSaga(action: ActionType<typeof TagActions.addTag.saga>) {
  try {
    yield put(TagActions.addTag.start());

    const { collectionName, tagsList, currentUser, activeContext, context } = action.payload;
    const { tagsToCreate, tagsToUpdate } = getTagsToCreateAndUpdate(
      tagsList,
      collectionName,
      currentUser as UserType,
      activeContext as IActiveContext
    );

    let tags = [];

    if (tagsToCreate.length) {
      tags = yield call(createTags, tagsToCreate as any);
    }

    if (tagsToUpdate.length) {
      yield call(updateTags, tagsToUpdate as any);
    }

    yield put(TagActions.addTag.success({ identifier: tags?.map((tag: TagType) => tag.identifier), context }));
  } catch (error: any) {
    console.error("addTagSaga error", error);
    yield put(TagActions.addTag.error(error));
  }
}

export function* updateTagSaga(action: ActionType<typeof TagActions.updateTag>) {
  try {
    const { tagId, tagData } = action.payload;
    yield call(updateTag, tagId, tagData);
  } catch (error) {
    console.error("updateTagSaga", error);
  }
}

export function* moveTagSaga(action: ActionType<typeof TagActions.moveTag>) {
  try {
    const { draggedTagIds, droppedOntoTagId } = action.payload;
    const tagsMap: Map<string, TagType> = yield select(getTagsMap);
    yield call(moveTagToAnotherTag, draggedTagIds, droppedOntoTagId, tagsMap);
  } catch (error) {
    console.error("moveTagSaga", error);
  }
}

export function* addToFavouriteSaga(action: ActionType<typeof TagActions.addToFavorite>) {
  try {
    const { tagIds } = action.payload;
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);
    if (activeContext && currentUser) {
      yield call(addToFavorite, tagIds, activeContext, currentUser);
    }
  } catch (error) {
    console.error("addToFavouriteSaga", error);
  }
}

export function* removeFromFavoriteSaga(action: ActionType<typeof TagActions.removeFavorite>) {
  try {
    const { tagId } = action.payload;
    const activeContext: IActiveContext = yield select(getActiveContext);
    const currentUser: UserType = yield select(getCurrentUser);
    if (activeContext && currentUser) {
      yield call(removeFromFavorite, tagId, activeContext, currentUser);
    }
  } catch (error) {
    console.error("removeFromFavoriteSaga", error);
  }
}

export function* editTagSaga(action: ActionType<typeof TagActions.editTag.saga>) {
  try {
    yield put(TagActions.editTag.start());
    const { tagId, newTagName, tagsList } = action.payload;
    const currentUser: UserType = yield select(getCurrentUser);
    const context: IActiveContext = yield select(getActiveContext);

    if (newTagName.includes("/")) {
      const tags = [{ path: newTagName, color: "#4A4A4A", parentTag: undefined }];
      yield call(createCollectionsFromPath, tags, tagsList, currentUser, context);
    } else {
      yield call(editTag, tagId, newTagName);
    }
    yield put(TagActions.editTag.success());
  } catch (error: any) {
    console.error("editTagSaga", error);
    yield put(TagActions.editTag.error(error));
  }
}

export function* deleteTagSaga(action: ActionType<typeof TagActions.deleteTag.saga>) {
  try {
    yield put(TagActions.deleteTag.start());

    const { tagToDelete } = action.payload;
    const tagsMap: Map<string, TagType> = yield select(getTagsMap);
    const findsMap: Map<string, FindzType> = yield select(getFindzMap);

    yield call(deleteTag, tagToDelete, tagsMap, findsMap);
    yield put(TagActions.deleteTag.success());
  } catch (error: any) {
    console.error("deleteTagSaga", error);
    yield put(TagActions.deleteTag.error(error));
  }
}

export function* multiUpdateTagSaga(action: ActionType<typeof TagActions.multiUpdateTag.saga>) {
  try {
    const { selectedFinds, tagsToAdd, tagsToRemove } = action.payload;
    yield put(TagActions.multiUpdateTag.start());
    yield call(updateIdsForTagsAndFinds, selectedFinds, tagsToRemove, tagsToAdd);
    yield put(TagActions.multiUpdateTag.success());
  } catch (error) {
    console.error("multiUpdateTagSaga", error);
    yield put(TagActions.multiUpdateTag.error(error as any));
  }
}

function* fetchLatestTagsListener() {
  yield takeLatest(TagActions.fetchLatestTags.toString(), fetchLatestTagsSaga);
}

function* addTagListener() {
  yield takeLatest(TagActions.addTag.saga.toString(), addTagSaga);
}

function* fetchLatestTagsFromIndexedDbListener() {
  yield takeLatest(TagActions.fetchLatestTags.toString(), fetchLatestTagsFromIndexedDbSaga);
}

function* moveTagToAnotherTagListener() {
  yield takeLatest(TagActions.moveTag.toString(), moveTagSaga);
}

function* updateTagListener() {
  yield takeLatest(TagActions.updateTag.toString(), updateTagSaga);
}

function* addToFavoriteListener() {
  yield takeLatest(TagActions.addToFavorite.toString(), addToFavouriteSaga);
}

function* removeFromFavoriteListener() {
  yield takeLatest(TagActions.removeFavorite.toString(), removeFromFavoriteSaga);
}

function* addTagToFindListener() {
  yield takeLatest(TagActions.addTagToFind.toString(), addTagForFindSaga);
}

function* updateTagToFindListener() {
  yield takeLatest(TagActions.updateTagToFind.toString(), updateTagForFindSaga);
}

function* editTagListener() {
  yield takeLatest(TagActions.editTag.saga.toString(), editTagSaga);
}

function* deleteTagListener() {
  yield takeLatest(TagActions.deleteTag.saga.toString(), deleteTagSaga);
}

function* multiUpdateTagListener() {
  yield takeLatest(TagActions.multiUpdateTag.saga.toString(), multiUpdateTagSaga);
}

export default function* tagSaga() {
  yield fork(fetchLatestTagsListener);
  yield fork(addTagListener);
  yield fork(fetchLatestTagsFromIndexedDbListener);
  yield fork(updateTagListener);
  yield fork(moveTagToAnotherTagListener);
  yield fork(addToFavoriteListener);
  yield fork(removeFromFavoriteListener);
  yield fork(addTagToFindListener);
  yield fork(updateTagToFindListener);
  yield fork(editTagListener);
  yield fork(deleteTagListener);
  yield fork(multiUpdateTagListener);
}
