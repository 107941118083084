import { FirebaseApp, FirebaseError, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { enableMultiTabIndexedDbPersistence, Firestore, getFirestore } from "firebase/firestore";
import firebaseConfig from "./firebase.env";
import { getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";
let db: Firestore;

const initializeFirestore = (app: FirebaseApp) => {
  if (db) {
    return db;
  }

  db = getFirestore(app);
  try {
    enableMultiTabIndexedDbPersistence(db);
  } catch (err: unknown) {
    if (err instanceof FirebaseError) {
      if (err.code === "failed-precondition") {
        //TODO: Can Log it
      } else if (err.code === "unimplemented") {
        //Persistance not supported
      }
    }
  }
  return db;
};

export const app = initializeApp(firebaseConfig);
const firestore = initializeFirestore(app);
export const firebaseAuth = getAuth(app);
export const firebaseStorage = getStorage(app);
export const messaging = getMessaging(app);

export const firebaseFunctions = getFunctions(app);

export default firestore;
