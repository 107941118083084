import { CommonActions, BrandActions, GroupActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { BrandType } from "common-library/lib/schema";

interface IBrandState {
  brandsMap: Map<string, BrandType>;
}

const defaultBrandState: IBrandState = {
  brandsMap: new Map()
};

export const BrandLabel = "Brand";

export const brandReducer = createReducer(defaultBrandState, builder => {
  builder
    .addCase(BrandActions.setBrandsMap, (state, action) => {
      state.brandsMap = action.payload.brandsMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultBrandState;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.brandsMap = new Map();
    });
});
