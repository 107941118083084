import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { TagType } from "common-library/lib/schema";
import { _getMruList } from "./commonSelectors";
import { getMRUMergedList } from "../../utils/commonUtils";

//#region from reducer
const _getTagsMap = (state: AppState): Map<string, TagType> => state.Tag.tagsMap;
//#endregion

//#region Memoized Selector
export const getTagsMap = createDraftSafeSelector(_getTagsMap, payload => payload);
//#endregion

export const getTagsList = createDraftSafeSelector([_getTagsMap, _getMruList], (tagsMap, mruList) => {
  const mruTagsList: string[] = mruList?.data?.tagList || [];
  let tagsList = Array.from(tagsMap.values());

  if (mruTagsList.length && tagsList.length) {
    tagsList = getMRUMergedList(mruTagsList, tagsList, "path", "asc");
  }

  return tagsList;
});

//#region from reducer
const _getCreateTag = (state: AppState) => state.Tag.createTag;
//#endregion

//#region Memoized Selector
export const getCreateTag = createDraftSafeSelector(_getCreateTag, payload => payload);
//#endregion

//#region from reducer
const _getEditTag = (state: AppState) => state.Tag.editTag;
//#endregion

//#region Memoized Selector
export const getEditTag = createDraftSafeSelector(_getEditTag, payload => payload);
//#endregion

//#region from reducer
const _getDeleteTag = (state: AppState) => state.Tag.deleteTag;
//#endregion

//#region Memoized Selector
export const getDeleteTag = createDraftSafeSelector(_getDeleteTag, payload => payload);
//#endregion

const _multiUpdateTags = (state: AppState) => state.Tag.multiUpdateTag;

export const getMultiUpdateTags = createDraftSafeSelector(_multiUpdateTags, payload => payload);
