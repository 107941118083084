import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";
import store from "./app/redux/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { register } from "swiper/element/bundle";
const container = document.getElementById("root")!;
const root = createRoot(container);

register();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </Provider>
  </React.StrictMode>
);

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
  const registrationWaiting = registration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: "SKIP_WAITING" });
    registrationWaiting.addEventListener("statechange", (e: any) => {
      if (e.target.state === "activated") {
        window.alert("A new version of Findz App is available.  Press OK to reload the page and start using the new version.");
        window.location.reload();
      }
    });
  }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: updateServiceWorker
});
