import { LocalTimeStampValue, GeolocationPositionType, ILocation } from "common-library/lib/schema";
import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

//#region from reducer
const _getTimestamp = (state: AppState): Map<string, LocalTimeStampValue> => state.Common.lastTimeStampValues;
//#endregion

//#region Memoized Selector
export const getTimestamp = createDraftSafeSelector(_getTimestamp, payload => payload);
//#endregion

//#region from reducer
const _getAppTheme = (state: AppState): string => state.Common.appTheme;
//#endregion

//#region Memoized Selector
export const getAppTheme = createDraftSafeSelector(_getAppTheme, payload => payload);
//#endregion

//#region from reducer
const _getNavigationRailDrawerIsOpen = (state: AppState): boolean => state.Common.navRailDrawerIsOpen;
//#endregion

//#region Memoized Selector
export const getNavigationRailDrawerIsOpen = createDraftSafeSelector(_getNavigationRailDrawerIsOpen, payload => payload);
//#endregion

//#region from reducer
const _getCreateFindzModalIsOpen = (state: AppState): boolean => state.Common.createFindzModalIsOpen;
//#endregion

//#region Memoized Selector
export const getCreateFindzModalIsOpen = createDraftSafeSelector(_getCreateFindzModalIsOpen, payload => payload);
//#endregion

//#region from reducer
const _getLocationArray = (state: AppState): ILocation[] => state.Common.locationArray;
//#endregion

//#region Memoized Selector
export const getLocationArray = createDraftSafeSelector(_getLocationArray, payload => payload);
//#endregion

//#region from reducer
const _getStaticMapImageData = (state: AppState): string => state.Common.staticMapImageData;
//#endregion

//#region Memoized Selector
export const getStaticMapImage = createDraftSafeSelector(_getStaticMapImageData, payload => payload);
//#endregion

//#region from reducer
const _getGeoLocationPosition = (state: AppState): GeolocationPositionType => state.Common.geoLocationPosition;
//#endregion

//#region Memoized Selector
export const getGeoLocationPosition = createDraftSafeSelector(_getGeoLocationPosition, payload => payload);
//#endregion

export const _getMruList = (state: AppState) => state.Common.mruList;

export const getMruList = createDraftSafeSelector(_getMruList, payload => payload);

const _getInitialUrlProcessingState = (state: AppState) => state.Common.initailUrlProcessing;

export const getInitialUrlProcessingState = createDraftSafeSelector(_getInitialUrlProcessingState, payload => payload);

//#region from reducer
const _getShowAddMediaToFindModal = (state: AppState): boolean => state.Common.showAddMediaToFindModal;
//#endregion

//#region Memoized Selector
export const getShowAddMediaToFindModal = createDraftSafeSelector(_getShowAddMediaToFindModal, payload => payload);
//#endregion

//#region from reducer
const _getReplyCommentId = (state: AppState) => state.Common.commentToReply;
//#endregion

//#region Memoized Selector
export const getReplyCommentId = createDraftSafeSelector(_getReplyCommentId, payload => payload);
//#endregion

//#region from reducer
const _getEditCommentId = (state: AppState) => state.Common.commentToEdit;
//#endregion

//#region Memoized Selector
export const getEditCommentId = createDraftSafeSelector(_getEditCommentId, payload => payload);
//#endregion

// #region from reducer
const _getShowMessageModal = (state: AppState) => state.Common.showSendMessageModal;
// #endregion

// #region Memoized Selector
export const getShowMessageModal = createDraftSafeSelector(_getShowMessageModal, payload => payload);
//#endregion

// #region from reducer
const _getShowAddReactionModal = (state: AppState) => state.Common.showAddReactionModal;
// #endregion

// #region Memoized Selector
export const getShowAddReactionModal = createDraftSafeSelector(_getShowAddReactionModal, payload => payload);
//#endregion

// #region from reducer
const _getShowFullscreenLoader = (state: AppState) => state.Common.showFullscreenLoader;
// #endregion

// #region Memoized Selector
export const showFullScreenLoader = createDraftSafeSelector(_getShowFullscreenLoader, payload => payload);
//#endregion

const _getShowFindOnFindsMap = (state: AppState) => state.Common.showFindsOnFindsMap;

export const showFindsOnFindsMap = createDraftSafeSelector(_getShowFindOnFindsMap, payload => payload);
//#region from reducer
const _getAppThemeUpdatedString = (state: AppState): string => state.Common.themeUpdated;
//#endregion

//#region Memoized Selector
export const getAppThemeUpdatedString = createDraftSafeSelector(_getAppThemeUpdatedString, payload => payload);
//#endregion
//#region from reducer
const _getshowForwardFindModal = (state: AppState) => state.Common.showForwardFindModal;
//#endregion

//#region Memoized Selector
export const getShowForwardFindModal = createDraftSafeSelector(_getshowForwardFindModal, payload => payload);
//#endregion

const _getPublicKey = (state: AppState) => state.Common.publicKey;

export const getPublicKey = createDraftSafeSelector(_getPublicKey, payload => payload);

const _getToggleCreateGroupModal = (state: AppState) => state.Common.showCreateGroupModal;

export const getToggleCreateGroupModal = createDraftSafeSelector(_getToggleCreateGroupModal, payload => payload);

const _getToggleShowInviteModal = (state: AppState) => state.Common.showInvitationModal;

export const getToggleShowInviteModal = createDraftSafeSelector(_getToggleShowInviteModal, payload => payload);

const _getUserIsOnline = (state: AppState) => state.Common.isOnline;

export const getUserIsOnline = createDraftSafeSelector(_getUserIsOnline, payload => payload);

const _getProcessWebFindBeforeSubmitState = (state: AppState) => state.Common.processWebFindBeforeSubmitStatus;

export const getProcessWebFindBeforeSubmitStatus = createDraftSafeSelector(_getProcessWebFindBeforeSubmitState, payload => payload);

const _isMobile = (state: AppState) => state.Common.isMobile;

export const getIsMobile = createDraftSafeSelector(_isMobile, payload => payload);

const _isShareAppOpen = (state: AppState) => state.Common.isShareAppOpen;

export const getIsShareAppOpen = createDraftSafeSelector(_isShareAppOpen, payload => payload);

const _shareAppData = (state: AppState) => state.Common.shareAppData;

export const getShareAppData = createDraftSafeSelector(_shareAppData, payload => payload);

export const _getBackgroundNotificationData = (state: AppState) => state.Common.backgroundNotificationData;

export const getBackgroundNotificationData = createDraftSafeSelector(_getBackgroundNotificationData, payload => payload);

const _getProcessUrl = (state: AppState) => state.Common.processUrl;

export const getProcessUrl = createDraftSafeSelector(_getProcessUrl, payload => payload);
