import { DateFormats, IEvent } from "common-library/lib/schema";
import { constructTimeForUi, getFormattedDate } from "common-library/lib/utils";

export const updateIndexOfFindToEvents = (event: IEvent, findId: string, indexFindToEvents: Map<string, Set<string>>, removed: boolean = false) => {
  const updatedIndexFindToEvent = new Map(indexFindToEvents);
  const eventIds = updatedIndexFindToEvent.get(findId);
  if (!removed) {
    if (eventIds) {
      const updatedEventIds = new Set(eventIds);
      updatedEventIds.add(event.identifier);
      updatedIndexFindToEvent.set(findId, updatedEventIds);
    } else {
      updatedIndexFindToEvent.set(findId, new Set([event.identifier]));
    }
  } else if (eventIds) {
    eventIds.delete(event.identifier);
    if (eventIds.size === 0) {
      updatedIndexFindToEvent.delete(findId);
    } else {
      updatedIndexFindToEvent.set(findId, new Set(eventIds));
    }
  }
  return updatedIndexFindToEvent;
};

export const getEventDuration = (scheduleStartDateTime: Date, scheduleEndDateTime: Date, scheduledTimeZone: string) => {
  const anotherDay =
    getFormattedDate(scheduleStartDateTime, DateFormats.FindzFilterDate) !== getFormattedDate(scheduleEndDateTime, DateFormats.FindzFilterDate);
  const startTimeForCurrentDevice = constructTimeForUi(new Date(scheduleStartDateTime));
  if (anotherDay) {
    const milliseconds = new Date(scheduleEndDateTime).getTime() - new Date(scheduleStartDateTime).getTime();
    const hours = Math.round(milliseconds / (1000 * 60 * 60) / 24);
    const string = `${startTimeForCurrentDevice} for ${Math.abs(hours)}`;
    return `${string} ${hours > 1 ? "days" : "day"}`;
  } else {
    const endTimeForCurrentDevice = constructTimeForUi(new Date(scheduleEndDateTime));
    return `${startTimeForCurrentDevice} to ${endTimeForCurrentDevice}`;
  }
};
