function parseUrl(url: string) {
  const params: any = {};
  const urlParams = new URLSearchParams(url);
  const entries = urlParams.entries();
  for (const entry of entries) {
    params[entry[0]] = entry[1];
  }
  return params;
}

function stringify(params: any) {
  const urlParams = new URLSearchParams();
  const entries = Object.entries(params);
  for (const entry of entries) {
    params[entry[0]] = entry[1];
    urlParams.set(entry[0], entry[1] as any);
  }
  return urlParams.toString();
}

export default {
  parseUrl,
  stringify
};
