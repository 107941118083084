import { takeLatest, fork, call, select, take, put } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { CommonActions, EventActions } from "../actions";
import {
  getListenerToFetchData,
  getDataFromChanges,
  syncWithIndexDB,
  listenToIndexedDb,
  IndexedDbListenerResponse,
  filterChangeData
} from "../../services";
import { getActiveContext, getTimestamp, getEventsMap, getIndexFindToEvents } from "../selectors";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { getBatch } from "../../utils/indexdb";
import { IEvent, LocalTimeStampValue } from "common-library/lib/schema";
import isEmpty from "lodash/isEmpty";
import { IActiveContext } from "../../types/types";
import { getQueryFromActiveContext } from "../../utils/commonUtils";
import { EventChannel } from "redux-saga";
import { IChanges } from "gsdb/dist/interfaces";
import { updateIndexOfFindToEvents } from "../../utils/eventUtils";

export function* fetchLatestEventsSaga(action: ActionType<typeof EventActions.fetchLatestEvents>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const timestamp: Map<string, LocalTimeStampValue> = yield select(getTimestamp);
    const { listener, timeStampValue } = yield call(getListenerToFetchData, activeContext, FirestoreCollectionEnum.Events, timestamp);
    const batch = getBatch();

    while (true) {
      const { changes } = yield take(listener);

      const { dataAdded, dataModified, dataDeleted, latestTimeStamp } = getDataFromChanges<IEvent>(changes, timeStampValue);
      const newData = dataAdded.concat(dataModified);
      yield call(syncWithIndexDB, batch, FirestoreCollectionEnum.Events, newData, dataDeleted);
      yield put(
        CommonActions.updateTimestamp({
          collectionType: FirestoreCollectionEnum.Events,
          timestamp: latestTimeStamp,
          groupId: activeContext.identifier
        })
      );
    }
  } catch (error) {
    console.error("fetchLatestEventsSaga", error);
  }
}

export function* fetchLatestEventsFromIndexedDbSaga(action: ActionType<typeof EventActions.fetchLatestEventsFromIndexedDb>) {
  try {
    const activeContext: IActiveContext = yield select(getActiveContext);
    if (isEmpty(activeContext)) {
      return;
    }
    const query = getQueryFromActiveContext(activeContext);
    const listener: EventChannel<any> = yield call(listenToIndexedDb, FirestoreCollectionEnum.Events, query);
    while (true) {
      const { data, event }: IndexedDbListenerResponse = yield take(listener);

      let amList: any[] = [];
      let rList: any[] = [];

      if (event === "onChange") {
        const filteredData: IChanges = filterChangeData(query, data);
        amList = [...(filteredData?.add || []), ...(filteredData?.update || [])];
        if (filteredData.remove.length) {
          rList = filteredData.remove;
        }
      } else {
        if (data.add.length) {
          amList = data.add;
        }
      }

      const existingEventMap: Map<string, IEvent> = yield select(getEventsMap);
      const newEventsMap = new Map(existingEventMap);
      const indexFindToEvent: Map<string, Set<string>> = yield select(getIndexFindToEvents);
      let updatedIndexFindToEvents = new Map(indexFindToEvent);

      amList.forEach(event => {
        newEventsMap.set(event.identifier, event);
        if (event.finds?.length) {
          for (const findId of event.finds) {
            updatedIndexFindToEvents = updateIndexOfFindToEvents(event, findId, updatedIndexFindToEvents);
          }
        }
      });

      rList.forEach(event => {
        newEventsMap.delete(event.identifier);
        if (event.finds?.length) {
          for (const findId of event.finds) {
            updatedIndexFindToEvents = updateIndexOfFindToEvents(event, findId, updatedIndexFindToEvents, true);
          }
        }
      });

      yield put(EventActions.setEventsMap({ eventsMap: newEventsMap }));
      yield put(EventActions.setIndexFindToEvents({ indexFindToEvents: updatedIndexFindToEvents }));
    }
  } catch (error) {
    console.error("fetchLatestEventsFromIndexedDbSaga", error);
  }
}

function* fetchLatestEventsListener() {
  yield takeLatest(EventActions.fetchLatestEvents.toString(), fetchLatestEventsSaga);
}

function* fetchLatestEventsFromIndexedDbListener() {
  yield takeLatest(EventActions.fetchLatestEventsFromIndexedDb.toString(), fetchLatestEventsFromIndexedDbSaga);
}

export default function* eventSaga() {
  yield fork(fetchLatestEventsListener);
  yield fork(fetchLatestEventsFromIndexedDbListener);
}
