import urlObj from "url";
import { isEmpty, split, toNumber, first, orderBy, entries } from "lodash";
import { getUrlQueryParams } from "./commonUtils";

const cheerio = require("cheerio");

const metaTagContent = (doc: any, type: string, attr: string) => doc(`meta[${attr}='${type}']`).attr(`content`);

function getTitle(doc: any) {
  let title =
    metaTagContent(doc, `og:title`, `property`) ||
    metaTagContent(doc, `og:title`, `name`) ||
    metaTagContent(doc, `twitter:title`, `name`) ||
    metaTagContent(doc, `twitter:title`, `property`) ||
    metaTagContent(doc, `title`, `itemprop`) ||
    metaTagContent(doc, `name`, `itemprop`) ||
    doc(`title`).text() ||
    metaTagContent(doc, `application-name`, `name`) ||
    metaTagContent(doc, `og:site_name`, `property`);
  return title as string;
}

function getSiteName(doc: any) {
  const siteName = metaTagContent(doc, `og:site_name`, `property`) || metaTagContent(doc, `og:site_name`, `name`);
  return siteName;
}

function getDescription(doc: any) {
  const description =
    metaTagContent(doc, `og:description`, `property`) ||
    metaTagContent(doc, `og:description`, `name`) ||
    metaTagContent(doc, `twitter:description`, `name`) ||
    metaTagContent(doc, `twitter:description`, `property`) ||
    metaTagContent(doc, `description`, `name`) ||
    metaTagContent(doc, `Description`, `name`) ||
    metaTagContent(doc, `description`, `itemprop`);
  return description;
}

function getDefaultImage(doc: any) {
  const defaultImage =
    doc(`#landingImage`).attr("src") || //amazon.com
    doc("img#comparison_image").attr("src") || //amazon.com
    metaTagContent(doc, `og:image`, `property`) ||
    metaTagContent(doc, `og:image`, `name`) ||
    metaTagContent(doc, `og:image:url`, `property`) ||
    metaTagContent(doc, `og:image:secure_url`, `property`) ||
    metaTagContent(doc, `image`, `name`) ||
    metaTagContent(doc, `image`, `itemprop`) ||
    doc('div.ImageComponent img[data-codeception-id="ImageComponent"]').attr("src"); //wayfair.com

  return defaultImage;
}

function getLocation(doc: any) {
  let latitude: number;
  let longitude: number;

  //*Get The Coords from Google Maps
  doc("a")?.each((index: Number, link: any): Boolean => {
    const uri: string = doc(link).attr("href");
    if (uri && uri.startsWith("https://maps.google.com/maps")) {
      const paramPos: number = uri.indexOf("?");
      if (paramPos >= 0) {
        const params: Array<string> = uri.substr(paramPos + 1).split("&");
        const data = params.find((x) => x.startsWith("ll"));
        const coords = data?.split("=")[1];
        latitude = parseFloat(coords?.split(",")[0]);
        longitude = parseFloat(coords?.split(",")[1]);

        latitude = !isNaN(latitude) ? latitude : undefined;
        longitude = !isNaN(longitude) ? longitude : undefined;
        return false;
      }
    }
    return true; // Just to Suppress a Warning
  });
  latitude = latitude || metaTagContent(doc, `place:location:latitude`, `property`);
  longitude = longitude || metaTagContent(doc, `place:location:longitude`, `property`);

  if (!!latitude && !!longitude) {
    console.debug("Coords", latitude, longitude);
    return { latitude, longitude };
  } else return undefined;
}

function getImages(doc: any, rootUrl: string, userInputUrl: string) {
  let images: string[] = [];
  let nodes: any | null;
  let src: string | undefined;
  let dic: Record<string, boolean> = {};

  src = doc(`link[rel=image_src]`).attr(`href`) || undefined;
  if (src) {
    src = urlObj.resolve(rootUrl, src);
    images = [src];
  }

  nodes = doc(`img`);

  if (nodes?.length) {
    dic = {};
    images = [];
    nodes.each((_: number, node: any) => {
      if (node.type !== "text") src = node.attribs.src;
      if (src && !dic[src]) {
        dic[src] = true;
        //If base64, try to compile
        if (src.startsWith("data:")) {
          try {
            window.atob(src.split(",")[1]);
          } catch (e) {
            //Invalid Base64
            return;
          }
        }
        images.push(urlObj.resolve(rootUrl, src));
      }
    });
  }

  // const defaultImage = getContectTypeFromUri(userInputUrl);
  // if (defaultImage)
  //   images.unshift(defaultImage);

  return images;
}

// returns default favicon (//hostname/favicon.ico) for a url
function getDefaultFavicon(rootUrl: string) {
  return urlObj.resolve(rootUrl, `/favicon.ico`);
}

const toSize = (str: string) => {
  const [size] = split(str, "x");
  return !isEmpty(size) ? toNumber(size) : 0;
};
const SIZE_REGEX = /\d+x\d+/;
const getSize = (url: string, sizes: any) => toSize(sizes) || toSize(first(url.match(SIZE_REGEX)) || "");

// returns an array of URL's to favicon images
function getFavicons(doc: any, rootUrl: string) {
  const favObj: any[] = [];
  let nodes: any | never[] = [];
  let src: string | undefined;
  let sizes: string | undefined;

  const relSelectors = [`rel=icon`, `rel="shortcut icon"`, `rel=apple-touch-icon`];

  relSelectors.forEach((relSelector) => {
    // look for all icon tags
    nodes = doc(`link[${relSelector}]`);

    // collect all images from icon tags
    if (nodes.length) {
      nodes.each((_: number, node: any) => {
        if (node.type !== "text") src = node.attribs.href;
        if (node.type !== "text") sizes = node.attribs.sizes;
        let obj = {
          link: "",
          size: 0,
        };
        if (src) {
          obj.link = urlObj.resolve(rootUrl, src);
          obj.size = toSize(src) || 0;
        }
        if (sizes) {
          obj.size = toSize(sizes);
        }

        favObj.push(obj);
      });
    }
  });
  let obj = {
    link: "",
    size: 0,
  };
  const node = metaTagContent(doc, `msapplication-tileimage`, `name*`) || metaTagContent(doc, `faviconUrl`, `itemprop`);
  obj.link = node;
  obj.size = toSize(node);

  const favs = orderBy(favObj, (obj) => obj.size, "desc").map((obj) => obj.link);

  if (favs.length == 0) favs.push(getDefaultFavicon(rootUrl));
  return favs;
}

const toPriceFormat = (price: any) => {
  if (typeof price === "string") {
    price = price.replace(/\$/g, "").replace(/,/g, "");
  }
  return ~~Number(price) > 0 ? +parseFloat(price).toFixed(2) : undefined;
};

function getPrice(doc: any, rootUrl: string) {
  try {
    const currency =
      doc('[property="og:price:currency"]').attr("content") ||
      doc("[data-asin-currency-code]").attr("data-asin-currency-code") || //amazon
      doc('[property="product:price:currency"]').attr("content");

    const price =
      toPriceFormat(doc('[property="og:price:amount"]').attr("content")) ||
      toPriceFormat(doc("[itemprop=price]").attr("content")) ||
      toPriceFormat(doc('[property="product:price:amount"]').attr("content")) ||
      toPriceFormat(doc("[data-asin-price]").attr("data-asin-price")) ||
      toPriceFormat(doc("[itemprop=price]").html()) ||
      toPriceFormat(doc("#priceblock_ourprice").text());

    if (currency || price)
      return {
        price,
        currency,
      };
  } catch (error) {
    console.log(error);
  }
  return undefined;
}

export const getLocalIconForUrl = (url: string) => {
  const linkMap = [
    {
      urlStart: "https://docs.google.com/document",
      includes: "docs.google.com/document",
      imageHandle: "googleDocsLogo",
      previewLabel: "Google Docs",
    },
    {
      urlStart: "https://docs.google.com/spreadsheets",
      includes: "docs.google.com/spreadsheets",
      imageHandle: "googleSheetsLogo",
      previewLabel: "Google Sheets",
    },
    {
      urlStart: "https://docs.google.com/presentation",
      includes: "docs.google.com/presentation",
      imageHandle: "googleSlidesLogo",
      previewLabel: "Google Slides",
    },
    {
      urlStart: "https://keep.google.com/",
      includes: "keep.google.com",
      imageHandle: "googleKeepLogo",
      previewLabel: "Google Keep",
    },
    {
      urlStart: "https://drive.google.com/",
      includes: "drive.google.com",
      imageHandle: "googleDriveLogo",
      previewLabel: "Google Drive",
    },
    {
      urlStart: "https://onedrive.live.com",
      includes: "onedrive.live.com",
      params: {
        ithint: "file%2cdocx",
      },
      imageHandle: "microsoftWordLogo",
      previewLabel: "OneDrive Word",
    },
    {
      urlStart: "https://onedrive.live.com",
      includes: "onedrive.live.com",
      params: {
        ithint: "file%2cxlsx",
      },
      imageHandle: "microsoftExcelLogo",
      previewLabel: "OneDrive Excel",
    },
    {
      urlStart: "https://onedrive.live.com",
      includes: "onedrive.live.com",
      params: {
        ithint: "file%2cpptx",
      },
      imageHandle: "microsoftPowerpointLogo",
      previewLabel: "OneDrive PPT",
    },
  ];
  const params = getUrlQueryParams(url);
  for (let i = 0; i < linkMap.length; i++) {
    let link = linkMap[i];
    if (url.startsWith(link.urlStart) || (link?.includes && url.includes(link.includes))) {
      if (link.params) {
        if (entries(link.params).every(([key, value]: [string, string]) => params && params[key] && params[key] == value)) {
          return {
            imageName: link.imageHandle,
            previewLabel: link.previewLabel,
          };
        }
      } else {
        return {
          imageName: link.imageHandle,
          previewLabel: link.previewLabel,
        };
      }
    }
  }
  return undefined;
};

export function parseHtmlTextResponse(body: string, url: string, userInputUrl: string = "") {
  try {
    if (!body) return undefined;
    const doc = cheerio.load(body);
    return {
      url,
      title: getTitle(doc),
      siteName: getSiteName(doc),
      description: getDescription(doc),
      defaultImage: getDefaultImage(doc),
      images: getImages(doc, url, userInputUrl),
      favicons: getFavicons(doc, url),
      location: getLocation(doc),
      price: getPrice(doc, url),
    };
  } catch (error) {
    console.debug("parseHtmlTextResponse - error ", error);
    return undefined;
  }
}
