import { GeolocationPositionType, maskGeolocationPositionType } from "./userType";
import { IDocumentSecured, EOwner, IMap, maskIDocumentSecured, collectionMasks } from "./commonTypes";

export interface ILocate {
  by: {
    identifier: string;
    name: string;
  };
  on: Date;
  at: {
    geoCoordinates?: GeolocationPositionType;
    address?: AddressType; // Should be populated if the app is able to obtain address using location services
    url?: string;
  };
}

export interface IFindzRestrictedAction {
  download?: boolean;
  share?: boolean;
  forward?: boolean;
}

export enum FindTypeEnum {
  "PHOTO" = "Photo",
  "VIDEO" = "Video",
  "FILE" = "File",
  "LINK" = "Link",
  "LOCATION" = "Location",
  "WEB" = "Web",
  "DOCUMENTS" = "Document",
  "CONTACT" = "Contact",
  "PRODUCT" = "Product",
}

export enum MediaTypeEnum {
  "PHOTO" = "Photo",
  "VIDEO" = "Video",
  "FILE" = "File",
  "LINK" = "Link",
  "LOCATION" = "Location",
  "WEB" = "Web",
  "DOCUMENTS" = "Document",
  "CONTACT" = "Contact",
}

interface FindzDefaultType extends IDocumentSecured {
  created: {
    by: {
      identifier: string;
      name: string;
    };
    on: Date;
    at: {
      geoCoordinates: GeolocationPositionType;
      address?: AddressType; // Should be populated if the app is able to obtain address using location services
    };
    from?: {
      // Populated when created by forwarding from
      findId: string; // The ID of the source Find
      owner: {
        // The owner of the source Find
        identifier: string; // The ID of the owner
        type: EOwner; // USER or GROUP
        name: string; // The name of the owner
      };
    };
  };
  found: {
    by: {
      // Creator, unless the Find is a forwarded Find.
      identifier: string;
      name: string;
    };
    on?: Date; // If created using a camera or link, the time of creation.  If created using an image from the gallery, then the exif datetime if available
    at: CapturedAtType;
  };
  located?: ILocate;
  title?: string; // Optional field
  note?: string;
  copies: string[]; // IDs of Finds created by directly forwarding this Find. Ideally the array should be optional.
  attributes?: IMap<AttributeType>; // Attributes.  The key is the name of the attribute.  <2008182127> <Anuj> Not yet supported
  priceQuotes?: PriceQuoteType[]; // A Find may or may not have Finds
  owner: {
    identifier: string;
    type: EOwner;
    name: string;
  };
  reactions?: Reaction;
  reactionsCount?: {
    count: number;
    reaction: string;
    isEmojiUsedByUser: boolean;
  }[];
  restrictedAction?: IFindzRestrictedAction;
  type: FindTypeEnum;
}

/*
 *  If creating a new Find
 *    In Find.found.at:
 *      If taken from camera:
 *        geoCoordinates
 *        address
 *      If taken from link:
 *        url
 *      If taken from Device Gallery:
 *        geoCoordinates from exif
 *        address from exif
 *        device
 *    In Media.created.at:
 *      In all cases:
 *        geoCoordinates
 *        address
 *    In Media.found.at:
 *      If taken from camera:
 *        geoCoordinates
 *        address
 *      If taken from link:
 *        url
 *      If taken from Device Gallery:
 *        geoCoordinates from exif
 *        address from exif
 *        device
 *  If migrating an existing Find
 *    In Find.found.at:
 *      If url is "":
 *        geoCoordinates
 *        address
 *      If url is "Device Gallery":
 *        device: {
 *          deviceName: "Device Gallery",
 *          systemName: "unknown",
 *          uniqueId: "unknown"
 *        }
 *      If url is anything else:
 *        url
 *    In Media.created.at:
 *      In all cases:
 *        geoCoordinates
 *        address
 *    In Media.found.at:
 *      If url is "":
 *        geoCoordinates
 *        address
 *      If url is "Device Gallery":
 *        device: {
 *          deviceName: "Device Gallery",
 *          systemName: "unknown",
 *          uniqueId: "unknown"
 *        }
 *      If url is anything else:
 *        url
 */

export interface ReactionData {
  by: {
    identifier: string; // Agent's id
    name: string; // Agent's name
  };
  on: Date; // Timestamp of reaction
  comment?: string; // identifier of the comment object
}

export interface Reaction {
  [key: string]: [ReactionData]; // Unicode character of reaction's emoji
}

export interface IDevice {
  // uniqueId: string; // Unique ID of the device
  deviceName: string; // Name of the device
  systemName: string;
}

export interface CapturedAtType {
  geoCoordinates?: GeolocationPositionType; // Must exist for Media/Find created using the camera.  For device uploaded images, populate only if exif information available.
  address?: AddressType; // Must be populated using location services, if geo-coordinates available
  event?: {
    // Only if found at an event.  <2008182100> <Anuj> Events are currently not supported.
    name: string;
    startDate: Date;
    endDate: Date;
    description?: string;
    address?: AddressType;
  };
  url?: string; // Must exist for media / Find created using a link.  The full link should be put here.
  device?: IDevice; // Must exist if uploaded from a device
  // IOS / Android
}

export interface ILocation {
  place_id?: string;
  osm_id?: string;
  osm_type?: string;
  licence?: string;
  lat: number;
  lon: number;
  boundingbox?: number[];
  class?: string;
  type?: string;
  display_name?: string;
  display_place?: string;
  display_address?: string;
  address?: {
    city?: string;
    country: string;
    country_code?: string;
    name?: string;
    neighbourhood?: string;
    postcode?: string;
    road?: string;
    state?: string;
    region?: string;
  };
}

export interface TagInfoForFind {
  identifier: string;
  color: string;
  path: string;
}

export interface VendorInfoForFind {
  identifier: string;
  businessName: string;
}

export interface BrandInfoForFind {
  identifier: string;
  name: string;
}

export interface IDownloadFileResponseType {
  photoCount: number;
  fileCount: number;
  failedCount: number;
  ignoredCount: number;
}

export interface FindzType extends FindzDefaultType {
  // Type to be used in client side code, Redux
  media: {
    default?: string; // Default media.  May not exist if
    sort: string[]; // The sort order of the media.  Should include all media in the mediaList
    mediaList: IMap<MediaType>; // The list of all Media for the Find.  Can be empty if no media is added
  };
  tags: TagInfoForFind[]; // Collection of Tags Info relevant to the Find
  vendors: VendorInfoForFind[]; // Collection of vendors.  <2008182100> <Anuj>  At this time only one vendor is supported.  In the future it may be available with multiple vendors.
  brands: BrandInfoForFind[]; // Collection of Brands.  <2008182100> <Anuj>  At this time only one brand is supported.  In the future it may be available with multiple brands.
  messageIds?: Set<string>;
}

export interface IFindzLocationQueueElement {
  findId: string;
}

export interface FindzDbType extends FindzDefaultType {
  // Type to be used for the DB
  media: {
    default?: string; // Default media.  May not exist if
    sort: string[]; // The sort order of the media.  Should include all media in the mediaList
    mediaList: IMap<MediaDbType>; // The list of all Media for the Find.  Can be empty if no media is added
  };
  tags: string[]; // Collection of Tag IDs
  vendors: string[]; // Collection of vendor IDs.  <2008182100> <Anuj>  At this time only one vendor is supported.  In the future it may be available with multiple vendors.
  brands: string[]; // Collection of Brand IDs.  <2008182100> <Anuj>  At this time only one brand is supported.  In the future it may be available with multiple brands.
}

export interface MediaDbType {
  identifier: string;
  created: {
    // used to be called "captured"
    by: {
      // User who added the image
      identifier: string;
      name: string;
    };
    on: Date; // Date time when added
    at: {
      geoCoordinates: GeolocationPositionType;
      address?: AddressType; // Should be populated if the app is able to obtain address using location services
    };
  };
  found: {
    on?: Date; // If created using a camera or link, the time of creation.  If created using an image from the gallery, then the exif datetime if available
    at: CapturedAtType;
  };
  located?: {
    on: Date;
    at: {
      geoCoordinates: GeolocationPositionType;
      address?: AddressType;
    };
  };
  size: {
    bytes: number; // Media size in bytes.  <2008182110> <Anuj> Was captured.size.  Has nothing to do with captured context
    widthPixels?: number; // Width of the image in pixels
    heightPixels?: number; // Height of the image in pixels
  };
  cloudStorageUrl?: string; // Must be optional.  Will be added once uploaded.
  contentType?: string;
  extensionType?: string;
  isLinkWithoutMedia?: boolean;
  preview?: {
    icon?: string; // Local Asset Name
    cloudStorageUrl?: string;
    iconLabel?: string;
  };
  note?: string;
  type: MediaTypeEnum;
}

export interface MediaType extends MediaDbType {
  filename?: any;
  scrapedImage?: ScrapedImageType;
}

export enum ScrapedImageType {
  Link = "link",
  File = "file",
}

export interface AddressType {
  name?: string;
  street?: string;
  locality?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  city?: string;
  region?: string;
}

export interface AttributeType {
  value: any;
  type: string; // data type of the attribute (string, number, boolean, date, timestamp)
  uom: string; // The unit of measure (weight:kgs, weight:pounds, distance:meters, etc.)
  changeLog: [
    {
      by: string; // identifier of the user who changed the value
      on: Date; // Time when the change was done
      prevValue: any; // The value before the change.  Will ben undefined
    }
  ];
}

export interface PriceQuoteType {
  type: string;
  unitPrice: number | null;
  moq: number;
  packSize: number;
  currency: string;
  quoteDate: Date;
  incoterm: string;
  incotermLocation: {
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  terms?: string;
  startDate: Date;
  endDate?: Date;
}

export interface IMediaFoundContext {
  on?: Date;
  at: CapturedAtType;
}

export const maskAddressType = {
  name: true,
  street: true,
  locality: true,
  state: true,
  postalCode: true,
  country: true,
  city: true,
  region: true,
};
Object.freeze(maskAddressType);

export const maskIDevice = {
  deviceName: true,
  systemName: true,
};
Object.freeze(maskIDevice);

export const maskCapturedAtType = {
  geoCoordinates: maskGeolocationPositionType,
  address: maskAddressType,
  event: {
    name: true,
    startDate: true,
    endDate: true,
    description: true,
    adddress: maskAddressType,
  },
  url: true,
  device: maskIDevice,
};

Object.freeze(maskCapturedAtType);

export const maskChangeLog = {
  by: true,
  on: true,
  prevValue: true,
};
Object.freeze(maskChangeLog);

export const maskAttributeType = {
  value: true,
  type: true,
  uom: true,
  changeLog: [maskChangeLog],
};
Object.freeze(maskAttributeType);

export const maskPriceQuoteType = {
  type: true,
  unitPrice: true,
  moq: true,
  packSize: true,
  currency: true,
  quoteDate: true,
  incoterm: true,
  incotermLocation: {
    locality: true,
    region: true,
    postalCode: true,
    country: true,
  },
  terms: true,
  startDate: true,
  endDate: true,
};
Object.freeze(maskPriceQuoteType);

export const maskFindzDefaultType = {
  ...maskIDocumentSecured,
  created: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
    at: {
      geoCoordinates: maskGeolocationPositionType,
      address: maskAddressType,
    },
    from: {
      findId: true,
      owner: {
        identifier: true,
        type: true,
        name: true,
      },
    },
  },
  found: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
    at: maskCapturedAtType,
  },
  located: {
    by: {
      identifier: true,
      name: true,
    },
    on: true,
    at: {
      geoCoordinates: maskGeolocationPositionType,
      address: maskAddressType,
      url: true,
    },
  },
  title: true,
  note: true,
  copies: [true],
  attributes: {
    __MAP__: maskAttributeType,
  },
  priceQuotes: [maskPriceQuoteType],
  owner: {
    identifier: true,
    type: true,
    name: true,
  },
  restrictedAction: {
    download: true,
    share: true,
    forward: true,
  },
  type: true,
};

Object.freeze(maskFindzDefaultType);

export const maskMediaDbType = {
  identifier: true,
  created: {
    by: {
      // User who added the image
      identifier: true,
      name: true,
    },
    on: true,
    at: {
      geoCoordinates: maskGeolocationPositionType,
      address: maskAddressType,
    },
  },
  found: {
    on: true,
    at: maskCapturedAtType,
  },
  located: {
    on: true,
    at: {
      geoCoordinates: maskGeolocationPositionType,
      address: maskAddressType,
    },
  },
  size: {
    bytes: true,
    widthPixels: true,
    heightPixels: true,
  },
  cloudStorageUrl: true, // Must be optional.  Will be added once uploaded.
  contentType: true,
  extensionType: true,
  isLinkWithoutMedia: true,
  preview: {
    icon: true, // Local Asset Name
    cloudStorageUrl: true,
    iconLabel: true,
  },
  note: true,
  type: true,
};

Object.freeze(maskMediaDbType);

export const maskReaction = {
  by: {
    identifier: true,
    name: true,
  },
  on: true,
  comment: true,
};

Object.freeze(maskReaction);

export const maskFindzDbType = {
  ...maskFindzDefaultType,
  media: {
    default: true,
    sort: [true],
    mediaList: { __MAP__: maskMediaDbType },
  },
  tags: [true],
  vendors: [true],
  brands: [true],
  reactions: { __MAP__: [maskReaction] },
};
Object.freeze(maskFindzDbType);

collectionMasks["findz"] = maskFindzDbType;
