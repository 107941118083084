import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { BrandType } from "common-library/lib/schema";
import { getActiveContext } from "./groupSelectors";
//#region from reducer
const _getBrandsMap = (state: AppState): Map<string, BrandType> => state.Brand.brandsMap;
//#endregion

//#region Memoized Selector
export const getBrandsMap = createDraftSafeSelector(_getBrandsMap, payload => payload);
//#endregion

export const getBrandsList = createDraftSafeSelector([_getBrandsMap, getActiveContext], (payload, getActiveContext) => {
  return Array.from(payload.values()).filter(brand => brand.owner.identifier === getActiveContext?.identifier);
});
