import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { CommentType } from "common-library/lib/schema";
import { AsyncState } from "../../types/types";

//#region from reducer
const _getCommentsMap = (state: AppState): Map<string, CommentType> => state.Comment.commentsMap;
//#endregion

//#region Memoized Selector
export const getCommentsMap = createDraftSafeSelector(_getCommentsMap, payload => payload);
//#endregion

//#region from reducer
const _getIndexFindToComment = (state: AppState): Map<string, Set<string>> => state.Comment.indexFindToComment;
//#endregion

//#region Memoized Selector
export const getIndexFindToComment = createDraftSafeSelector(_getIndexFindToComment, payload => payload);
//#endregion

//#region from reducer
const _getCreateCommentState = (state: AppState): AsyncState => state.Comment.createComment.status;
//#endregion

//#region Memoized Selector
export const getCreateCommentState = createDraftSafeSelector(_getCreateCommentState, payload => payload);
//#endregion
