import { CommentType, FindzDbType, FindzType } from "common-library/lib/schema";
import { DraftFindMediaType, IActiveContext } from "../../types/types";
import { actionCreator, asyncActionCreator } from "../helper";

enum FindActionTypes {
  FETCH_LATEST_FINDS = "FINDS/FETCH",
  FETCH_LATEST_FINDS_FROM_INDEXEDDB = "FINDS/INDEXEDDB/FETCH",
  ADD_FIND = "FINDS/ADD",
  DELETE_FINDS = "FINDS/DELETE",
  EDIT_FIND = "FINDS/EDIT",
  SET_FINDZ = "FINDS/SET",
  UPDATE_FINDZ = "FINDS/UPDATE",
  SET_IS_LOADING = "FINDS/SET_IS_LOADING",
  "DRAG_MULTIPLE_FINDS_AND_TAGS" = "DRAG_MULTIPLE_FINDS_AND_TAGS",
  FORWARD_FINDS = "FINDS/FORWARD_FINDS",
  "DELETE_REACTION" = "FINDS/DELETE_REACTION"
}

export const fetchLatestFinds = actionCreator(FindActionTypes.FETCH_LATEST_FINDS);

export const fetchLatestFindsFromIndexedDb = actionCreator(FindActionTypes.FETCH_LATEST_FINDS_FROM_INDEXEDDB);

export const createFind = asyncActionCreator<
  { findsId?: string[] },
  {
    find: Partial<FindzDbType>;
    selectedMedia?: string[];
    draftMedia?: DraftFindMediaType[];
    defaultMediaId?: string;
    useFileNameAsTitle?: boolean;
    isLinkWithoutMedia?: boolean;
    extractExif?: boolean;
    isMultipleFind?: boolean;
    isMessageScreen?: boolean;
  }
>(FindActionTypes.ADD_FIND);

export const editFind = actionCreator<{ find: Partial<FindzDbType> }>(FindActionTypes.EDIT_FIND);

export const deleteFinds = asyncActionCreator<undefined, { finds: FindzType[] }>(FindActionTypes.DELETE_FINDS);

export const setFinds = actionCreator<{ findzMap: Map<string, FindzType> }>(FindActionTypes.SET_FINDZ);

export const updateFinds = actionCreator<{
  findId: string;
  find: Partial<FindzDbType>;
  findDataToRemove?: Partial<FindzDbType>;
  mediaToDelete?: string[];
  isPathUpdate?: boolean;
}>(FindActionTypes.UPDATE_FINDZ);

export const setIsLoading = actionCreator<{ isLoading: boolean }>(FindActionTypes.SET_IS_LOADING);

export const dragFindsAndTags = actionCreator<{ findIds: string[]; tagIds: string[] }>(FindActionTypes.DRAG_MULTIPLE_FINDS_AND_TAGS);

export const forwardFinds = asyncActionCreator<
  undefined,
  {
    findzList: FindzType[];
    targetActiveContext: IActiveContext;
    messageText?: string;
    messageMentions?: { identifier: string; name: string }[];
    newTagsToAdd?: string[];
    copyVendor?: Boolean;
  }
>(FindActionTypes.FORWARD_FINDS);

export const deleteReaction = actionCreator<{
  commentId: string;
  commentData: CommentType;
}>(FindActionTypes.DELETE_REACTION);
