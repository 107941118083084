import { Database } from "gsdb";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { ESavedPreferanceKeys } from "../types/appEnum";
import { IActiveContext, EntityType } from "../types/types";
import { getQueryFromActiveContext } from "./commonUtils";

export enum IndexedDbDatabases {
  Finds = "findz",
  FindzMedia = "findz-media",
  FindzPref = "findz-pref"
}

export const database = new Database(IndexedDbDatabases.Finds, {
  [FirestoreCollectionEnum.Groups]: "identifier",
  [FirestoreCollectionEnum.Tags]: "identifier",
  [FirestoreCollectionEnum.Vendors]: "identifier",
  [FirestoreCollectionEnum.Brands]: "identifier",
  [FirestoreCollectionEnum.Invitations]: "identifier",
  [FirestoreCollectionEnum.Findz]: "identifier",
  [FirestoreCollectionEnum.Events]: "identifier",
  [FirestoreCollectionEnum.Comments]: "identifier",
  [FirestoreCollectionEnum.ContentReport]: "identifier",
  [FirestoreCollectionEnum.Activities]: "identifier"
});

export const getBatch = () => database.batch();

export const getById = async (collectionType: FirestoreCollectionEnum, identifier: string) => {
  const data = await database
    .collection(collectionType)
    .query({ query: { identifier: { $eq: identifier } } })
    .get();

  if (data.length === 1) {
    return data[0];
  } else {
    return undefined;
  }
};

export const persistData = async (collectionType: FirestoreCollectionEnum, records: EntityType[]): Promise<void> => {
  try {
    await database.collection(collectionType).set(records);
  } catch (err) {
    console.error(`Error Persisting Document ${records} from ${collectionType}`, err);
  }
};

//Return type Batch Not Exported
export const queueForDeletion = async (batch: any, collectionType: FirestoreCollectionEnum, identifier: string): Promise<void> => {
  const exists = await database.collection(collectionType).exists(identifier);
  if (exists) {
    batch.collection(collectionType).doc(identifier).delete();
  }
};

// For only media

export enum FindzMediaTables {
  UploadQueue = "uploadQueue",
  DraftContent = "draftContent"
}

export const mediaDatabase = new Database(IndexedDbDatabases.FindzMedia, {
  [FindzMediaTables.DraftContent]: "identifier",
  [FindzMediaTables.UploadQueue]: "identifier"
});

export const getMediaBatch = () => mediaDatabase.batch();

export const insertBulkIntoMediaDb = async (collectionType: FindzMediaTables, records: any[]): Promise<boolean> => {
  try {
    await mediaDatabase.collection(collectionType).set(records);
    return true;
  } catch (err) {
    console.error(`Error Persisting Document ${records} from ${collectionType} in media db`, err);
    return false;
  }
};

export const getMediaById = async (collectionType: FindzMediaTables, identifier: string) => {
  const data = await mediaDatabase
    .collection(collectionType)
    .query({ query: { identifier: { $eq: identifier } } })
    .get();

  if (data.length === 1) {
    return data[0];
  } else {
    return undefined;
  }
};

// For User Preferences and local persistances

export enum FindzPrefTables {
  Common = "common",
  RouteState = "routeState"
}

export const prefDatabase = new Database(IndexedDbDatabases.FindzPref, {
  [FindzPrefTables.Common]: "key",
  [FindzPrefTables.RouteState]: "pathname"
});

export const getPrefBatch = () => prefDatabase.batch();

export const insertBulkIntoPrefDb = async (collectionType: FindzPrefTables, records: any[]): Promise<void> => {
  try {
    await prefDatabase.collection(collectionType).set(records);
  } catch (err) {
    console.error(`Error Persisting Document ${records} from ${collectionType} in media db`, err);
  }
};

// export const insertIntoPrefDb = async (collectionType: FindzPrefTables, key: string, value: any): Promise<void> => {
//   try {
//     await prefDatabase.collection(collectionType).set({ [key]: value });
//   } catch (err) {
//     console.error(`Error Persisting Document ${{ [key]: value }} from ${collectionType} in pref db`, err);
//   }
// };

export const saveCommonPref = async (key: ESavedPreferanceKeys, value: any): Promise<void> => {
  try {
    await prefDatabase.collection(FindzPrefTables.Common).set({ key, value });
  } catch (err) {
    console.error(`Error Persisting Document ${{ key, value }} from  in pref db`, err);
  }
};

export const getCommonPref = async (key: ESavedPreferanceKeys): Promise<any> => {
  try {
    const data: any = await prefDatabase
      .collection(FindzPrefTables.Common)
      .query({ query: { key: { $eq: key } } })
      .get();
    if (data && data.length) {
      return data[0].value;
    }
  } catch (err) {
    console.error(`Error getting Persisted value for ${key} from  in pref db`, err);
  }
};

export const deleteCommonPref = async (key: ESavedPreferanceKeys): Promise<any> => {
  try {
    const newBatch = prefDatabase.batch();
    newBatch.collection(FindzPrefTables.Common).doc(key).delete();
    await newBatch.commit();
  } catch (err) {
    console.error(`Error getting Persisted value for ${key} from  in pref db`, err);
  }
};

export const getRouteStatePref = async (pathname: string) => {
  const data = await prefDatabase
    .collection(FindzPrefTables.RouteState)
    .query({ query: { pathname: { $eq: pathname } } })
    .get();

  if (data.length === 1) {
    return data[0];
  } else {
    return undefined;
  }
};

export const getCollectionListFromGroup = async (context: IActiveContext, collection: FirestoreCollectionEnum) => {
  try {
    const query = getQueryFromActiveContext(context);
    const collectionList: any[] = await database.collection(collection).query(query).get();
    return collectionList;
  } catch (error) {
    return [];
  }
};
