import { useCallback, useRef, useState } from "react";

const useLongPress = (
  onLongPress: (e?: any) => void,
  onClick: (e?: any) => void,
  { shouldPreventDefault = true, delay = 1000, touchThreshold = 5 }: { shouldPreventDefault: boolean; delay: number; touchThreshold: number } = {
    shouldPreventDefault: true,
    delay: 1000,
    touchThreshold: 4
  }
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<any>(null);
  const touchMoved = useRef(false); // Track if touch moved
  const touchStartPosition = useRef({ x: 0, y: 0 });

  const start = useCallback(
    (e: any) => {
      touchMoved.current = false; // Reset touchMoved on touch start
      if (shouldPreventDefault) {
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
      }
      timeout.current = setTimeout(() => {
        if (!touchMoved.current) {
          onLongPress(e);
          setLongPressTriggered(true);
        }
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (shouldTriggerClick = true, e: any) => {
      timeout.current && clearTimeout(timeout.current);
      !touchMoved.current && shouldTriggerClick && !longPressTriggered && onClick(e);
      setLongPressTriggered(false);
      if (shouldPreventDefault) {
        document.removeEventListener("touchmove", handleTouchMove);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  const handleTouchMove = useCallback((e: any) => {
    const touchPosition = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
    const deltaX = Math.abs(touchPosition.x - touchStartPosition.current.x);
    const deltaY = Math.abs(touchPosition.y - touchStartPosition.current.y);
    if (deltaX > touchThreshold || deltaY > touchThreshold) {
      touchMoved.current = true;
    }
  }, []);

  return {
    onMouseDown: (e: any) => start(e),
    onTouchStart: (e: any) => {
      start(e);
    },
    onMouseUp: (e: any) => clear(undefined, e),
    onMouseLeave: (e: any) => clear(false, e),
    onTouchEnd: (e: any) => {
      clear(undefined, e);
    }
  };
};

export default useLongPress;
