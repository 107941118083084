import { TreeNodeDataProps } from "@gsynergy/gs-react-components";
import { isEmpty, concat, indexOf, orderBy } from "lodash";
import { FindzType } from "common-library/lib/schema";
import { TagType } from "common-library/lib/schema";

/**
 * Sort Tags to display Tree View and returns an array of tags structured like TreeNodeDataProps;
 * @param tagsMap
 * @param findzMap
 * @param activeTagId
 * @returns TreeNodeDataProps[]
 */

export function sortTagsForGroup(tagsMap: Map<string, TagType>, findzMap: Map<string, FindzType>, activeTagId?: string) {
  const tagsDataArray = Array.from(tagsMap.values());
  const rootTags = tagsDataArray.filter(tags => tags.isRootTag && !tags.ancestors.length);
  const finalData: TreeNodeDataProps[] = [];
  const activeTagData = tagsMap.get(activeTagId || "");
  rootTags.forEach(rootTag => {
    const rootTagData: TreeNodeDataProps = {
      id: rootTag.identifier,
      label: rootTag.name,
      children: [],

      customData: {
        count: getFindzForTag(rootTag, tagsMap, findzMap).length
      }
    };

    rootTagData.extended = !!activeTagData?.ancestors?.includes(rootTag.identifier);
    rootTag.children.forEach((child: string) => {
      const childData = tagsMap.get(child);
      if (childData) {
        const childTreeData: TreeNodeDataProps = {
          id: child,
          label: childData.name,

          children: [],
          customData: {
            count: getFindzForTag(childData, tagsMap, findzMap).length
          }
        };
        childTreeData.extended = !!activeTagData?.ancestors?.includes(child);
        if (!isEmpty(childData.children)) {
          childTreeData.children = sortTagsChildren(childData.children, tagsMap, findzMap, activeTagData?.identifier) as any;
        }
        rootTagData?.children?.push(childTreeData);
      }
    });
    finalData.push(rootTagData);
  });

  return orderBy(finalData, "label", "asc");
}

/**
 * Sort the children for root Tags and return array of structured data for tree view
 * @param childrenIdentifierArr
 * @param tagsMap
 * @param findzMap
 * @param activeTagId
 * @returns TreeNodeDataProps[]
 */
export function sortTagsChildren(
  childrenIdentifierArr: string[],
  tagsMap: Map<string, TagType>,
  findzMap: Map<string, FindzType>,
  activeTagId?: string
) {
  const activeTagData = tagsMap.get(activeTagId || "");
  return childrenIdentifierArr.map(childId => {
    const data = tagsMap.get(childId);

    if (data) {
      const treeData: TreeNodeDataProps = {
        id: data.identifier,
        label: data.name,
        customData: {
          count: getFindzForTag(data, tagsMap, findzMap).length
        }
      };
      treeData.extended = activeTagData && activeTagData.ancestors.includes(childId);
      if (!isEmpty(data.children)) {
        const result = sortTagsChildren(data.children, tagsMap, findzMap, activeTagData?.identifier);
        treeData.children = result as any;
      }
      return treeData;
    }
  });
}

export const getFindzForTag = (selectedTag: TagType, tagsMap: Map<string, TagType>, findsMap: Map<string, FindzType>) => {
  if (!selectedTag) {
    return [];
  }
  const indexTagToFinds = tagsMap || new Map();
  const allDescendants = getAllDescendantsForTag(selectedTag, tagsMap, []);
  const allTags = concat(allDescendants, selectedTag);
  let findIds: Set<string> = new Set();
  const findsForTag: FindzType[] = [];
  // Get unique findIds.  Two sub-collections can have the same find.
  if (indexTagToFinds) {
    allTags.map((tag: TagType) => {
      indexTagToFinds.get(tag.identifier)?.finds?.forEach((findId: string) => findIds.add(findId));
    });
  }
  // Get all finds for collected Ids
  findIds.forEach((findId: string) => {
    const findData = findsMap.get(findId) as FindzType;
    findData && findsForTag.push(findData);
  });
  return findsForTag;
};

export const getAllDescendantsForTag = (tagData: TagType, allTagsMap: Map<string, TagType>, childrenArray: TagType[]): TagType[] => {
  const allTags = allTagsMap;
  const childArray = tagData.children;
  if (!isEmpty(childArray) && childArray.length) {
    for (const k in childArray) {
      const currentTagData = allTags.get(childArray[k]);
      if (currentTagData && currentTagData.children.length !== 0) {
        getAllDescendantsForTag(currentTagData, allTags, childrenArray);
      }
      if (currentTagData) {
        childrenArray.push(currentTagData as TagType);
      }
    }
  }
  return childrenArray;
};

export function getFindzWithoutTags(findzMap: Map<string, FindzType>) {
  return Array.from(findzMap.values()).filter(find => isEmpty(find.tags));
}

export const getTagPath = (tagIdentifier: string, tagsMap: Map<string, TagType> = new Map()) => {
  const tagData = tagsMap.get(tagIdentifier);
  let tagPath: Array<{ id: string; path: string }> = [];
  if (tagData) {
    for (const ancestorId of tagData.ancestors) {
      const ancestorData = tagsMap.get(ancestorId);
      if (ancestorData) {
        tagPath.push({ id: ancestorId, path: capitaliseString(ancestorData.name) });
        // tagPath = tagPath ? `${tagPath} / ${ancestorData.name}` : ancestorData.name;
      }
    }
    tagData.isRootTag
      ? (tagPath = [{ id: tagData.identifier, path: capitaliseString(tagData.name) }])
      : tagPath.push({ id: tagData.identifier, path: capitaliseString(tagData.name) });
    // tagPath = tagData.isRootTag ? tagData.name : `${tagPath} / ${tagData.name}`;
  }
  return tagPath;
};

export const createTagPath = (tagIdentifier: string, tagsMap: Map<string, TagType>) => {
  const tagData = tagsMap.get(tagIdentifier);
  let tagPath = "";
  if (tagData) {
    for (const ancestorId of tagData.ancestors) {
      const ancestorData = tagsMap.get(ancestorId);
      if (ancestorData) {
        // tagPath.push({ id: ancestorId, path: capitaliseString(ancestorData.name) });
        tagPath = tagPath ? `${tagPath} / ${ancestorData.name}` : ancestorData.name;
      }
    }
    // tagData.isRootTag
    //   ? (tagPath = [{ id: tagData.identifier, path: capitaliseString(tagData.name) }])
    //   : tagPath.push({ id: tagData.identifier, path: capitaliseString(tagData.name) });
    tagPath = tagData.isRootTag ? tagData.name : `${tagPath} / ${tagData.name}`;
  }
  return tagPath;
};

export function capitaliseString(str: string) {
  const firstLetter = str[0];
  return firstLetter.toUpperCase().concat(str.slice(str.indexOf(str[0]) + 1));
}

export const removeRedundantParentOrChild = (currentFind: FindzType, tagsMap: Map<string, TagType>, tagToAdd: TagType) => {
  const removedTags: string[] = [];

  for (const tagItem of currentFind.tags) {
    const tagItemAncestors = tagsMap.get(tagItem.identifier)?.ancestors || [];

    if (
      indexOf(tagToAdd.children, tagItem.identifier) > -1 ||
      indexOf(tagToAdd.ancestors, tagItem.identifier) > -1 ||
      indexOf(tagItemAncestors, tagToAdd.identifier) > -1
    ) {
      removedTags.push(tagItem.identifier);
    }
  }
  return removedTags;
};
