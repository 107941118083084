import { FindzType, EOwner, UserType, LocationDataType, FindTypeEnum } from "../schema";
import { defaultCurrency } from "../constants";

export const createDefaultFind = ({
  currentUser,
  locationData,
  currency,
}: {
  currentUser: UserType;
  locationData?: LocationDataType;
  currency?: string;
}): FindzType => {
  return {
    tags: [],
    owner: {
      identifier: currentUser.identifier,
      type: EOwner.User,
      name: currentUser.name || "",
    },
    identifier: "",
    title: "",
    note: "",
    media: {
      default: "",
      sort: [],
      mediaList: {},
    },
    copies: [],
    priceQuotes: [
      {
        type: "",
        unitPrice: null,
        moq: 0,
        packSize: 1,
        currency: currency || defaultCurrency,
        quoteDate: new Date(),
        incoterm: "",
        incotermLocation: {
          locality: "",
          region: "",
          postalCode: "",
          country: "",
        },
        terms: "",
        startDate: new Date(),
        endDate: new Date(),
      },
    ],
    vendors: [],
    brands: [],
    created: {
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name ? currentUser.name : "",
      },
      on: new Date(),
      /**
       * TODO need furthur refactor like we need to remove {lat : 0, long : 0, alti : 0} (4 Sep, 2020).
       */
      at: {
        geoCoordinates: locationData
          ? locationData.geoCoordinates
          : {
              latitude: 0,
              longitude: 0,
              altitude: 0,
            },
      },
    },
    found: {
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name ? currentUser.name : "",
      },
      at: {},
    },
    _documentUpdatedOn: new Date(),
    type: FindTypeEnum.FILE,
  };
};
