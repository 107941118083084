import { QueryConstraint, collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import firestore from "../../config/firebaseApp";
import { FirestoreReference } from "../services/firebaseReference";

export const firestoreCollectionRef = (firestoreCollectionEnum: FirestoreCollectionEnum) => {
  return collection(firestore, firestoreCollectionEnum);
};

export const firestoreDocRef = (firestoreCollectionEnum: FirestoreCollectionEnum, documentId?: string) => {
  return documentId ? doc(firestoreCollectionRef(firestoreCollectionEnum), documentId) : doc(firestoreCollectionRef(firestoreCollectionEnum));
};

export const firestoreGetDoc = async (firestoreCollectionEnum: FirestoreCollectionEnum, documentId: string) => {
  return await getDoc(firestoreDocRef(firestoreCollectionEnum, documentId));
};

export const queryFirebaseDocs = async (collection: FirestoreCollectionEnum, where: QueryConstraint[]) => {
  return await getDocs(query(FirestoreReference(collection), ...where));
};
