import set from "lodash/set";
import { CommonActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { GeolocationPositionType, ILocation, EOwner, LocalTimeStampValue, InvitationType } from "common-library/lib/schema";
import { asyncCaseCreator, InitialAsyncState } from "../helper";
import { AsyncState } from "../../types/types";
import { CommentActions } from "../actions";
interface ICommonState {
  isAuthorized?: boolean | undefined;
  publicKey: string | undefined;
  lastTimeStampValues: Map<string, LocalTimeStampValue>;
  appTheme: string;
  mruList: any;
  navRailDrawerIsOpen: boolean;
  createFindzModalIsOpen: boolean;
  locationArray: ILocation[];
  staticMapImageData: string;
  geoLocationPosition: GeolocationPositionType;
  initailUrlProcessing: InitialAsyncState;
  processWebFindBeforeSubmitStatus: InitialAsyncState;
  showAddMediaToFindModal: boolean;
  showSendMessageModal: boolean;
  isOnline: boolean;
  showAddReactionModal: boolean;
  commentToEdit: string | undefined;
  commentToReply: string | undefined;
  showFullscreenLoader?: boolean;
  showFindsOnFindsMap:
    | {
        showModal: boolean;
        isFullScreen: boolean;
        primaryFindId: string;
      }
    | undefined;
  showInvitationModal?: {
    showModal: boolean;
    invite: InvitationType;
  };
  themeUpdated: string;
  showCreateGroupModal: boolean;
  showForwardFindModal: { selectedFindIds: string[]; showModal: boolean; forwardTo?: { name: string; identifier: string; type: EOwner } } | undefined;
  isMobile: boolean;
  backgroundNotificationData: any;
  isShareAppOpen: boolean;
  shareAppData: any;
  processUrl: InitialAsyncState;
}

export const CommonLabel = "Common";

export const defaultCommonState: ICommonState = {
  isAuthorized: undefined,
  publicKey: undefined,
  mruList: {},
  lastTimeStampValues: new Map(),
  appTheme: "light",
  navRailDrawerIsOpen: false,
  createFindzModalIsOpen: false,
  locationArray: [],
  staticMapImageData: "",
  geoLocationPosition: {
    altitude: 0,
    longitude: 0,
    latitude: 0
  },
  isOnline: true,
  initailUrlProcessing: { status: AsyncState.idle },
  processUrl: { status: AsyncState.idle },
  processWebFindBeforeSubmitStatus: { status: AsyncState.idle },
  showAddMediaToFindModal: false,
  showSendMessageModal: false,
  showAddReactionModal: false,
  commentToEdit: undefined,
  commentToReply: undefined,
  showFullscreenLoader: false,
  showFindsOnFindsMap: undefined,
  themeUpdated: "",
  showForwardFindModal: undefined,
  showCreateGroupModal: false,
  showInvitationModal: undefined,
  isMobile: false,
  backgroundNotificationData: undefined,
  isShareAppOpen: false,
  shareAppData: {}
};

export const commonReducer = createReducer(defaultCommonState, builder => {
  // async
  asyncCaseCreator(CommonActions.initializationAppData, "initailUrlProcessing", builder);
  asyncCaseCreator(CommonActions.processWebFindBeforeSubmit, "processWebFindBeforeSubmitStatus", builder);
  asyncCaseCreator(CommonActions.processURL, "processUrl", builder);
  //sync
  builder
    .addCase(CommonActions.setIsAuthorized, (state, action) => {
      state.isAuthorized = action.payload.isAuthorized;
    })
    .addCase(CommonActions.setPublicKey, (state, action) => {
      state.publicKey = action.payload.publicKey;
    })
    .addCase(CommonActions.setAppTheme, (state, action) => {
      state.appTheme = action.payload.theme;
    })
    .addCase(CommonActions.setNavigationRailDrawerIsOpen, (state, action) => {
      state.navRailDrawerIsOpen = action.payload.isOpen;
    })
    .addCase(CommonActions.updateTimestamp, (state, action) => {
      const { lastTimeStampValues = new Map() } = state;
      const lastData = lastTimeStampValues.get(action.payload.groupId) || {};
      set(lastData, action.payload.collectionType, { timeStampValue: action.payload.timestamp });
      lastTimeStampValues.set(action.payload.groupId, lastData);
      state.lastTimeStampValues = lastTimeStampValues;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return { ...defaultCommonState, isMobile: state.isMobile };
    })
    .addCase(CommonActions.setLocationArray, (state, action) => {
      state.locationArray = action.payload.searchResponse;
    })
    .addCase(CommonActions.setStaticMapImageData, (state, action) => {
      state.staticMapImageData = action.payload.imgData;
    })
    .addCase(CommonActions.setCreateFindzModalIsOpen, (state, action) => {
      state.createFindzModalIsOpen = action.payload.isOpen;
    })
    .addCase(CommonActions.setGeoLocationPosition, (state, action) => {
      state.geoLocationPosition = action.payload.geoLocationPosition;
    })
    .addCase(CommonActions.setMRUList, (state, action) => {
      state.mruList = action.payload.mruList;
    })
    .addCase(CommonActions.toggleShowAddMediaToFindModal, (state, action) => {
      state.showAddMediaToFindModal = !state.showAddMediaToFindModal;
    })
    .addCase(CommentActions.editComment, (state, action) => {
      state.commentToEdit = action.payload.commentId;
    })
    .addCase(CommentActions.replyToComment, (state, action) => {
      state.commentToReply = action.payload.commentId;
    })
    .addCase(CommonActions.toggleShowSendMessageModal, (state, action) => {
      state.showSendMessageModal = action.payload.isOpen;
    })
    .addCase(CommonActions.toggleAddReactionModal, (state, action) => {
      state.showAddReactionModal = action.payload.isOpen;
    })
    .addCase(CommonActions.showFullScreenLoader, (state, action) => {
      state.showFullscreenLoader = action.payload.showLoader;
    })
    .addCase(CommonActions.shareAppOpen, (state, action) => {
      state.isShareAppOpen = action.payload.value;
    })
    .addCase(CommonActions.shareAppData, (state, action) => {
      state.shareAppData = action.payload.value;
    })
    .addCase(CommonActions.toggleShowOnFindsMap, (state, action) => {
      state.showFindsOnFindsMap = action.payload.data;
    })
    .addCase(CommonActions.updateThemeObject, (state, action) => {
      state.themeUpdated = action.payload.updated;
    })
    .addCase(CommonActions.toggleForwardFindModal, (state, data) => {
      state.showForwardFindModal = data.payload.data;
    })
    .addCase(CommonActions.toggleCreateGroupModal, (state, data) => {
      state.showCreateGroupModal = data.payload.isOpen;
    })
    .addCase(CommonActions.toggleInvitationModal, (state, data) => {
      state.showInvitationModal = data.payload.data;
    })
    .addCase(CommonActions.setUserIsOnline, (state, data) => {
      state.isOnline = data.payload.value;
    })
    .addCase(CommonActions.toggleIsMobile, (state, data) => {
      state.isMobile = data.payload.isMobile;
    })
    .addCase(CommonActions.setBackgrounNotificationData, (state, data) => {
      state.backgroundNotificationData = data.payload.data;
    });
});
