import { useCallback, useState, useRef, useLayoutEffect } from "react";

const noop = () => {
  // do nothing.
};

type parserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => void;
    };

const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  options?: parserOptions<T>
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, () => void] => {
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) {
    return [initialValue as T, noop, noop];
  }
  if (!key) {
    throw new Error("useLocalStorage key may not be falsy");
  }

  // eslint-disable-next-line no-nested-ternary
  const deserializer = options ? (options.raw === true ? <M>(value: M) => value : options.deserializer) : JSON.parse;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initializer = useRef((k: string) => {
    try {
      // eslint-disable-next-line no-nested-ternary
      const serializer = options ? (options.raw === true ? String : options.serializer) : JSON.stringify;

      const localStorageValue = localStorage.getItem(k);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      }
      if (initialValue) {
        localStorage.setItem(k, serializer(initialValue));
      }
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<T | undefined>(() => initializer.current(key));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const set: React.Dispatch<React.SetStateAction<T | undefined>> = useCallback(
    valOrFunc => {
      try {
        const newState = typeof valOrFunc === "function" ? (valOrFunc as (prevState: T | undefined) => T | undefined)(state) : valOrFunc;
        if (typeof newState === "undefined") {
          return;
        }
        let value: string;

        if (options) {
          if (options.raw === true) {
            if (typeof newState === "string") {
              value = newState;
            } else {
              value = JSON.stringify(newState);
            }
          } else if (options.serializer) {
            value = options.serializer(newState);
          } else {
            value = JSON.stringify(newState);
          }
        } else {
          value = JSON.stringify(newState);
        }

        localStorage.setItem(key, value);
        setState(deserializer(value));
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, setState]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setState]);

  return [state, set, remove];
};

export default useLocalStorage;
