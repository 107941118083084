import { Box, Typography, AppBar, Button, SxProps, Theme } from "@mui/material";
import GSIcon from "../../../components/GSIcon/GSIcon";
import { ICONSIZE, SIZE } from "../../../../styles/size";
import Avatar from "../../../components/Avatar";
import GSImage from "../../../components/GSImage/GSImage";
import { Icons } from "../../../../assets/icons";

type ActionButton = {
  label: React.ReactNode;
  onClick: (e?: any) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

export type MobileHeaderProps = {
  onBackPress: (e?: any) => void;
  onClickHeader?: (e?: any) => void;
  title: string;
  subTitle?: string;
  actionButtons?: ActionButton[];
  showFindzIcon?: boolean;
} & (
  | {
      showAvatar: false;
      hideMenuButton: true;
    }
  | {
      showAvatar: true;
      photoUrl?: string;
      hideMenuButton: false;
      onClickMenu: (e?: any) => void;
    }
);

function Header(props: MobileHeaderProps) {
  const { onBackPress, actionButtons, onClickHeader, title, subTitle, showAvatar, hideMenuButton, showFindzIcon } = props;
  const disableRipple = onClickHeader ? false : true;
  return (
    <AppBar data-testid="header-mobile" elevation={0} sx={headerStyles.appBarSx}>
      <Box width="100%" className="d-flex fd-row al-center">
        <Button data-testid="header-back-navigation-btn" sx={headerStyles.buttonSx} onClick={onBackPress} className="d-flex al-center jc-center">
          <GSIcon fontSize={ICONSIZE.NORMAL} name="chevron_left" />
        </Button>
        <Button
          data-testid="header-app-context"
          disableFocusRipple={disableRipple}
          disableTouchRipple={disableRipple}
          disableRipple={disableRipple}
          onClick={onClickHeader}
          sx={headerStyles.appContextSx}>
          {showAvatar && <Avatar sx={headerStyles.avatarSx as any} name={title} photoUrl={props.photoUrl} />}
          {showFindzIcon && <GSImage src={Icons.findzIcon} sx={{ width: "40px", height: "40px" }} />}
          <Box whiteSpace={"nowrap"} width={"100%"} marginLeft={SIZE.SMALL} textOverflow={"ellipsis"} className="d-flex fd-column">
            <Typography
              maxWidth={"70%"}
              whiteSpace={"nowrap"}
              overflow={"clip"}
              textOverflow={"ellipsis"}
              fontSize={"16px"}
              fontWeight={500}
              variant="titleLarge"
              data-testid="header-title">
              {title}
            </Typography>
            <Typography
              maxWidth={"70%"}
              whiteSpace={"nowrap"}
              overflow={"clip"}
              textOverflow={"ellipsis"}
              variant="bodySmall"
              fontSize={"12px"}
              data-testid="header-subTitle">
              {subTitle}
            </Typography>
          </Box>
        </Button>
        <Box data-testid="header-action-button-container">
          {actionButtons?.map((btn, i) => {
            return (
              <Button
                data-testid="header-action-buttons"
                key={i}
                disabled={btn?.disabled}
                sx={btn.sx}
                className="d-flex al-center jc-center"
                onClick={btn.onClick}>
                {btn.label}
              </Button>
            );
          })}

          {hideMenuButton ? null : (
            <Button data-testid="header-menu-button" sx={headerStyles.buttonSx} className="d-flex al-center jc-center" onClick={props.onClickMenu}>
              <GSIcon name="menu" baseClassName="material-icons-outlined" />
            </Button>
          )}
        </Box>
      </Box>
    </AppBar>
  );
}

export default Header;

const headerStyles: Record<string, SxProps<Theme>> = {
  appBarSx: theme => ({
    zIndex: 99,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    height: "48px",
    padding: "8px",
    width: "100vw",
    color: "surface.onVariant",
    borderBottom: `0.2px solid ${theme.palette.outline?.main}`,
    bgcolor: theme.palette.background.paper
  }),
  buttonSx: { minWidth: "24px !important", padding: SIZE.XSMALL },
  appContextSx: {
    px: "0 !important",
    textTransform: "none",
    flex: 1,
    textAlign: "start",
    marginLeft: "8px",
    padding: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  avatarSx: { width: "36px", height: "36px" }
};
