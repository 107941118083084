import {
  UserType,
  GroupMemberDbType,
  BrandType,
  VendorType,
  GroupDbType,
  TagDbType,
  FindzDbType,
  IEvent,
  CommentDbType,
  ContentReportDbType,
  ActivityType,
  ContactType,
  FindzType,
  GroupMode
} from "common-library/lib/schema";
import { EOwner } from "./appEnum";

export interface GroupMemberRenderType extends GroupMemberDbType {
  color?: string;
  isPastMember?: boolean;
  name?: string;
}

export interface IActiveContext {
  identifier: string;
  type: EOwner;
  name: string;
}

export interface Country {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
  currencyCode: string;
  symbol: string;
}

export interface PhoneType {
  countryCode: string;
  number: string;
  userId: string;
}

export interface VerifyOtpType {
  phone: {
    countryCode: string;
    number: string;
    otpCode: string;
  };
  currentUser: UserType;
  publicKey: string;
}

export interface SignInMethodsType {
  email: string;
  methods: string[];
}

export enum AsyncState {
  idle = "IDLE",
  inProgress = "IN_PROGRESS",
  success = "SUCCESS",
  failed = "FAILED"
}

export interface IUploadMediaType {
  identifier: string;
  data: File;
  findId: string;
  groupId: string;
  contentType: string;
  thumbnailUrl?: string;
  extensionType: string;
  owner: IActiveContext;
  cloudStorageUrl?: string;
  previewUrl?: string;
}

export interface IScrapedImage {
  url: string;
  height: number;
  width: number;
  isBase64Image?: boolean;
  data?: string;
  isDefaultImage?: boolean;
  isFavicon?: boolean;
  isScreenShot?: boolean;
}

export interface DraftFindMediaType {
  identifier: string;
  contentType: string;
  thumbnailUrl: string;
  extensionType: string;
  data: File;
  owner: IActiveContext;
}

export interface CreateGroupPayloadType {
  title: string;
  owner: UserType | ContactType;
  collaborators: {};
  administrators: {};
  viewers: {};
  pendingOwnerId?: string;
  moveOwnerTo?: string;
  selectedFindzList?: FindzType[];
  flowId?: any;
  groupMode: GroupMode;
  identifier?: string;
}
export type MediaObjType = {
  identifier: string;
  mediaURL: string;
  owner: {
    type: EOwner;
    identifier: string;
    name: string;
  };
  data?: Blob;
};

export type CachedMediaType = Required<MediaObjType>;

export type EntityType =
  | BrandType
  | VendorType
  | GroupDbType
  | TagDbType
  | FindzDbType
  | CachedMediaType
  | IEvent
  | CommentDbType
  | ContentReportDbType
  | ActivityType;

export enum LayoutViewEnum {
  GRID = "GRID",
  LIST = "LIST",
  MASONRY = "MASONRY",
  MAPS = "MAPS"
}
