import { ActivityType, EOwner, GeolocationPositionType, GroupRole, UserType } from "common-library/lib/schema";
import mentionUtils from "common-library/lib/utils/mentionUtils";
import { ActivityVerb, BaseActivityType, ForwardActivityType } from "common-library/lib/schema";
import { IActiveContext } from "../types/types";

export const updateIndexOfFindToActivities = (
  activity: ActivityType,
  finds: { from: string; to: string },
  indexFindToActivities: Map<string, Set<string>>,
  removed: boolean = false
) => {
  const updatedIndexFindToActivities = new Map(indexFindToActivities);
  const activityIds = updatedIndexFindToActivities.get(finds.from);
  if (!removed) {
    if (activityIds) {
      const updatedActivityIds = new Set(activityIds);
      updatedActivityIds.add(activity.identifier);
      updatedIndexFindToActivities.set(finds.from, updatedActivityIds);
    } else {
      updatedIndexFindToActivities.set(finds.from, new Set([activity.identifier]));
    }
  } else if (activityIds) {
    activityIds.delete(activity.identifier);
    if (activityIds.size === 0) {
      updatedIndexFindToActivities.delete(finds.from);
    } else {
      updatedIndexFindToActivities.set(finds.from, new Set(activityIds));
    }
  }
  return updatedIndexFindToActivities;
};

export const getActivityObjForForwardFind = (
  newActivityId: string,
  currentUser: UserType,
  activeContext: IActiveContext,
  targetActiveContext: IActiveContext,
  finds: { from: string; to: string }[],
  messageText: string,
  locationData: GeolocationPositionType
) => {
  let accessibleTo: BaseActivityType["accessibleTo"] = {};
  if (activeContext.type === EOwner.Group && targetActiveContext.type === EOwner.Group) {
    accessibleTo = { groups: [targetActiveContext.identifier, activeContext.identifier] };
  }
  if (activeContext.type === EOwner.User && targetActiveContext.type === EOwner.Group) {
    accessibleTo = {
      groups: [targetActiveContext.identifier],
      users: [activeContext.identifier]
    };
  }
  if (activeContext.type === EOwner.Group && targetActiveContext.type === EOwner.User) {
    accessibleTo = {
      users: [targetActiveContext.identifier],
      groups: [activeContext.identifier]
    };
  }

  const activityObj: ForwardActivityType = {
    verb: ActivityVerb.ForwardFind,
    identifier: newActivityId,
    agent: currentUser.identifier,
    created: {
      at: locationData,
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name || ""
      },
      on: new Date()
    },
    accessibleTo: accessibleTo,
    object: {
      invitation: {
        role: GroupRole.collaborator,
        invitationId: "",
        inviter: {
          name: currentUser.name || "",
          phone: `${currentUser.phone?.countryCode}${currentUser.phone?.number}`,
          photoUrl: currentUser.userPhoto,
          email: currentUser.email || "",
          identifier: currentUser.identifier
        },
        invitee: "",
        inviteeName: ""
      },
      fromGroup: {
        identifier: activeContext.identifier,
        name: activeContext.name,
        type: activeContext.type
      },
      toGroup: {
        identifier: targetActiveContext.identifier,
        name: targetActiveContext.name,
        type: targetActiveContext.type
      },
      finds: finds,
      message: messageText
    },
    readBy: {
      [currentUser.identifier]: new Date()
    },
    _documentUpdatedOn: new Date()
  };

  const mentionsList = mentionUtils.getMentionsWithInputText(messageText);
  const mentions = Array.from(mentionsList.map.values());

  if (mentions.length > 0) {
    activityObj.object.mentions = mentions;
  }

  return activityObj;
};

export const getMessageActivityString = (activity: ActivityType, currentUserId?: string): string => {
  switch (activity?.verb) {
    case ActivityVerb.GroupInvite:
      if ((activity.object as any).invitation.inviter.identifier === currentUserId) {
        return `I invited ${(activity.object as any).invitation.inviteeName}`;
      } else {
        return `${(activity.object as any).invitation.inviter.name} invited ${(activity.object as any).invitation.inviteeName}`;
      }
    case ActivityVerb.GroupInviteDeclined:
      return `${(activity.object as any).invitation.inviteeName} declined the group invitation`;

    case ActivityVerb.GroupInviteAccepted:
      if (activity.agent === currentUserId) {
        return `I joined`;
      } else {
        return `${(activity.object as any).invitation.inviteeName} has joined`;
      }

    case ActivityVerb.GroupRename:
      if (activity.agent === currentUserId) {
        return `I renamed the group from "${(activity.object as any).group.oldName}" to "${(activity.object as any).group.newName}"`;
      } else {
        return `${activity.created.by.name} renamed the group from "${(activity.object as any).group.oldName}" to "${
          (activity.object as any).group.newName
        }"`;
      }

    case ActivityVerb.MemberLeft:
      if (activity.agent === currentUserId) {
        return `I left`;
      } else {
        return `${activity.created.by.name} left`;
      }

    case ActivityVerb.GroupDelete:
      if (activity.agent === currentUserId) {
        return `I deleted the group`;
      } else {
        return `${activity.created.by.name} deleted the group`;
      }
    case ActivityVerb.MemberRemoved:
      if (activity.agent === currentUserId) {
        return `I removed ${(activity.object as any).member.name}`;
      } else {
        return `${activity.created.by.name} removed ${(activity.object as any).member.name}`;
      }
  }

  return "";
};
