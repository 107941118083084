import { chunk, cloneDeep } from "lodash";
import { serverTimestamp, writeBatch } from "firebase/firestore";
import db from "../../../config/firebaseApp";
import { collectionMasks } from "common-library/lib/schema";
import { guardObjectUsingGivenMask } from "../../utils/maskingUtil";
import { appConstant } from "../../constants/appContants";

// Number of writes in a firestore batch is limited to 500.
//    When deleting a group, It is very likely that this limit would be hit as a user may have thousands of documents.
//    We therefore chunks all write requests into batches of 500, or MaxBatchSize and process them in parallel.
// Additionally,
//    All documents should have their _documentUpdatedOn field set every time they are updated
//    We don't delete documents, but set their _documentDeletedOn field when they are deleted, along with setting their _documentUpdatedOn field

export const maxBatchOps = 500; // HARDCODE: <Anuj> <2005161433>  Firestore Limit
// export interface IBatchAutoCommit {
//   set: (ref: any, data: any, options?: any) => IBatchAutoCommit;
//   update: (ref: any, data: any) => IBatchAutoCommit;
//   delete: (ref: any, isHardDelete?: boolean) => IBatchAutoCommit;
//   commit: () => Promise<IBatchAutoCommit>;
//   size: () => number;
// }

export type IBatchUtil = ReturnType<typeof batchUtil>;

export const batchUtil = (maxBatchSize: number = maxBatchOps) => {
  const localTime = new Date();
  const serverTime = serverTimestamp();
  const batchOpsQueue: any[] = [];

  // ensure maxBatchSize is within firestore limits
  maxBatchSize = maxBatchSize > maxBatchOps || maxBatchSize < 1 ? maxBatchOps : maxBatchSize;

  const _commit = async () => {
    try {
      const qCopy = Array.from(batchOpsQueue); // Create a shallow copy so users can continue with new batches.
      batchOpsQueue.length = 0; // Clear the queue to make it available for additional operations.
      qCopy.length &&
        (await Promise.all(
          chunk(qCopy, maxBatchSize).map(async qOps => {
            const batch = writeBatch(db);
            // const batch = firebase.firestore().batch();
            for (const op of qOps) {
              switch (op.op) {
                case "set":
                  batch.set(op.ref, op.data, op.options);
                  break;
                case "update":
                  batch.update(op.ref, op.data);
                  break;
                case "delete":
                  batch.delete(op.ref);
                  break;
              }
            }
            await batch.commit();
          })
        ));
    } catch (error) {
      console.error("Error: ", error);
      throw error; // TODO: <Anuj> <2005161550>  Device the best way to handle errors. Perhaps, we should be able to retry if commit fails.  We will need to know how to deal with Set and Delete if they had previously succeeded.
    }
    return api;
  };

  const _set = (ref: any, data: any, options?: any) => {
    batchOpsQueue.push({
      op: "set",
      ref: ref,
      data: guardObjectUsingGivenMask(
        Object.assign(cloneDeep(data), {
          _documentUpdatedOn: localTime,
          _serverDocumentUpdatedOn: serverTime,
          _appVersion: appConstant.currentVersion,
          _serverDocumentCreatedOn: serverTime,
          _documentDeletedOn: null
        }),
        collectionMasks[ref.parent.id]
      ),
      options: options
    });
    return api;
  };
  const _update = (ref: any, data: any, overrideDefaults: string[] = []) => {
    const defaults = {
      _documentUpdatedOn: localTime,
      _serverDocumentUpdatedOn: serverTime,
      _appVersion: appConstant.currentVersion
    } as any;
    for (const key of overrideDefaults) {
      delete defaults[key];
    }
    batchOpsQueue.push({
      op: "update",
      ref: ref,
      data: guardObjectUsingGivenMask(Object.assign(cloneDeep(data), defaults), collectionMasks[ref.parent.id])
    });

    return api;
  };

  const _delete = (ref: any, isHardDelete: boolean = false) => {
    if (isHardDelete) {
      batchOpsQueue.push({
        op: "delete",
        ref: ref
      });
      return api;
    }

    // Default to soft delete
    return _update(ref, { _documentDeletedOn: localTime, _appVersion: appConstant.currentVersion });
  };

  const _size = () => {
    return batchOpsQueue.length;
  };

  const api = {
    set: _set,
    update: _update,
    delete: _delete,
    commit: _commit,
    size: _size
  };

  return api;
};
