import { FindzType, TagType, UserType } from "common-library/lib/schema";
import { IActiveContext } from "../../types/types";
import { actionCreator, asyncActionCreator } from "../helper";

enum TagActionTypes {
  FETCH_LATEST_TAGS = "TAGS/FETCH",
  ADD_TAG = "TAGS/ADD",
  FETCH_LATEST_TAGS_FROM_INDEXEDDB = "TAGS/INDEXEDDB/FETCH",
  SET_TAGS_MAP = "TAGS/SET_MAP",
  UPDATE_TAG = "TAGS/UPDATE",
  MOVE_TAG = "TAGS/MOVE",
  ADD_TO_FAVORITE = "TAGS/ADD_TO_FAVORITE",
  REMOVE_FAVORITE = "TAGS/REMOVE_FAVORITE",
  ADD_TAG_TO_FIND = "TAGS/ADD_TAG_TO_FIND",
  UPDATE_TAG_TO_FIND = "TAGS/UPDATE_TAG_TO_FIND",
  EDIT_TAG = "TAGS/EDIT_TAG",
  DELETE_TAG = "TAGS/DELETE_TAG",
  MULTI_UPDATE = "TAGS/MULTI_UPDATE"
}

export const fetchLatestTags = actionCreator(TagActionTypes.FETCH_LATEST_TAGS);

export const addTag = asyncActionCreator<
  { identifier?: string[]; context?: "CREATE_FIND" | "FORWARD_FIND" },
  { collectionName: string; tagsList: TagType[]; currentUser: UserType; activeContext: IActiveContext; context?: "CREATE_FIND" | "FORWARD_FIND" }
>(TagActionTypes.ADD_TAG);

export const fetchLatestTagsFromIndexedDb = actionCreator(TagActionTypes.FETCH_LATEST_TAGS_FROM_INDEXEDDB);

export const setTagsMap = actionCreator<{ tagsMap: Map<string, TagType> }>(TagActionTypes.SET_TAGS_MAP);

export const updateTag = actionCreator<{ tagId: string; tagData: Partial<TagType> }>(TagActionTypes.UPDATE_TAG);

export const moveTag = actionCreator<{ draggedTagIds: string[]; droppedOntoTagId: string }>(TagActionTypes.MOVE_TAG);

export const addToFavorite = actionCreator<{ tagIds: string[] }>(TagActionTypes.ADD_TO_FAVORITE);

export const removeFavorite = actionCreator<{ tagId: string }>(TagActionTypes.REMOVE_FAVORITE);

export const addTagToFind = actionCreator<{ newTagPath: string; tagsMap: Map<string, TagType>; currentFind: FindzType }>(
  TagActionTypes.ADD_TAG_TO_FIND
);

export const updateTagToFind = actionCreator<{ currentFind: FindzType; tagsMap: Map<string, TagType>; tagToAdd: TagType }>(
  TagActionTypes.UPDATE_TAG_TO_FIND
);

export const editTag = asyncActionCreator<undefined, { tagId: string; newTagName: string; tagsList: TagType[] }>(TagActionTypes.EDIT_TAG);

export const deleteTag = asyncActionCreator<undefined, { tagToDelete: TagType }>(TagActionTypes.DELETE_TAG);

export const multiUpdateTag = asyncActionCreator<undefined, { tagsToRemove: string[]; tagsToAdd: string[]; selectedFinds: string[] }>(
  TagActionTypes.MULTI_UPDATE
);
