import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const _getDraftMedia = (state: RootState) => state.Media.draft;

const _getMediaIdsUploading = (state: RootState) => state.Media.uploadQueueIdentifiers;

const _getDraftMediaStatus = (state: RootState) => state.Media.draftMediaStatus.status;

export const getDraftMedia = createDraftSafeSelector(_getDraftMedia, payload => payload);

const _getDeleteFindMedia = (state: RootState) => state.Media.deleteFindMedia;

export const getDeleteFindMedia = createDraftSafeSelector(_getDeleteFindMedia, payload => payload);

export const getMediaIdsUploading = createDraftSafeSelector(_getMediaIdsUploading, payload => payload);

export const getDraftMediaStatus = createDraftSafeSelector(_getDraftMediaStatus, payload => payload);
