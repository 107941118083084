import { createAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { FirestoreError } from "firebase/firestore";
import { AsyncState } from "../../types/types";

// Sync action helper
export const actionCreator = createAction;

// Async action helper

export const asyncActionCreator = <SuccessPayload = undefined, SagaPayload = undefined, ErrorPayload = FirestoreError>(baseType: string) => {
  return {
    saga: createAction<SagaPayload>(`${baseType}/SAGA`),

    start: createAction(`${baseType}/START`),

    success: createAction<SuccessPayload>(`${baseType}/SUCCESS`),

    error: createAction<ErrorPayload>(`${baseType}/ERROR`),

    reset: createAction(`${baseType}/RESET`, (clearData: boolean = false) => {
      return {
        payload: {
          clearData
        }
      };
    })
  };
};

// Async reducer case helper
export interface InitialAsyncState<DataType = undefined, ErrorType = Error> {
  status: AsyncState;
  error?: ErrorType;
  data?: DataType;
}

export const asyncCaseCreator = <S extends Record<string, any>, T extends ActionReducerMapBuilder<S>>(
  actions: Record<string, ReturnType<typeof createAction>>,
  propertyName: string,
  builder: T
) => {
  builder
    .addCase(actions.start, state => {
      if (state[propertyName]) {
        state[propertyName].status = AsyncState.inProgress;
      }
    })
    .addCase(actions.success, (state, action) => {
      if (action.payload && state[propertyName]) {
        state[propertyName].data = action.payload;
      }
      if (state[propertyName]) {
        state[propertyName].status = AsyncState.success;
        state[propertyName].error = undefined;
      }
    })
    .addCase(actions.error, (state, action) => {
      if (state[propertyName]) {
        state[propertyName].status = AsyncState.failed;
        state[propertyName].error = action.payload;
      }
    })
    .addCase(actions.reset, (state, action) => {
      if (action.payload.clearData && state[propertyName]) {
        state[propertyName].data = undefined;
      }
      if (state[propertyName]) {
        state[propertyName].status = AsyncState.idle;
        state[propertyName].error = undefined;
      }
    });
};
