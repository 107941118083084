import { IActiveContext } from "../types/types";

import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { BrandType, UserType } from "common-library/lib/schema";
import { batchUtil } from "./common/batch";
import { firestoreDocRef } from "../utils/firestoreUtils";

export async function createBrand(currentUser: UserType, activeContext: IActiveContext, brandName: string) {
  const batch = batchUtil();

  if (!brandName) {
    throw new Error(`Brand name not available for ${activeContext?.type}`);
  }

  const identifier = firestoreDocRef(FirestoreCollectionEnum.Brands).id;
  if (!identifier) {
    throw new Error("[BRAND SERVICE]: Error generating brand ID");
  }
  const date = new Date();

  const brand: BrandType = {
    name: brandName,
    identifier: identifier,
    created: {
      by: currentUser.identifier,
      on: date
    },
    owner: {
      identifier: activeContext.identifier,
      type: activeContext.type
    },
    _documentUpdatedOn: date
  };

  batch.set(firestoreDocRef(FirestoreCollectionEnum.Brands, brand.identifier), brand);
  await batch.commit();
  return Promise.resolve(brand);
}
