import { createTheme, Palette, Theme } from "@mui/material";

const surfaceOpacity = [0, 5, 8, 11, 15, 20];

// const surfaceOpacity = [0, 5, 13, 24, 39, 59];
const getHexValueForPercentage = (percent: number) => {
  const i = percent / 100;
  var alpha = Math.round(i * 255);
  var hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
  var perc = Math.round(i * 100);
  return hex;
};

const hexToDec = (hex: string) => {
  return parseInt(hex, 16);
};

const decToHex = (dec: number) => {
  return dec.toString(16);
};

const getBackgroundColor = (overLayColor: string, backgroundColor: string, opacity: number) => {
  const isHexColors = overLayColor.includes("#");
  const opacityInDec = opacity / 100;
  let bgColor = "inheret";
  if (isHexColors) {
    const overLayR = overLayColor.split("#")[1].substring(0, 2);
    const overLayG = overLayColor.split("#")[1].substring(2, 4);
    const overLayB = overLayColor.split("#")[1].substring(4, 6);
    const overLayRDec = hexToDec(overLayR);
    const overLayGDec = hexToDec(overLayG);
    const overLayBDec = hexToDec(overLayB);

    const backgroundR = backgroundColor.split("#")[1].substring(0, 2);
    const backgroundG = backgroundColor.split("#")[1].substring(2, 4);
    const backgroundB = backgroundColor.split("#")[1].substring(4, 6);
    const backgroundRDec = hexToDec(backgroundR);
    const backgroundGDec = hexToDec(backgroundG);
    const backgroundBDec = hexToDec(backgroundB);

    const targetRDec = Math.floor(opacityInDec * overLayRDec + (1 - opacityInDec) * backgroundRDec);
    const targetGDec = Math.floor(opacityInDec * overLayGDec + (1 - opacityInDec) * backgroundGDec);
    const targetBDec = Math.floor(opacityInDec * overLayBDec + (1 - opacityInDec) * backgroundBDec);

    bgColor = `rgb(${targetRDec},${targetGDec},${targetBDec})`;
  }
  return bgColor;
};

const getRGB = (str: string) => {
  var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  return match
    ? {
        red: Number(match[1]),
        green: Number(match[2]),
        blue: Number(match[3])
      }
    : {};
};

const getHexColorFromRGBValue = (rgbColor: string) => {
  let hexColor = "";

  const colorNumber = getRGB(rgbColor);
  const decRHex = decToHex(colorNumber?.red || 0);
  const decGHex = decToHex(colorNumber?.green || 0);
  const decBHex = decToHex(colorNumber?.blue || 0);
  hexColor = `#${decRHex}${decGHex}${decBHex}`;

  return hexColor;
};

export const getCustomizedThemeObject = (theme: any, savedPaletteValue?: any) => {
  let updatedThemeObject = { ...theme };
  if (savedPaletteValue) {
    updatedThemeObject = { ...savedPaletteValue };
  }

  const surfaceOneColor = getBackgroundColor(
    updatedThemeObject.palette.primary.main,
    updatedThemeObject.palette.background.default,
    surfaceOpacity[1]
  );
  const surfaceTwoColor = getBackgroundColor(updatedThemeObject.palette.primary.main, getHexColorFromRGBValue(surfaceOneColor), surfaceOpacity[2]);

  const surfaceThreeColor = getBackgroundColor(updatedThemeObject.palette.primary.main, getHexColorFromRGBValue(surfaceTwoColor), surfaceOpacity[3]);
  const surfaceFourColor = getBackgroundColor(updatedThemeObject.palette.primary.main, getHexColorFromRGBValue(surfaceThreeColor), surfaceOpacity[4]);
  const surfaceFiveColor = getBackgroundColor(updatedThemeObject.palette.primary.main, getHexColorFromRGBValue(surfaceFourColor), surfaceOpacity[5]);

  updatedThemeObject.palette.surface = {
    ...theme?.palette?.surface,
    ...savedPaletteValue?.palette?.surface,
    level1: surfaceOneColor,
    level2: surfaceTwoColor,
    level3: surfaceThreeColor,
    level4: surfaceFourColor,
    level5: surfaceFiveColor
  };
  updatedThemeObject.shape.getBorderRadius = (a: number) => {
    return `${a * theme.shape.borderRadius}px`;
  };

  //adding typography

  updatedThemeObject.typography = {
    ...updatedThemeObject.typography,
    displayLarge: {
      lineHeight: "4rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "3.5625rem"
    },
    displayMedium: {
      lineHeight: "3.25rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "2.8125rem"
    },
    displaySmall: {
      lineHeight: "2.75rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "2.25rem"
    },
    headLineLarge: {
      lineHeight: "2.5rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "2rem"
    },
    headLineMedium: {
      lineHeight: "2.25rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1.75rem"
    },
    headLineSmall: {
      lineHeight: "2rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1.5rem"
    },
    titleLarge: {
      lineHeight: "1.75rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1.375rem"
    },
    titleMedium: {
      lineHeight: "1.5rem",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "1rem"
    },
    titleSmall: {
      lineHeight: "1.25rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "0.875rem"
    },
    labelLarge: {
      lineHeight: "1.25rem",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "0.875rem"
    },
    labelMedium: {
      lineHeight: "1rem",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "0.75rem"
    },
    labelSmall: {
      lineHeight: "1rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "0.6875rem"
    },
    bodyLarge: {
      lineHeight: "1.5rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "1rem"
    },
    bodyMedium: {
      lineHeight: "1.25rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "0.875rem"
    },
    bodySmall: {
      lineHeight: "1rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "0.75rem"
    }
  };

  // adding breakpoints

  updatedThemeObject.breakpoints = { values: { xxs: 0, xs: 340, sm: 600, md: 900, lg: 1200, xl: 1536 } };

  return createTheme(updatedThemeObject);
};

export const applyAplhaToColor = (color: string | undefined, opacity: number) => {
  if (!color) {
    return null;
  }

  let actualColor = color;
  if (color.length > 7) {
    actualColor = actualColor.substring(0, actualColor.length - 2);
  }

  const op = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  let opacityHex = op.toString(16).toUpperCase();

  if (opacityHex.length === 1) {
    opacityHex = `0${opacityHex}`;
  }

  return actualColor + opacityHex;
};
