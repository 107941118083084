import { GeolocationPositionType, FindzDbType, InvitationType } from "common-library/lib/schema";
import { RefObject } from "react";
import { Location, NavigateFunction } from "react-router-dom";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { actionCreator, asyncActionCreator } from "../helper";
import { EOwner } from "common-library";

enum CommonActionTypes {
  "SET_SHOW_FULL_SCREEN_LOADER" = "SET_SHOW_FULL_SCREEN_LOADER",
  "SET_SHARE_APP_OPEN" = "SET_SHARE_APP_OPEN",
  "SET_SHARE_APP_DATA" = "SET_SHARE_APP_DATA",
  FETCH_LATEST_MRU_DATA = "FETCH_LATEST_MRU_DATA",
  SET_LOCATION_ARRAY = "COMMON/SET_LOCATION_ARRAY",
  FETCH_LATEST_DATA = "COMMON/FETCH",
  SEARCH_LOCATION = "COMMON/SEARCHLOCATION",
  IS_AUTHORIZED = "COMMON/IS_AUTHORIZED",
  CLEAR_REDUX_STORE = "COMMON/CLEAR_REDUX_STORE",
  SET_PUBLIC_KEY = "COMMON/SET_PUBLIC_KEY",
  "SET_USER_IS_ONLINE" = "COMMON/SET_USER_IS_OFFLINE",
  UPDATE_TIMESTAMP = "COMMON/UPDATE_TIMESTAMP",
  SET_APP_THEME = "COMMON/SET_APP_THEME",
  INITIALIZE_APP_DATA = "COMMON/INITIALIZE_APP_DATA",
  SET_NAVIGATION_RAIL_DRAWER_IS_OPEN = "COMMOM/UI/NAV_RAIL_DRAWER_STATE_IS_OPEN",
  OPEN_CREATE_FINDZ_MODAL = "COMMON/UI/OPEN_CREATE_FINDZ_MODAL",
  FETCH_STATIC_MAP_IMAGE_DATA = "COMMON/FETCH_STATIC_MAP_IMAGE_DATA",
  SET_STATIC_MAP_IMAGE_DATA = "COMMON/SET_STATIC_MAP_IMAGE_DATA",
  PROCESS_WEB_FIND_BEFORE_SUBMIT = "COMMON/PROCESS_WEB_FIND_BEFORE_SUBMIT",
  SET_GEO_LOCATION_POSITION = "COMMON/SET_GEO_LOCATION_POSITION",
  "SET_MRU_LIST" = "COMMON/SET_MRU_LIST",
  "UPDATE_MRU_LIST" = "COMMON/UPDATE_MRU_LIST",
  PROCESS_URL = "COMMON/PROCESS_URL",
  TOGGLE_SHOW_ADD_MEDIA_TO_FIND_MODAL = "COMMON/UI/TOGGLE_SHOW_ADD_MEDIA_TO_FIND_MODAL",
  TOGGLE_SEND_MESSAGE_MODAL = "COMMON/UI/TOGGLE_SEND_MESSAGE_MODAL",
  TOGGLE_ADD_REACTION_MODAL = "COMMON/UI/TOGGLE_ADD_REACTION_MODAL",
  TOGGLE_SHOW_ON_FINDS_MAP = "COMMON/UI/TOGGLE_SHOW_ON_FINDS_MAP",
  TOGGLE_FORWARD_FIND_MODAL = "COMMON/UI/TOGGLE_FORWARD_FIND_MODAL",
  UPDATE_THEME_OBJECT = "UPDATE_THEME_OBJECT",
  "TOGGLE_CREATE_GROUP_MODAL" = "COMMON/TOGGLE_CREATE_GROUP_MODAL",
  "TOGGLE_SHOW_INVITATION_MODAL" = "COMMON/TOGGLE_SHOW_INVITATION_MODAL",
  "TOGGLE_IS_MOBILE" = "COMMON/TOGGLE_IS_MOBILE",
  SET_BACKGROUND_NOTIFICATION_DATA = "SET/BACKGROUND_NOTIFICATION_DATA"
}

export const initializationAppData = asyncActionCreator<undefined, { isMobile: boolean; location: Location; navigate?: NavigateFunction }, undefined>(
  CommonActionTypes.INITIALIZE_APP_DATA
);

export const fetchLatestData = actionCreator(CommonActionTypes.FETCH_LATEST_DATA);

export const setIsAuthorized = actionCreator<{ isAuthorized: boolean }>(CommonActionTypes.IS_AUTHORIZED);

export const clearReduxStore = actionCreator(CommonActionTypes.CLEAR_REDUX_STORE);

export const setPublicKey = actionCreator<{ publicKey: string }>(CommonActionTypes.SET_PUBLIC_KEY);

export const updateTimestamp = actionCreator<{ collectionType: FirestoreCollectionEnum; timestamp: Date; groupId: string }>(
  CommonActionTypes.UPDATE_TIMESTAMP
);

export const setAppTheme = actionCreator<{ theme: string }>(CommonActionTypes.SET_APP_THEME);

export const setNavigationRailDrawerIsOpen = actionCreator<{ isOpen: boolean }>(CommonActionTypes.SET_NAVIGATION_RAIL_DRAWER_IS_OPEN);

export const setCreateFindzModalIsOpen = actionCreator<{ isOpen: boolean }>(CommonActionTypes.OPEN_CREATE_FINDZ_MODAL);

export const setLocationArray = actionCreator<{ searchResponse: [] }>(CommonActionTypes.SET_LOCATION_ARRAY);

export const searchLocation = actionCreator<{ searchString: string; calledFrom: RefObject<any> }>(CommonActionTypes.SEARCH_LOCATION);

export const getStaticMapImage = actionCreator<{ coords: { lat: number; long: number } }>(CommonActionTypes.FETCH_STATIC_MAP_IMAGE_DATA);

export const setStaticMapImageData = actionCreator<{ imgData: string }>(CommonActionTypes.SET_STATIC_MAP_IMAGE_DATA);

export const processWebFindBeforeSubmit = asyncActionCreator<
  undefined,
  { findDetails: Partial<FindzDbType>; imagesToDownload: Set<string>; defaultImage?: string }
>(CommonActionTypes.PROCESS_WEB_FIND_BEFORE_SUBMIT);

export const setGeoLocationPosition = actionCreator<{ geoLocationPosition: GeolocationPositionType }>(CommonActionTypes.SET_GEO_LOCATION_POSITION);

export const setMRUList = actionCreator<{ mruList: any }>(CommonActionTypes.SET_MRU_LIST);

export const updateMRUList = actionCreator<{ identifier: string; key: string; itemIdentifier: any }>(CommonActionTypes.UPDATE_MRU_LIST);

export const fetchLatestMruData = actionCreator(CommonActionTypes.FETCH_LATEST_MRU_DATA);
export const processURL = asyncActionCreator<undefined, { location: Location; navigate?: NavigateFunction }>(CommonActionTypes.PROCESS_URL);

export const toggleShowAddMediaToFindModal = actionCreator(CommonActionTypes.TOGGLE_SHOW_ADD_MEDIA_TO_FIND_MODAL);

export const toggleShowSendMessageModal = actionCreator<{ isOpen: boolean }>(CommonActionTypes.TOGGLE_SEND_MESSAGE_MODAL);

export const toggleAddReactionModal = actionCreator<{ isOpen: boolean }>(CommonActionTypes.TOGGLE_ADD_REACTION_MODAL);

export const showFullScreenLoader = actionCreator<{ showLoader: boolean }>(CommonActionTypes.SET_SHOW_FULL_SCREEN_LOADER);

export const shareAppOpen = actionCreator<{ value: boolean }>(CommonActionTypes.SET_SHARE_APP_OPEN);

export const shareAppData = actionCreator<{ value: any }>(CommonActionTypes.SET_SHARE_APP_DATA);

export const toggleShowOnFindsMap = actionCreator<{ data: { showModal: boolean; primaryFindId: string; isFullScreen: false } | undefined }>(
  CommonActionTypes.TOGGLE_SHOW_ON_FINDS_MAP
);
export const updateThemeObject = actionCreator<{ updated: string }>(CommonActionTypes.UPDATE_THEME_OBJECT);

export const toggleForwardFindModal = actionCreator<{
  data: { selectedFindIds: string[]; showModal: boolean; forwardTo?: { name: string; identifier: string; type: EOwner } } | undefined;
}>(CommonActionTypes.TOGGLE_FORWARD_FIND_MODAL);

export const toggleCreateGroupModal = actionCreator<{ isOpen: boolean }>(CommonActionTypes.TOGGLE_CREATE_GROUP_MODAL);

export const toggleInvitationModal = actionCreator<{ data: { showModal: boolean; invite: InvitationType } | undefined }>(
  CommonActionTypes.TOGGLE_SHOW_INVITATION_MODAL
);

export const setUserIsOnline = actionCreator<{ value: boolean }>(CommonActionTypes.SET_USER_IS_ONLINE);

export const toggleIsMobile = actionCreator<{ isMobile: boolean }>(CommonActionTypes.TOGGLE_IS_MOBILE);

export const setBackgrounNotificationData = actionCreator<{ data?: any }>(CommonActionTypes.SET_BACKGROUND_NOTIFICATION_DATA);
