import { arrayRemove, arrayUnion } from "firebase/firestore";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { batchUtil } from "./common/batch";

import { FindzType, TagDbType, TagType } from "common-library/lib/schema";
import isEmpty from "lodash/isEmpty";
import { firestoreDocRef } from "../utils/firestoreUtils";
import { IActiveContext } from "../types/types";
import { UserType } from "common-library/lib/schema";
import { concat, findIndex, split } from "lodash";
import { getAllDescendantsForTag, removeRedundantParentOrChild } from "../utils/tagsUtils";

export async function updateTag(
  collectionId: string,
  dataToUpdate: Partial<TagType | any>,
  dataToRemove?: Partial<TagType | any>,
  writeBatch?: ReturnType<typeof batchUtil>
) {
  try {
    let batch = writeBatch ? writeBatch : batchUtil();
    const collectionRef = firestoreDocRef(FirestoreCollectionEnum.Tags, collectionId);

    if (dataToRemove) {
      if (dataToRemove.finds) {
        const partialDataToUpdate: any = {};
        partialDataToUpdate["finds"] = arrayRemove(...dataToRemove.finds);
        batch.update(collectionRef, partialDataToUpdate);
      }
    }

    if (dataToUpdate?.finds?.length) {
      const partialDataToUpdate: any = {};
      partialDataToUpdate["finds"] = arrayUnion(...dataToUpdate?.finds);
      batch.update(collectionRef, partialDataToUpdate);
    }
    if (dataToUpdate?.child) {
      const partialDataToUpdate: any = {};
      partialDataToUpdate["children"] = arrayUnion(dataToUpdate.child);
      batch.update(collectionRef, partialDataToUpdate);
    }
    if (writeBatch) {
      return batch;
    }
    await batch.commit();
    return;
  } catch (error) {
    throw error;
  }
}

export async function createTags(tags: TagType[]) {
  const batch = batchUtil();
  const updatedTags: TagType[] = [];
  for (const tag of tags) {
    if (tag.identifier) {
      updatedTags.push(tag);
      const newCollectionRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tag.identifier);
      batch.set(newCollectionRef, tag);
    } else {
      const newCollectionRef = firestoreDocRef(FirestoreCollectionEnum.Tags);
      const newCollectionId = newCollectionRef.id;

      if (!newCollectionId) {
        throw new Error("couldn't generate tag id");
      }
      const obj = {
        ...tag,
        identifier: newCollectionId
      };
      updatedTags.push(obj);
      batch.set(newCollectionRef, obj);
    }
  }

  await batch.commit();
  return Promise.resolve(updatedTags);
}

export async function updateTags(tags: any[]) {
  const batch = batchUtil();
  // const updatedTags: TagType[] = [];
  for (const tag of tags) {
    const newCollectionRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tag.identifier);
    const obj = {
      children: arrayUnion(tag.child)
    };

    batch.update(newCollectionRef, obj);
  }
  await batch.commit();
  return Promise.resolve(tags);
}

export async function addTag(newCollectionData: TagType) {
  //TODO: Add Nesting and Other Features on top of it
  const newCollectionRef = firestoreDocRef(FirestoreCollectionEnum.Tags);
  const newCollectionId = newCollectionRef.id;

  if (!newCollectionId) {
    throw new Error("couldn't generate tag id");
  }

  const batch = batchUtil();
  batch.set(newCollectionRef, {
    ...newCollectionData,
    identifier: newCollectionId
  });
  await batch.commit();
  return newCollectionData;
}

export async function addToFavorite(tagIds: string[], activeContext: IActiveContext, currentUser: UserType) {
  const batch = batchUtil();
  const userRef = firestoreDocRef(FirestoreCollectionEnum.Users, currentUser.identifier);
  for (const tagId of tagIds) {
    const obj = {} as any;
    obj[`favorites.${activeContext.identifier}.tags`] = arrayUnion(tagId);
    batch.update(userRef, obj);
  }
  await batch.commit();
  return Promise.resolve(true);
}

export async function removeFromFavorite(tagId: string, activeContext: IActiveContext, currentUser: UserType) {
  const batch = batchUtil();
  const userRef = firestoreDocRef(FirestoreCollectionEnum.Users, currentUser.identifier);

  const obj = {} as any;
  obj[`favorites.${activeContext.identifier}.tags`] = arrayRemove(tagId);
  batch.update(userRef, obj);
  await batch.commit();
  return Promise.resolve(true);
}

export const moveTagToAnotherTag = async (tagsMovedIds: string[], tagMoveOnto: string, tagsMap: Map<string, TagType>) => {
  const tagMovedOntoData = tagsMap.get(tagMoveOnto);
  const batch = batchUtil();
  const tagMovedOntoRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tagMoveOnto); // get tagMovedOnto Ref
  if (tagMovedOntoData) {
    tagsMovedIds.forEach(tagMovedId => {
      const tagMovedData = tagsMap.get(tagMovedId);
      const tagMovedRef = firestoreDocRef(FirestoreCollectionEnum.Tags, tagMovedId); // get TagMovedData ref
      if (tagMovedData) {
        const tagMovedOntoAncestors = tagMovedOntoData.ancestors;
        if (tagMovedData.ancestors) {
          tagMovedData.ancestors.forEach(ancestorId => {
            const ancestorData = tagsMap.get(ancestorId);
            if (ancestorData) {
              const ancestorRef = firestoreDocRef(FirestoreCollectionEnum.Tags, ancestorId);
              const ancestorChild = arrayRemove(tagMovedId);
              batch.update(ancestorRef, { children: ancestorChild });
            }
          });
        }
        batch.update(tagMovedOntoRef, { children: arrayUnion(tagMovedId) });
        const tagMovedDataAncestors = [...tagMovedOntoAncestors, tagMoveOnto];

        const updatedTagMovedObj: Partial<TagDbType> = {
          ancestors: tagMovedDataAncestors
        };
        if (tagMovedData.ancestors.length === 0 && tagMovedData.isRootTag) {
          updatedTagMovedObj["isRootTag"] = false;
        }
        batch.update(tagMovedRef, updatedTagMovedObj);
        if (tagMovedData.children) {
          updateChildrenTagsAncestorData(tagMovedData.children, [...tagMovedDataAncestors, tagMovedId], tagsMap, batch); //,pass batch
        }
      }
    });
  }
  await batch.commit();
  return;
};

async function updateChildrenTagsAncestorData(childTagsIds: string[], ancestorsIDs: string[], tagsMap: Map<string, TagType>, batch: any) {
  childTagsIds.forEach(childTagId => {
    const childTagData = tagsMap.get(childTagId);
    if (childTagData) {
      batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, childTagId), { ancestors: ancestorsIDs });
      if (!isEmpty(childTagData.children) && childTagData.children) {
        updateChildrenTagsAncestorData(childTagData.children, [...ancestorsIDs, childTagData.identifier], tagsMap, batch);
      }
    }
  });
  return;
}

export const createCollectionsFromPath = async (
  tags: { path: string; color: string; parentTag?: TagType }[],
  existingTagsList: TagType[],
  currentUser: UserType,
  context: IActiveContext
) => {
  const newTagsList: TagType[] = [];
  for (const singleTag of tags) {
    let lastActiveTagIndex: number = 0;
    let lastActiveTag: TagType | undefined = undefined;
    let path: string = "";
    const tagArray = split(singleTag.path, "/");
    let i = 0;

    while (i < tagArray.length) {
      const tag = tagArray[i];
      let result;
      path = path ? `${path} / ${tag.trim()}` : tag.trim();
      if (existingTagsList.length > 0) {
        lastActiveTagIndex = findIndex(existingTagsList, item => path.toLowerCase() === item.path.toLowerCase());
      } else {
        lastActiveTagIndex = -1;
      }
      if (lastActiveTagIndex >= 0) {
        lastActiveTag = existingTagsList[lastActiveTagIndex];
      } else if (i === 0 && singleTag.parentTag) {
        lastActiveTag = singleTag.parentTag;
      } else {
        const tagName = tag.trim();
        if (tagName.length) {
          result = await createNewTag({ name: tagName, color: singleTag.color || "" }, context, currentUser, lastActiveTag);
          lastActiveTag = result.newTagData;
          newTagsList.push(result.newTagData);
        }
      }
      i += 1;
    }
  }
  return newTagsList;
};

export const createNewTag = async (
  tagData: { name: string; color: string },
  context: IActiveContext,
  currentUser: UserType,
  activeTagData?: TagType
) => {
  const newTagId = firestoreDocRef(FirestoreCollectionEnum.Tags).id;
  const updateTime = new Date();

  if (!newTagId) {
    throw new Error("couldn't generate tag id");
  }

  let path = "";

  let newTagData = {
    owner: {
      type: context.type,
      identifier: context.identifier,
      name: context.name
    },

    identifier: newTagId,
    name: tagData.name,
    color: tagData.color,
    finds: [],
    children: [],
    ancestors: [] as string[],
    isRootTag: false
  };

  if (activeTagData) {
    path = `${activeTagData.path} / ${tagData.name}`;
    newTagData = {
      ...newTagData,
      ancestors: concat(activeTagData.ancestors, [activeTagData.identifier]),
      isRootTag: false
    };
  } else {
    path = tagData.name;
    newTagData = {
      ...newTagData,
      ancestors: [],
      isRootTag: true
    };
  }

  const batch = batchUtil();

  const newTagObj = {
    ...newTagData,
    created: {
      on: updateTime,
      by: {
        identifier: currentUser.identifier,
        name: currentUser.name as string
      }
    },
    _documentUpdatedOn: updateTime
  };

  batch.set(firestoreDocRef(FirestoreCollectionEnum.Tags, newTagId), newTagObj);

  const newTagDataWithPath: TagType = { ...newTagObj, path };

  if (activeTagData) {
    batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, activeTagData.identifier), {
      children: arrayUnion(newTagId)
    });
  }

  await batch.commit();
  return Promise.resolve({ newTagData: newTagDataWithPath });
};

export const updateIdsForTagsAndFinds = async (finds: string[], removedTags: string[] = [], addedTags: string[] = []) => {
  let batch = batchUtil();
  finds.forEach(findzUid => {
    if (removedTags.length) {
      for (let i = 0; i < removedTags.length; i++) {
        batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, removedTags[i]), {
          finds: arrayRemove(findzUid)
        });
      }
    }
    batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findzUid), {
      tags: arrayRemove(...removedTags)
    });
    if (addedTags.length) {
      for (let i = 0; i < addedTags.length; i++) {
        batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, addedTags[i]), {
          finds: arrayUnion(findzUid)
        });
      }
    }
    batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findzUid), {
      tags: arrayUnion(...addedTags)
    });
  });

  await batch.commit();
  return Promise.resolve(true);
};

export const updateTagsForFind = async (tagsList: TagType[], newTagPath: string, currentFind: FindzType, tagsMap: Map<string, TagType>) => {
  const newTag = newTagPath.toLowerCase().replace(/\s*\/\s*/g, "/");
  const newTagIndex = findIndex(tagsList, tag => tag && tag.path.toLowerCase().replace(/\s*\/\s*/g, "/") === newTag);

  if (newTagIndex >= 0) {
    const tagToAdd = tagsList[newTagIndex];
    const removedTags = removeRedundantParentOrChild(currentFind, tagsMap, tagToAdd);
    await updateIdsForTagsAndFinds([currentFind.identifier], removedTags, [tagToAdd.identifier]);
  }

  return Promise.resolve(true);
};

export const editTag = async (tagId: string, newTagName: string) => {
  const batch = batchUtil();

  batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, tagId), {
    name: newTagName
  });

  await batch.commit();
  return Promise.resolve(true);
};

export const deleteTag = async (tagData: TagType, tagsMap: Map<string, TagType>, findzMap: Map<string, FindzType>) => {
  const batch = batchUtil();

  const tagsToBeDeleted = getAllDescendantsForTag(tagData, tagsMap, []);
  tagsToBeDeleted.push(tagData);

  for (const ancestorId of tagData.ancestors) {
    batch.update(firestoreDocRef(FirestoreCollectionEnum.Tags, ancestorId), {
      children: arrayRemove(tagData.identifier)
    });
  }

  for (const tagToDelete of tagsToBeDeleted) {
    if (tagToDelete.finds?.length) {
      for (const findId of tagData.finds) {
        if (findzMap.has(findId)) {
          batch.update(firestoreDocRef(FirestoreCollectionEnum.Findz, findId), {
            tags: arrayRemove(tagToDelete.identifier)
          });
        }
      }
    }
    batch.delete(firestoreDocRef(FirestoreCollectionEnum.Tags, tagToDelete.identifier));
  }

  await batch.commit();
  return Promise.resolve(true);
};
