import { firebaseFunctions } from "../../config/firebaseApp";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { FirebaseFunctions } from "common-library/lib/constants";

const reverseCoords = async (lat: number, lon: number): Promise<HttpsCallableResult> => {
  const operation = httpsCallable(firebaseFunctions, FirebaseFunctions.ReverseCoords);
  const response = await operation({ lat, lon });
  return response;
};

const searchLocation = async (query: string): Promise<HttpsCallableResult> => {
  const locationOperation = httpsCallable(firebaseFunctions, FirebaseFunctions.SearchLocation);
  const response = await locationOperation(query);
  return response;
};

const autoComplete = async (query: string): Promise<HttpsCallableResult> => {
  const locationOperation = httpsCallable(firebaseFunctions, FirebaseFunctions.AutoCompleteLocation);
  const response = await locationOperation(query);

  return response;
};

const getStaticMap = async (lat: number | undefined, lon: number | undefined) => {
  const operation = httpsCallable(firebaseFunctions, FirebaseFunctions.GetStaticMap);
  const response = await operation({ lat, lon });

  return response;
};

const locationServices = {
  reverseCoords,
  searchLocation,
  autoComplete,
  getStaticMap
};

export default locationServices;
