import { useEffect, useRef, useState } from "react";
import type { Swiper } from "swiper";

function useSwiper(id?: string) {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiper = useRef<Swiper | null>(null);
  useEffect(() => {
    let swiperEl: any;
    if (id) {
      swiperEl = document.getElementById(id);
    } else {
      swiperEl = document.querySelector("swiper-container");
    }
    if (swiperEl) {
      const _swiper = (swiperEl as any)?.swiper;
      if (_swiper) {
        setActiveIndex(_swiper.activeIndex);
        swiper.current = _swiper;
        swiperEl.addEventListener("swiper-slidechange", (event: any) => {
          const [_swiper] = event.detail;
          setActiveIndex(_swiper.activeIndex);
        });
      }
    }
    return () => {
      swiper.current = null;
    };
  });

  return { activeIndex, swiper: swiper?.current, setActiveIndex };
}

export default useSwiper;
