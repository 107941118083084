export enum FindzContainerSearchParams {
  TAG = "tag",
  SEARCH = "search",
  SELECTED = "selected",
  GROUP = "group",
  SELECTED_VIEW = "selected-view"
}

export enum FindDetailSearchParams {
  FIND = "findId"
}

export enum DragElementIds {
  TagFromRightToolPanel = "TagFromRightToolPanel"
}

export enum DropAcceptTypes {
  Findz_Card = "Findz_Card"
}

export enum ChatContainerSearchParams {
  TAG = "tag",
  FIND = "find",
  SEARCH = "search"
}
