import { call, put, select, takeLatest, fork, take, takeEvery } from "@redux-saga/core/effects";
import {
  loginThroughMedium,
  generateFirebaseCustomToken,
  encryptCustomToken,
  resetPassword,
  createAccount,
  sendOTP,
  signOutUser,
  closeAccountServerFunction,
  verifyPhoneNumber,
  getAuthRedirectResult,
  saveUserToDatabase,
  listenToCurrentUser,
  getSignInMethodsForEmail,
  clearListeners,
  subscribeOrUnsubscribeTopics,
  updateUserById,
  sendVerificationEmail
} from "../../services/auth.service";
import { CommonActions, AuthActions, GroupActions } from "../actions";
import { FuncNotificationSubscriptionParams, GroupType, LoginMedium, UserType } from "common-library/lib/schema";
import { appTexts } from "../../constants/appTexts";
import { EventChannel } from "redux-saga";
import { ActionType } from "typesafe-actions";
import { firebaseAuth } from "../../../config/firebaseApp";
import { EOwner } from "../../types/appEnum";
import { getActiveContext, getCurrentUser, getGroupList, getGroupsMap } from "../selectors";
import { IActiveContext } from "../../types/types";
import isEmpty from "lodash/isEmpty";
import { UserCredential, getAdditionalUserInfo, getAuth } from "firebase/auth";
import { FirebaseAuthErrorCodes, FirebaseProviderId } from "shared-web/lib/constants/firebaseEnums";
import { clearIndexedDbAndCaches, transformFSDatesToJSDates } from "../../services";
import { getCloudMessagingToken } from "../../services/cloudMessaging.services";
import { NotificationSubscriptionAction } from "common-library/lib/constants";
import { shouldSignoutWhenVersionChange } from "../../utils/commonUtils";

export function* validateUserSaga(): Generator<unknown, void, any> {
  try {
    yield put(AuthActions.validateUser.start());
    const redirectResult: UserCredential | null = yield call(getAuthRedirectResult);
    const user = getAuth().currentUser;
    const info = redirectResult ? getAdditionalUserInfo(redirectResult) : null;
    let currentUser = null;
    if (user && redirectResult && info?.isNewUser) {
      // below actions will only dispatch for gmail and apple login for new user
      const loginMedium = redirectResult.providerId === FirebaseProviderId.GOOGLE ? LoginMedium.Google : LoginMedium.Apple;
      const user = redirectResult.user;
      const photo = user?.photoURL || "";
      currentUser = yield call(saveUserToDatabase, user, photo, loginMedium, true);
    } else if (user) {
      const providerId = user.providerData[0].providerId;
      const loginMedium =
        providerId === FirebaseProviderId.GOOGLE
          ? LoginMedium.Google
          : providerId === FirebaseProviderId.PASSWORD
          ? LoginMedium.Email
          : LoginMedium.Apple;
      currentUser = yield call(saveUserToDatabase, user, user?.photoURL || "", loginMedium, false);
    }
    if (currentUser) {
      yield put(AuthActions.setCurrentUser(currentUser));
      if (shouldSignoutWhenVersionChange()) {
        yield put(AuthActions.signOut.saga({ force: true }));
      } else {
        yield put(CommonActions.setIsAuthorized({ isAuthorized: true }));
        yield put(AuthActions.subscribeToPushNotification.saga({ type: NotificationSubscriptionAction.signIn, userId: currentUser?.identifier }));
      }
    }
    yield put(AuthActions.validateUser.success());
  } catch (error: any) {
    console.error("validateUserSaga", error);
    AuthActions.validateUser.error(error);
    //TODO - handle error
  }
}

export function* loginWithMediumSaga(action: any): Generator<unknown, void, any> {
  const { loginMedium, credentials } = action.payload;
  const isEmailLogin: boolean = loginMedium === LoginMedium.Email;
  try {
    if (isEmailLogin) {
      yield put(AuthActions.loginWithMedium.start());
    } else {
      yield put(CommonActions.showFullScreenLoader({ showLoader: true }));
    }
    const user: any = yield call(loginThroughMedium, loginMedium, credentials);
    if (user) {
      // below actions will only dispatch for email login
      yield put(AuthActions.loginWithMedium.success());
      yield put(CommonActions.showFullScreenLoader({ showLoader: false }));
      yield put(AuthActions.subscribeToPushNotification.saga({ type: NotificationSubscriptionAction.signIn, userId: user?.identifier }));
    }
  } catch (error: any) {
    if (error.code === FirebaseAuthErrorCodes.WRONG_PASSWORD) {
      const signInMethods: string[] = yield call(getSignInMethodsForEmail, credentials.email);
      if (signInMethods && signInMethods.length) {
        yield put(
          AuthActions.signInMethodsForEmail({
            email: credentials.email,
            methods: signInMethods
          })
        );
      }
    }
    if (isEmailLogin) {
      yield put(AuthActions.loginWithMedium.error(error));
    } else {
      yield put(AuthActions.loginWithMedium.error(error));
    }
    yield put(CommonActions.showFullScreenLoader({ showLoader: false }));

    console.error("loginWithMediumSaga", error);
  }
}

export function* listenToCurrentUserSaga(action: any) {
  try {
    const authCurrentUser = firebaseAuth.currentUser;

    if (authCurrentUser) {
      const currentUser: EventChannel<UserType> = yield call(listenToCurrentUser, authCurrentUser.uid);
      while (true) {
        const currentUserLatest: UserType = yield take(currentUser);

        if (currentUserLatest._documentDeletedOn) {
          yield put(AuthActions.signOut.saga({ force: true }));
        } else {
          const activeContext: IActiveContext = yield select(getActiveContext);
          const transformedCurrentUser = transformFSDatesToJSDates(currentUserLatest);
          if (!isEmpty(activeContext)) {
            if (activeContext.type === EOwner.User && transformedCurrentUser.name !== activeContext.name) {
              const newActiveContext = {
                ...activeContext,
                name: transformedCurrentUser.name || ""
              };

              yield put(GroupActions.setActiveContext({ context: newActiveContext }));
            }
          }
          yield put(AuthActions.setCurrentUser(transformedCurrentUser));
        }

        // logDocsReadEvent("currentUser", 1);
        // logTotalDocsCountEvent("currentUser", 1);
      }
    } else {
      yield put(AuthActions.signOut.saga({ force: true }));
    }
  } catch (error: any) {
    console.error("listenToCurrentUserSaga", error);
    if (error.code === "firestore/permission-denied" || error.code === "[firestore/permission-denied]") {
      yield put(AuthActions.signOut.saga({ force: true }));
    }
  }
}

export function* sendCustomTokenSaga(action: any) {
  try {
    yield put(AuthActions.sendCustomToken.start());
    const { user, publicKey } = action.payload;
    if (user && publicKey) {
      const token: { customToken: string } = yield call(generateFirebaseCustomToken, user.identifier);
      const encryptedCustomToken: string = yield call(encryptCustomToken, publicKey, token.customToken);

      window.postMessage(
        {
          type: "AuthStateListner",
          token: encryptedCustomToken
        },
        "*"
      );
      yield put(AuthActions.sendCustomToken.success());
    }
  } catch (error: any) {
    console.error("sendCustomTokenSaga", error);
    yield put(AuthActions.sendCustomToken.error(error));
  }
}

export function* resetPasswordSaga(action: any) {
  try {
    const { email } = action.payload;
    yield put(AuthActions.resetPassword.start());
    const userParam = {
      name: "",
      email,
      identifier: ""
    };
    yield call(resetPassword, userParam);
    yield put(AuthActions.resetPassword.success());
  } catch (error: any) {
    console.error("resetPasswordSaga", error);
    yield put(AuthActions.resetPassword.error(error));
  }
}

export function* createAccountSaga(action: any): Generator<unknown, void, any> {
  try {
    yield put(AuthActions.createAccount.start());
    const { savedUser }: { savedUser: UserType } = yield call(createAccount, { accountData: action.payload });
    if (savedUser) {
      yield put(AuthActions.createAccount.success());
    }
  } catch (error: any) {
    console.error("createAccountSaga", error);
    if (error.code === FirebaseAuthErrorCodes.EMAIL_ALREADY_IN_USE) {
      const signInMethods: string[] = yield call(getSignInMethodsForEmail, action.payload.email);
      if (signInMethods && signInMethods.length) {
        yield put(
          AuthActions.signInMethodsForEmail({
            email: action.payload.email,
            methods: signInMethods
          })
        );
      }
    }

    yield put(AuthActions.createAccount.error(error));
  }
}

export function* sendOTPSaga(action: any) {
  try {
    yield put(AuthActions.sendOtp.start());
    const otpResponse: Record<string, any> = yield call(sendOTP, action.payload.phone);
    if (otpResponse?.data && otpResponse.data.data) {
      yield put(AuthActions.sendOtp.success());
      // yield put(AuthActions.changePhoneNumberSuccess(action.phone));
    } else {
      throw new Error(otpResponse?.data.error || "Something went wrong!");
    }
  } catch (error: any) {
    console.error("sendOTPSaga", error);
    yield put(AuthActions.sendOtp.error(error));
  }
}

export function* signOutSaga(action: ActionType<typeof AuthActions.signOut.saga>) {
  try {
    yield put(AuthActions.signOut.start());
    const currentUser: UserType = yield select(getCurrentUser);
    const token: string | undefined = yield call(getCloudMessagingToken);
    const params = { userId: currentUser.identifier, fcmToken: token || "", type: NotificationSubscriptionAction.signOut };
    yield call(subscribeOrUnsubscribeTopics, params);
    // unsubscribeChannelListeners();
    yield put(CommonActions.setIsAuthorized({ isAuthorized: false }));
    yield call(clearListeners);
    yield call(clearIndexedDbAndCaches);
    yield call(signOutUser);

    yield put(AuthActions.signOut.success());
    yield put(CommonActions.clearReduxStore());
  } catch (error) {
    console.error("signOutSaga", error);
    const e = new Error("Something went wrong. Please try signing out again.") as any;
    yield put(AuthActions.signOut.error(e));
  }
}

export function* closeAccountSaga(action: any) {
  try {
    const { userId } = action.payload;
    yield put(AuthActions.closeAccount.start());
    const currentUser: UserType = yield select(getCurrentUser);
    const token: string | undefined = yield call(getCloudMessagingToken);
    const params = { userId: currentUser.identifier, fcmToken: token || "", type: NotificationSubscriptionAction.signOut };
    yield call(subscribeOrUnsubscribeTopics, params);
    yield call(closeAccountServerFunction, userId);
    yield call(signOutUser);
    yield put(AuthActions.closeAccount.success());
    yield put(CommonActions.clearReduxStore());
    yield put(CommonActions.setIsAuthorized({ isAuthorized: false }));
  } catch (error: any) {
    console.error("closeAccountSaga", error);
    yield put(AuthActions.closeAccount.error(error));
  }
}

export function* verifyOTPSaga(action: any) {
  try {
    yield put(AuthActions.verifyOtp.start());
    const otpResponse: Record<string, any> = yield call(verifyPhoneNumber, action.payload.phone, action.payload.currentUser);
    if (otpResponse.data && otpResponse.data.data) {
      yield put(AuthActions.verifyOtp.success());
      if (action.payload.publicKey) {
        const token: Record<string, any> = yield call(generateFirebaseCustomToken, action.payload.currentUser.identifier);
        const encryptedCustomToken: string = yield call(encryptCustomToken, action.payload.publicKey, token.customToken);

        window.postMessage(
          {
            type: "AuthStateListner",
            token: encryptedCustomToken
          },
          "*"
        );
      }
    } else if (otpResponse.data && otpResponse.data.error) {
      throw new Error(otpResponse.data.error);
    } else {
      throw new Error(appTexts.somethingWentWrong);
    }
  } catch (error: any) {
    console.error("verifyOTPSaga", error);
    yield put(AuthActions.verifyOtp.error(error));
  }
}

export function* resendOTPSaga(action: ActionType<typeof AuthActions.resendOtp.saga>) {
  try {
    yield put(AuthActions.resendOtp.start());
    const otpResponse: Record<string, any> = yield call(sendOTP, action.payload.phone);

    if (otpResponse.data && otpResponse.data?.data) {
      yield put(AuthActions.resendOtp.success());
    } else {
      throw new Error(otpResponse?.data?.error?.message || appTexts.somethingWentWrong);
    }
  } catch (error: any) {
    console.error("resendOTPSaga", error);
    yield put(AuthActions.resendOtp.error(error));
  }
}

export function* subscribeToPushNotificationSaga(action: ActionType<typeof AuthActions.subscribeToPushNotification.saga>) {
  try {
    yield put(AuthActions.subscribeToPushNotification.start());
    const { type, groupId, notificationType } = action.payload as any;
    const fcmToken: string = yield call(getCloudMessagingToken);
    const currentUser: UserType = yield select(getCurrentUser);
    if (fcmToken && !currentUser?.fcmTokens?.includes(fcmToken)) {
      const params: any = { userId: currentUser?.identifier, fcmToken, type: NotificationSubscriptionAction.signIn };
      if (type) {
        params.type = type;
      }
      if (groupId) {
        params.groupId = groupId;
      }
      if (notificationType) {
        params.notificationType = notificationType;
      }
      yield call(subscribeOrUnsubscribeTopics, params);
    }
    yield put(AuthActions.subscribeToPushNotification.success());
  } catch (error: any) {
    console.error("subscribeToPushNotificationSaga", error);
    yield put(AuthActions.subscribeToPushNotification.error(error));
  }
}

export function* updateUserSaga(action: ActionType<typeof AuthActions.updateUser.saga>) {
  try {
    const { user } = action.payload;
    yield put(AuthActions.updateUser.start());
    const currentUser: UserType = yield select(getCurrentUser);
    const groupsList: GroupType[] = yield select(getGroupList);
    if (currentUser) {
      yield call(updateUserById, currentUser.identifier, user as any, currentUser, groupsList);
    }
    yield put(AuthActions.updateUser.success());
  } catch (error: any) {
    console.error("updateUserSaga", error);
    yield put(AuthActions.updateUser.error(error));
  }
}

export function* setNotificationSubscriptionSaga(action: ActionType<typeof AuthActions.setNotificationSubscription.saga>) {
  const { type, notificationType, groupId } = action.payload;
  yield put(AuthActions.setNotificationSubscription.start());
  const currentUser: UserType = yield select(getCurrentUser);
  const params: FuncNotificationSubscriptionParams = { userId: currentUser.identifier, type };
  if (notificationType) {
    params.notificationType = notificationType;
  }
  if (groupId) {
    params.groupId = groupId;
  }

  yield call(subscribeOrUnsubscribeTopics, params);
  yield put(AuthActions.setNotificationSubscription.success());
}

export function* resendVerificationMailSaga(action: ActionType<typeof AuthActions.resendVerificationMail.saga>) {
  try {
    const { user } = action.payload;
    yield put(AuthActions.resendVerificationMail.start());
    const result: unknown = yield call(sendVerificationEmail, user);
    if ((result as any).data.data) {
      yield put(AuthActions.resendVerificationMail.success());
    }
    if ((result as any).data.error) {
      yield put(AuthActions.resendVerificationMail.error((result as any).data.error));
    }
  } catch (error: any) {
    yield put(AuthActions.resendVerificationMail.error(error));
    console.error("resendVerificationMailSaga", error);
  }
}

function* validateUserListener() {
  yield takeEvery(AuthActions.validateUser.saga.toString(), validateUserSaga);
}

function* loginWithMediumListener() {
  yield takeLatest(AuthActions.loginWithMedium.saga.toString(), loginWithMediumSaga);
}

function* signOutListener() {
  yield takeLatest(AuthActions.signOut.saga.toString(), signOutSaga);
}

function* resetPasswordListener() {
  yield takeLatest(AuthActions.resetPassword.saga.toString(), resetPasswordSaga);
}

function* createAccountListener() {
  yield takeLatest(AuthActions.createAccount.saga.toString(), createAccountSaga);
}

function* sendOTPListener() {
  yield takeLatest(AuthActions.sendOtp.saga.toString(), sendOTPSaga);
}

function* closeAccountListener() {
  yield takeLatest(AuthActions.closeAccount.saga.toString(), closeAccountSaga);
}

function* verifyOTPListener() {
  yield takeLatest(AuthActions.verifyOtp.saga.toString(), verifyOTPSaga);
}

function* resendOTPListener() {
  yield takeLatest(AuthActions.resendOtp.saga.toString(), resendOTPSaga);
}

function* subscribeToPushNotificationListener() {
  yield takeLatest(AuthActions.subscribeToPushNotification.saga.toString(), subscribeToPushNotificationSaga);
}

function* sendCustomTokenListener() {
  yield takeLatest(AuthActions.sendCustomToken.saga.toString(), sendCustomTokenSaga);
}

function* listenToCurrentUserSagaListener() {
  yield takeLatest(AuthActions.listenToCurrentUser.toString(), listenToCurrentUserSaga);
}

function* updateUserListener() {
  yield takeLatest(AuthActions.updateUser.saga.toString(), updateUserSaga);
}

function* setNotificationSubscriptionListener() {
  yield takeLatest(AuthActions.setNotificationSubscription.saga.toString(), setNotificationSubscriptionSaga);
}

function* resendVerificationMailListener() {
  yield takeLatest(AuthActions.resendVerificationMail.saga.toString(), resendVerificationMailSaga);
}

export default function* authSaga() {
  yield fork(validateUserListener);
  yield fork(loginWithMediumListener);
  yield fork(resetPasswordListener);
  yield fork(createAccountListener);
  yield fork(sendOTPListener);
  yield fork(closeAccountListener);
  yield fork(verifyOTPListener);
  yield fork(resendOTPListener);
  yield fork(sendCustomTokenListener);
  yield fork(listenToCurrentUserSagaListener);
  yield fork(signOutListener);
  yield fork(subscribeToPushNotificationListener);
  yield fork(updateUserListener);
  yield fork(setNotificationSubscriptionListener);
  yield fork(resendVerificationMailListener);
}
