import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { AppState } from "./redux/reducers/root.reducer";
import { useAppSelector } from "./redux/hooks";
import { NavigationPath } from "./constants/router.names";

export function GuardRoute(props: any) {
  const location = useLocation();
  const { children } = props;
  const isAuthorized = useAppSelector((state: AppState) => state.Common.isAuthorized);

  if (!isAuthorized) {
    return <Navigate to={NavigationPath.AuthContainer} state={location} />;
  }

  return children ? children : <Outlet />;
}

export function AuthGuard(props: any) {
  const isAuthorized = useAppSelector((state: AppState) => state.Common.isAuthorized);
  const { children } = props;

  if (isAuthorized) {
    return <Navigate to={NavigationPath.HomeContainer} replace />;
  }

  return children ? children : <Outlet />;
}
