import { CommonActions, GroupActions, TagActions } from "../actions";
import { createReducer } from "@reduxjs/toolkit";
import { TagType } from "common-library/lib/schema";
import { asyncCaseCreator, InitialAsyncState } from "../helper";
import { AsyncState } from "../../types/types";

interface ITagState {
  tagsMap: Map<string, TagType>;
  createTag: InitialAsyncState<{ identifier?: string[]; context?: "CREATE_FIND" | "FORWARD_FIND" }>;
  editTag: InitialAsyncState;
  deleteTag: InitialAsyncState;
  multiUpdateTag: InitialAsyncState;
}

const defaultTagState: ITagState = {
  tagsMap: new Map(),
  createTag: { status: AsyncState.idle },
  editTag: { status: AsyncState.idle },
  deleteTag: { status: AsyncState.idle },
  multiUpdateTag: { status: AsyncState.idle }
};

export const TagLabel = "Tag";

export const tagReducer = createReducer(defaultTagState, builder => {
  // async
  asyncCaseCreator(TagActions.addTag, "createTag", builder);
  asyncCaseCreator(TagActions.editTag, "editTag", builder);
  asyncCaseCreator(TagActions.deleteTag, "deleteTag", builder);
  asyncCaseCreator(TagActions.multiUpdateTag, "multiUpdateTag", builder);

  //sync
  builder
    .addCase(TagActions.setTagsMap, (state, action) => {
      state.tagsMap = action.payload.tagsMap;
    })
    .addCase(CommonActions.clearReduxStore, (state, action) => {
      return defaultTagState;
    })
    .addCase(GroupActions.setActiveContext, (state, action) => {
      state.tagsMap = new Map();
    });
});
