export var stringToHslColor = function (str, s, l) {
    if (s === void 0) { s = "50"; }
    if (l === void 0) { l = "70"; }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
};
