import {
  IScreenWalkthroughRemoteConfig,
  IUserAction,
  IUserActionEventConfig,
  IUserActionRemoteConfig,
  IUserActionRemoteConfigData,
  IUserAnalytics,
  IWalkthroughData,
} from "../schema";
import { isEmpty, sortBy } from "lodash";
import { userActionEvents, userActionEventsKey } from "../constants";
import { replaceChar } from "./commonUtils";

export const getRuleIdAndWalkthroughData = (
  screen: string,
  walkthroughRemoteConfig: IScreenWalkthroughRemoteConfig,
  userActionAnalytics: IUserAnalytics,
  indexUserActionToConfig: Map<string, userActionEventsKey>,
  platform: string
) => {
  const screenWalkthroughRemoteConfig = walkthroughRemoteConfig?.[screen];
  const userActionsData = userActionAnalytics?.platform?.[platform]?.actions;

  let ruleId = undefined;
  let walkthroughData: IWalkthroughData[] = [];
  let userActionToLog: IUserActionEventConfig | undefined = undefined;
  let analyticsEventToLog: string | undefined = undefined;

  if (!walkthroughRemoteConfig || !userActionAnalytics || !screenWalkthroughRemoteConfig)
    return { walkthroughData, ruleId, userActionToLog, analyticsEventToLog };

  // sorting by priority
  const rulesOrderedByPriority = Object.entries(screenWalkthroughRemoteConfig.rules).sort(
    (rule1, rule2) => rule1[1].priority - rule2[1].priority
  );

  for (const [ruleId, ruleData] of rulesOrderedByPriority) {
    const ruleConfig = screenWalkthroughRemoteConfig.rules[ruleId];
    const userScreenRuleData = userActionAnalytics.screens?.[screen]?.[ruleId];

    if (
      !isRuleMaxFired(userScreenRuleData?.count, ruleConfig.maxFires) &&
      isRuleSpanCompleted(userScreenRuleData?.lastFiredTimeStamp, ruleConfig.spanDays) &&
      isRuleConditionsSatisfied(ruleConfig.if, userActionsData)
    ) {
      const event = ruleData.then.event || "";
      const walkthroughId = ruleData.then.walkthrough;

      if (event) {
        const userAction = indexUserActionToConfig.get(event);
        if (userAction) {
          const userActionEvent = userActionEvents[userAction];
          if (userActionEvent) userActionToLog = userActionEvent;
        } else {
          analyticsEventToLog = event;
        }
      }

      // returning walkthrough data and rule id
      if (walkthroughId) {
        const walkthroughData = walkthroughRemoteConfig[screen].walkthroughs?.[walkthroughId] || [];
        return { walkthroughData, ruleId, userActionToLog, analyticsEventToLog };
      }
    }
  }

  return { walkthroughData, ruleId, userActionToLog, analyticsEventToLog };
};

export const getUserActionToShow = (
  userActionAnalytics: IUserAnalytics,
  userActionsRemoteConfig: IUserActionRemoteConfig,
  indexUserActionConditionTrueToUserActions: Map<string, IUserActionRemoteConfig>,
  indexUserActionConditionFalseToUserActions: Map<string, IUserActionRemoteConfig>,
  indexUserActionToRemoteConfig: Map<string, IUserActionRemoteConfigData>,
  platform: string,
  userActions?: Array<string>
) => {
  try {
    const userActionsData = userActionAnalytics?.platform?.[platform]?.actions;
    let userActionsToShowAsPopup: { [userAction: string]: IUserActionRemoteConfigData } = {};

    if (userActions && userActions.length > 0) {
      userActions.forEach((userAction) => {
        if (!userActionsRemoteConfig?.[userAction]) return;
        if (userActionsData) {
          if (
            Boolean(userActionsData[userAction]?.doNotShowAgain) ||
            !isSpanDaysCompleted(
              userActionsData,
              userAction,
              userActionsRemoteConfig[userAction].spanDays
            )
          )
            return;
          if (!isEmpty(userActionsRemoteConfig[userAction].conditions)) {
            let allUserActionTakenByUser = true;
            for (const [condition, value] of Object.entries(
              userActionsRemoteConfig[userAction].conditions
            )) {
              if (Boolean(userActionsData[condition]?.lastActionTakenOn) !== value) {
                allUserActionTakenByUser = false;
                break;
              }
            }
            if (allUserActionTakenByUser)
              userActionsToShowAsPopup = {
                ...userActionsToShowAsPopup,
                ...{ [userAction]: userActionsRemoteConfig[userAction] },
              };
          } else {
            const userActionData = indexUserActionToRemoteConfig.get(userAction);
            if (userActionData) {
              userActionsToShowAsPopup = {
                ...userActionsToShowAsPopup,
                ...{ [userAction]: userActionData },
              };
            }
          }
        } else {
          userActionsToShowAsPopup = {
            ...userActionsToShowAsPopup,
            ...{ [userAction]: userActionsRemoteConfig[userAction] },
          };
        }
      });
    } else {
      if (indexUserActionToRemoteConfig) {
        userActionsToShowAsPopup = { ...Object.fromEntries(indexUserActionToRemoteConfig) };
      }

      // checking user action conditions for true
      if (indexUserActionConditionTrueToUserActions?.size && !isEmpty(userActionsData)) {
        for (const [condition, data] of indexUserActionConditionTrueToUserActions) {
          if (Boolean(userActionsData[condition]?.lastActionTakenOn)) {
            userActionsToShowAsPopup = { ...userActionsToShowAsPopup, ...data };
          }
        }
      }

      // checking user action conditions for false
      if (indexUserActionConditionFalseToUserActions?.size && !isEmpty(userActionsData)) {
        for (const [condition, data] of indexUserActionConditionFalseToUserActions) {
          if (!Boolean(userActionsData[condition]?.lastActionTakenOn)) {
            userActionsToShowAsPopup = { ...userActionsToShowAsPopup, ...data };
          }
        }
      }

      // checking do not show again and span days
      for (const userAction in userActionsToShowAsPopup) {
        if (
          Boolean(userActionsData?.[userAction]?.doNotShowAgain) ||
          !isSpanDaysCompleted(
            userActionsData,
            userAction,
            userActionsRemoteConfig?.[userAction]?.spanDays
          )
        ) {
          delete userActionsToShowAsPopup[userAction];
        }
      }
    }

    // removing user actions that are already taken by user
    for (const condition in userActionsToShowAsPopup) {
      if (Boolean(userActionsData?.[condition]?.lastActionTakenOn)) {
        delete userActionsToShowAsPopup[condition];
      }
    }

    //sorting by priority
    const sortedUserActionsByPriority = sortBy(
      Object.entries(userActionsToShowAsPopup),
      (userAction: any) => {
        return userAction[1].priority;
      }
    );

    if (sortedUserActionsByPriority.length) {
      return sortedUserActionsByPriority[0][0];
    }
    return "";
  } catch (error) {
    console.log("error", error);
    return "";
  }
};

const isSpanDaysCompleted = (
  userActionsData: { [actionId: string]: IUserAction },
  action: string,
  spanDays?: number
) => {
  if (!spanDays || !userActionsData) return true;
  if (userActionsData[action]?.lastNotificationShownOn) {
    const diffInTime =
      new Date().getTime() - +new Date(userActionsData[action].lastNotificationShownOn);
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return diffInDays >= spanDays;
  }
  return true;
};

const isRuleSpanCompleted = (userScreenRuleTimeStamp?: number, span?: number) => {
  if (!userScreenRuleTimeStamp || !span) return true;
  const diffInTime = new Date().getTime() - userScreenRuleTimeStamp;
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return diffInDays >= span;
};

const isRuleMaxFired = (userScreenRuleCount?: number, maxFires?: number) => {
  if (!userScreenRuleCount) return false;
  if (!maxFires) {
    return userScreenRuleCount >= 1;
  }
  return userScreenRuleCount >= maxFires;
};

const isRuleConditionsSatisfied = (
  ruleConditions: { [condition: string]: boolean },
  userActionsData?: { [userAction: string]: IUserAction }
) => {
  if (!userActionsData) return false;
  for (const condition in ruleConditions) {
    if (ruleConditions[condition] !== Boolean(userActionsData[condition]?.lastActionTakenOn))
      return false;
  }
  return true;
};

export const getActionHelpWalkthroughData = (
  action: string,
  screen: string,
  walkthroughRemoteConfig: IScreenWalkthroughRemoteConfig
) => {
  const screenWalkthroughRemoteConfig = walkthroughRemoteConfig[screen];
  if (!screenWalkthroughRemoteConfig) return [];
  const actionWalkthroughId = screenWalkthroughRemoteConfig.onScreenHelp?.[action] || "";
  if (actionWalkthroughId)
    return screenWalkthroughRemoteConfig.walkthroughs[actionWalkthroughId] || [];
  return [];
};

export const getScreenWalkthroughDataById = (
  screen: string,
  walkthroughId: string,
  walkthroughRemoteConfig: IScreenWalkthroughRemoteConfig
) => {
  const screenWalkthroughRemoteConfig = walkthroughRemoteConfig[screen];
  if (!screenWalkthroughRemoteConfig) return [];
  return screenWalkthroughRemoteConfig.walkthroughs[walkthroughId] || [];
};

export const checkSubscriptionTopicsToShowConfetti = (userActionAnalytics: IUserAnalytics) => {
  if (userActionAnalytics?.subscriptionTopics) {
    for (const [topic, data] of Object.entries(userActionAnalytics.subscriptionTopics)) {
      if (!data.subscribed && !data.shownConfetti) return topic;
    }
  }
  return "";
};

export const getOriginalImageName = (imageName: string) => {
  let originalImageName = imageName;
  const index = originalImageName.lastIndexOf(".");
  if (index !== -1) originalImageName = replaceChar(imageName, "-", index);
  return originalImageName;
};
