import { AppState } from "../reducers/root.reducer";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { IEvent } from "common-library/lib/schema";

//#region from reducer
const _getEventsMap = (state: AppState): Map<string, IEvent> => state.Event.eventsMap;
//#endregion

//#region Memoized Selector
export const getEventsMap = createDraftSafeSelector(_getEventsMap, payload => payload);
//#endregion

//#region from reducer
const _getIndexFindToEvents = (state: AppState): Map<string, Set<string>> => state.Event.indexFindToEvents;
//#endregion

//#region Memoized Selector
export const getIndexFindToEvents = createDraftSafeSelector(_getIndexFindToEvents, payload => payload);
//#endregion
