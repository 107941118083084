import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import commonSaga from "./commonSaga";
import groupSaga from "./groupSaga";
import findSaga from "./findSaga";
import brandSaga from "./brandSaga";
import vendorSaga from "./vendorSaga";
import eventSaga from "./eventSaga";
import tagSaga from "./tagSaga";
import mediaSaga from "./mediaSaga";
import commentSaga from "./commentSaga";
import activitySaga from "./activitySaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    commonSaga(),
    groupSaga(),
    findSaga(),
    tagSaga(),
    brandSaga(),
    vendorSaga(),
    eventSaga(),
    mediaSaga(),
    activitySaga(),
    commentSaga()
  ]);
}
