import firestore, { firebaseFunctions } from "../../config/firebaseApp";
import { collection, query, where, collectionGroup, orderBy, onSnapshot, limit, DocumentData, DocumentChange, Timestamp } from "firebase/firestore";
import { IMap } from "common-library";
import { EntityType, IActiveContext } from "../types/types";
import { FirestoreCollectionEnum } from "shared-web/lib/constants/firebaseEnums";
import { IDocumentSecured, IDocument, LocalTimeStampValue } from "common-library/lib/schema";
import { buffers, eventChannel, EventChannel } from "redux-saga";
import { queueForDeletion, persistData, insertBulkIntoPrefDb, FindzPrefTables, mediaDatabase, prefDatabase, getById } from "../utils/indexdb";
import { database } from "../utils/indexdb";
import { IChanges, IQuery } from "gsdb/dist/interfaces";
import { get, transform } from "lodash";
import { httpsCallable } from "firebase/functions";
import { FirebaseFunctions } from "common-library/lib/constants";
import { parseHtmlTextResponse } from "common-library/lib/utils";
import { getQueryFromMruList } from "../utils/commonUtils";
import { Location } from "react-router-dom";
import { firestoreGetDoc, queryFirebaseDocs } from "../utils/firestoreUtils";

export interface IQueryBuilderType {
  key: string;
  condition: any;
  value: boolean | string | string[];
}

export interface IndexedDbListenerResponse {
  data: IChanges;
  event: "query" | "onChange";
}

export interface IDirection {
  key: string;
  direction: "asc" | "desc" | undefined;
}

//TODO: Unsubscribe on Close Account or Logout
export const dbListenerUnsubscribers: IMap<{
  channel?: EventChannel<any>;
  snapshotUnsubscribe?: () => void;
}> = {};

export const indexdbListenerUnsubscribers: IMap<{
  channel?: EventChannel<any>;
  snapshotUnsubscribe?: () => void;
}> = {};

export async function clearIndexedDbAndCaches() {
  await database.dropDatabase();
  await mediaDatabase.dropDatabase();
  await prefDatabase.dropDatabase();
  await caches.delete("findz-media");
}

export function queryBuilder(whereClauseParams: IQueryBuilderType[], collectionName: string, isCollectionGroup?: boolean, _orderBy?: IDirection[]) {
  const clauseParms = [...whereClauseParams];
  const firestoreRef = collectionName && !isCollectionGroup ? collection(firestore, collectionName) : collectionGroup(firestore, collectionName);
  if (!firestoreRef) {
    throw new Error();
  }
  let _query = firestoreRef;
  if (clauseParms.length) {
    _query = query(_query, where(clauseParms[0].key, clauseParms[0].condition, clauseParms[0].value));
    for (const queryParam of clauseParms.splice(1, clauseParms.length)) {
      _query = query(_query, where(queryParam.key, queryParam.condition, queryParam.value));
    }
  }
  if (_orderBy && _orderBy.length) {
    for (const order of _orderBy) {
      _query = query(_query, orderBy(order.key, order.direction));
    }
  }
  return _query;
}

export const listenToFirebase = (
  whereClause: IQueryBuilderType[],
  collectionType: FirestoreCollectionEnum,
  timeStampValue: Date | undefined,
  isCollectionGroup?: boolean,
  orderBy?: IDirection[]
): EventChannel<any> | unknown => {
  const unsubscribeDBListener = dbListenerUnsubscribers[collectionType]?.snapshotUnsubscribe;
  if (unsubscribeDBListener) {
    unsubscribeDBListener();
  }
  dbListenerUnsubscribers[collectionType] = {};

  const lastSyncedServerTimestamp = timeStampValue ? timeStampValue : new Date(1);
  const _orderBy: IDirection[] = orderBy ? orderBy : [{ key: "_serverDocumentUpdatedOn", direction: "desc" }];

  const listener = eventChannel<any>(emit => {
    const thresholdTimestamp = new Date(lastSyncedServerTimestamp.valueOf() - 5 * 60000);
    const builtQuery = queryBuilder(whereClause, collectionType, isCollectionGroup, _orderBy);
    const _query = query(builtQuery, where("_serverDocumentUpdatedOn", ">", thresholdTimestamp));
    const unsubscribeDBSnapshot = onSnapshot(_query, { includeMetadataChanges: false }, querySnapshot => {
      emit({ changes: querySnapshot.docChanges() });
    });
    dbListenerUnsubscribers[collectionType].snapshotUnsubscribe = unsubscribeDBSnapshot;
    return () => unsubscribeDBSnapshot();
  }, buffers.expanding(10));

  return listener;
};

export const getListenerToFetchData = (
  activeContext: IActiveContext,
  collectionType: FirestoreCollectionEnum,
  timestampMap: Map<string, LocalTimeStampValue>
) => {
  const timeStampForCurrentGroup = timestampMap.get(activeContext.identifier);
  const timeStamp = timeStampForCurrentGroup ? timeStampForCurrentGroup[collectionType]?.timeStampValue : undefined;
  const timeStampValue = timeStamp ? new Date(timeStamp) : undefined;
  const whereClause = getWhereClauseFromActiveContext(activeContext);
  const listener = listenToFirebase(whereClause, collectionType, timeStampValue);
  return { listener, timeStampValue };
};

export const transformFSDatesToJSDates = (data: any) => {
  return transform(data, (accum: any, value: any, key) => {
    if (value && ["object", "array"].includes(typeof value)) {
      if (value instanceof Timestamp) {
        accum[key] = value.toDate();
      } else if (value instanceof Date) {
        accum[key] = value;
      } else {
        accum[key] = transformFSDatesToJSDates(value);
      }
    } else {
      accum[key] = value;
    }
  });
};

export function getDataFromChanges<T>(
  changes: DocumentChange<DocumentData>[],
  timestamp: any
): { dataAdded: T[]; dataModified: T[]; dataDeleted: T[]; latestTimeStamp: any } {
  const dataAdded: T[] = [];
  const dataModified: T[] = [];
  const dataDeleted: T[] = [];

  let latestTimeStamp = timestamp || new Date(1);

  for (const change of changes) {
    const doc = change.doc;
    const data = transformFSDatesToJSDates(doc.data());

    if (!!data._documentDeletedOn) {
      dataDeleted.push(data as T);
    } else {
      if (change.type === "modified") {
        dataModified.push(data as T);
      } else if (change.type === "added") {
        dataAdded.push(data as T);
      } else if (change.type === "removed") {
        dataDeleted.push(data as T);
      }
    }

    latestTimeStamp = new Date(Math.max(latestTimeStamp.valueOf(), data?._serverDocumentUpdatedOn?.valueOf() || data._documentUpdatedOn.valueOf()));
  }
  return { dataAdded, dataModified, dataDeleted, latestTimeStamp };
}

export async function syncWithIndexDB(
  batch: any,
  collectionType: FirestoreCollectionEnum,
  dataToPersist: Array<IDocumentSecured | IDocument>,
  dataToDelete: Array<IDocumentSecured | IDocument>
) {
  await persistData(collectionType, dataToPersist as EntityType[]);
  for (const data of dataToDelete) {
    if (collectionType === FirestoreCollectionEnum.Groups && data) {
      await queueForDeletion(batch, collectionType, data.identifier || "");
    } else if (data && !!data._documentDeletedOn) {
      await queueForDeletion(batch, collectionType, data.identifier || "");
    }
  }
  await batch.commit();
}

export const getWhereClauseFromActiveContext = (activeContext: IActiveContext) => {
  const whereClause = [
    { key: "owner.identifier", condition: "==", value: activeContext.identifier },
    { key: "owner.type", condition: "==", value: activeContext.type }
  ];
  return whereClause;
};

export async function listenToIndexedDb(collectionType: FirestoreCollectionEnum, query: IQuery) {
  const unsubscribeIndexedDBListener = indexdbListenerUnsubscribers[collectionType]?.snapshotUnsubscribe;
  if (unsubscribeIndexedDBListener) {
    unsubscribeIndexedDBListener();
  }
  indexdbListenerUnsubscribers[collectionType] = {};

  let unsubscribeDBSnapshot: () => void;

  const unsubscribe = () => {
    if (unsubscribeDBSnapshot) {
      unsubscribeDBSnapshot();
    }
  };

  const listener = eventChannel<any>(emit => {
    (async () => {
      const dataFromIndexDb = await database.collection(collectionType).query(query).get();
      emit({
        data: {
          add: dataFromIndexDb
        },
        event: "query"
      });

      database
        .collection(collectionType)
        .onChange((obj: IChanges) => {
          if (obj.add.length || obj.remove.length || obj.update.length) {
            emit({ data: obj, event: "onChange" });
          }
        })
        .then((_subscription: any) => {
          unsubscribeDBSnapshot = _subscription;
          indexdbListenerUnsubscribers[collectionType].snapshotUnsubscribe = _subscription;
        })
        .catch(err => {
          console.error("Error in getting data", err);
        });
    })();

    return () => unsubscribe();
  }, buffers.expanding(10));

  indexdbListenerUnsubscribers[collectionType].channel = listener;
  return listener;
}

export async function listenMRUData() {
  const query = getQueryFromMruList();
  const unsubscribeIndexedDBListener = indexdbListenerUnsubscribers[`${FindzPrefTables.Common}_mruData`]?.snapshotUnsubscribe;
  if (unsubscribeIndexedDBListener) {
    unsubscribeIndexedDBListener();
  }
  indexdbListenerUnsubscribers[`${FindzPrefTables.Common}_mruData`] = {};

  let unsubscribeDBSnapshot: () => void;

  const unsubscribe = () => {
    if (unsubscribeDBSnapshot) {
      unsubscribeDBSnapshot();
    }
  };

  const listener = eventChannel<any>(emit => {
    (async () => {
      const dataFromIndexDb = await prefDatabase.collection(FindzPrefTables.Common).query(query).get();

      emit({
        data: dataFromIndexDb,
        event: "query"
      });

      prefDatabase
        .collection(FindzPrefTables.Common)
        .onChange((obj: IChanges) => {
          if (obj.add.length) {
            const mruData = obj.add.find(item => item.key === "mruData");
            if (mruData) {
              emit({ data: [mruData], event: "onChange" });
            }
          } else if (obj.update.length) {
            const mruData = obj.update.find(item => item.key === "mruData");
            if (mruData) {
              emit({ data: [mruData], event: "onChange" });
            }
          }
        })
        .then((_subscription: any) => {
          unsubscribeDBSnapshot = _subscription;
          indexdbListenerUnsubscribers[`${FindzPrefTables.Common}_mruData`].snapshotUnsubscribe = _subscription;
        })
        .catch(err => {
          console.error("Error in getting data", err);
        });
    })();

    return () => unsubscribe();
  }, buffers.expanding(10));

  indexdbListenerUnsubscribers[`${FindzPrefTables.Common}_mruData`].channel = listener;
  return listener;
}

export const filterChangeData = (query: IQuery, data: any) => {
  const filteredData = {
    add: [] as any[],
    remove: [] as any[],
    update: [] as any[]
  } as any;

  // TODO: 12-05-2023 Optimize below code as the query requirement changes
  Object.keys(data).forEach((dataKey: string) => {
    if (data[`${dataKey}`] && data[`${dataKey}`].length) {
      data[`${dataKey}`].forEach((obj: any) => {
        let shouldAdd = false;
        if (query.query) {
          const keys = Object.keys(query.query);

          if (keys.length) {
            let andQueryResult: boolean[] = [];
            keys.forEach(key => {
              const value = get(obj, key);
              if (query.query) {
                if (value?.constructor === String && query.query[key].$eq === value) {
                  andQueryResult.push(true);
                } else if (value?.constructor === Array && value.includes(query.query[key].$eq)) {
                  andQueryResult.push(true);
                } else {
                  andQueryResult.push(false);
                }
              }
            });
            shouldAdd = andQueryResult.every(x => x);
          }
        } else if (query.$or) {
          const keys = Object.keys(query.$or);

          if (keys.length) {
            let orQueryResult: boolean[] = [];
            keys.forEach(key => {
              const value = get(obj, key);
              if (query.$or) {
                if (value?.constructor === String && query.$or[key].$eq === value) {
                  orQueryResult.push(true);
                } else if (value?.constructor === Array && value.includes(query.$or[key].$eq)) {
                  orQueryResult.push(true);
                } else {
                  orQueryResult.push(false);
                }
              }
            });
            shouldAdd = orQueryResult.some(x => x);
          }
        }

        if (shouldAdd) {
          filteredData[`${dataKey}`].push(obj);
        }
      });
    }
  });

  return filteredData as IChanges;
};

export async function urlToFile(url: string) {
  return fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], "static_map_image.png", {
        type: "image/png"
      });
      return file;
    });
}

export async function getHtmlPageData(webUrl: string) {
  const operation = httpsCallable(firebaseFunctions, FirebaseFunctions.GetHtmlPage);
  const response = await operation({ webUrl });
  return response;
}

export async function parseHtmlData(webUrl: string) {
  let newWebUrl = "";
  if (webUrl.startsWith("https://") || webUrl.startsWith("http://")) {
    newWebUrl = webUrl;
  } else {
    newWebUrl = "https://" + webUrl;
  }
  const response = await getHtmlPageData(newWebUrl);
  if (response.data) {
    const parsedHtmlData: {
      url: string;
      title?: string;
      siteName?: any;
      description?: any;
      defaultImage: any;
      images: string[];
      favicons?: any;
      location?: {
        latitude: number;
        longitude: number;
      };
      price?: {
        price: number;
        currency: any;
      };
    } = parseHtmlTextResponse(response.data as any, webUrl, webUrl);

    return parsedHtmlData;
  }
}

export async function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      error => {
        reject(error);
      }
    );
  });
}

export async function putUrlStateToPref(location: Location) {
  return await insertBulkIntoPrefDb(FindzPrefTables.RouteState, [location]);
}

export async function getDocumentData(collection: FirestoreCollectionEnum, documentId: string, from: "IndexedDb" | "Sever") {
  if (from === "Sever") {
    const docs = await firestoreGetDoc(collection, documentId);
    const docData: any = docs.data();
    return docData;
  } else {
    const data = await getById(collection, documentId);
    return data;
  }
}

export const blobToBase64 = async (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const getDataForContext = async (collection: FirestoreCollectionEnum, activeContext: IActiveContext) => {
  let documents: any[] = [];
  const docs = await queryFirebaseDocs(collection, [
    where("owner.identifier", "==", activeContext.identifier),
    where("owner.type", "==", activeContext.type)
  ]);
  docs.docs.forEach(doc => {
    const data = doc.data();
    if (data._documentDeletedOn) {
      return;
    }
    documents.push(data);
  });
  return documents;
};

export const commonServices = {
  getDocumentData,
  blobToBase64,
  getDataForContext
};
