export var FirestoreCollectionEnum;
(function (FirestoreCollectionEnum) {
    FirestoreCollectionEnum["Tags"] = "tags";
    FirestoreCollectionEnum["Findz"] = "findz";
    FirestoreCollectionEnum["Vendors"] = "vendors";
    FirestoreCollectionEnum["Users"] = "users";
    FirestoreCollectionEnum["Groups"] = "groups";
    FirestoreCollectionEnum["Invitations"] = "invitations";
    FirestoreCollectionEnum["Activities"] = "activities";
    FirestoreCollectionEnum["Comments"] = "comments";
    FirestoreCollectionEnum["Brands"] = "brands";
    FirestoreCollectionEnum["PendingUser"] = "pendingUser";
    FirestoreCollectionEnum["PublicUserInfo"] = "publicUserInfo";
    FirestoreCollectionEnum["PushNotificationRequests"] = "pushNotificationRequests";
    FirestoreCollectionEnum["Events"] = "events";
    FirestoreCollectionEnum["FindzMedia"] = "findz-media";
    FirestoreCollectionEnum["ContentReport"] = "contentReports";
})(FirestoreCollectionEnum || (FirestoreCollectionEnum = {}));
export var FirebaseFunctions;
(function (FirebaseFunctions) {
    FirebaseFunctions["SendOtp"] = "sendOtp";
    FirebaseFunctions["VerifyOtp"] = "verifyOtp";
    FirebaseFunctions["RespondToInvitation"] = "respondToInvitation";
    FirebaseFunctions["ImageDownloader"] = "imageDownloader";
    FirebaseFunctions["AddUserToGroup"] = "addUserToGroup";
    FirebaseFunctions["DeleteGroup"] = "deleteGroup";
    FirebaseFunctions["RemoveUserFromGroup"] = "removeUserFromGroup";
    FirebaseFunctions["NotificationSubscription"] = "notificationSubscription";
    FirebaseFunctions["CloseAccount"] = "closeAccount";
    FirebaseFunctions["reverseCoords"] = "reverseCoords";
    FirebaseFunctions["searchLocation"] = "searchLocation";
    FirebaseFunctions["autoCompleteLocation"] = "autoCompleteLocation";
    FirebaseFunctions["getStaticMap"] = "getStaticMap";
    FirebaseFunctions["SendEmail"] = "sendEmail";
    FirebaseFunctions["GenerateFirebaseCustomToken"] = "generateFirebaseCustomToken";
})(FirebaseFunctions || (FirebaseFunctions = {}));
// Firebase potential Auth error codes - https://firebase.google.com/docs/reference/js/auth.md#autherrorcodes
export var FirebaseAuthErrorCodes;
(function (FirebaseAuthErrorCodes) {
    FirebaseAuthErrorCodes["WRONG_PASSWORD"] = "auth/wrong-password";
    FirebaseAuthErrorCodes["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    FirebaseAuthErrorCodes["USER_NOT_FOUND"] = "auth/user-not-found";
    FirebaseAuthErrorCodes["TOO_MANY_REQUESTS"] = "auth/too-many-requests";
})(FirebaseAuthErrorCodes || (FirebaseAuthErrorCodes = {}));
// https://firebase.google.com/docs/reference/js/auth.md#signinmethod
export var FirebaseSignInMethod;
(function (FirebaseSignInMethod) {
    FirebaseSignInMethod["EMAIL_PASSWORD"] = "password";
    FirebaseSignInMethod["GOOGLE"] = "google.com";
    FirebaseSignInMethod["APPLE"] = "apple.com";
})(FirebaseSignInMethod || (FirebaseSignInMethod = {}));
// https://firebase.google.com/docs/reference/js/auth.md#providerid
export var FirebaseProviderId;
(function (FirebaseProviderId) {
    FirebaseProviderId["GOOGLE"] = "google.com";
    FirebaseProviderId["APPLE"] = "apple.com";
    FirebaseProviderId["PASSWORD"] = "password";
})(FirebaseProviderId || (FirebaseProviderId = {}));
