export enum NavigationPath {
  HomeContainer = "/",
  ValidateUser = "/validate-user",
  AuthContainer = "/signin", // TODO - change sign-in route name from 'signin' to 'sign-in' in PWA and extension
  EmailLoginContainer = "/email-login",
  CreateAccountContainer = "/create-account",
  ResetPasswordContainer = "/reset-password",
  PhoneNumberContainer = "/phone-number",
  PhoneVerificationContainer = "/phone-verification",
  ProfileCompletionContainer = "/profile-completion",
  ExtensionLoginSuccessContainer = "/extension-login-success",
  UnAuthorized = "/unauthorized",
  FindzContainer = "/finds",
  MessagesContainer = "/messages",
  ForwardedMessagesContainer = "/forwarded-messages",
  TagsContainer = "/tags",
  ShareContainer = "/_share-target",
  GroupContainer = "/groups",
  ProfileContainer = "/info",
  blockedUsers = "/blocked-users",
  MoreContainer = "/more",
  UserStatsContainer = "/user-stats",
  VendorContainer = "/vendors",
  MediaDetails = "/media-details",
  addNewGroup = "/add-group",
  FindMultiSelectContainer = "/multi-select",
  AddFindsToCollection = "/add-finds-to-collection",
  FindzMapContainer = "/map-view"
}

export enum HomeContainerNavigationPath {
  FindzContainer = "finds",
  MessagesContainer = "messages",
  EventsContainer = "events",
  GroupContainer = "groups",
  UnAuthorizedContainer = "unauthorized"
}
