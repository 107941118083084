import { ActivityType } from "common-library/lib/schema";
import { actionCreator } from "../helper";

enum ActivityActionTypes {
  FETCH_LATEST_ACTIVITIES = "ACTIVITIES/FETCH",
  FETCH_LATEST_USER_ACTIVITIES = "ACTIVITIES/FETCH/USER",
  FETCH_LATEST_GROUP_ACTIVITIES = "ACTIVITIES/FETCH/GROUP",
  FETCH_LATEST_ACTIVITIES_FROM_INDEXEDDB = "ACTIVITIES/INDEXEDDB/FETCH",
  SET_ACTIVITIES_MAP = "ACTIVITIES/SET_MAP",
  SET_INDEX_FIND_TO_ACTIVITIES = "ACTIVITIES/SET_INDEX_FIND_TO_ACTIVITIES"
}

export const fetchLatestGroupActivities = actionCreator(ActivityActionTypes.FETCH_LATEST_ACTIVITIES);

export const fetchLatestUserActivities = actionCreator(ActivityActionTypes.FETCH_LATEST_USER_ACTIVITIES);

export const fetchLatestActivitiesFromIndexedDb = actionCreator(ActivityActionTypes.FETCH_LATEST_ACTIVITIES_FROM_INDEXEDDB);

export const setActivitiesMap = actionCreator<{ activitiesMap: Map<string, ActivityType> }>(ActivityActionTypes.SET_ACTIVITIES_MAP);

export const setIndexFindToActivities = actionCreator<{ indexFindToActivities: Map<string, Set<string>> }>(
  ActivityActionTypes.SET_INDEX_FIND_TO_ACTIVITIES
);
