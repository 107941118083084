import { CommentType } from "common-library/lib/schema";
import { actionCreator, asyncActionCreator } from "../helper";

enum CommentActionTypes {
  FETCH_LATEST_COMMENTS = "COMMENTS/FETCH",
  FETCH_LATEST_COMMENTS_FROM_INDEXEDDB = "COMMENTS/INDEXEDDB/FETCH",
  SET_COMMENTS_MAP = "COMMENTS/SET_MAP",
  SET_INDEX_FIND_TO_COMMENT = "COMMENTS/SET_INDEX_FIND_TO_COMMENT",
  CREATE_COMMENT = "COMMENTS/CREATE_COMMENT",
  EDIT_COMMENT = "COMMENTS/EDIT_COMMENT",
  REPLY_COMMENT = "COMMENTS/REPLY_COMMENT",
  UPDATE_COMMENT = "UPDATE/UPDATE_COMMENT"
}

export const fetchLatestComments = actionCreator(CommentActionTypes.FETCH_LATEST_COMMENTS);

export const fetchLatestCommentsFromIndexedDb = actionCreator(CommentActionTypes.FETCH_LATEST_COMMENTS_FROM_INDEXEDDB);

export const setCommentsMap = actionCreator<{ commentsMap: Map<string, CommentType> }>(CommentActionTypes.SET_COMMENTS_MAP);

export const setIndexFindToComment = actionCreator<{ indexFindToComment: Map<string, Set<string>> }>(CommentActionTypes.SET_INDEX_FIND_TO_COMMENT);

export const createComment = asyncActionCreator<
  null,
  {
    commentText?: string;
    mentions?: { name: string; identifier: string }[];
    findzId?: string | string[];
    eventId?: string;
    tagId?: string;
    repliedTo?: string;
    reaction?: string;
  }
>(CommentActionTypes.CREATE_COMMENT);

export const editComment = actionCreator<{ commentId: string }>(CommentActionTypes.EDIT_COMMENT);

export const replyToComment = actionCreator<{ commentId: string }>(CommentActionTypes.REPLY_COMMENT);

export const updateComment = actionCreator<{
  commentId: string;
  commentDataToUpdate: Partial<CommentType>;
  commentDataToDelete?: Partial<CommentType>;
}>(CommentActionTypes.UPDATE_COMMENT);
