import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const spacing = 4;
const borderRadius = 4;

const theme = {
  status: {
    danger: "#e53e3e"
  },
  palette: {
    mode: "light",
    primary: {
      main: "#4A4A4A",
      on: "#FFFFFF",
      container: "#EADDFF",
      onContainer: "#21005D"
    },
    secondary: {
      main: "#625B71",
      contrastText: "#FF0000",
      on: "#FFFFFF",
      container: "#E8DEF8",
      onContainer: "#1D192B"
    },
    tertiary: {
      main: "",
      on: "",
      container: "",
      onContainer: ""
    },
    error: {
      main: red.A400,
      on: "",
      container: "",
      onContainer: ""
    },
    background: {
      default: "#FFFBFE",
      paper: "#FFFBFE",
      on: ""
    },
    surface: {
      main: "#FFFBFE",
      on: "#1C1B1F",
      onVariant: "#49454F",
      tint: "#6750A4"
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#7B7B7B"
    },
    action: {
      hover: "lightblue"
    },
    shadow: {
      main: "#000000"
    },
    scrim: {
      main: "#000000"
    },
    outline: {
      main: "#79747E",
      variant: "#C4C7C5"
    }
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 12
  },
  shape: {
    borderRadius
  },
  spacing,
  state: {
    hover: {
      stateLayerOpacity: 0.08
    },
    focus: {
      stateLayerOpacity: 0.12
    },
    pressed: {
      stateLayerOpacity: 0.12
    },
    dragged: {
      stateLayerOpacity: 0.16
    }
  },
  elevation: {
    level0: "1px",
    level1: "2px",
    level2: "3px",
    level3: "4px",
    level4: "5px",
    level5: "6px"
  }
};

const themeDark = {
  status: {
    danger: "#e53e3e"
  },
  shape: {
    borderRadius
  },
  spacing,
  palette: {
    mode: "dark",
    primary: {
      main: "#D0BCFF",
      on: "#381E72",
      container: "#4F378B",
      onContainer: "#EADDFF"
    },
    secondary: {
      main: "#CCC2DC",
      on: "#332D41",
      container: "#4A4458",
      onContainer: "#E8DEF8",
      contrastText: "#332D41"
    },
    error: {
      main: red.A400
    },
    action: {
      hover: "lightblue"
    },
    background: {
      default: "#121212",
      paper: "#121212"
    },
    surface: {
      main: "#1C1B1F",
      on: "#E6E1E5",
      onVariant: "#CAC4D0",
      tint: "#D0BCFF"
    },
    text: {
      primary: "#FAFAFA",
      secondary: "#7B7B7B"
    },
    shadow: {
      main: "#000000"
    },
    scrim: {
      main: "#000000"
    },
    outline: {
      main: "#938F99",
      variant: "#444746"
    }
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 12,
    h1: {}
  },
  state: {
    hover: {
      stateLayerOpacity: 0.08
    },
    focus: {
      stateLayerOpacity: 0.12
    },
    pressed: {
      stateLayerOpacity: 0.12
    },
    dragged: {
      stateLayerOpacity: 0.16
    }
  }
};

export default {
  light: theme,
  dark: themeDark
};
